import { useAtom, useAtomValue } from 'jotai';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, Modal, Select, Text } from '@/lib/v2/components';

import { useHandleEvents } from './hooks/useHandleEvents';
import { useMoveActions } from './hooks/useMoveActions';

import { atomTotalActionsSelectedRows } from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomDetailCampaign,
  atomDropdownCampaignList,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleMoveActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ButtonActions } from '@/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/components/ButtonActions';
import { IFormMoveAction } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { ECampaignsTypeOptions } from '@/modules/CampaignsModule/interfaces/Campaigns';

const TRANSLATE_MODAL_DUPLICATE = 'CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS';
const TRANSLATE_MODAL_EDIT = 'CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS';
const TRANSLATE_MODAL_MOVE = 'CAMPAIGN_ACTIONS_MAIN.MOVE_ACTIONS';

const MoveActions = () => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<IFormMoveAction>();
  const { handleCloseModal, handleSubmitData, handleChangeCampaigns, loading } = useHandleEvents();
  const { loadingCampaign, VALIDATION_RULES } = useMoveActions();

  const detailCampaign = useAtomValue(atomDetailCampaign);
  const [toggleMoveActionsModal] = useAtom(atomToggleMoveActionsModal);
  const totalActionsSelectedRows = useAtomValue(atomTotalActionsSelectedRows);
  const dropdownCampaignList = useAtomValue(atomDropdownCampaignList);

  return (
    <Modal open={toggleMoveActionsModal} onClose={handleCloseModal}>
      <div className="flex flex-col gap-2 divide-y">
        <header>
          <Text as="h3" fontWeight="medium" variant="sub-headline">
            {detailCampaign?.campaignTypeId === ECampaignsTypeOptions.MANUAL
              ? t(`${TRANSLATE_MODAL_MOVE}.title`)
              : t(`${TRANSLATE_MODAL_MOVE}.titleAutomatic`)}
          </Text>
        </header>
        <section className="flex w-full flex-col pt-6">
          <form
            autoComplete="off"
            id="createEditCampaigns"
            onSubmit={handleSubmit(handleSubmitData)}
          >
            <Select
              isRequired
              withSearch
              control={control}
              id="campaigns"
              isLoading={loadingCampaign}
              label={
                totalActionsSelectedRows > 1
                  ? t(`${TRANSLATE_MODAL_MOVE}.DROPDOWN.MULTIPLE.label`)
                  : t(`${TRANSLATE_MODAL_MOVE}.DROPDOWN.SINGLE.label`)
              }
              name="campaigns"
              options={dropdownCampaignList}
              placeholder={t(`${TRANSLATE_MODAL_MOVE}.DROPDOWN.placeholder`)}
              rules={VALIDATION_RULES.campaigns}
              onChange={handleChangeCampaigns}
            />
            <div className="mt-4">
              <Alert
                description={
                  detailCampaign?.campaignTypeId === ECampaignsTypeOptions.MANUAL
                    ? t(`${TRANSLATE_MODAL_DUPLICATE}.description`)
                    : t(`${TRANSLATE_MODAL_MOVE}.descriptionAutomatic`)
                }
                type="info"
              />
            </div>
            <ButtonActions
              disabledSubmitButton={loadingCampaign || !isValid}
              isLoading={loading}
              textCancelButton={t(`${TRANSLATE_MODAL_EDIT}.cancelButton`)}
              textSubmitButton={t(`${TRANSLATE_MODAL_EDIT}.successButton`)}
              onClose={handleCloseModal}
            />
          </form>
        </section>
      </div>
    </Modal>
  );
};

export default MoveActions;
