import { ArrowLeftIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { memo, RefObject, useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  IconSvg,
  If,
  Modal,
  Tab,
  TabContent,
  TabItem,
  TabNav,
  TabPane,
  Text,
} from '@/lib/v2/components';
import { TabItemProps } from '@/lib/v2/components/Tab/TabItem';
import { TabPaneProps } from '@/lib/v2/components/Tab/TabPane';

interface ModalActionCreationProps {
  isOpen: boolean;
  title?: string;
  icon?: JSX.Element;
  onClose: () => void;
  onBack?: () => void;
  tabItems: TabItemProps[];
  tabPanes: TabPaneProps[];
  currentTab: string | number;
}

const ModalActionCreation = ({
  isOpen,
  icon,
  title,
  onClose,
  onBack,
  tabItems,
  tabPanes,
  currentTab,
}: ModalActionCreationProps) => {
  const [activeTab, setActiveTab] = useState(currentTab);

  const containerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [tabItems]);

  const handleTabClick = useCallback((tabId: string | number) => {
    setActiveTab(tabId);
  }, []);

  const handleClose = useCallback(() => {
    setActiveTab(1);
    onClose();
  }, [onClose]);

  const handleBack = useCallback(() => {
    setActiveTab(1);
    onBack?.();
  }, [onBack]);

  const handleResize = useCallback(() => {
    if (childrenRef.current && containerRef.current) {
      const containerHeight = containerRef.current.clientHeight - 73 - 32 - 42 - 16;
      const childrenHeight = childrenRef.current.scrollHeight;

      if (childrenHeight > containerHeight) {
        childrenRef.current.style.overflowY = 'scroll';
      } else {
        childrenRef.current.style.overflowY = 'hidden';
      }
    }
  }, []);

  const mountTransitionEnd = useCallback(
    (refContainerModal?: RefObject<HTMLDivElement>) => {
      if (refContainerModal?.current && containerRef?.current) {
        containerRef.current.style.height = `${refContainerModal.current.clientHeight}px`;
      }
      handleResize();
    },
    [handleResize]
  );

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    //TODO: Fix ResizeObserver, that broke the build when you add or reduce zoom
    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleResize, containerRef.current]);

  const containerContentClass = classNames('relative size-full overflow-hidden px-6 py-4', {
    invisible: !isOpen,
  });

  return (
    <Modal
      noPadding
      withAuto
      externalClose={true}
      mountTransitionEnd={mountTransitionEnd}
      open={isOpen}
      onClose={handleClose}
    >
      <div
        ref={containerRef}
        className={'flex h-auto max-h-[95vh] w-[90vw] flex-col md:w-[80vw] 2xl:w-[70vw]'}
      >
        <div className="flex w-full items-center gap-2 border-b px-6 py-4">
          <If condition={!!onBack}>
            <Button
              onlyIcon
              outline
              standard
              className="-ml-2.5"
              id="modal-back-button"
              onClick={handleBack}
            >
              <IconSvg fillColor="gray-dark" svgComponent={<ArrowLeftIcon />} />
            </Button>
          </If>
          {icon}
          <Text fontWeight="medium" variant="sub-headline">
            {title}
          </Text>
        </div>
        <div className={containerContentClass}>
          <Tab currentTab={activeTab}>
            <TabNav>
              {tabItems.map((tab) => {
                const { tabId, onClick, ...restTabProps } = tab;
                return (
                  <TabItem
                    key={tabId}
                    tabId={tabId}
                    onClick={(e) => (handleTabClick(tab.tabId), onClick?.(e))}
                    {...restTabProps}
                  />
                );
              })}
            </TabNav>
            <TabContent
              ref={childrenRef}
              className="visible-scrollbar overflow-y-scroll pr-2 xl:overflow-hidden"
            >
              {tabPanes.map((pane) => {
                const { tabId, children } = pane;
                return (
                  <TabPane key={tabId} tabId={tabId}>
                    {children}
                  </TabPane>
                );
              })}
            </TabContent>
          </Tab>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalActionCreation);
