import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { atomRuleDataRaw } from '@/src/modules/RulesModule/atoms/rules';

import { STATES_PROCESS_ACTION } from '@/modules/CampaignsModule/constants';
import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';

export const useRuleStatus = () => {
  const { actionId } = useParams();

  const rulesDataRaw = useAtomValue(atomRuleDataRaw);

  const { action } = useActionData(Number(actionId));
  const status = Number(action?.status);

  const isRuleActive = useMemo(() => {
    //cspell: disable-next-line
    return rulesDataRaw?.activo ?? false;
  }, [rulesDataRaw]);

  const isRuleReadyForActivate = useMemo(() => {
    return STATES_PROCESS_ACTION.confirmableDraft.includes(status);
  }, [status]);

  const isRuleInactive = useMemo(() => {
    return STATES_PROCESS_ACTION.inactive.includes(status);
  }, [status]);

  return { isRuleActive, isRuleReadyForActivate, isRuleInactive };
};
