import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomActionEmailAddresseesByType,
  atomActionInfoEmail,
  atomEmailAddresseesInfo,
  atomTotalAddressees,
} from '@/src/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomDeliveryInformation } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  EGroupAndListsType,
  EGroupAndListsTypeV1Map,
  IAddresseesByType,
  IAddresseesInfoResponse,
  IEmailInfoPayload,
  IEmailInfoResponse,
  IListAddresseesByType,
  ISetDeliveryActionPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  EActionStatusAvailable,
  EChannelsID,
  IAddresseesByAction,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';
export const useActionEmailData = (actionId: number) => {
  const [loading, setLoading] = useState(false);
  const campaignService = useCampaignsService();
  const serviceV1 = useService();

  const setActionInfoEmail = useSetAtom(atomActionInfoEmail);
  const setActionEmailAddresseesByType = useSetAtom(atomActionEmailAddresseesByType);
  const setEmailAddresseesInfo = useSetAtom(atomEmailAddresseesInfo);
  const setTotalAddressees = useSetAtom(atomTotalAddressees);
  const setDeliveryInformation = useSetAtom(atomDeliveryInformation);

  const saveInfoAction = useCallback(
    async (payload: Partial<IEmailInfoPayload>) => {
      await campaignService.setInfoAction(payload as IEmailInfoPayload, actionId);
    },
    [actionId, campaignService]
  );

  const getEmailInfo = useCallback(async () => {
    setLoading(true);
    const emailInfo = await campaignService.getEmailInfo(actionId);

    const listAddresseesByType =
      addresseesByType(emailInfo?.groupsAndLists as IAddresseesByType[]) || [];

    setActionEmailAddresseesByType(listAddresseesByType as unknown as IListAddresseesByType);
    setActionInfoEmail(emailInfo as IEmailInfoResponse);

    setLoading(false);
  }, [actionId, campaignService]);

  const getAddresseesInfo = useCallback(async () => {
    setLoading(true);
    const addresseesInfo = await campaignService.getAddresseesInfo(actionId);

    setEmailAddresseesInfo(addresseesInfo as IAddresseesInfoResponse);

    setLoading(false);
  }, [actionId, campaignService]);

  const addresseesByType = useCallback((groupsAndLists: IAddresseesByType[]) => {
    return groupsAndLists?.reduce((acc: { [key: string]: IAddresseesByType[] }, item) => {
      const key = EGroupAndListsType[item.type];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }, []);

  // cspell:disable
  //TODO: Remove this when migrate service CantidadDestinatarios to V1
  const addresseesByTypeV1 = useCallback((groupsAndLists: IAddresseesByType[]) => {
    return groupsAndLists?.map((item: IAddresseesByType) => ({
      id: item.id,
      tipo: EGroupAndListsTypeV1Map[item.type].toLowerCase(),
    }));
  }, []);
  // cspell:enable

  // cspell:disable
  const getTotalAddresseesByAction = useCallback(
    async (groupsAndLists: IAddresseesByType[]) => {
      const groups = addresseesByTypeV1(groupsAndLists);
      const payload: IAddresseesByAction = {
        grupos: groups,
        tipoAccion: EChannelsID.EMAIL,
      };

      const totalAddressees = await serviceV1.getTotalAddresseesByAction(payload);
      setTotalAddressees(totalAddressees as number);
    },
    [addresseesByTypeV1, serviceV1]
  );
  // cspell:enable

  const getDeliveryAction = useCallback(async () => {
    setLoading(true);
    const response = await campaignService.getDeliveryAction({ actionId });

    setDeliveryInformation(response || {});

    setLoading(false);
  }, [campaignService, actionId]);

  const saveDeliveryAction = useCallback(
    async (payload: ISetDeliveryActionPayload) => {
      const response = await campaignService.setDeliveryAction(payload);
      return response;
    },
    [campaignService]
  );

  const setActionState = useCallback(
    async ({ actionId, statusId }: { actionId: number; statusId: EActionStatusAvailable }) => {
      const response = await campaignService.setStateAction({ actionId, statusId });
      return response;
    },
    [campaignService]
  );

  const refetchSummary = useCallback(async () => {
    setLoading(true);
    await Promise.all([getEmailInfo(), getAddresseesInfo()]);
    setLoading(false);
  }, [getAddresseesInfo, getEmailInfo]);

  const setConfirmEmail = useCallback(async () => {
    setLoading(true);
    const response = await campaignService.confirmMail(actionId);
    setLoading(false);
    return response;
  }, [actionId, campaignService]);

  const setPauseEmail = useCallback(async () => {
    setLoading(true);
    await campaignService.pauseMail(actionId);
    setLoading(false);
  }, [actionId, campaignService]);

  const getShippingTags = useCallback(async () => {
    setLoading(true);
    const response = await serviceV1.getV1ShippingTags();
    setLoading(false);
    return response.statusOK ? response.tags.rulesTags : [];
  }, [serviceV1]);

  const getCustomField = useCallback(async () => {
    try {
      setLoading(true);
      const response = await serviceV1.getListCustomFields();
      return response;
    } finally {
      setLoading(false);
    }
  }, [serviceV1]);

  const deleteEmailTemplate = useCallback(
    async (actionId: number) => {
      setLoading(true);
      await campaignService.deleteMailContent(actionId);
      setLoading(true);
    },
    [campaignService]
  );

  return {
    saveInfoAction,
    getEmailInfo,
    getAddresseesInfo,
    getTotalAddresseesByAction,
    refetchSummary,
    setConfirmEmail,
    setPauseEmail,
    getDeliveryAction,
    saveDeliveryAction,
    setActionState,
    getShippingTags,
    getCustomField,
    deleteEmailTemplate,
    loading,
    action: [],
  };
};
