import Parser from 'html-react-parser';

import { Button } from '@/lib/components/Button/Button';
import { Card } from '@/lib/components/Card/Card';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import { IStep, Stepper } from './Stepper';

import './Stepper.tailwind.css';

function formatText(text: string): JSX.Element {
  // eslint-disable-next-line regexp/no-unused-capturing-group
  const regex = /\*(.*?)\*/g;
  const matches = text.match(regex);
  let wordClean;
  if (matches) {
    matches.forEach((el) => {
      wordClean = el.replace(/\*/g, '');
      text = text.replace(el, `<strong className="text-emblueBlue-dark">${wordClean}</strong>`);
    });
  }
  return <span>{Parser(text)}</span>;
}

export const StepperCard = ({ currentStep, steps }: { steps: IStep[]; currentStep?: IStep }) => {
  return (
    <Card>
      <div className="-m-4 bg-gradient-to-r from-white to-blue-100">
        <div className="relative overflow-hidden">
          <div className="absolute inset-y-0 right-0 z-0 -mr-32">
            {currentStep && currentStep.image()}
          </div>
          <div className="relative z-10">
            <Card.Body padded>
              <div className="h-64">
                <Flex alignment="center" itemAlignment="center">
                  <div className="w-1/3 pl-8">
                    <Stepper steps={steps} />
                  </div>
                  {currentStep ? (
                    <div className="w-1/3">
                      <Flex column withGap>
                        <p className="whitespace-nowrap text-center text-20 font-medium">
                          {formatText(currentStep.taskTitle)}
                        </p>
                        <p className="p-4 text-center">{formatText(currentStep.taskDescription)}</p>
                        <Button fullWidth onAction={currentStep.onClick}>
                          {currentStep.clickCallToAction}
                        </Button>
                        {/* {currentStep.id !== 1 && (
                          <Button plain onAction={currentStep.onSkip}>
                            {currentStep.skipCallToAction}
                          </Button>
                        )} */}
                      </Flex>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="w-1/3"></div>
                </Flex>
              </div>
            </Card.Body>
          </div>
        </div>
      </div>
    </Card>
  );
};
