import { useTranslation } from 'react-i18next';

import { Select, Text } from '@/lib/v2/components';

import { useTriggerOptions } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useTriggerOptions';

import { ITriggerForm } from '@/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';
import { TRANSLATE_RULES_LABELS } from '@/modules/RulesModule/constants';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface IRepeatedEmailsProps {
  loading?: boolean;
  form: IFormProps<ITriggerForm>;
  disabled?: boolean;
}

const RepeatedEmails = ({ form, disabled = false }: IRepeatedEmailsProps) => {
  const { t } = useTranslation('rules');
  const { repeatEmailsOptions } = useTriggerOptions();

  return (
    <div className="flex w-full flex-col items-start justify-start">
      <Text className="mr-2 whitespace-nowrap" fontWeight="medium" variant="text">
        {t(`${TRANSLATE_RULES_LABELS}.repeatedEmails.title`)}
      </Text>
      <div className="mb-5 w-full flex-col items-center">
        <Text className="mr-2 whitespace-nowrap" variant="text">
          {t(`${TRANSLATE_RULES_LABELS}.repeatedEmails.description`)}
        </Text>
        <Select
          isRequired
          control={form.control}
          disabled={disabled}
          name="repeatEmails"
          options={repeatEmailsOptions}
        />
      </div>
    </div>
  );
};

export default RepeatedEmails;
