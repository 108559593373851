import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomIsActionComplete } from '@/modules/RulesModule/atoms/rules';

const useIsActionContentComplete = () => {
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const setIsActionComplete = useSetAtom(atomIsActionComplete);
  const { emailInfo, content, sender } = actionInfoEmail || {};

  useEffect(() => {
    const isComplete = Boolean(content?.message);
    setIsActionComplete(isComplete);
  }, [content?.message, emailInfo?.subject, sender?.emailFrom, setIsActionComplete]);
};

export default useIsActionContentComplete;
