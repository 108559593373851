import { memo, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, Position } from 'reactflow';

import { Button, IconSvg, If, Text } from '@/lib/v2/components';
import { DeleteIcon as DeleteIconSolid } from '@/lib/v2/icons/solid';

import { TRANSLATE_RULES_CALL_TO_ACTION } from '@/src/modules/RulesModule/constants';

interface Props {
  title: string;
  deletable?: boolean;
  onDelete?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
  icon: ReactNode;
}

const InitialNode = ({ title, deletable = false, onDelete, icon }: Props) => {
  const { t } = useTranslation('rules');

  const handleDeleteNode = async (e: MouseEvent<HTMLButtonElement>) => {
    if (onDelete) {
      await onDelete(e);
    }
  };

  return (
    <>
      <Handle className="handle" isConnectable={false} position={Position.Top} type="target" />
      <div className="mb-2 flex items-center">
        <IconSvg fillColor="primary" height="24px" id="cube" svgComponent={icon} width="24px" />
        <Text className="ml-2 mt-1" fontWeight="semibold" variant="text">
          {title}
        </Text>
        <If condition={deletable}>
          <div className="z-10 ml-auto">
            <Button
              gray
              outline
              id="delete-condition"
              size="small"
              title="Delete condition"
              onClick={handleDeleteNode}
            >
              <IconSvg
                fillColor="gray-dark"
                height="16px"
                svgComponent={<DeleteIconSolid />}
                width="13px"
              />
            </Button>
          </div>
        </If>
      </div>
      <hr className="w-full min-w-64" />
      <Button link className="text-14">
        {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.clickToEdit`)}
      </Button>
      <Handle className="handle" isConnectable={false} position={Position.Bottom} type="source" />
    </>
  );
};

export default memo(InitialNode);
