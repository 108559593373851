import BeePlugin from '@mailupinc/bee-plugin';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  atomBeefreePreview,
  atomBeefreeSaving,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';

import { useSendEmailTest } from './useSendEmailTest';

import { RULES_TYPES_ALLOW } from '@/modules/RulesModule/constants';
import { useRuleData } from '@/modules/RulesModule/hooks';

export const useSendPreview = () => {
  const { actionType, actionId } = useParams();
  const navigate = useNavigate();

  const { sendTestEmail } = useSendEmailTest();
  const { rule, getRuleData } = useRuleData(Number(actionId));

  const [showSendPreview, setShowSendPreview] = useState(false);

  const handleClosePreview = useCallback(() => setShowSendPreview(false), []);

  const allowTrigger = useMemo(
    () => RULES_TYPES_ALLOW.includes(rule?.trigger?.id as string),
    [rule?.trigger?.id]
  );

  useEffect(() => {
    void getRuleData();
  }, []);

  const goToBack = () => {
    const path = actionType !== 'email-trigger' ? '' : '/email';
    let newUrl = window.location.pathname.replace('/editor', '');

    if (actionType !== 'email') {
      if (allowTrigger && !newUrl.endsWith(path)) {
        newUrl += path;
      } else if (!allowTrigger && newUrl.endsWith(path)) {
        newUrl = newUrl.slice(0, -path.length);
      }
    }
    navigate(newUrl);
  };

  const handleOpenPreview = useCallback(() => {
    setShowSendPreview(true);
  }, []);

  return {
    handleOpenPreview,
    handleClosePreview,
    goToBack,
    showSendPreview,
    sendTestEmail,
  };
};

interface IUseMergeTags {
  beefree?: BeePlugin;
}

export const useMergeTags = ({ beefree }: IUseMergeTags) => {
  const [showMergeTags, setShowMergeTags] = useState(false);

  const toggleMergeTags = useCallback(() => {
    beefree?.toggleMergeTagsPreview();
    setShowMergeTags(!showMergeTags);
  }, [beefree, showMergeTags]);

  return {
    toggleMergeTags,
    showMergeTags,
  };
};

interface IUsePreview {
  beefree?: BeePlugin;
}

export const usePreview = ({ beefree }: IUsePreview) => {
  const [showPreview, setShowPreview] = useAtom(atomBeefreePreview);

  const togglePreview = useCallback(() => {
    beefree?.togglePreview();
    setShowPreview(!showPreview);
  }, [beefree, setShowPreview, showPreview]);

  return {
    togglePreview,
    showPreview,
  };
};

interface IUseStructure {
  beefree?: BeePlugin;
}
export const useStructure = ({ beefree }: IUseStructure) => {
  const [showStructure, setShowStructure] = useState(false);

  const toggleStructure = useCallback(() => {
    beefree?.toggleStructure();
    setShowStructure(!showStructure);
  }, [beefree, showStructure]);

  return {
    showStructure,
    toggleStructure,
  };
};

interface IUseSaveMail {
  beefree?: BeePlugin;
}
export const useEmailSave = ({ beefree }: IUseSaveMail) => {
  const setBeefreeSaving = useSetAtom(atomBeefreeSaving);
  const save = useCallback(() => {
    return new Promise<void>((resolve) => {
      setBeefreeSaving(true);
      localStorage.removeItem('@beefree/template.create');
      beefree?.save();
      resolve();
    });
  }, [beefree, setBeefreeSaving]);
  return {
    save,
  };
};
export const useEmailSaveTemplate = ({ beefree }: IUseSaveMail) => {
  const saveTemplate = useCallback(() => {
    localStorage.setItem('@beefree/template.create', 'true');
    beefree?.saveAsTemplate();
    beefree?.save();
  }, [beefree]);

  return {
    saveTemplate,
  };
};
