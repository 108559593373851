import { useEffect } from 'react';
import { FieldErrors, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { If, SelectExternalSearch, Text } from '@/lib/v2/components';

import {} from '@/src/modules/RulesModule/components/FormCondition/hooks/useFormCondition';

import { useGroupContactsDropdown } from './useGroupContactsDropdown';

import { ITriggerForm } from '@/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';
import { TRANSLATE_RULES_PLACEHOLDERS } from '@/modules/RulesModule/constants';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface IGroupContactProps {
  loading?: boolean;
  form: IFormProps<ITriggerForm>;
  rules?: Record<string, RegisterOptions>;
  disabled?: boolean;
  errors: FieldErrors<ITriggerForm>;
}

const GroupContactsDropdown = ({
  form,
  loading,
  rules,
  disabled = false,
  errors,
}: IGroupContactProps) => {
  const { t } = useTranslation('rules');

  const {
    events: {
      getRecipients,
      handleChangeRecipients,
      handleCloseRecipients,
      handleLoadLastRecipients,
    },
    setters: { setRecipientsSearch },
    state: { recipientListPayload, recipientsOptions, loading: loadingRecipients },
  } = useGroupContactsDropdown({ setValue: form.setValue });

  useEffect(() => void getRecipients(recipientListPayload), []);

  const { control } = form;

  return (
    <>
      <SelectExternalSearch
        isRequired
        control={control}
        disabled={disabled}
        id="create-trigger-group-select"
        isLoading={loading}
        isSearching={loadingRecipients}
        name="groupContacts"
        options={recipientsOptions}
        placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.group`)}
        rules={rules?.recipients}
        searchPlaceholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.searchSelect`)}
        onChange={handleChangeRecipients}
        onChangeSearch={setRecipientsSearch}
        onClose={handleCloseRecipients}
        onLastItem={handleLoadLastRecipients}
      />
      {/* TODO: Pending review if applicable */}
      <If condition={!!errors.groupContacts}>
        <Text color="alert" variant="text-sm">
          {errors?.groupContacts?.message}
        </Text>
      </If>
    </>
  );
};

export default GroupContactsDropdown;
