import { FieldErrors, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select } from '@/lib/v2/components';

import { useConditionOptions } from '@/src/modules/RulesModule/components/FormCondition/hooks/useConditionOptions';
import { IConditionForm } from '@/src/modules/RulesModule/components/FormCondition/hooks/useFormCondition';

import {
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PLACEHOLDERS,
} from '@/modules/RulesModule/constants';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface IPresenceConditionSelectProps {
  loading?: boolean;
  form: IFormProps<IConditionForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
  errors: FieldErrors<IConditionForm>;
}

export const PresenceConditionSelect = ({
  form,
  disabled = false,
  rules,
}: IPresenceConditionSelectProps) => {
  const { t } = useTranslation('rules');
  const { presenceOptions } = useConditionOptions();

  return (
    <div className="mb-6 mr-8">
      <Select
        isRequired
        control={form.control}
        disabled={disabled}
        label={t(`${TRANSLATE_RULES_LABELS}.recipients`)}
        name="presenceCondition"
        options={presenceOptions}
        placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.presenceCondition`)}
        rules={rules.presenceCondition}
      />
    </div>
  );
};
