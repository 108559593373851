import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import { IReportParams } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ActionTypeId,
  ActionTypeName,
  IActionState,
  IGroupedStateOption,
  IOrderByCell,
  IPager,
  IStateOption,
  ITableColumnWidth,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

// eslint-disable-next-line regexp/no-obscure-range
export const REGEX_INPUT = /^[\wÀ-ÖØ-öø-ÿ\s\-,'.!¡¿?()\\"&$%]+$/u;
// eslint-disable-next-line regexp/no-obscure-range
export const REGEX_OTHER_STRATEGY = /^[\dA-Za-zÀ-ÖØ-öø-ÿ ]+$/;
// eslint-disable-next-line regexp/no-obscure-range
export const REGEX_CUSTOM_FIELDS = /\$\{\d+#[\wÀ-ÖØ-öø-ú\s-]+\}/g;

export const STRATEGY_OTHER: DropdownItemProps = {
  id: 10,
  value: 'other',
  label: 'Other',
};

export const CAMPAIGNS_PATH = '/v2/campaigns';

export const TRANSLATE_MODAL_EDIT = 'CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.TOAST_MESSAGES';
export const TRANSLATE_RULES_EMAIL = 'CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.RULES';
export const TRANSLATE_CREATE_EMAIL_ACTION = 'CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION';
export const TRANSLATE_TOAST_CONFIRM_ACTION = `${TRANSLATE_CREATE_EMAIL_ACTION}.TOAST_MESSAGES.CONFIRM_ACTION`;

export const TRANSLATE_CALENDAR = 'CALENDAR';
export const TRANSLATE_CALENDAR_HEADER = `${TRANSLATE_CALENDAR}.HEADER`;
export const TRANSLATE_CALENDAR_SIDEBAR = `${TRANSLATE_CALENDAR}.SIDEBAR`;
export const TRANSLATE_CALENDAR_MAIN = `${TRANSLATE_CALENDAR}.MAIN`;

export const TRANSLATE_SMS_ACTION_FORM = 'CAMPAIGN_ACTIONS_MAIN.SMS_ACTION.FORM_INFO';
export const TRANSLATE_SMS_ACTION_SUMMARY = 'CAMPAIGN_ACTIONS_MAIN.SMS_ACTION.SUMMARY';
export const TRANSLATE_SMS_ACTION_REPORT = 'CAMPAIGN_ACTIONS_MAIN.SMS_ACTION.REPORT';
export const TRANSLATE_SMS_ACTION_DOWNLOAD_REPORT =
  'CAMPAIGN_ACTIONS_MAIN.SMS_ACTION.MODAL_REPORT_SMS_DOWNLOAD';

export const DEFAULT_ORDERBY: IOrderByCell[] = [
  { id: 'name', isAsc: undefined },
  { id: 'lastUpdate', isAsc: false },
  { id: 'duration', isAsc: undefined },
  { id: 'audience', isAsc: undefined },
  { id: 'actions', isAsc: undefined },
];

export const DEFAULT_ORDERBY_RECURRENT_MESSAGE: IOrderByCell[] = [
  { id: 'name', isAsc: undefined },
  { id: 'recurrenceId', isAsc: undefined },
  { id: 'creationDate', isAsc: false },
  { id: 'audience', isAsc: undefined },
  { id: 'executionDate', isAsc: undefined },
];

const DEFAULT_ORDERBY_ACTIONS: IOrderByCell[] = [
  { id: 'name', isAsc: undefined },
  { id: 'creationDate', isAsc: false },
  { id: 'exeDate', isAsc: undefined },
  { id: 'audience', isAsc: undefined },
];

const DRAFT_ORDERBY_ACTIONS: IOrderByCell[] = [
  { id: 'name', isAsc: undefined },
  { id: 'creationDate', isAsc: undefined },
  { id: 'modificationDate', isAsc: false },
];

const FINISHED_ORDERBY_ACTIONS: IOrderByCell[] = [
  { id: 'name', isAsc: undefined },
  { id: 'campaignsName', isAsc: undefined },
  { id: 'exeDate', isAsc: false },
  { id: 'audience', isAsc: undefined },
  { id: 'openingRate', isAsc: undefined },
  { id: 'clickRate', isAsc: undefined },
];

const ALL_ORDERBY_ACTIONS: IOrderByCell[] = [
  { id: 'name', isAsc: undefined },
  { id: 'creationDate', isAsc: false },
  { id: 'exeDate', isAsc: undefined },
  { id: 'audience', isAsc: undefined },
];

export const ORDERBY_ACTIONS: Record<string, IOrderByCell[]> = {
  default: DEFAULT_ORDERBY_ACTIONS,
  draft: DRAFT_ORDERBY_ACTIONS,
  finished: FINISHED_ORDERBY_ACTIONS,
  all: ALL_ORDERBY_ACTIONS,
};

export const DROPDOWN_STATE: IStateOption[] = [
  { id: 1, label: 'active', value: 1 },
  { id: 2, label: 'inactive', value: 0 },
];

export const DROPDOWN_CHANNELS: IStateOption[] = [
  { id: 1, label: 'email', value: 1 },
  { id: 2, label: 'email-trigger', value: 3 },
  { id: 3, label: 'sms', value: 8 },
  { id: 4, label: 'email-attachment', value: 9 },
  { id: 5, label: 'mail-api', value: 4 },
  { id: 6, label: 'sms-api', value: 5 },
];

export const DROPDOWN_ACTIONS_STATE: IStateOption[] = [
  { id: 1, label: 'active', value: 18 },
  { id: 2, label: 'draft', value: 1 },
  { id: 3, label: 'draft-errors', value: 22 },
  { id: 4, label: 'confirmable-draft-errors', value: 23 },
  { id: 5, label: 'confirmable-draft', value: 2 },
  { id: 6, label: 'draft-without-contacts', value: 11 },
  { id: 7, label: 'shipment-canceled', value: 6 },
  { id: 8, label: 'confirmed-shipment', value: 3 },
  { id: 9, label: 'sending', value: 4 },
  { id: 10, label: 'waiting-results', value: 17 },
  { id: 11, label: 'shipment-completed', value: 5 },
  { id: 12, label: 'finished-without-contacts', value: 12 },
  { id: 13, label: 'inactive', value: 19 },
  { id: 14, label: 'incomplete-inactive', value: 20 },
  { id: 15, label: 'processing-shipment', value: 7 },
  { id: 16, label: 'processing-reinforcement', value: 9 },
  { id: 17, label: 'confirmed-reinforcement', value: 8 },
  { id: 18, label: 'processing-split-test', value: 15 },
  { id: 19, label: 'sending-split-test', value: 16 },
];

export const DROPDOWN_ACTIONS_STATE_GROUPED: IGroupedStateOption[] = [
  { id: 1, label: 'draft', values: [1, 2, 11, 23, 22] },
  { id: 2, label: 'in-progress', values: [18, 3, 4, 8, 9, 15, 16, 17, 7, 19, 20] },
  { id: 3, label: 'finished', values: [6, 5, 12] },
];

export const LIST_ACTIONS_STATE: IActionState[] = [
  { type: 'draft', values: [1, 2, 11, 23, 22], badge: 'inactive', background: 'inactive' },
  {
    type: 'active',
    values: [18, 3, 4, 8, 9, 16, 15, 16, 17, 7],
    badge: 'success',
    background: 'success_new',
  },
  { type: 'finished', values: [6, 5, 12], badge: 'frequent', background: 'frequent' },
  { type: 'inactive', values: [19, 20], badge: 'inactive', background: 'inactive' },
];

export const STATES_PROCESS_ACTION = {
  confirmableDraft: [2, 23],
  draft: [1, 11, 22, 20],
  active: [18, 3, 4, 8, 9, 15, 16, 17, 7],
  finished: [6, 5, 12],
  inactive: [19],
};

export const VARIATION_STATUS_BADGE: { [key: string]: string } = {
  draft: 'inactive',
  active: 'success',
  finished: 'frequent',
  inactive: 'inactive',
  default: '',
};

export const MASTER_ACTION = 7;
export const RECURRENT_CAMPAIGN = 2;

export const ACTION_TYPE_MAPPING_ROUTES: Record<ActionTypeName, ActionTypeId> = {
  master: 7,
  email: 1,
  'email-trigger': 3,
  'mail-api': 4,
  'sms-api': 5,
  sms: 8,
  'email-attachment': 9,
};

//cspell:disable
export const ACTION_CREATE_MESSAGE = 'ELEMENTO_CREADO_OK';
//cspell:enable

export const defaultPager: IPager = {
  page: 1,
  limit: 10,
};

export const TABLE_COLUMN_WIDTH: ITableColumnWidth = {
  report: '120px',
};

export const MAX_SIZE_SUBJECT = 100;

export const CALENDAR_COL_START_CLASSES = [
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
];

export const ITEMS_PER_PAGE_SEARCH_CAMPAIGNS = 20;

export const REPORT_PARAMS: IReportParams[] = [
  //cspell:disable
  { clave: 'tipo', valor: 'sms' },
  { clave: 'camposPersonalizados', valor: '0' },
  { clave: 'reportesIdAFiltrar', valor: '' },
  { clave: 'selectedItems', valor: '0' },
  { clave: 'enviados', valor: 'true' },
  { clave: 'pendings', valor: 'true' },
  { clave: 'fallidos', valor: 'true' },
  { clave: 'depurados', valor: 'true' },
  //cspell:enable
];
