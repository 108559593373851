/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { IEmblueApiMethodNameMap, IRulesService } from './IRulesService';

import {
  IRulesV1Response,
  PayloadSaveCloseDateRule,
  PayloadSaveRules,
} from '@/modules/RulesModule/interfaces';

const STATUS_SUCCESS = 200;

/* eslint-disable @typescript-eslint/no-explicit-any */
export class RulesService implements IRulesService {
  private api: APIService;
  private pathEditor = 'api/v2.1/editor';

  private constructor(api: APIService) {
    this.api = api;
  }

  /**
   * @param error AxiosError<Error>
   * @description Catch errors from API
   * @returns void
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error RulesService[${method}]:`, message);
  }

  /**
   * @param api APIService
   * @returns {ICampaignsService}
   * @description Create new instance of CampaignsService
   */
  static create(api: APIService): IRulesService {
    return new RulesService(api);
  }

  /* cspell:disable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /**
   * @param payload: actionId: number
   * @returns {Promise<{ statusOK: boolean; data?: IRulesV1Response }>}
   * @description Get rule detail
   */
  async getRuleById(actionId: number): Promise<IRulesV1Response> {
    try {
      const response = await this.api.post(
        `/Services/Campanias.svc/ObtenerResumenElementoTriggerActividad`,
        {
          elementoId: actionId,
        }
      );

      return response.data as IRulesV1Response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getRuleDetail');
      return {} as IRulesV1Response;
    }
  }

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /**
   * @param payload: IRulesV1Response
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Save rules
   */
  async saveRule(payload: PayloadSaveRules): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.api.post(
        `/Services/Campanias.svc/SetearDatosTriggerActividad`,
        payload
      );

      if (response.status !== STATUS_SUCCESS) return { statusOK: false };

      return { statusOK: true };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'saveRules');
      return { statusOK: false };
    }
  }

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /**
   * @param payload: { active: boolean; actionId: number }
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Set touch rules
   */
  async setTouchRules(payload: {
    active: boolean;
    actionId: number;
  }): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.api.post(`/Services/Campanias.svc/SetEnvioTouchRules`, {
        activo: payload.active,
        elementoId: payload.actionId,
      });

      if (response.status !== STATUS_SUCCESS) return { statusOK: false };

      return { statusOK: true };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'setTouchRules');
      return { statusOK: false };
    }
  }

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /**
   * @param payload: { actionId: number }
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Activate rule
   */
  async activateRule(actionId: number): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.api.post(`/Services/Campanias.svc/ActivarTrigger`, {
        elementoId: actionId,
      });

      if (response.status !== STATUS_SUCCESS) return { statusOK: false };

      return { statusOK: true };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'activateRule');
      return { statusOK: false };
    }
  }

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /**
   * @param payload: { actionId: number }
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Inactivate rule
   */
  async inactivateRule(actionId: number): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.api.post(`/Services/Campanias.svc/DesactivarTrigger`, {
        elementoId: actionId,
      });

      if (response.status !== STATUS_SUCCESS) return { statusOK: false };

      return { statusOK: true };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'inactivateRule');
      return { statusOK: false };
    }
  }

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /**
   * @param payload: PayloadSaveCloseDateRule
   * @returns {Promise<{ statusOK: boolean }>}
   * @description Save the closing date of the rules
   */
  async saveCloseDate(payload: PayloadSaveCloseDateRule): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.api.post(
        `/Services/Campanias.svc/SetearDatosTriggerFechaCierre`,
        payload
      );

      if (response.status !== STATUS_SUCCESS) return { statusOK: false };

      return { statusOK: response.data === 'FECHA_TRIGGER_ACTIVIDAD_OK' };
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'saveRules');
      return { statusOK: false };
    }
  }
}

/* cspell:enable */

export const RulesServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  RulesService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return h;
}, {} as typeof RulesService);
