import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import { dropConfig } from '@/src/modules/CampaignsModule/components/HtmlUpload/config/dropZone.config';
import { removeScriptHTML } from '@/src/modules/CampaignsModule/components/HtmlUpload/util/removeScriptHTML';

import { useAlertMessages } from './useAlertMessages';
import { useHtml } from './useHtml';
import { useSaveHtml } from './useSaveHtml';

import {
  isLoadingUploadAtom,
  isUploadFilePageModalOpenAtom,
} from '@/modules/CampaignsModule/atoms/htmlEditorAtom';
import { useHtmlEditorLogger } from '@/modules/CampaignsModule/hooks/useHtmlEditorLogger';

export const useUploadFile = () => {
  const [fileName, setFileName] = useState('');
  const [isDraged, setIsDraged] = useState(false);
  const [isUrlUploader, setIsUrlUploader] = useState(false);

  const { actionId } = useHtml();
  const { logger } = useHtmlEditorLogger();
  const navigate = useNavigate();
  const {
    showFileNotFoundError,
    showWrongFileError,
    showInvalidFileError,
    showInvalidLoadingFileError,
    showUploadFileMessage,
    showSaveMessage,
  } = useAlertMessages();
  const { getHtmlByUrl, updateHtml } = useSaveHtml();

  const setIsLoading = useSetAtom(isLoadingUploadAtom);
  const setIsUploadFileOpen = useSetAtom(isUploadFilePageModalOpenAtom);

  const closeModal = () => setIsUploadFileOpen(false);

  const validateHTML = useCallback((html: string) => {
    // eslint-disable-next-line regexp/no-unused-capturing-group
    const TAG_REGEX = /(<head\b|<body\b|<table\b|<tr\b)/g;
    return TAG_REGEX.test(html);
  }, []);

  const onDragEnter = () => {
    setIsDraged(true);
  };

  const onDragLeave = () => {
    setIsDraged(false);
  };

  const uploadFile = async (actionId: number, htmlBody: string) => {
    const response = await updateHtml({
      html: htmlBody,
      actionId,
    });

    const error = response?.error ? true : false;

    const isSuccess = response && response.status === 200;

    if (!isSuccess) {
      showSaveMessage(false, response?.message);
      return;
    }

    if (!error) {
      await logger('uploadHtml', 'action', {
        actionId: actionId,
      });
    } else {
      await logger('uploadHtml', 'error', actionId);
    }

    showUploadFileMessage(isSuccess);
    isSuccess ? window.location.reload() : setIsLoading(false);
  };

  const getAndSaveHtmlByUrl = async (url: string, code: string) => {
    const result = await getHtmlByUrl({ url, actionId, code });
    const isSuccess = result && result.status === 200;
    showUploadFileMessage(isSuccess as boolean);
    isSuccess ? window.location.reload() : setIsLoading(false);
  };

  let fileString = '';

  const onDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      setIsDraged(false);
      if (fileRejections.length) {
        fileRejections.forEach(({ errors }: any) => {
          errors.forEach((error: any) => {
            if (error.code === 'file-invalid-type') {
              showWrongFileError();
            }
            if (error.code === 'file-too-large') {
              showInvalidFileError();
            }
          });
        });
      }
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          try {
            fileString = (reader.result as string) || '';
            const isValidHtml = validateHTML(fileString);

            if (!isValidHtml) {
              showInvalidFileError();
            } else {
              setIsLoading(true);
              fileString = removeScriptHTML(fileString);
              setFileName(file.name);
              !fileString && showFileNotFoundError();
              await uploadFile(actionId, fileString);
              closeModal();
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
            showInvalidLoadingFileError();
          }
        };
        reader.readAsText(file);
      });
    },
    [validateHTML, navigate, actionId]
  );

  const { getRootProps, open, getInputProps } = useDropzone({
    ...dropConfig,
    onDrop,
    onDragEnter,
    onDragLeave,
  });

  return {
    isUrlUploader,
    setIsUrlUploader,
    getRootProps,
    getInputProps,
    open,
    isDraged,
    fileName,
    getAndSaveHtmlByUrl,
  };
};
