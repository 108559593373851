import ReactFlowRules, { Background } from 'reactflow';

import { SidePanel } from '@/lib/v2/examples/SidePanel';

import FormCondition from '@/src/modules/RulesModule/components/FormCondition';
import FormTrigger from '@/src/modules/RulesModule/components/FormTrigger';
import {
  generateInitialEdges,
  generateInitialNodes,
} from '@/src/modules/RulesModule/constants/InitialFlow';
import { useAutoLayout, useFlowManagement } from '@/src/modules/RulesModule/hooks';
import { FormTypes } from '@/src/modules/RulesModule/interfaces';
import { EDGE_TYPES, NODE_TYPES } from '@/src/modules/RulesModule/types';

import 'reactflow/dist/style.css';
import './RulesDetail.css';

const RulesDetail = () => {
  const initialNodes = generateInitialNodes();
  const initialEdges = generateInitialEdges();

  const {
    edges,
    nodes,
    handleEdgeClick,
    onEdgesChange,
    onNodesChange,
    handleNodeClick,
    toggleSidePanel,
    currentFormType,
  } = useFlowManagement({ initialNodes, initialEdges });

  useAutoLayout();

  return (
    <div className="wrapper h-[calc(100vh-112px)] w-full cursor-default overflow-hidden">
      <Background color="#F4F5FA" gap={16} size={4} />
      <ReactFlowRules
        edgeTypes={EDGE_TYPES}
        edges={edges}
        nodeTypes={NODE_TYPES}
        nodes={nodes}
        nodesConnectable={false}
        nodesDraggable={false}
        panOnDrag={false}
        panOnScroll={false}
        zoomOnDoubleClick={false}
        zoomOnScroll={false}
        onEdgeClick={handleEdgeClick}
        onEdgesChange={onEdgesChange}
        onNodeClick={handleNodeClick}
        onNodesChange={onNodesChange}
      />
      <SidePanel noPadding open={toggleSidePanel} side="right">
        {currentFormType === FormTypes.TRIGGER ? <FormTrigger /> : <FormCondition />}
      </SidePanel>
    </div>
  );
};

export default RulesDetail;
