import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, If, Text } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';

import { atomTriggerType } from '@/src/modules/RulesModule/atoms/rules';
import TouchRules from '@/src/modules/RulesModule/components/TouchRules';
import { TRANSLATE_RULES_LABELS } from '@/src/modules/RulesModule/constants';
import { useRuleStatus } from '@/src/modules/RulesModule/hooks/useRuleStatus';
import { TriggerTypes } from '@/src/modules/RulesModule/interfaces';

import HeaderActionButtons from './components/HeaderActionButtons';
import TriggerApi from './components/TriggerApi';
import TriggerDate from './components/TriggerDate';
import { TriggerSelect } from './components/TriggerSelect';
import { useFormTrigger } from './hooks/useFormTrigger';

const FormTrigger = () => {
  const { t } = useTranslation('rules');

  const triggerType = useAtomValue(atomTriggerType);

  const { control, handleSubmit, RULES_DATE, handleCancel, setValue, getValues, watch, errors } =
    useFormTrigger();
  const { isRuleActive } = useRuleStatus();

  const formProps = useMemo(
    () => ({ control, setValue, getValues, watch, errors }),
    [control, setValue, getValues, watch, errors]
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="pl-4 pr-2">
          <SectionHeader
            sticky
            withoutDivide
            actionsButtons={<HeaderActionButtons onCancel={handleCancel} />}
            isLoading={false}
            titleNode={
              <Text as="h3" className="!2xl:text-20 !text-16" fontWeight="medium" variant="title-1">
                <Flex withGap>{t(`${TRANSLATE_RULES_LABELS}.trigger`)}</Flex>
              </Text>
            }
          />
          <hr className="w-full" />
        </div>
        <div className="px-8 pb-8 pt-6">
          <TriggerSelect disabled={isRuleActive} form={formProps} rules={RULES_DATE} />
          <If condition={triggerType?.value === TriggerTypes.DATE}>
            <TriggerDate disabled={isRuleActive} form={formProps} rules={RULES_DATE} />
          </If>
          <If condition={triggerType?.value === TriggerTypes.API_INTEGRATOR}>
            <TriggerApi disabled={isRuleActive} errors={errors} form={formProps} />
          </If>
          <If condition={triggerType?.value !== TriggerTypes.FLOW_TEMPLATE}>
            <TouchRules disabled={isRuleActive} form={formProps} />
          </If>
        </div>
      </form>
    </>
  );
};

export default FormTrigger;
