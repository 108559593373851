import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

import { atomRuleData } from '@/modules/RulesModule/atoms/rules';
import { TRANSLATE_RULES_PRESENCE_OPTIONS } from '@/modules/RulesModule/constants';
import { RulesOptions } from '@/modules/RulesModule/interfaces';

const NodeContentDate = () => {
  const { t } = useTranslation('rules');
  const rulesData: RulesOptions = useAtomValue(atomRuleData);

  const { presenceCondition } = rulesData || {};

  const presenceText = useMemo(() => {
    return presenceCondition?.value
      ? t(`${TRANSLATE_RULES_PRESENCE_OPTIONS}.group`)
      : t(`${TRANSLATE_RULES_PRESENCE_OPTIONS}.segment`);
  }, [presenceCondition, t]);

  const formattedText = useMemo(() => {
    return `${rulesData?.presenceCondition?.name || ''} : ${
      rulesData?.selectedGroupOrSegment?.name?.toString() || ''
    }`;
  }, [rulesData?.presenceCondition?.name, rulesData?.selectedGroupOrSegment?.name]);

  return (
    <>
      <Text fontWeight="medium" variant="text">
        {presenceText}
      </Text>
      <Text truncate fontWeight="medium" variant="text-sm">
        {formattedText}
      </Text>
    </>
  );
};

export default NodeContentDate;
