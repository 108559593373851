import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { ModalSingleInput } from '@/lib/v2/examples';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import { atomSelectedRowActionName } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomLoadingDetail } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { atomActionDragDrop } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { useDeprecatedBanner } from '@/modules/CampaignsModule/hooks/useDeprecatedBanner';
import { useDuplicateActions } from '@/modules/CampaignsModule/hooks/useDuplicateActions';

const TRANSLATE_MODAL_DUPLICATE = 'CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS';

const DuplicateActions = () => {
  const { t } = useTranslation();
  const { VALIDATION_RULES, handleValidation, handleCloseModal, handleSubmitData, loading } =
    useDuplicateActions();
  const disableLegacyAction = useFeatureFlag('disableLegacyActionDuplication');

  // TODO remove this when the feature flag showDragDropRemovalNotice is removed
  const { showDeprecatedDragDropForRow } = useDeprecatedBanner();
  const [toggleDuplicateActionsModal] = useAtom(atomToggleDuplicateActionsModal);
  const actionDragDrop = useAtomValue(atomActionDragDrop);
  const loadingDetail = useAtomValue(atomLoadingDetail);
  const actionName = useAtomValue(atomSelectedRowActionName);
  const disabledSubmitButton = disableLegacyAction && actionDragDrop;

  return (
    <ModalSingleInput
      validateFieldsOnOpen
      alert={
        disabledSubmitButton
          ? {
              description: t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.disableActions'),
              type: 'warning',
            }
          : !showDeprecatedDragDropForRow
          ? {
              description: t(`${TRANSLATE_MODAL_DUPLICATE}.description`),
              type: 'info',
            }
          : {
              description: t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.deprecated.featured'),
              type: 'warning',
            }
      }
      disabledSubmitButton={disabledSubmitButton || loading || loadingDetail}
      id="duplicate-action-modal"
      isLoading={loadingDetail || loading}
      isOpen={toggleDuplicateActionsModal}
      rules={VALIDATION_RULES}
      title={t(`${TRANSLATE_MODAL_DUPLICATE}.title`)}
      value={`${String(actionName)} ${t(`${TRANSLATE_MODAL_DUPLICATE}.copy`)}`}
      onClose={handleCloseModal}
      onSubmit={handleSubmitData}
      onValidateInputFetch={handleValidation}
    />
  );
};

export default DuplicateActions;
