import { RegisterOptions } from 'react-hook-form';

import { CustomFieldSelect } from '@/src/modules/RulesModule/components/FormTrigger/components/CustomFieldSelect';
import { ExecutionDateFields } from '@/src/modules/RulesModule/components/FormTrigger/components/ExecutionDateFields';
import { ExecutionTimeSelect } from '@/src/modules/RulesModule/components/FormTrigger/components/ExecutionTimeSelect';
import { FrequencySelect } from '@/src/modules/RulesModule/components/FormTrigger/components/FrequencySelect';
import { ITriggerForm } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';

import { IFormProps } from '@/modules/RulesModule/interfaces';

interface ITriggerDateProps {
  form: IFormProps<ITriggerForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const TriggerDate = ({ form, rules, disabled = false }: ITriggerDateProps) => {
  return (
    <>
      <CustomFieldSelect disabled={disabled} form={form} rules={rules} />
      <FrequencySelect disabled={disabled} form={form} rules={rules} />
      <ExecutionTimeSelect disabled={disabled} form={form} rules={rules} />
      <ExecutionDateFields disabled={disabled} form={form} rules={rules} />
    </>
  );
};

export default TriggerDate;
