import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import { useSaveDataRules } from './useSaveDataRules';

import {
  atomRuleData,
  atomToggleSidePanel,
  atomTouchRules,
  atomTriggerType,
} from '@/modules/RulesModule/atoms/rules';
import { useResetRulesState } from '@/modules/RulesModule/hooks/useResetRulesState';

export interface ITriggerForm {
  trigger: Option;
  customField: Option;
  frequency: Option;
  execution: Option;
  days: Option;
  period: Option;
  hours: Option;
  minutes: Option;
  actionId: number;
  repeatEmails: Option;
  groupContacts: OptionExternal;
  touchRules: boolean;
}

export const useFormTrigger = () => {
  const { t } = useTranslation();

  const [triggerType, setTriggerType] = useAtom(atomTriggerType);

  const rulesData = useAtomValue(atomRuleData);
  const touchRules = useAtomValue(atomTouchRules);

  const setToggleSidePanel = useSetAtom(atomToggleSidePanel);

  const { saveRule } = useSaveDataRules();
  const { refetchData } = useResetRulesState();

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ITriggerForm>();

  useEffect(() => {
    setValue('trigger', rulesData?.trigger as Option);
    setValue('customField', rulesData?.customField as Option);
    setValue('frequency', rulesData?.frequency as Option);
    setValue('execution', rulesData?.execution as Option);
    setValue('days', rulesData?.days as Option);
    setValue('period', rulesData?.period as Option);
    setValue('hours', rulesData?.hours as Option);
    setValue('minutes', rulesData?.minutes as Option);
    setValue('repeatEmails', rulesData?.repeatEmails as Option);
    setValue('groupContacts', rulesData?.groupContacts as OptionExternal);
  }, [rulesData, setValue, touchRules]);

  const onSubmit: SubmitHandler<ITriggerForm> = useCallback(
    async (triggerValues) => {
      const updatedRulesData = {
        ...rulesData,
        ...triggerValues,
        trigger: triggerType,
        touchRules: triggerValues.touchRules,
      };

      await saveRule(updatedRulesData);
      setToggleSidePanel(false);
      refetchData();
    },
    [rulesData, triggerType, saveRule, setToggleSidePanel, refetchData]
  );

  const RULES_DATE = useMemo(() => {
    const fields = [
      'trigger',
      'customField',
      'frequency',
      'execution',
      'days',
      'period',
      'hours',
      'minutes',
    ];

    return fields.reduce((acc, field) => {
      acc[field] = { required: t('FORMS_RULES_MESSAGES.required') };
      return acc;
    }, {} as Record<string, { required: string }>);
  }, [t]);

  const handleCancel = useCallback(() => {
    setTriggerType(rulesData?.trigger);
    setToggleSidePanel(false);
  }, [rulesData?.trigger, setToggleSidePanel, setTriggerType]);

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    RULES_DATE,
    handleCancel,
    setValue,
    getValues,
    watch,
  };
};
