import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select } from '@/lib/v2/components';

import { ITriggerForm } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';
import { useTriggerOptions } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useTriggerOptions';

import {
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PLACEHOLDERS,
} from '@/modules/RulesModule/constants';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface ICustomFieldSelectProps {
  form: IFormProps<ITriggerForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

export const CustomFieldSelect = ({ form, disabled = false, rules }: ICustomFieldSelectProps) => {
  const { t } = useTranslation('rules');
  const { customFieldOptions } = useTriggerOptions();

  return (
    <div className="mb-5 mr-8 w-full">
      <Select
        isRequired
        control={form.control}
        disabled={disabled}
        label={t(`${TRANSLATE_RULES_LABELS}.customFieldTrigger`)}
        name="customField"
        options={customFieldOptions}
        placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.customField`)}
        rules={rules.customField}
      />
    </div>
  );
};
