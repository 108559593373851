import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Text } from '@/lib/v2/components';
import { CopyIcon, PencilIcon, RemoveIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import {
  atomSelectedRowsWithID,
  atomTotalSelectedRows,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomModalTypeProcess,
  atomToggleDeleteModal,
  atomToggleDuplicateCampaignModal,
  atomToggleEditCreateCampaignModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { IActionIcon, ModalType } from '@/modules/CampaignsModule/interfaces/Campaigns';

const ActionBarButtons = () => {
  const { t } = useTranslation();
  const { getDetailCampaign } = useCampaign();

  const duplicateCampaignV2 = useFeatureFlag('duplicateCampaign');

  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);
  const selectedRowsWithID = useAtomValue(atomSelectedRowsWithID);
  const setToggleDeleteModal = useSetAtom(atomToggleDeleteModal);
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);
  const setToggleDuplicateCampaignModal = useSetAtom(atomToggleDuplicateCampaignModal);

  const handleModalDeleteCampaigns = useCallback(() => {
    setToggleDeleteModal(true);
  }, [setToggleDeleteModal]);

  const handleClickEditCampaign = useCallback(async () => {
    if (totalSelectedRows > 1) return;
    await getDetailCampaign({ campaignID: Number(selectedRowsWithID[0]) });
    setModalTypeProcess(ModalType.EDIT);
    setToggleEditCategoryModal(true);
  }, [
    getDetailCampaign,
    selectedRowsWithID,
    setModalTypeProcess,
    setToggleEditCategoryModal,
    totalSelectedRows,
  ]);

  const handleDuplicateCampaign = useCallback(async () => {
    await getDetailCampaign({ campaignID: Number(selectedRowsWithID[0]) });

    if (duplicateCampaignV2) {
      setModalTypeProcess(ModalType.DUPLICATE);
      setToggleEditCategoryModal(true);
    } else {
      setToggleDuplicateCampaignModal(true);
    }
  }, [
    duplicateCampaignV2,
    getDetailCampaign,
    selectedRowsWithID,
    setModalTypeProcess,
    setToggleDuplicateCampaignModal,
    setToggleEditCategoryModal,
  ]);

  const ICONS_LIST_ACTIONS: IActionIcon[] = [
    {
      icon: <PencilIcon />,
      onClick: handleClickEditCampaign,
      tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipEdit'),
      hide: totalSelectedRows > 1,
      id: 'edit-campaign',
    },
    {
      icon: <CopyIcon />,
      onClick: handleDuplicateCampaign,
      tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDuplicate'),
      hide: totalSelectedRows > 1,
      id: 'duplicate-campaign-action',
    },
    {
      icon: <RemoveIcon />,
      onClick: handleModalDeleteCampaigns,
      tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDelete'),
      hide: false,
      id: 'delete-campaign',
    },
  ];

  return (
    <Flex alignment="start" className="bg-emblue-primary">
      <Flex alignment="start" className="px-4">
        <Flex className="min-w-[120px] flex-1">
          <Text color="white" variant="text-sm">
            {totalSelectedRows > 1
              ? `${totalSelectedRows} ${t('CAMPAIGNS_MAIN.ACTIONS.selected')}`
              : `${totalSelectedRows} ${t('CAMPAIGNS_MAIN.ACTIONS.select')}`}
          </Text>
        </Flex>
        <Flex withGap alignment="start" gapSize="small">
          {ICONS_LIST_ACTIONS.map((element) => {
            const { icon, hide, tooltip, onClick: handleOnClick, id } = element;
            return (
              <>
                {!hide && (
                  <div key={id}>
                    <Button
                      fullWidth
                      outline
                      standard
                      iconLeft={icon}
                      id={id}
                      tooltip={tooltip}
                      onClick={handleOnClick}
                    />
                  </div>
                )}
              </>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ActionBarButtons;
