import { t } from 'i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Option } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import { TRANSLATE_MODAL } from '@/src/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/constants';

import {
  atomDetailCampaign,
  atomFiltersCampaign,
  atomHistorySearchPagerCampaign,
  atomPagerCampaign,
  atomRefetchAllData,
  atomRefetchDetailData,
  atomRefetchStrategyList,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomCampaignValidation,
  atomModalTypeProcess,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { CAMPAIGNS_PATH, defaultPager } from '@/modules/CampaignsModule/constants';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  IDefaultFormToCompareProps,
  IFormInput,
  ModalType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IUseHandleEventsProps {
  setHasOtherStrategy: Dispatch<SetStateAction<boolean>>;
  setDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  onClose: () => void;
  setError: UseFormSetError<IFormInput>;
  defaultFormToCompare: IDefaultFormToCompareProps | undefined;
}

export const useHandleEvents = ({
  setHasOtherStrategy,
  setDateRange,
  onClose,
  setError,
  defaultFormToCompare,
}: IUseHandleEventsProps) => {
  const { createCampaign, loading, duplicateCampaign, isValidNameResource } = useCampaign();
  const navigate = useNavigate();
  const { fetchServiceValidName } = useValidNameResource();
  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const setRefetchDetailData = useSetAtom(atomRefetchDetailData);
  const setRefetchStrategyList = useSetAtom(atomRefetchStrategyList);
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);
  const [loadingCampaignValidation, setLoadingCampaignValidation] = useAtom(atomCampaignValidation);

  const [historySearchPagerCampaign, setHistorySearchPagerCampaign] = useAtom(
    atomHistorySearchPagerCampaign
  );
  const filters = useAtomValue(atomFiltersCampaign);
  const pager = useAtomValue(atomPagerCampaign);

  const handleChangeStrategy = useCallback(
    (value: Option | Option[]) => {
      const data = value as Option;
      setHasOtherStrategy(data?.value === 'other');
    },
    [setHasOtherStrategy]
  );

  const handleCloseModal = useCallback(() => onClose(), [onClose]);

  const isValidName = useCallback(
    async (formData: IFormInput) => {
      const isValidName = await fetchServiceValidName({
        name: formData.name,
        resource: EResourceType.CAMPAIGN,
      });
      if (!isValidName) {
        setError('name', {
          type: 'validate',
          message: t('CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS.existCampaign'),
        });
      }
      return isValidName;
    },
    [fetchServiceValidName, setError]
  );

  const isValidStrategyName = useCallback(
    async (formData: IFormInput) => {
      const isNewStrategy = formData.otherStrategy?.length > 0;

      if (isNewStrategy) {
        const isNameAvailable = await isValidNameResource({
          name: formData.otherStrategy,
          resource: EResourceType.STRATEGY,
        });

        if (!isNameAvailable) {
          setError('otherStrategy', {
            type: 'validate',
            message: t(`${TRANSLATE_MODAL}.existStrategy`),
          });
          return false;
        }
      }

      return true;
    },
    [isValidNameResource, setError]
  );

  const handleSubmitCreate = useCallback(
    async (formData: IFormInput) => {
      setLoadingCampaignValidation(true);
      if ((await isValidName(formData)) && (await isValidStrategyName(formData))) {
        await createCampaign(formData);
        setRefetchDetailData(true);
        setRefetchStrategyList(true);
        handleCloseModal();
        setRefetchAllData(true);
      }
      setLoadingCampaignValidation(false);
    },
    [
      createCampaign,
      handleCloseModal,
      isValidName,
      isValidStrategyName,
      setLoadingCampaignValidation,
      setRefetchAllData,
      setRefetchDetailData,
      setRefetchStrategyList,
    ]
  );

  const handleSubmitEdit = useCallback(
    async (formData: IFormInput) => {
      setLoadingCampaignValidation(true);
      let isValidNameResponse: boolean | undefined = true;
      let isValidStrategyNameResponse: boolean | undefined = true;

      if (detailCampaign.name !== formData.name) {
        isValidNameResponse = await isValidName(formData);
      }

      if (detailCampaign.strategyName !== (formData.strategy as Option)?.name) {
        isValidStrategyNameResponse = await isValidStrategyName(formData);
      }

      if (isValidNameResponse && isValidStrategyNameResponse) {
        await createCampaign(formData);
        setRefetchDetailData(true);
        setRefetchStrategyList(true);
        handleCloseModal();
        setRefetchAllData(true);
      }
      setLoadingCampaignValidation(false);
    },
    [
      createCampaign,
      detailCampaign.name,
      detailCampaign.strategyName,
      handleCloseModal,
      isValidName,
      isValidStrategyName,
      setLoadingCampaignValidation,
      setRefetchAllData,
      setRefetchDetailData,
      setRefetchStrategyList,
    ]
  );

  const handleSubmitDuplicate = useCallback(
    async (formData: IFormInput) => {
      setLoadingCampaignValidation(true);

      let isValidStrategyNameResponse: boolean | undefined = true;

      const isValidNameResponse = await isValidName(formData);

      if (detailCampaign.strategyName !== (formData.strategy as Option)?.value) {
        isValidStrategyNameResponse = await isValidStrategyName(formData);
      }

      if (isValidNameResponse && isValidStrategyNameResponse) {
        const campaignIdDuplicate = await duplicateCampaign({
          ...formData,
          campaignId: detailCampaign.campaignId,
        });
        setRefetchDetailData(true);
        setRefetchStrategyList(true);
        handleCloseModal();
        if (campaignIdDuplicate) {
          if (!historySearchPagerCampaign) {
            setHistorySearchPagerCampaign({
              filters: { ...filters },
              pager: {
                page: pager?.page || defaultPager.page,
                limit: pager?.limit || defaultPager.limit,
              },
            });
          }
          navigate(`${CAMPAIGNS_PATH}/${campaignIdDuplicate}`);
        }
        setRefetchAllData(true);
      }
      setLoadingCampaignValidation(false);
    },
    [
      setLoadingCampaignValidation,
      isValidName,
      detailCampaign.strategyName,
      detailCampaign.campaignId,
      isValidStrategyName,
      duplicateCampaign,
      setRefetchDetailData,
      setRefetchStrategyList,
      handleCloseModal,
      setRefetchAllData,
      historySearchPagerCampaign,
      navigate,
      setHistorySearchPagerCampaign,
      filters,
      pager?.page,
      pager?.limit,
    ]
  );

  const handleSubmitData = useCallback(
    async (formData: IFormInput) => {
      if (modalTypeProcess !== ModalType.DUPLICATE && isEqual(formData, defaultFormToCompare)) {
        onClose();
        return;
      }

      switch (modalTypeProcess) {
        case ModalType.CREATE:
          return await handleSubmitCreate(formData);
        case ModalType.EDIT:
          return await handleSubmitEdit(formData);
        case ModalType.DUPLICATE:
          return await handleSubmitDuplicate(formData);
      }
    },
    [
      defaultFormToCompare,
      modalTypeProcess,
      onClose,
      handleSubmitCreate,
      handleSubmitEdit,
      handleSubmitDuplicate,
    ]
  );

  const handleOnChangeDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) setDateRange(event as [Date | null, Date | null]);
    },
    [setDateRange]
  );

  return {
    loading,
    loadingCampaignValidation,
    handleChangeStrategy,
    handleCloseModal,
    handleSubmitData,
    handleOnChangeDate,
  };
};
