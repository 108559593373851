import { InformationCircleIcon } from '@heroicons/react/solid';
import { useCallback } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Flex, IconSvg, Input, Text, Tooltip } from '@/lib/v2/components';

import { FirstLineHelp } from '@/src/modules/CampaignsModule/components/actionManagement/EmailAction/assets/firstLineHelp';

import { useRulesEmailSubject } from './useRulesEmailSubject.hooks';

import { SubjectButtons } from '@/modules/CampaignsModule/components/SubjectButtons';
import { TRANSLATE_CREATE_EMAIL_ACTION } from '@/modules/CampaignsModule/constants';
import { IFormProps } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { IRulesEmailFormInfo } from '@/modules/RulesModule/interfaces';

interface ISubjectInputProps {
  loading?: boolean;
  form: IFormProps<IRulesEmailFormInfo>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const SubjectInput = ({ form, loading, rules, disabled = false }: ISubjectInputProps) => {
  const { t } = useTranslation();
  const { actionId } = useParams();

  const {
    events: { handleBlurInput, handleChangeSubjectButton },
    setter: { setShowFirstLineContent },
    state: { firstLine, showFirstLineContent },
  } = useRulesEmailSubject({ setValue: form.setValue, getValues: form.getValues });

  const { control } = form;

  const handleSetShowFirstLineContent = useCallback(() => {
    setShowFirstLineContent(true);
  }, [setShowFirstLineContent]);

  return (
    <>
      <Text
        className="sticky top-0 z-10 border-b border-solid border-emblue-borders bg-emblue-white pb-2"
        color="primary"
        fontWeight="medium"
        variant="sub-headline"
      >
        {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.subject`)}
      </Text>
      <Flex withGap className="mt-9" gapSize="small" itemAlignment="start" textAlignment="center">
        <Input
          isRequired
          control={control}
          disabled={disabled}
          id="create-action-subject-input"
          isLoading={loading}
          name="subject"
          placeHolder={t(`${TRANSLATE_CREATE_EMAIL_ACTION}.subjectPlaceholder`)}
          rules={rules?.subject}
          onBlur={handleBlurInput}
        />
        <div className="mt-1">
          <SubjectButtons
            actionId={Number(actionId)}
            disabled={disabled}
            id="email-form-buttons"
            searchPlaceholder={t(`${TRANSLATE_CREATE_EMAIL_ACTION}.searchDropdown`)}
            onChangeButtons={handleChangeSubjectButton}
          />
        </div>
      </Flex>
      {!showFirstLineContent && !firstLine ? (
        <div className="pt-4">
          <div className="flex gap-1">
            <Text
              color="primary"
              fontWeight="normal"
              id="create-action-first-line-label"
              variant={'text'}
              onClick={handleSetShowFirstLineContent}
            >
              {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.addFirstLine`)}
            </Text>
            <Tooltip alignment={'start'} content={<FirstLineHelp />}>
              <IconSvg
                fillColor="primary"
                height="20px"
                svgComponent={<InformationCircleIcon />}
                width="20px"
              />
            </Tooltip>
          </div>
        </div>
      ) : (
        <>
          <Text className="pt-4" variant="text">
            {t(`${TRANSLATE_CREATE_EMAIL_ACTION}.firstLine`)}
          </Text>
          <Input
            control={control}
            disabled={disabled}
            id="create-action-first-line-input"
            isLoading={loading}
            name="firstLine"
            placeholder={t(`${TRANSLATE_CREATE_EMAIL_ACTION}.firstLinePlaceholder`)}
            rules={rules?.firstLine}
            onBlur={handleBlurInput}
          />
        </>
      )}
    </>
  );
};

export default SubjectInput;
