//cspell:disable
import { IRulesV1Response } from '@/src/modules/RulesModule/interfaces';

export const parseRuleDataRaw = (data: IRulesV1Response | null) => {
  const safeData = data ?? ({} as IRulesV1Response);

  return {
    activo: safeData.activo ?? false,
    acumuladaCantidad: safeData.acumuladaCantidad ?? null,
    campoDTO: safeData.campoDTO ?? null,
    camposPersonalizadosDTO: safeData.camposPersonalizadosDTO ?? [],
    desencadenante: safeData.desencadenante ?? '',
    elementoId: safeData.elementoId ?? 0,
    elementosHnosCantidad: safeData.elementosHnosCantidad ?? 0,
    enviarCadaAnio: safeData.enviarCadaAnio ?? null,
    envioRecurrente: safeData.envioRecurrente ?? null,
    fechaEnvio: safeData.fechaEnvio ?? '',
    group_rules: safeData.group_rules ?? null,
    group_rules_in: safeData.group_rules_in ?? null,
    group_rules_type: safeData.group_rules_type ?? '',
    grupoDTO: safeData.grupoDTO ?? null,
    horaEnvio: safeData.horaEnvio ?? null,
    landingPageDTO: safeData.landingPageDTO ?? null,
    landingPageErrorDTO: safeData.landingPageErrorDTO ?? null,
    redireccionamientoFinal: safeData.redireccionamientoFinal ?? null,
    redireccionamientoFinalError: safeData.redireccionamientoFinalError ?? null,
    tag_id: safeData.tag_id ?? [],
    tipo: safeData.tipo ?? null,
    urlDestino: safeData.urlDestino ?? null,
    urlDestinoError: safeData.urlDestinoError ?? null,
    campoEmpresaDTO: safeData.campoEmpresaDTO ?? [],
    elementosDTO: safeData.elementosDTO ?? [],
    tags_DTO: safeData.tags_DTO ?? [],
    urlExterna: safeData.urlExterna ?? '',
  };
};
//cspell:enable
