import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Container, If } from '@/lib/v2/components';
import { SecondaryNavigationMenu } from '@/lib/v2/components/SecondaryNavigationMenu';
import { Link } from '@/lib/v2/components/SecondaryNavigationMenu/SecondaryNavigationMenu';
import { useNavigationBar } from '@/lib/v2/hooks/useNavigationBar';

import { atomPagerFlows, atomTotalsProcess } from '@/src/modules/AutomationModule/atoms/flows';
import { AUTOMATION_PATHS } from '@/src/modules/AutomationModule/constants';
import { useHandleEventsFlows } from '@/src/modules/AutomationModule/hooks/useHandleEventsFlows';

import {
  AutomationVersion,
  useAutomationLogger,
} from '@/modules/CampaignsModule/hooks/useAutomationLogger';

const AutomationLayout = () => {
  const { pathname } = useLocation();
  const { automationNavigationLinks } = useNavigationBar();
  const { logger } = useAutomationLogger();

  const { clearFilters } = useHandleEventsFlows();
  const setPagination = useSetAtom(atomPagerFlows);
  const setTotalProcess = useSetAtom(atomTotalsProcess);

  const handleClearFiltersAndPagination = useCallback(() => {
    clearFilters(false);
    setTotalProcess({
      total: 0,
      partial: 0,
    });
    setPagination({
      page: 1,
      limit: 10,
    });
  }, [clearFilters, setPagination, setTotalProcess]);

  const automationNAvigationLinksWithOnClick = useMemo<Link[]>(
    () =>
      automationNavigationLinks.map((link) => ({
        ...link,
        onClick: handleClearFiltersAndPagination,
      })),
    [automationNavigationLinks, handleClearFiltersAndPagination]
  );

  const isFlowOrEventView =
    pathname === `/v2/${AUTOMATION_PATHS.MODULE_PATH}` ||
    pathname === `/v2/${AUTOMATION_PATHS.MODULE_PATH}/${AUTOMATION_PATHS.EVENTS_PATH}`;

  useEffect(() => {
    void logger('load', AutomationVersion.V1_5, 'action');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full">
      <If condition={isFlowOrEventView}>
        <SecondaryNavigationMenu links={automationNAvigationLinksWithOnClick} />
      </If>
      <If condition={isFlowOrEventView}>
        <Container
          fullHeight
          background="gray"
          className="h-full min-h-[calc(100vh_-_48px_-_64px)]"
          fluid="screen"
        >
          <Outlet />
        </Container>
      </If>
      <If condition={!isFlowOrEventView}>
        <Outlet />
      </If>
    </div>
  );
};

export default memo(AutomationLayout);
