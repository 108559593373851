import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModalSingleInput } from '@/lib/v2/examples';

import { SEGMENT_PATH } from '@/src/modules/ContactsModule/constants';

interface IMutationCreateSegmentModalContext {
  setCreateSegmentModal: Dispatch<SetStateAction<boolean>>;
}

type ICreateSegmentModalContext = boolean;

interface CreateSegmentModalProps {
  children: ReactNode;
}

const MutationCreateSegmentModalContext = createContext<
  IMutationCreateSegmentModalContext | undefined
>(undefined);
const StateCreateSegmentModalContext = createContext<ICreateSegmentModalContext | undefined>(
  undefined
);

const CreateSegmentModalProvider = ({ children }: CreateSegmentModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createSegmentModal, setCreateSegmentModal] = useState<boolean>(false);

  const memoizedMutation = useMemo<IMutationCreateSegmentModalContext>(
    () => ({
      setCreateSegmentModal,
    }),
    [setCreateSegmentModal]
  );

  const handleCloseCreateSegmentModal = useCallback(() => {
    setCreateSegmentModal(false);
  }, []);

  const handleSubmit = useCallback(
    (segmentName: string) => {
      handleCloseCreateSegmentModal();
      navigate(`${SEGMENT_PATH}/create-segment/${segmentName}`);
    },
    [handleCloseCreateSegmentModal, navigate]
  );

  return (
    <MutationCreateSegmentModalContext.Provider value={memoizedMutation}>
      <StateCreateSegmentModalContext.Provider value={createSegmentModal}>
        <ModalSingleInput
          disabledUntilOnChange
          id="create-action"
          isOpen={createSegmentModal}
          title={t('CREATE_SEGMENT_MODAL.title')}
          onClose={handleCloseCreateSegmentModal}
          onSubmit={handleSubmit}
        />
        {children}
      </StateCreateSegmentModalContext.Provider>
    </MutationCreateSegmentModalContext.Provider>
  );
};

export const useStateCreateSegmentModalContext = (): ICreateSegmentModalContext =>
  useGetContext<ICreateSegmentModalContext>(StateCreateSegmentModalContext);

export const useMutationCreateSegmentModalContext = (): IMutationCreateSegmentModalContext =>
  useGetContext<IMutationCreateSegmentModalContext>(MutationCreateSegmentModalContext);

const useGetContext = <T,>(contextName: React.Context<T | undefined>): T => {
  const context = useContext<T | undefined>(contextName);
  if (!context) {
    throw new Error('CreateSegmentModalProvider is required');
  }
  return context;
};

export default CreateSegmentModalProvider;
