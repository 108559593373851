import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { Jodit } from '@/src/modules/CampaignsModule/components/HtmlUpload/enums';

export const useAlertMessages = () => {
  const { t } = useTranslation(['editorHtml']);

  const showFileNotFoundError = () =>
    toast({
      title: t('FILE_NOT_FOUND_ALERT.title'),
      body: t('FILE_NOT_FOUND_ALERT.desc'),
      variant: 'error',
    });

  const showWrongFileError = () =>
    toast({
      title: t('WRONG_FILE_ALERT.title'),
      body: t('WRONG_FILE_ALERT.desc'),
      variant: 'error',
    });

  const showInvalidFileError = () =>
    toast({
      title: t('INVALID_FILE_ALERT.title'),
      body: t('INVALID_FILE_ALERT.desc'),
      variant: 'error',
    });

  const showInvalidLoadingFileError = () =>
    toast({
      title: t('LOADING_FILE_ALERT.title'),
      body: t('LOADING_FILE_ALERT.desc'),
      variant: 'error',
    });

  const unknownError = () =>
    toast({
      title: t('Error.errorTitle'),
      body: t('Error.unknownError'),
      variant: 'error',
    });

  const errorAddingFile = () =>
    toast({
      title: t('Error.errorTitle'),
      body: t('Error.errorAddingFile'),
      variant: 'error',
    });

  const unsupportedFile = () =>
    toast({
      title: t('Error.errorTitle'),
      body: t('Error.unsupportedFile'),
      variant: 'error',
    });

  const showUploadFileMessage = (isSuccess: boolean) =>
    toast({
      title: isSuccess ? t('SUCCESS_FILE_UPLOAD.title') : t('ERROR_FILE_UPLOAD.title'),
      body: isSuccess ? t('SUCCESS_FILE_UPLOAD.desc') : t('ERROR_FILE_UPLOAD.desc'),
      variant: isSuccess ? 'success' : 'error',
    });

  const showSaveMessage = (isSuccess: boolean, errorMessage?: string) => {
    const isLargeFileError = errorMessage === Jodit.htmlContentToLarge;

    const titleKey = isSuccess
      ? 'SUCCESS_FILE_SAVE.title'
      : isLargeFileError
      ? 'LARGE_FILE_ALERT.title'
      : 'ERROR_FILE_SAVE.title';

    const descKey = isSuccess
      ? 'SUCCESS_FILE_SAVE.desc'
      : isLargeFileError
      ? 'LARGE_FILE_ALERT.desc'
      : 'ERROR_FILE_SAVE.desc';

    toast({
      title: t(titleKey),
      body: t(descKey),
      variant: isSuccess ? 'success' : 'error',
    });
  };

  const directoryExist = () =>
    toast({
      title: t('Error.errorTitle'),
      body: t('Error.directoryExist'),
      variant: 'error',
    });

  const errorLoadingFile = () =>
    toast({
      title: t('LOADING_FILE_ALERT.title'),
      body: t('Error.unknownError'),
      variant: 'error',
    });

  const maxFilesAllowed = () =>
    toast({
      title: t('LOADING_FILE_ALERT.title'),
      body: t('Error.maxNumberImages'),
      variant: 'error',
    });

  return {
    showFileNotFoundError,
    showWrongFileError,
    showInvalidFileError,
    showInvalidLoadingFileError,
    showUploadFileMessage,
    showSaveMessage,
    unknownError,
    errorAddingFile,
    unsupportedFile,
    directoryExist,
    errorLoadingFile,
    maxFilesAllowed,
  };
};
