import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Card, CardSection, Stepper, Steps, Text } from '@/lib/v2/components';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { useVersion } from '@/src/application/hooks/useVersion';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { atomShowCampaignsV1 } from '@/src/atoms/Campaigns';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';
import { atomSignalSendersOnboarding } from '@/src/modules/OnboardingModule/atoms/OnboardingModule.atoms';
import {
  onCreateCampaignCardClick,
  onCreateContactCardClick,
} from '@/src/presentation/components/Main/Cards';
import { StepEnum } from '@/src/presentation/util/enum/StepEnum';

let lastRefresh = 0;
const CardStepper = () => {
  const service = useService();
  const { t } = useTranslation();
  const [currenStep, setCurrentStep] = useState(StepEnum.sender);
  const [signalSenders] = useAtom(atomSignalSendersOnboarding);
  const { userData } = useStateUserContext();
  const { version } = useVersion();
  const navigate = useNavigate();
  const showCampaignsV1 = useAtomValue(atomShowCampaignsV1);
  const [, setSignalSenders] = useAtom(atomSignalSendersOnboarding);
  const [currentStepInitialData, , currentStepIsLoading] = useEmblue(
    ServiceMethods.getGetCurrentStep,
    userData.companyId
  );

  const nextStep = useCallback(() => {
    const numberStep = service?.setNextStep(userData.companyId, Number(currenStep));
    setCurrentStep(numberStep);
  }, [currenStep, service, userData.companyId]);

  const steps: Steps = useMemo(
    () => [
      {
        id: 1,
        stepName: t('ONBOARDING.CARD_STEPPER.step1.name'),
        title: t('ONBOARDING.CARD_STEPPER.step1.title'),
        subTitle: t('ONBOARDING.CARD_STEPPER.step1.time'),
        buttonText: t('ONBOARDING.CARD_STEPPER.step1.confirm'),
        description: t('ONBOARDING.CARD_STEPPER.step1.description'),
        onClickButton: () => {
          setSignalSenders({
            origin: '/v2',
            complete: false,
          });
          navigate('/v2/configuration/senders');
        },
      },
      {
        id: 2,
        stepName: t('ONBOARDING.CARD_STEPPER.step2.name'),
        title: t('ONBOARDING.CARD_STEPPER.step2.title'),
        subTitle: t('ONBOARDING.CARD_STEPPER.step2.time'),
        buttonText: t('ONBOARDING.CARD_STEPPER.step1.confirm'),
        description: t('ONBOARDING.CARD_STEPPER.step2.description'),
        onClickButton: () => {
          onCreateContactCardClick(userData, version, navigate);
        },
      },
      {
        id: 3,
        stepName: t('ONBOARDING.CARD_STEPPER.step3.name'),
        title: t('ONBOARDING.CARD_STEPPER.step3.title'),
        subTitle: t('ONBOARDING.CARD_STEPPER.step3.time'),
        buttonText: t('ONBOARDING.CARD_STEPPER.step3.send'),
        description: t('ONBOARDING.CARD_STEPPER.step3.description'),
        onClickButton: () => {
          onCreateCampaignCardClick(userData, version, navigate, showCampaignsV1 === 'true');
        },
      },
    ],
    [navigate, setSignalSenders, showCampaignsV1, t, userData, version]
  );

  const handleSenderFinished = useCallback(
    (complete: boolean) => {
      if (complete) {
        nextStep();
      }
    },
    [nextStep]
  );

  useEffect(() => {
    if (!signalSenders.complete || currenStep !== StepEnum.sender) return;
    handleSenderFinished(true);
  }, [currenStep, handleSenderFinished, signalSenders]);

  const haveSenders = useCallback(async () => {
    const { pager } = await service.getSenders();

    if (pager.countTotal > 0) handleSenderFinished(true);
  }, [handleSenderFinished, service]);

  useEffect(() => {
    if (!currentStepIsLoading && currentStepInitialData) {
      if (!signalSenders.complete && currenStep === StepEnum.sender) void haveSenders();
    }
  }, [
    currenStep,
    currentStepInitialData,
    currentStepIsLoading,
    haveSenders,
    signalSenders.complete,
  ]);

  useEffect(() => {
    const totalContacts = async () => {
      lastRefresh++;
      const response = await service.getContactsCount({ lastRefresh });
      const totalContacts = response.count;
      if (totalContacts >= 2) nextStep();
    };

    if (Number(currenStep) === StepEnum.contacts) void totalContacts();
  }, [currenStep, nextStep, service]);

  return (
    <div className="basis-2/3">
      <Card>
        <CardSection>
          <div className="flex size-full gap-4">
            <div className="basis-5/12">
              <div className="flex flex-col">
                <Text variant="sub-headline">{t('ONBOARDING.CARD_STEPPER.fastGuide')}</Text>
                <Text variant="title-1">{t('ONBOARDING.CARD_STEPPER.start')}</Text>
                <Text variant="title-1">{t('ONBOARDING.CARD_STEPPER.quickly')}</Text>
              </div>
            </div>
            <div className="basis-7/12">
              <Stepper
                currentStep={Number(currenStep)}
                isLoading={currentStepIsLoading}
                steps={steps}
              />
            </div>
          </div>
        </CardSection>
      </Card>
    </div>
  );
};

export default CardStepper;
