import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';
import { GroupsIcon, SegmentsIcon, TagsIcon } from '@/lib/v2/icons/solid';

import { useDebounce } from '@/src/application/hooks/useDebounce';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { atomRecipientOptions } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  EGroupAndListsTypeV1MapString,
  IAddresseesList,
  IAddresseesPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { IConditionForm } from '@/src/modules/RulesModule/components/FormCondition/hooks/useFormCondition';

import { EcommerceIcon } from '@/modules/OnboardingModule/images/icons';

interface IRecipientsRulesProps {
  setValue?: UseFormSetValue<IConditionForm>;
}

const ICONS_BY_TYPE: { [key: string]: JSX.Element } = {
  interest: <TagsIcon />,
  user: <GroupsIcon />,
  integration: <EcommerceIcon />,
  profile: <SegmentsIcon />,
};

const PAYLOAD_RECIPIENTS: IAddresseesPayload = {
  offset: 0,
  groupQuantity: 20,
  filterType: {
    user: true,
    profile: true,
    integration: false,
    interest: false,
  },
};

export const useRecipientsRules = ({ setValue }: IRecipientsRulesProps = {}) => {
  const [loading, setLoading] = useState(false);
  const [recipientsSearch, setRecipientsSearch] = useState<string>('');
  const [hasMoreRecipient, setHasMoreRecipient] = useState<boolean>(false);
  const [recipientListPayload, setRecipientListPayload] =
    useState<IAddresseesPayload>(PAYLOAD_RECIPIENTS);

  const campaignService = useCampaignsService();
  const recipientsSearchDebounced = useDebounce(recipientsSearch, 400);

  const [recipientsOptions, setRecipientOptions] = useAtom(atomRecipientOptions);

  useEffect(() => {
    if (recipientsSearchDebounced.length === 0) return;

    const payloadOffset = {
      ...recipientListPayload,
      search: recipientsSearchDebounced,
      offset: 0,
    };

    void getRecipients(payloadOffset);

    setRecipientListPayload(payloadOffset);
    setHasMoreRecipient(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientsSearchDebounced]);

  //cspell:disable
  const getRecipientsOptions = useCallback(
    ({ addresseesList }: { addresseesList: IAddresseesList[] }): OptionExternal[] => {
      const addresseesOptions = addresseesList
        .filter(
          (address) =>
            address.type === EGroupAndListsTypeV1MapString.USUARIO ||
            address.type === EGroupAndListsTypeV1MapString.PERFIL
        )
        .map((address) => {
          return {
            id: Number(address.groupId),
            name: String(address.name).trim() || '-',
            value: address.type,
            metadata: {
              icon: ICONS_BY_TYPE[address.type],
              alt: `(${address.contactCount})`,
            },
          };
        });
      return addresseesOptions;
    },
    []
  );
  //cspell:enable

  const getRecipients = useCallback(
    async (payload: IAddresseesPayload) => {
      if (!payload) return;

      setLoading(true);

      const newOffset = payload.offset || 0;
      const response = await campaignService.getAddressees(payload);
      const addresseesOptionsDropdown = getRecipientsOptions({ addresseesList: response });

      setRecipientOptions((prevOptions) =>
        newOffset === 0 ? addresseesOptionsDropdown : [...prevOptions, ...addresseesOptionsDropdown]
      );

      setHasMoreRecipient(response.length === payload.groupQuantity);
      setLoading(false);
    },
    [campaignService, getRecipientsOptions, setHasMoreRecipient, setRecipientOptions]
  );

  const handleChangeRecipients = useCallback(
    (value: OptionExternal | OptionExternal[]) => {
      const selectedItems = Array.isArray(value) ? value : [value];
      setValue?.('selectedGroupOrSegment', selectedItems.at(0));
    },
    [setValue]
  );

  const handleLoadLastRecipients = useCallback(
    async (option: OptionExternal) => {
      if (!hasMoreRecipient) return;

      if (option.id === recipientsOptions[recipientsOptions.length - 1].id) {
        const payloadOffset = {
          ...recipientListPayload,
          offset: recipientsOptions.length,
        };

        await getRecipients(payloadOffset);
        setRecipientListPayload(payloadOffset);
      }
    },
    [
      getRecipients,
      hasMoreRecipient,
      recipientListPayload,
      recipientsOptions,
      setRecipientListPayload,
    ]
  );

  const handleCloseRecipients = useCallback(() => {
    if (recipientsSearch.length === 0) return;

    const payloadOffset = { ...recipientListPayload, search: '', offset: 0 };

    void getRecipients(payloadOffset);
    setRecipientListPayload(payloadOffset);
    setHasMoreRecipient(true);
  }, [
    getRecipients,
    recipientListPayload,
    recipientsSearch.length,
    setHasMoreRecipient,
    setRecipientListPayload,
  ]);

  return {
    events: {
      getRecipients,
      handleChangeRecipients,
      handleCloseRecipients,
      handleLoadLastRecipients,
    },
    setters: {
      setRecipientsSearch,
    },
    state: {
      loading,
      recipientsOptions,
      recipientListPayload,
    },
  };
};
