import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { IconSvg, Text } from '@/lib/v2/components';

import { atomRuleData } from '@/modules/RulesModule/atoms/rules';
import { TRANSLATE_RULES_TRIGGER_TYPES } from '@/modules/RulesModule/constants';
import { RulesFlowIcon } from '@/modules/RulesModule/images/icons';

const NodeContentFlows = () => {
  const { t } = useTranslation('rules');
  const { trigger } = useAtomValue(atomRuleData);

  return (
    <div className="flex flex-col justify-center">
      <div className="mb-2 flex items-center">
        <IconSvg fillColor="primary" height="24px" svgComponent={<RulesFlowIcon />} width="24px" />
        <Text className="ml-2 mt-1" fontWeight="semibold" variant="text">
          {t(`${TRANSLATE_RULES_TRIGGER_TYPES}.${trigger?.id ?? ''}`)}
        </Text>
      </div>
      <hr className="w-full min-w-64 pb-4" />
    </div>
  );
};

export default NodeContentFlows;
