import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import {
  ActionNameWithOptionalRule,
  ERulesActionType,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { IRestricted } from '@/src/presentation/types/types';

export const atomActionTypeWithOptionalRule = atomWithReset<ActionNameWithOptionalRule>({
  name: 'email',
});
atomActionTypeWithOptionalRule.debugLabel = 'atomActionTypeWithOptionalRule';

export const atomCreateActionsModalIsOpen = atom(false);
atomCreateActionsModalIsOpen.debugLabel = 'atomCreateActionsModalIsOpen';

export const atomActionInputModalIsOpen = atom(false);
atomActionInputModalIsOpen.debugLabel = 'atomActionInputModalIsOpen';

export const atomRestrictions = atom<IRestricted | null>(null);
atomRestrictions.debugLabel = 'atomRestrictions';

export const atomCreateRules = atom<ERulesActionType>(ERulesActionType.ALL);
atomRestrictions.debugLabel = 'atomCreateRules';
