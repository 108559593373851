import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import { ActionBar } from '@/lib/components/ActionBar';
import { ItemsListProps } from '@/lib/components/DropDown/DropDown';
import { openModal } from '@/lib/components/Modal/Modal';
import { FlowsIcon, GroupAddIconSolid, TagAddIconSolid } from '@/lib/icon';
import { Button, IconSvg } from '@/lib/v2/components';

import { ContactsToFlowModal } from '@/src/ContactsModule/components/ContactsModals';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

const flowsListsAux: ItemsListProps[] = [
  {
    id: '1',
    title: 'flows 1',
  },
  {
    id: '2',
    title: 'flows 2',
  },
  {
    id: '3',
    title: 'flows 3',
  },
];

interface IHappinessTableActionBarProps {
  contactSelection: number;
  flowsLists?: ItemsListProps[];
  setFlowId: Dispatch<SetStateAction<number>>;
  setShowGroupsModal: Dispatch<SetStateAction<boolean>>;
  setShowTagsModal: Dispatch<SetStateAction<boolean>>;
  setConfirmToFlow: Dispatch<SetStateAction<boolean>>;
  isAllResultsSelected?: boolean;
}

export const HappinessTableActionBar = ({
  contactSelection,
  flowsLists,
  setShowGroupsModal,
  setShowTagsModal,
  setConfirmToFlow,
  setFlowId,
}: IHappinessTableActionBarProps) => {
  const iconsActions = [
    {
      actionName: 'addToTag',
      icon: <IconSvg height="28px" svgComponent={<TagAddIconSolid />} width="28px" />,
      handleOnAction: () => setShowTagsModal(true),
      tooltip: t('CONTACTS_ACTIONS_DROPDOWN.AddToTag'),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
    },
    {
      actionName: 'addToGroup',
      icon: <IconSvg height="28px" svgComponent={<GroupAddIconSolid />} width="28px" />,
      handleOnAction: () => setShowGroupsModal(true),
      tooltip: t('CONTACTS_ACTIONS_DROPDOWN.AddToGroup'),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
    },
    {
      actionName: 'addToFlow',
      icon: <IconSvg height="28px" svgComponent={<FlowsIcon />} width="28px" />,
      handleOnAction: () => {
        //TO DO: change this openModal to new Modal component v2
        openModal({
          element: (
            <ContactsToFlowModal
              itemList={flowsLists ? flowsLists : flowsListsAux}
              setConfirmToFlow={setConfirmToFlow}
              setFlowId={setFlowId}
            />
          ),
        });
      },
      tooltip: t('CONTACT_FLOW_MODAL.toolTip'),
      flagOn: true,
      disabled: false,
      disabledTooltip: '',
    },
  ];

  return (
    <div className="mb-4 w-full">
      <ActionBar>
        <div>
          <section className="flex h-full items-center">
            <div className="text-xs ml-10 mr-5 text-white" style={{ fontSize: '12px' }}>
              {`${quantifier(contactSelection) as number} ${
                contactSelection > 1
                  ? t('CONTACTS_ACTIONS_DROPDOWN.Selected')
                  : t('CONTACTS_ACTIONS_DROPDOWN.singleSelected')
              }`}
            </div>
            <div className="ml-5 flex">
              {iconsActions.map((element) => {
                return (
                  element.flagOn && (
                    <div key={element.actionName} className="mr-2">
                      {element.disabled ? (
                        <Button
                          disabled
                          secondary
                          iconLeft={element.icon}
                          tooltip={element.disabledTooltip}
                          onClick={element.handleOnAction}
                        />
                      ) : (
                        <Button
                          secondary
                          iconLeft={element.icon}
                          tooltip={element.tooltip}
                          onClick={element.handleOnAction}
                        />
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </section>
        </div>
      </ActionBar>
    </div>
  );
};
