import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { formValidations } from '@/src/constants/FormValidations';

import { atomInfoCampaignV1 } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomDetailCampaignRecurrent,
  atomFiltersRecurrent,
  atomHistorySearchPagerRecurrent,
  atomPagerCampaignsRecurrent,
  atomRefetchAllData,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomModalTypeProcess } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { defaultPager } from '@/modules/CampaignsModule/constants';
import { useCampaignRecurrent } from '@/modules/CampaignsModule/hooks/useCampaignRecurrent';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { ModalType } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useRecurrentForm = ({ onClose }: any) => {
  const navigate = useNavigate();

  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const setRefetchDetailData = useSetAtom(atomRefetchAllData);
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const detailCampaign = useAtomValue(atomDetailCampaignRecurrent);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);
  const [historySearchPagerCampaign, setHistorySearchPagerCampaign] = useAtom(
    atomHistorySearchPagerRecurrent
  );
  const filters = useAtomValue(atomFiltersRecurrent);
  const pager = useAtomValue(atomPagerCampaignsRecurrent);
  const { t } = useTranslation();
  const { fetchServiceValidName } = useValidNameResource();
  const { loading, createCampaignRecurrent, duplicateRecurrent } = useCampaignRecurrent();
  const { inputLengths, formRegExp } = formValidations;

  const VALIDATION_RULES = {
    input: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
    },
  };

  const handleCloseModal = useCallback(() => onClose(), [onClose]);

  const handleValidation = useCallback(
    async (value: string) => {
      const isValidName = await fetchServiceValidName({
        name: value,
        resource: EResourceType.CAMPAIGN,
      });
      return {
        isValidInput: isValidName,
        message: t('RECURRENT_MESSAGE.CREATE_RECURRENT.existCampaignRecurrent'),
      };
    },
    [fetchServiceValidName, t]
  );

  const handleSubmitData = useCallback(
    async (data: { name: string }) => {
      const { campaignId, actionId } = await createCampaignRecurrent(data.name.trim());
      setRefetchDetailData(true);
      handleCloseModal();
      setRefetchAllData(true);
      setInfoCampaignV1({
        campaign: {
          id: Number(campaignId),
          name: data.name ?? '',
        },
        action: {
          id: actionId,
          name: data.name,
        },
        route: `/v2/campaigns/${campaignId}`,
      });
      if (modalTypeProcess === ModalType.CREATE) {
        navigate(`/v2/campaigns/${campaignId}/master/${actionId}`);
      }
    },
    [
      createCampaignRecurrent,
      setRefetchDetailData,
      handleCloseModal,
      setRefetchAllData,
      setInfoCampaignV1,
      modalTypeProcess,
      navigate,
    ]
  );

  const handleDuplicate = useCallback(
    async (value: string) => {
      const campaignIdDuplicate = await duplicateRecurrent({
        name: value,
        campaignId: Number(detailCampaign.campaignId),
      });
      handleCloseModal();

      if (campaignIdDuplicate) {
        if (!historySearchPagerCampaign) {
          setHistorySearchPagerCampaign({
            filters: { ...filters },
            pager: {
              page: pager?.page || defaultPager.page,
              limit: pager?.limit || defaultPager.limit,
            },
          });
        }
        navigate(`/v2/campaigns/recurrent/${campaignIdDuplicate}`);
      }
    },
    [
      duplicateRecurrent,
      detailCampaign.campaignId,
      handleCloseModal,
      historySearchPagerCampaign,
      navigate,
      setHistorySearchPagerCampaign,
      filters,
      pager?.page,
      pager?.limit,
    ]
  );

  return {
    handleValidation,
    handleDuplicate,
    handleCloseModal,
    handleSubmitData,
    VALIDATION_RULES,
    loading,
  };
};
