import { useUserData } from '@/src/infrastructure/services/useUserData';

/* eslint-disable pii/no-email */
export const useTemplateCreatorsPermissions = () => {
  const [userData] = useUserData();

  const usersAllowed = [
    'hernan.s@embluemail.com',
    'luz.lugo@embluemail.com',
    'giselle.guz@embluemail.com',
    'maxi.g@embluemail.com',
    'romina.l@embluemail.com',
    'luis.e@embluemail.com',
    'cristian.mari@embluemail.com',
  ];
  return usersAllowed.includes(userData.email);
};
