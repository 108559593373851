import { useEffect, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { ActionContent } from '@/src/modules/CampaignsModule/screens/ActionContent';

import ActionsLayout from './ActionsLayout';

import RouterSkeleton from '@/modules/RulesModule/components/RouterSkeleton';
import { RULES_TYPES_ALLOW } from '@/modules/RulesModule/constants';
import { useRuleData } from '@/modules/RulesModule/hooks';
import { useResetRulesState } from '@/modules/RulesModule/hooks/useResetRulesState';
import RulesLayout from '@/modules/RulesModule/layouts/RulesLayout';
import RulesDetail from '@/modules/RulesModule/screens/RulesDetail';
import RulesEmailMain from '@/modules/RulesModule/screens/RulesEmail/RulesEmailMain';

//TODO: Temporal router for mixed content from V1 & V2 experiences
const ActionContentRouter = () => {
  const { actionId, actionType } = useParams();
  const showRulesModuleV2 = useFeatureFlag('showRulesModuleV2');

  const { rule, getRuleData } = useRuleData(Number(actionId));
  const { resetAllAtomStates } = useResetRulesState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    void getRuleData();
    return () => resetAllAtomStates();
  }, []);

  const allowTrigger = useMemo(
    () => RULES_TYPES_ALLOW.includes(rule?.trigger?.id as string),
    [rule?.trigger?.id]
  );

  const isEmailTrigger = useMemo(() => actionType === 'email-trigger', [actionType]);

  const showRulesContent = useMemo(
    () => isEmailTrigger && showRulesModuleV2 && allowTrigger,
    [isEmailTrigger, showRulesModuleV2, allowTrigger]
  );

  const lastParam = useMemo(() => {
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    return pathSegments[pathSegments.length - 1];
  }, []);

  if (Object.keys(rule).length === 0 && lastParam !== 'email' && isEmailTrigger)
    return <RouterSkeleton />;

  return (
    <Routes>
      <Route element={showRulesContent ? <RulesLayout /> : <ActionsLayout />}>
        <Route index element={showRulesContent ? <RulesDetail /> : <ActionContent />} />
        {showRulesContent && <Route element={<RulesEmailMain />} path="email" />}
      </Route>
    </Routes>
  );
};

export default ActionContentRouter;
