import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, If, Text } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/examples/SectionHeader';

import { atomRuleData } from '@/src/modules/RulesModule/atoms/rules';
import { TRANSLATE_RULES_LABELS } from '@/src/modules/RulesModule/constants';
import { useRuleStatus } from '@/src/modules/RulesModule/hooks/useRuleStatus';
import { TriggerTypes } from '@/src/modules/RulesModule/interfaces';

import ConditionTriggerDate from './components/ConditionTriggerDate';
import HeaderActionButtons from './components/HeaderActionButtons/HeaderActionButtons';
import { useFormCondition } from './hooks/useFormCondition';

const FormCondition = () => {
  const { t } = useTranslation('rules');

  const { control, setValue, getValues, RULES_DATE, errors, handleSubmit, handleCancel } =
    useFormCondition();

  const { isRuleActive } = useRuleStatus();

  const rulesData = useAtomValue(atomRuleData);
  const triggerType = rulesData.trigger?.value;

  const formProps = useMemo(
    () => ({ control, setValue, getValues, errors }),
    [control, setValue, getValues, errors]
  );

  return (
    <>
      <form id="condition-form" onSubmit={handleSubmit}>
        <div className="pl-4 pr-2">
          <SectionHeader
            sticky
            withoutDivide
            actionsButtons={<HeaderActionButtons onCancel={handleCancel} />}
            isLoading={false}
            titleNode={
              <Text as="h3" className="!2xl:text-20 !text-16" fontWeight="medium" variant="title-1">
                <Flex withGap>{t(`${TRANSLATE_RULES_LABELS}.condition`)}</Flex>
              </Text>
            }
          />
          <hr className="w-full" />
        </div>
        <div className="p-8">
          <If condition={triggerType === TriggerTypes.DATE}>
            <ConditionTriggerDate
              disabled={isRuleActive}
              errors={errors}
              form={formProps}
              rules={RULES_DATE}
            />
          </If>
        </div>
      </form>
    </>
  );
};

export default FormCondition;
