import { t } from 'i18next';
import { useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useMemo } from 'react';

import { ActionBar } from '@/lib/components/ActionBar';
import { DeleteIconSolid, DistributeContactsIcon, FlowsIcon } from '@/lib/icon';
import { Button } from '@/lib/v2/components/Button';
import { DownloadIcon } from '@/lib/v2/icons/general/DownloadIcon';
import { CopyIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { atomContactsDownload } from '@/src/atoms/Contacts';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';

import { ISegmentInfo } from '@/modules/ContactsModule/types/Segments';

interface ISegmentsActionBarProps {
  contactSelection: number;
  segmentsSwitcher: string;
  distributeIsDisabled: boolean;
  segmentsSelected: ISegmentInfo[];
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  setOpenDuplicateModal: Dispatch<SetStateAction<boolean>>;
  setOpenDistributeModal: Dispatch<SetStateAction<boolean>>;
  setOpenContactsToFlow: Dispatch<SetStateAction<boolean>>;
  setOpenDownloadModal: Dispatch<SetStateAction<boolean>>;
}

// TODO: Refactor all actionBar modals (ContactsActionBAr, GroupsActionBar, TagsActionBar, SegmentsActionBar ActionBar)
export const SegmentsActionBar = ({
  contactSelection,
  segmentsSwitcher,
  segmentsSelected,
  distributeIsDisabled,
  setOpenDeleteModal,
  setOpenDuplicateModal,
  setOpenDistributeModal,
  setOpenContactsToFlow,
  setOpenDownloadModal,
}: ISegmentsActionBarProps) => {
  const contactsDownload = useAtomValue(atomContactsDownload);

  const iconsActions = useMemo(
    () => [
      {
        icon: <FlowsIcon />,
        onClick: () => setOpenContactsToFlow(true),
        tooltip: t('CONTACT_FLOW_MODAL.toolTip'),
        flagOn: true,
        disabled:
          contactSelection !== 1 ||
          (segmentsSelected.length === 1 && segmentsSelected?.[0]?.contacts === 0),
        disabledTooltip: '',
        id: 'connect-to-flow',
      },
      {
        id: 'duplicate',
        icon: <CopyIcon />,
        onClick: () => setOpenDuplicateModal(true),
        tooltip: t('SEGMENT_DUPLICATE_MODAL.title'),
        flagOn: segmentsSwitcher !== 'eCommerce',
        disabled: contactSelection !== 1,
        disabledTooltip: t('SEGMENT_DUPLICATE_MODAL.duplicateOnly1'),
      },
      {
        id: 'distribute-contacts',
        icon: <DistributeContactsIcon />,
        onClick: () => setOpenDistributeModal(true),
        tooltip: t('MANAGE_DISTRIBUTE_CONTACTS_MODAL.title'),
        flagOn: true,
        disabled: distributeIsDisabled,
        disabledTooltip: t('SEGMENT_DUPLICATE_MODAL.duplicateOnly1'),
      },
      {
        id: 'delete',
        icon: <DeleteIconSolid />,
        onClick: () => setOpenDeleteModal(true),
        tooltip:
          contactSelection === 1
            ? t('GROUP_DELETE_MODAL.title')
            : t('GROUP_DELETE_MODAL.titlePlural'),
        flagOn: true,
        disabled: false,
        disabledTooltip: '',
      },
      {
        id: 'export',
        icon: <DownloadIcon color="#004DBC" />,
        onClick: () => setOpenDownloadModal(true),
        tooltip: t('SEGMENT_DOWNLOAD_MODAL.title'),
        flagOn: true,
        disabled: contactSelection > 1 || contactsDownload.show,
        disabledTooltip: '',
      },
    ],
    [
      contactSelection,
      segmentsSelected,
      segmentsSwitcher,
      distributeIsDisabled,
      contactsDownload.show,
      setOpenContactsToFlow,
      setOpenDuplicateModal,
      setOpenDistributeModal,
      setOpenDeleteModal,
      setOpenDownloadModal,
    ]
  );

  return (
    <ActionBar id="segmentActionBar">
      <div>
        <section className="flex h-full items-center">
          <div className="text-xs ml-10 text-white" style={{ fontSize: '12px' }}>
            {`${quantifier(contactSelection) ?? 0} ${t('CONTACTS_ACTIONS_DROPDOWN.Selected')}`}
          </div>
          <div className="ml-5 flex">
            {iconsActions.map((element) => {
              const {
                disabled,
                icon,
                id,
                disabledTooltip,
                tooltip,
                onClick: handleOnClick,
              } = element;
              return (
                element.flagOn && (
                  <div key={id} className="mr-2">
                    <Button
                      fullWidth
                      standard
                      disabled={disabled}
                      iconLeft={icon}
                      id={id}
                      tooltip={disabled ? disabledTooltip : tooltip}
                      onClick={handleOnClick}
                    />
                  </div>
                )
              );
            })}
          </div>
        </section>
      </div>
    </ActionBar>
  );
};
