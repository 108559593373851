import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { RulesOptions, TriggerTypes } from '@/src/modules/RulesModule/interfaces';

import { atomIsConditionFormComplete } from '@/modules/RulesModule/atoms/rules';

const checkIsConditionFormComplete = (rulesData: RulesOptions): boolean => {
  if (!rulesData) return false;
  const { trigger, presenceCondition, selectedGroupOrSegment } = rulesData;

  if (trigger?.value === TriggerTypes.DATE) {
    return !!(presenceCondition?.name && selectedGroupOrSegment?.id);
  }

  return false;
};

const useIsConditionFormComplete = (rulesData: RulesOptions) => {
  const setIsConditionFormComplete = useSetAtom(atomIsConditionFormComplete);

  useEffect(() => {
    const isComplete = checkIsConditionFormComplete(rulesData);
    setIsConditionFormComplete(isComplete);
  }, [rulesData, setIsConditionFormComplete]);
};

export default useIsConditionFormComplete;
