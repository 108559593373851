import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { RulesOptions, TriggerTypes } from '@/src/modules/RulesModule/interfaces';

import { atomIsTriggerFormComplete } from '@/modules/RulesModule/atoms/rules';

const checkIsTriggerFormComplete = (rulesData: RulesOptions): boolean => {
  if (!rulesData) return false;

  const { trigger, customField, frequency, execution, days, period, hours, minutes } = rulesData;

  if (trigger?.value === TriggerTypes.DATE) {
    if (execution?.id === 1) {
      return Boolean(
        trigger?.value &&
          customField?.value &&
          frequency?.value &&
          execution?.value &&
          hours?.value &&
          minutes?.value
      );
    }

    return Boolean(
      trigger?.value &&
        customField?.value &&
        frequency?.value &&
        execution?.value &&
        days?.value &&
        period?.value &&
        hours?.value &&
        minutes?.value
    );
  }

  if (
    trigger?.value === TriggerTypes.API_INTEGRATOR ||
    trigger?.value === TriggerTypes.FLOW_TEMPLATE
  ) {
    return true;
  }

  return false;
};

const useIsTriggerFormComplete = (rulesData: RulesOptions) => {
  const setIsTriggerFormComplete = useSetAtom(atomIsTriggerFormComplete);

  useEffect(() => {
    const isComplete = checkIsTriggerFormComplete(rulesData);
    setIsTriggerFormComplete(isComplete);
  }, [rulesData, setIsTriggerFormComplete]);
};

export default useIsTriggerFormComplete;
