import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { formValidations } from '@/src/constants/FormValidations';
import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { atomActionType } from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomDetailCampaign,
  atomInfoCampaignV1,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomActionInputModalIsOpen,
  atomActionTypeWithOptionalRule,
  atomRestrictions,
} from '@/src/modules/CampaignsModule/atoms/createActionsAtom';
import { ACTION_CREATE_MESSAGE } from '@/src/modules/CampaignsModule/constants';
import { EResourceType } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { convertValuesToString } from '@/src/utils/Objects';

import { useValidNameResource } from './useValidNameResource';

import { handleInvalidMFA } from '@/modules/AuthModule/hooks/useMfa';
import { ACTION_CREATED } from '@/modules/ContactsModule/constants';
import { FieldDTO, PayloadSaveRules, TriggerTypes } from '@/modules/RulesModule/interfaces';

export const useCreateAction = (campaignId: string) => {
  const service = useService();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const setActionInputModalIsOpen = useSetAtom(atomActionInputModalIsOpen);
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const [actionTypeWithOptionalRule, setActionTypeWithOptionalRule] = useAtom(
    atomActionTypeWithOptionalRule
  );
  const [restrictions, setRestrictions] = useAtom(atomRestrictions);
  const actionType = useAtomValue(atomActionType);
  const { name: campaignName } = useAtomValue(atomDetailCampaign);

  const [createActionIsLoading, setCreateActionIsLoading] = useState(false);

  const { userData } = useStateUserContext();
  const rulesService = useRulesService();
  const { fetchServiceValidName } = useValidNameResource();

  const { formRegExp, inputLengths } = formValidations;

  // cspell:disable
  const saveDefaultRuleTypeAfterCreate = useCallback(
    async (actionId: number) => {
      const { ruleType } = actionTypeWithOptionalRule;

      if (!ruleType) return;

      const templatePayload = {
        acumuladaCantidad: null,
        campoDTO: {} as FieldDTO,
        camposPersonalizadosDTO: [],
        elementoId: actionId,
        elementosDTO: [],
        elementosHnosCantidad: 0,
        enviarCadaAnio: null,
        envioRecurrente: false,
        fechaEnvio:
          ruleType === TriggerTypes.DATE || ruleType === TriggerTypes.TAG
            ? '1|mes|antes|dia'
            : 'de inmediato',
        tag_id: [],
        tipo: 'Clicks',
        urlDestino: null,
        urlDestinoError: null,
      };
      const payloadSave = {
        ...templatePayload,
        desencadenante: ruleType,
      } as PayloadSaveRules;

      await rulesService.saveRule(payloadSave);
    },
    [actionTypeWithOptionalRule, rulesService]
  );
  // cspell:enable

  const VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
        pattern: {
          value: formRegExp.lettersNumbersAndSpecials,
          message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
        },
      },
    }),
    [formRegExp.lettersNumbersAndSpecials, inputLengths.max, inputLengths.min, t]
  );

  const handleValidation = useCallback(
    async (value: string) => {
      const isValidName = await fetchServiceValidName({
        name: value,
        resource: EResourceType.ACTION,
        campaignId: Number(campaignId),
      });
      return {
        isValidInput: isValidName,
        message: t('CAMPAIGN_ACTIONS_MAIN.existAction'),
      };
    },
    [campaignId, fetchServiceValidName, t]
  );

  const handleCloseModal = useCallback(() => {
    setActionInputModalIsOpen(false);
    setActionTypeWithOptionalRule(RESET);
  }, [setActionInputModalIsOpen, setActionTypeWithOptionalRule]);

  const handleSubmitData = useCallback(
    async (actionName: string) => {
      setCreateActionIsLoading(true);
      actionName = actionName.trim();
      try {
        const response = await service.createCampaignAction(
          actionName,
          actionTypeWithOptionalRule.name,
          campaignId ?? ''
        );

        if (response.message === ACTION_CREATE_MESSAGE) {
          const actionId = response.id;

          await saveDefaultRuleTypeAfterCreate(actionId);

          const userDataStr = convertValuesToString(userData);
          const { name, email, plan, contractId } = userDataStr;
          await service.sendEventTrack('actions', {
            names: name as string,
            email: email as string,
            plan: plan as string,
            contractId: contractId as string,
            type: ACTION_CREATED,
          });

          toast({
            title: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateSuccess.title'),
            body: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateSuccess.body'),
          });
          setInfoCampaignV1({
            campaign: {
              id: parseInt(campaignId || '0'),
              name: campaignName ?? '',
            },
            action: {
              id: Number(actionId),
              name: actionName,
            },
            route: actionType
              ? `/v2/campaigns/actions-${actionType}`
              : `/v2/campaigns/${campaignId}`,
          });
          navigate(`${actionTypeWithOptionalRule.name}/${actionId}`);
        }
      } catch (e) {
        if ((e as Error).message === 'INVALID_MFA_TOKEN') {
          handleInvalidMFA(t);
        } else {
          toast({
            variant: 'error',
            title: t('CREATE_ACTIONS_MODAL.NOTIFICATION.actionCreateError.title'),
            body: (e as Error).message,
          });
        }
      }
      setCreateActionIsLoading(false);
      setActionInputModalIsOpen(false);
    },
    [
      campaignId,
      service,
      actionTypeWithOptionalRule.name,
      userData,
      campaignName,
      actionType,
      t,
      setActionInputModalIsOpen,
      saveDefaultRuleTypeAfterCreate,
      setInfoCampaignV1,
      navigate,
    ]
  );

  const getRestrictions = useCallback(async () => {
    const response = await service.getRestrictions();
    setRestrictions(response);
  }, [service, setRestrictions]);

  useEffect(() => {
    if (restrictions !== null) return;

    void getRestrictions();
  }, [getRestrictions, restrictions]);

  return {
    handleValidation,
    handleSubmitData,
    handleCloseModal,
    VALIDATION_RULES,
    createActionIsLoading,
    restrictions,
  };
};
