import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { formValidations } from '@/src/constants/FormValidations';

import {
  atomDetailCampaign,
  atomFiltersCampaign,
  atomHistorySearchPagerCampaign,
  atomLoadingDuplicateCampaign,
  atomPagerCampaign,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateCampaignModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { defaultPager } from '@/modules/CampaignsModule/constants';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { IFormInput } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useDuplicateCampaignSingle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { duplicateCampaign } = useCampaign();
  const { fetchServiceValidName } = useValidNameResource();

  const pager = useAtomValue(atomPagerCampaign);
  const filters = useAtomValue(atomFiltersCampaign);
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const [loading, setLoading] = useAtom(atomLoadingDuplicateCampaign);
  const setToggleDuplicateCampaignModal = useSetAtom(atomToggleDuplicateCampaignModal);
  const [historySearchPagerCampaign, setHistorySearchPagerCampaign] = useAtom(
    atomHistorySearchPagerCampaign
  );

  const { inputLengths } = formValidations;

  const VALIDATION_RULES = {
    input: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
      pattern: {
        value: formValidations.formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
    },
  };

  const handleCloseModal = useCallback(() => {
    setToggleDuplicateCampaignModal(false);
  }, [setToggleDuplicateCampaignModal]);

  const handleValidation = useCallback(
    async (value: string) => {
      const isValidName = await fetchServiceValidName({
        name: value,
        resource: EResourceType.CAMPAIGN,
      });
      return {
        isValidInput: isValidName,
        message: t('CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS.existCampaign'),
      };
    },
    [fetchServiceValidName, t]
  );

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      setLoading(true);
      const campaignIdDuplicate = await duplicateCampaign({
        name: nameValue,
        campaignId: Number(detailCampaign.campaignId),
      } as IFormInput);
      handleCloseModal();
      if (campaignIdDuplicate) {
        if (!historySearchPagerCampaign) {
          setHistorySearchPagerCampaign({
            filters: { ...filters },
            pager: {
              page: pager?.page || defaultPager.page,
              limit: pager?.limit || defaultPager.limit,
            },
          });
        }
        navigate(`/v2/campaigns/${campaignIdDuplicate}`);
      }
      setLoading(false);
    },
    [
      setLoading,
      duplicateCampaign,
      detailCampaign.campaignId,
      handleCloseModal,
      historySearchPagerCampaign,
      navigate,
      setHistorySearchPagerCampaign,
      filters,
      pager?.page,
      pager?.limit,
    ]
  );

  return {
    VALIDATION_RULES,
    handleValidation,
    handleSubmitData,
    handleCloseModal,
    loading,
  };
};
