import { FieldErrors } from 'react-hook-form';

import { Flex } from '@/lib/v2/components';

import { ITriggerForm } from '@/src/modules/RulesModule/components/FormTrigger/hooks/useFormTrigger';

import GroupContacts from '@/modules/RulesModule/components/FormTrigger/components/TriggerApi/components/GroupContacts';
import RepeatedEmails from '@/modules/RulesModule/components/FormTrigger/components/TriggerApi/components/RepeatedEmails';
import { IFormProps } from '@/modules/RulesModule/interfaces';

interface IConditionTriggerApiProps {
  errors: FieldErrors<ITriggerForm>;
  form: IFormProps<ITriggerForm>;
  disabled?: boolean;
}
const TriggerApi = ({ form, errors, disabled = false }: IConditionTriggerApiProps) => {
  return (
    <Flex column alignment="start" className="mb-5 flex flex-col" itemAlignment="start">
      <RepeatedEmails disabled={disabled} form={form} />
      <GroupContacts disabled={disabled} errors={errors} form={form} />
    </Flex>
  );
};

export default TriggerApi;
