const SkeletonNode = () => {
  return (
    <div className="mb-2 flex w-full animate-pulse flex-col gap-2 pt-1">
      <div className="h-3 w-20 rounded-full bg-gray-200"></div>
      <hr />
      <div className="h-3 w-20 rounded-full bg-gray-200"></div>
      <div className="h-3 w-36 rounded-full bg-gray-200"></div>
    </div>
  );
};

export default SkeletonNode;
