import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import { Card } from '@/lib/v2/components';

import { atomIsLoadingRuleData, atomTriggerType } from '@/src/modules/RulesModule/atoms/rules';
import { SkeletonNode } from '@/src/modules/RulesModule/components/Skeleton';
import { TriggerTypes } from '@/src/modules/RulesModule/interfaces';

import EmailContent from './components/EmailContent';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';

const ActionNode = () => {
  const { campaignId, actionId, actionType } = useParams();
  const navigate = useNavigate();
  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const triggerType = useAtomValue(atomTriggerType);

  const { content, emailInfo } = actionInfoEmail || {};

  const handleAddAction = useCallback(() => {
    if (campaignId && actionId && actionType) {
      navigate(`${CAMPAIGNS_PATH}/${campaignId}/${actionType}/${actionId}/email`);
    }
  }, [campaignId, actionId, actionType, navigate]);

  const showBorderAlert = useMemo(() => {
    return (
      !emailInfo?.subject ||
      (triggerType?.value === TriggerTypes.FLOW_TEMPLATE &&
        (!emailInfo?.subject || !content?.message))
    );
  }, [emailInfo?.subject, content?.message, triggerType?.value]);

  const classNameCard = classNames(
    'w-72 !px-4 pb-0 hover:outline-none hover:ring-2 hover:ring-emblue-primary hover:ring-offset-2',
    { '!border-emblue-alert': showBorderAlert }
  );

  return (
    <>
      <Handle className="handle" position={Position.Top} type="target" />
      <Card className={classNameCard} onClick={handleAddAction}>
        {isLoadingRuleData ? <SkeletonNode /> : <EmailContent />}
      </Card>
    </>
  );
};

export default memo(ActionNode);
