import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Button, IconSvg, Modal, Text } from '@/lib/v2/components';
import { ActionConfirmIcon } from '@/lib/v2/icons/general';

import { atomToggleActivateRuleModal } from '@/src/modules/RulesModule/atoms/rules';
import { useRuleStatus } from '@/src/modules/RulesModule/hooks/useRuleStatus';

const MODAL_ACTIVATE_RULE = 'RULES.MODAL_ACTIVATE_RULE';

interface IActivateActionProps {
  onConfirm: () => void;
  loading?: boolean;
}

const ActivateAction = ({ onConfirm, loading }: IActivateActionProps) => {
  const { t } = useTranslation('rules');

  const [toggleActivateRuleModal, setToggleActivateRuleModal] = useAtom(
    atomToggleActivateRuleModal
  );

  const { isRuleActive } = useRuleStatus();

  const handleActivateAction = () => onConfirm?.();
  const handleCancel = () => setToggleActivateRuleModal(false);
  const detail = isRuleActive
    ? t(`${MODAL_ACTIVATE_RULE}.detailInactivate`)
    : t(`${MODAL_ACTIVATE_RULE}.detailActivate`);

  return (
    <Modal open={toggleActivateRuleModal} showCloseButton={false}>
      <div className="flex flex-col justify-center">
        <div className="flex w-full flex-col">
          <Text fontWeight="medium" variant="sub-headline">
            {t(`${MODAL_ACTIVATE_RULE}.title`)}
          </Text>
          <hr className="my-4 divide-y" />
        </div>
        <div className="mx-auto flex flex-col justify-center align-middle">
          <IconSvg
            className="mx-auto my-6"
            height="164px"
            svgComponent={<ActionConfirmIcon />}
            width="164px"
          />
          <Text alignment="center" as="p" variant="sub-headline">
            {detail}
          </Text>
          <Text alignment="center" as="p" className="px-6" variant="sub-headline">
            {t(`${MODAL_ACTIVATE_RULE}.description`)}
          </Text>
        </div>
        <div className="mt-9 flex w-full justify-center gap-3">
          <Button outline id="modal-confirm__cancel" onClick={handleCancel}>
            {t(`${MODAL_ACTIVATE_RULE}.cancel`)}
          </Button>
          <Button id="modal-confirm__action" isLoading={loading} onClick={handleActivateAction}>
            {t(`${MODAL_ACTIVATE_RULE}.confirm`)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ActivateAction;
