import { EdgeProps, NodeProps } from 'reactflow';

import ActionNode from '@/src/modules/RulesModule/components/ActionNode';
import ConditionNode from '@/src/modules/RulesModule/components/ConditionNode';
import EmptyNode from '@/src/modules/RulesModule/components/EmptyNode';
import SelectorNode from '@/src/modules/RulesModule/components/SelectorNode';
import TriggerNode from '@/src/modules/RulesModule/components/TriggerNode';

import EmptyEdge from '@/modules/RulesModule/components/EmptyEdge';
import SelectorEdge from '@/modules/RulesModule/components/SelectorEdge';

export type NodeTypes = 'empty' | 'selector' | 'trigger' | 'condition' | 'action';

export const NODE_TYPES = {
  empty: () => <EmptyNode />,
  selector: () => <SelectorNode />,
  trigger: (props: NodeProps) => <TriggerNode {...props} />,
  condition: (props: NodeProps) => <ConditionNode {...props} />,
  action: () => <ActionNode />,
};

export const EDGE_TYPES = {
  empty: (props: EdgeProps) => <EmptyEdge {...props} />,
  selector: (props: EdgeProps) => <SelectorEdge {...props} />,
};
