import { EdgeProps, getBezierPath } from 'reactflow';

interface CustomEdgeData {
  text?: string;
  onClick?: () => void;
}

const CustomEdge: React.FC<EdgeProps<CustomEdgeData>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        className="react-flow__edge-path"
        d={edgePath}
        id={id}
        markerEnd={markerEnd}
        style={style}
      />
      <text>
        <textPath
          href={`#${id}`}
          startOffset="50%"
          style={{ fontSize: '12px', backgroundColor: 'red', padding: '1rem' }}
          textAnchor="middle"
        >
          {data?.text}
        </textPath>
      </text>
    </>
  );
};

export default CustomEdge;
