import { Handle, Position } from 'reactflow';

const EmptyNode = () => (
  <div className="w-72">
    <Handle position={Position.Top} type="target" />
    <div className="text-transparent">.</div>
    <Handle position={Position.Bottom} type="source" />
  </div>
);

export default EmptyNode;
