/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { ICategory, ICollection, ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { IBeefreeService, IEmblueApiMethodNameMap } from './IBeefreeService';

import { ILinkTemplateV1 } from '@/modules/CampaignsModule/adapters/TemplateAdapter';
import {
  IFiltersMyTemplateCatalog,
  IFiltersTemplateCatalog,
} from '@/modules/CampaignsModule/atoms/beefreeAtom';
import {
  IBeefreeMailPayload,
  IBeefreeParamStart,
  IBeefreePreviewResponse,
  IBeefreePreviewResponseV1,
  IBeefreeRowPayload,
  IBeefreeSaveLinks,
  IBeefreeSaveLinksResponse,
  IBeefreeSaveLinksResponseContent,
  IBeefreeSaveResponse,
  IBeefreeSaveTemplatePayload,
  IBeefreeSaveTemplateResponse,
  IBeefreeSendResponse,
  IEmailOfCatalog,
  IEmblueCatalogTemplatesResponse,
  IEmblueRowsResponse,
} from '@/modules/CampaignsModule/interfaces/Beefree';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class BeefreeService implements IBeefreeService {
  private api: APIService;
  private apiV1: APIService;

  private constructor(payload: IBeefreeParamStart) {
    this.api = new APIService(payload.url_ema_app_v2, payload.jwt);
    this.apiV1 = new APIService(payload.url_ema_app_v1, payload.jwt);
  }

  /**
   * @desc Catch errors from API
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error BeefreeService[${method}]:`, message);
  }

  /**
   * @desc Create new instance of BeefreeService
   */
  static create(payload: IBeefreeParamStart): IBeefreeService {
    return new BeefreeService(payload);
  }

  /**
   * @desc Save a email template
   */
  async getEmail(actionId: number): Promise<IBeefreeSaveResponse> {
    try {
      const { data }: { data: IBeefreeSaveResponse } = await this.api.get(
        `api/v2.1/editor/mailContent`,
        { actionId }
      );

      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getEmail');
      return { statusOK: false };
    }
  }

  /**
   * @desc Save a email
   */
  async saveEmail(payload: IBeefreeMailPayload): Promise<IBeefreeSaveResponse> {
    try {
      const { actionId, ...body } = payload;
      // cspell:disable
      const { data }: { data: IBeefreeSaveResponse } = await this.api.post(
        `api/v2.1/editor/saveMailContent`,
        {
          ...body,
          envioId: actionId,
        }
      );
      // cspell:disable
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'saveEmail');
      return { statusOK: false };
    }
  }

  /**
   * @desc Send a email template
   */
  async sendEmail(id: number): Promise<boolean> {
    try {
      // cspell:disable
      const { data } = (await this.apiV1.get(`Services/Campanias.svc/ConfirmarElemento`, {
        elementoId: id,
      })) as { data: IBeefreeSendResponse };
      // cspell:disable
      return data.id_notification === 'ELEMENTO_CONFIRMADO_OK';
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'sendEmail');
      return false;
    }
  }

  /**
   * @desc Save the links of a template of beefree with endpoint of v1
   */
  async saveTemplateLinks(
    id: number,
    links: ILinkTemplateV1[],
    linksTemporal: ILinkTemplateV1[]
  ): Promise<IBeefreeSaveLinks> {
    try {
      // cspell:disable
      const { data } = (await this.apiV1.post(`Services/Campanias.svc/GuardarModificarLinks`, {
        elementoId: id,
        Links: links,
        LinksTemporal: linksTemporal,
      })) as { data: IBeefreeSaveLinksResponse };

      const content: IBeefreeSaveLinksResponseContent = JSON.parse(data.content ?? '') ?? {};
      return {
        newLinks: content?.NewLinks,
        modifiedLinks: content?.ModifiedLinks,
      };
      // cspell:disable
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'sendTemplateLinks');
      return {
        newLinks: [],
        modifiedLinks: [],
      };
    }
  }

  /**
   * @desc Get email of preview
   */
  async getPreview(id: number): Promise<IBeefreePreviewResponse | null> {
    try {
      // cspell:disable
      const { data } = (await this.apiV1.get(`Services/Campanias.svc/ResumenMensajePreview`, {
        elementoId: id,
      })) as { data: IBeefreePreviewResponseV1 };

      return {
        id: data.elementoId,
        sendId: data.elementoEnvioId,
        emailFrom: data.emailFrom,
        emailReply: data.emailReply,
        fromId: data.nombreFromId,
        name: data.nameFrom,
        remarketing: data.remarketing,
        subject: data.asunto,
        message: data.mensaje,
        header: data.header,
        footer: data.footer,
        origenId: data.origenId,
        history: data.tieneHistorial,
        errors: data.errores,
      };
      // cspell:disable
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'sendEmail');
      return null;
    }
  }

  /**
   * @desc Get all tags of the catalog
   */
  async getTags(): Promise<ITag[]> {
    try {
      // cspell:disable
      const { data: response } = (await this.api.get(
        `api/v2.1/beefree/emails/templates/tags`
      )) as IBeefreeSaveResponse;
      // cspell:disable
      return response.data as ITag[];
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getTags');
      return [];
    }
  }

  async deleteCurrentLinks(actionId: number): Promise<boolean> {
    try {
      // cspell:disable
      const { status } = (await this.api.delete(
        `api/v2.1/editor/mailContent/${actionId}/links`
      )) as IBeefreeSaveResponse;
      // cspell:disable
      return status === 200;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status !== 403)
        this.catchErrors(error as AxiosError<Error>, 'deleteCurrentLinks');
      return false;
    }
  }

  /**
   * @desc Get all categories of the catalog
   */
  async getCategories(): Promise<ICategory[]> {
    try {
      // cspell:disable
      const { data: response } = (await this.api.get(
        `api/v2.1/beefree/emails/templates/categories`
      )) as IBeefreeSaveResponse;
      // cspell:disable
      return response.data as ICategory[];
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getCategories');
      return [];
    }
  }
  /**
   * @desc Get all collections of the catalog
   */
  async getCollections(): Promise<ICollection[]> {
    try {
      // cspell:disable
      const { data: response } = (await this.api.get(
        `api/v2.1/beefree/emails/templates/collections`
      )) as IBeefreeSaveResponse;
      // cspell:disable
      return response.data as ICollection[];
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getCollections');
      return [];
    }
  }

  /**
   * @desc Get all collections of the catalog
   */
  async getMyTemplates(filters: IFiltersMyTemplateCatalog) {
    try {
      // cspell:disable
      const { data: response } = (await this.api.get(
        `api/v2.1/beefree/emails/my-templates`,
        filters
      )) as IBeefreeSaveResponse;
      // cspell:disable
      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getMyTemplates');
      return [];
    }
  }

  /**
   * @desc Save a template email
   */
  async saveTemplate(
    payload: IBeefreeSaveTemplatePayload
  ): Promise<IBeefreeSaveTemplateResponse | null> {
    try {
      // cspell:disable
      const { data }: { data: IBeefreeSaveResponse } = await this.api.post(
        `api/v2.1/beefree/emails/template`,
        payload
      );
      // cspell:disable
      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          const { data } = error.response;
          return data as IBeefreeSaveTemplateResponse;
        }
      }

      this.catchErrors(error as AxiosError<Error>, 'saveTemplate');
      return null;
    }
  }

  /**
   * @desc Get a template email
   */
  async getTemplate(templateId: number): Promise<IEmailOfCatalog | null> {
    try {
      // cspell:disable
      const { data } = (await this.api.get(`api/v2.1/beefree/emails/template`, {
        templateId,
      })) as { data: IEmailOfCatalog };
      // cspell:disable
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getTemplate');
      return null;
    }
  }

  async getTemplatesEmblue(
    filters: IFiltersTemplateCatalog
  ): Promise<IEmblueCatalogTemplatesResponse> {
    try {
      // cspell:disable
      const { data } = await this.api.get(`api/v2.1/beefree/emails/templates`, {
        tags: filters.tags?.map((item) => item.id),
        collections: filters.collections?.map((item) => item.id),
        categories: filters.categories?.map((item) => item.id),
        search: filters.search,
        pagination: {
          limit: filters.limit,
          page: filters.page,
        },
      });
      // cspell:disable
      return data as IEmblueCatalogTemplatesResponse;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getTemplatesEmblue');
      return {
        data: [],
        pagination: {
          page: 0,
          total: 0,
        },
      };
    }
  }

  async useTemplate(templateId: number, actionId: number): Promise<boolean> {
    try {
      // cspell:disable
      const { data }: { data: IBeefreeSaveResponse } = await this.api.post(
        `api/v2.1/beefree/emails/template/use`,
        {
          templateId,
          actionId,
        }
      );
      // cspell:disable
      return data.success;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'useTemplate');
      return false;
    }
  }

  async deleteTemplate(id: number): Promise<boolean> {
    try {
      // cspell:disable
      const { data }: { data: IBeefreeSaveResponse } = await this.api.delete(
        `api/v2.1/beefree/emails/template/${id}`
      );
      // cspell:disable
      return data.success;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'deleteTemplate');
      return false;
    }
  }
  async deleteTemplateEmblue(id: number): Promise<boolean> {
    try {
      // cspell:disable
      const { data }: { data: IBeefreeSaveResponse } = await this.api.delete(
        `api/v2.1/beefree/emails/template/emblue/${id}`
      );
      // cspell:disable
      return data.success;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'deleteTemplateEmblue');
      return false;
    }
  }

  async getBeefreeTemplate(id: number) {
    try {
      // cspell:disable
      const { data }: { data: IBeefreeSaveResponse } = await this.api.get(`/v1/catalog/template`, {
        id,
      });
      // cspell:disable
      return data as IEmailOfCatalog;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'deleteTemplateEmblue');
      return null;
    }
  }

  async getDefaultRows(): Promise<IEmblueRowsResponse> {
    try {
      // cspell:disable
      const { data } = await this.api.get(`api/v2.1/beefree/emails/rows/by_default`);
      // cspell:disable
      return data as IEmblueRowsResponse;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getDefaultRows');
      return [];
    }
  }

  async getRowsByUser(): Promise<IEmblueRowsResponse> {
    try {
      // cspell:disable
      const { data } = await this.api.get(`api/v2.1/beefree/emails/rows/user_saved`);
      // cspell:disable
      return data as IEmblueRowsResponse;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getRowsByUser');
      return [];
    }
  }

  /**
   * @desc Save a row
   */
  async saveRow(payload: IBeefreeRowPayload): Promise<IBeefreeSaveResponse> {
    try {
      // cspell:disable
      const { data }: { data: IBeefreeSaveResponse } = await this.api.post(
        `api/v2.1/beefree/emails/rows`,
        payload
      );
      // cspell:disable
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'saveRow');

      if (error instanceof AxiosError) {
        const { response = {} } = error as { response: any };
        const { type } = response?.data ?? {};
        return {
          error: true,
          type: type as unknown as string,
        };
      }
      return {
        error: true,
      };
    }
  }

  /**
   * @desc Delete a row
   */
  async deleteRow(id: string): Promise<boolean> {
    try {
      // cspell:disable
      const { data }: { data: boolean } = await this.api.delete(
        `api/v2.1/beefree/emails/rows/${id}`
      );
      // cspell:disable
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'deleteRow');
      return false;
    }
  }
}

export const BeefreeServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  BeefreeService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof BeefreeService);
