import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDown, SearchInputBar } from '@/lib/components';
import { CleanBoxRef } from '@/lib/components/SearchInputBar/SearchInputBar';
import { ActivityCleanFilterIcon, ActivityFilterIcon, ChannelsTableIcon } from '@/lib/icon';
import { Button, DateInput } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';
import { Flex } from '@/lib/v2/components/Layout/Stack';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
export interface IChannelFilter {
  onSearch?: (search: string) => void;
  filterChannel?: (filter: number[]) => void;
  filterActivity?: (filter: string[]) => void;
  onReset?: () => void;
  resetSearch?: () => void;
  filterChannelSelected?: number[];
  filterActivitySelected?: string[];
  cleanBoxRef?: RefObject<CleanBoxRef>;
}

export const ChannelFilters = ({
  onSearch,
  resetSearch,
  onReset,
  cleanBoxRef,
  filterChannel,
  filterActivity,
  filterChannelSelected = [],
  filterActivitySelected = [],
}: IChannelFilter) => {
  const { t } = useTranslation();
  const [activityFilters] = useEmblue(ServiceMethods.getContactActivityFilter);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const handleOnChange: eventOnChangeDateInput = (e) => {
    if (Array.isArray(e)) {
      setDateRange(e as [Date | null, Date | null]);
    }
  };
  return (
    <>
      <Flex alignment="start" itemAlignment="center">
        <Flex withGap alignment="start">
          <div className="w-[250px]">
            <SearchInputBar
              ref={cleanBoxRef}
              withButton
              cleanBox={resetSearch}
              placeHolder={t('CHANNEL_FILTERS.searchInputBar')}
              onAction={(ev) => onSearch && onSearch(ev)}
            />
          </div>
          <div className="w-auto">
            <DropDown
              checkBox
              overflowWidth
              btnOnAction={(itemFilterList: string[]) => {
                filterChannel && filterChannel(itemFilterList.map((i) => Number(i)));
              }}
              btnText={t('CONTACTS_FILTERS.apply')}
              iconHead={<ChannelsTableIcon color="#004DBC" />}
              itemList={activityFilters?.channels?.map((x) => ({
                id: String(x.id),
                title: String(t(`PROFILE_CHANNEL_TABLE.channelType.${x.id}`)),
              }))}
              titleHead={t('CHANNEL_FILTERS.channel')}
              tooltip={t('CHANNEL_FILTERS.filterChannel')}
              totalSelectedChk={filterChannelSelected?.length}
              valuesSelected={filterChannelSelected.map((i) => String(i))}
            />
          </div>
          <div className="w-auto">
            <DropDown
              checkBox
              overflowWidth
              btnOnAction={(itemFilterLists: string[]) => {
                filterActivity && filterActivity(itemFilterLists);
              }}
              btnText={t('CONTACTS_FILTERS.apply')}
              iconHead={<ActivityFilterIcon color="#004DBC" />}
              itemList={activityFilters?.activities?.map((x) => ({
                id: String(x.id),
                title: String(t(`PROFILE_CHANNEL_FILTER.activityType.${x.id}`)),
              }))}
              titleHead={t('CHANNEL_FILTERS.activity')}
              tooltip={t('CHANNEL_FILTERS.filterActivity')}
              totalSelectedChk={filterActivitySelected?.length}
              valuesSelected={filterActivitySelected}
            />
          </div>
          <div className="z-10 w-auto">
            <Button
              standard
              className="!px-2.5"
              iconLeft={<ActivityCleanFilterIcon color="#004DBC" />}
              id="clear-filters-button"
              tooltip={t('NOTES_FILTERS.cleanAllFilters')}
              onClick={onReset}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
};
