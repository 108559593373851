export enum Jodit {
  directoryCreated = 'Directory successfully created',
  directoryExist = 'Directory already exists',
  fileRemove = 'File remove successfully',
  errorAddingFile = 'Error adding file to BD',
  unsupportedFile = 'Input file contains unsupported image format',
  emptyPath = '',
  basePath = '/',
  htmlContentToLarge = 'Error: HTML content too large',
}
