export const CreateEdgeIcon = ({ color = '#FFFFFF' }) => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="10" cy="9.90203" fill="#BFBFBF" rx="10" ry="9.67742" />
      <path
        d="M14.834 9.37305V11.0723H5.08789V9.37305H14.834ZM10.8691 5.22266V15.5742H9.0625V5.22266H10.8691Z"
        fill={color}
      />
    </svg>
  );
};
