import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { ModalSingleInput } from '@/lib/v2/examples';

import {
  atomSelectedActionsRowsWithID,
  atomSelectedRowActionCampaignId,
  atomSelectedRowActionName,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomToggleEditActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useEditActions } from '@/modules/CampaignsModule/hooks/useEditActions';

const EditActions = () => {
  const { t } = useTranslation();
  const toggleEditActionsModal = useAtomValue(atomToggleEditActionsModal);
  const actionId = useAtomValue(atomSelectedActionsRowsWithID);
  const actionName = useAtomValue(atomSelectedRowActionName);
  const campaignId = useAtomValue(atomSelectedRowActionCampaignId);

  const { handleSubmitData, handleCloseModal, handleValidation, VALIDATION_RULES, loading } =
    useEditActions({ actionId: Number(actionId[0]), campaignId });

  return (
    <>
      <ModalSingleInput
        disabledUntilOnChange
        id="edit-action-table"
        isLoading={loading}
        isOpen={toggleEditActionsModal}
        rules={VALIDATION_RULES}
        title={t('CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.title')}
        value={actionName}
        onClose={handleCloseModal}
        onSubmit={handleSubmitData}
        onValidateInputFetch={handleValidation}
      />
    </>
  );
};

export default EditActions;
