/* cspell:disable*/
export const formValidations = {
  formRegExp: {
    email: /^[\wñÑ.%+-]+@[a-zA-ZñÑ\d.-]+\.[a-zA-ZñÑ]{2,}$/,
    letters: /^[a-zA-Z\s\-ñÑáéíóúÁÉÍÓÚâÂãÃàÀçêÊôÔõÕ]+$/,
    numbers: /^[\d\s-]+$/,
    lettersNumbersAndSpecials: /^[\w\s\-.,:;!?()'"&%$#@*+=<>/\\[\]ñÑáéíóúÁÉÍÓÚâÂãÃàÀçêÊôÔõÕ]+$/,
    lettersAndNumbers: /^[a-zA-Z\d\s\-ñÑáéíóúÁÉÍÓÚâÂãÃàÀçêÊôÔõÕ]+$/,
  },
  inputLengths: {
    min: 2,
    max: 60,
    maxObjective: 150,
  },
};
/** cspell:enable */
