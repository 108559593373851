import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIcon } from '@/lib/icon';
import { Button, IconSvg } from '@/lib/v2/components';

import { useCatalogTemplateLink } from './CatalogTemplateMenuBar.hooks';

const CatalogTemplateMenuBar = () => {
  const navigate = useNavigate();

  const { links, linksMapper } = useCatalogTemplateLink();

  const goToBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="relative z-10 flex h-[72px] w-full items-center justify-between bg-white px-4 py-10 shadow-md lg:gap-4 xl:gap-12 2xl:gap-16">
      <div className="flex h-full">
        <nav className="flex items-center lg:gap-2 xl:gap-10 2xl:gap-16">
          <Button outline standard className="w-4" size="small" onClick={() => goToBack()}>
            <IconSvg svgComponent={<ArrowBackIcon />} />
          </Button>
          <>{links?.map(linksMapper)}</>
        </nav>
      </div>
    </div>
  );
};

export default CatalogTemplateMenuBar;
