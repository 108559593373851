import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, Button, IconSvg } from '@/lib/v2/components';
import { CloseIcon } from '@/lib/v2/icons/solid';

import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';

const ActionPreviewHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { actionId } = useParams();
  const { action } = useActionData(actionId ? parseInt(actionId) : 0);

  const handleNavigate = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const ActionPreviewButtons = useMemo(() => {
    return (
      <Button onlyIcon id="preview__close" onClick={handleNavigate}>
        <IconSvg fillColor="gray-dark" height="20px" svgComponent={<CloseIcon />} width="20px" />
      </Button>
    );
  }, [handleNavigate]);

  const ActionPreviewPaths = useMemo(
    () => [
      {
        id: 'action-preview',
        name: action ? action?.name : t('CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.preview'),
      },
    ],
    [t, action]
  );

  return (
    <Breadcrumb
      withoutBackArrow
      actionButtons={ActionPreviewButtons}
      customPaths={ActionPreviewPaths}
      handleOnBackClick={handleNavigate}
    />
  );
};

export default ActionPreviewHeader;
