import { Edge, Node } from 'reactflow';

import { NodeData } from '@/src/modules/RulesModule/interfaces';
import { getId } from '@/src/modules/RulesModule/utils/getId';

import { NODE_WIDTH } from '.';

export const generateInitialNodes = (): Node<NodeData>[] => {
  const nodes: Node<NodeData>[] = [];
  const centerX = window.innerWidth / 2 - NODE_WIDTH / 2;
  nodes.push({
    id: getId(),
    type: 'trigger',
    position: { x: centerX, y: 20 },
    data: {},
  });

  return nodes;
};

export const generateInitialEdges = (): Edge[] => {
  const edges: Edge[] = [];

  return edges;
};
