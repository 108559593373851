/* eslint-disable pii/no-email */
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Input, Text, toast } from '@/lib/v2/components';

import HeaderLogin from '@/src/modules/AuthModule/components/HeaderLogin';
import { AUTH_PATHS } from '@/src/modules/AuthModule/constants';
import { useLogin } from '@/src/modules/AuthModule/hooks/useLogin';

interface FormLogin {
  email: string;
  password: string;
}

const Login = () => {
  const { t } = useTranslation();

  const LOGIN_RULES = useMemo(
    () => ({
      email: {
        required: t('AUTH.LOGIN.emailRequired'),
        pattern: {
          value: /^[^@]*@[^@]*$/,
          message: t('AUTH.LOGIN.invalidEmail'),
        },
      },
      password: {
        required: t('AUTH.LOGIN.passwordRequired'),
      },
    }),
    [t]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormLogin>();

  const [reCaptchaRequired, setReCaptchaRequired] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const recaptchaRef = useRef<any>(null);

  const { isLoading, error, login } = useLogin(recaptchaRef);

  const navigate = useNavigate();

  const handleRecoverPassword = () => {
    navigate(AUTH_PATHS.RECOVER_PATH);
  };

  const onSubmitLogin: SubmitHandler<FormLogin> = useCallback(
    (data) => {
      let recaptchaValue = '';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (recaptchaRef.current) recaptchaValue = recaptchaRef.current.getValue();

      if (recaptchaValue) {
        setReCaptchaRequired('');
        const { email, password } = data;
        void login(email, password, recaptchaValue);
      } else {
        setReCaptchaRequired(t('AUTH.LOGIN.requiredReCaptcha'));
      }
    },
    [login, t]
  );

  const {
    i18n: { language },
  } = useTranslation();

  const redirectSignUp = useCallback(() => {
    /**cspell: disable */
    navigate(
      `${AUTH_PATHS.SIGN_UP_PATH}?plan=free&utm_campaign=Organico&utm_content=LoginEmblue&utm_medium=cpc&utm_source=web`
    );
    /**cspell: enable */
  }, [navigate]);

  useEffect(() => {
    const isExpired = localStorage.getItem('token-expired') === 'true';
    if (!isExpired) return;
    localStorage.removeItem('token-expired');
    toast({
      variant: 'warning',
      title: t('AUTH.DISCONNECT.NOTIFY.title'),
      body: t('AUTH.DISCONNECT.NOTIFY.body'),
    });
  }, [t]);

  /** cspell:disable */
  return (
    <div className="flex size-full flex-col items-center justify-center gap-4 text-center">
      <HeaderLogin subTitle={t('AUTH.LOGIN.subTitle')} title={t('AUTH.LOGIN.title')} />
      <form onSubmit={handleSubmit(onSubmitLogin)}>
        <div className="flex w-full flex-col items-center gap-4 sm:w-[300px]">
          <Input
            {...register('email', LOGIN_RULES.email)}
            error={errors?.email && true}
            id="email-input"
            label={t('AUTH.LOGIN.emailLabel')}
            message={errors?.email?.message}
            placeHolder={t('AUTH.LOGIN.emailPlaceholder')}
          />
          <Input
            {...register('password', LOGIN_RULES.password)}
            error={errors?.password && true}
            id="password-input"
            label={t('AUTH.LOGIN.passwordLabel')}
            message={errors?.password?.message}
            placeHolder="ej. JohnC@rt$32,#"
            type="password"
          />
          <div>
            <ReCAPTCHA
              ref={recaptchaRef}
              hl={language && language.toUpperCase()}
              sitekey="6Ld6shATAAAAAEAFByTFZlojzOeFF-UWMZg1fw_t"
            />
            <p className="message-error">{reCaptchaRequired}</p>
          </div>
          {error && <p className="message-error !text-center">{error}</p>}
          <Button fullWidth id="login-button" isLoading={isLoading} type="submit">
            {t('AUTH.LOGIN.login')}
          </Button>
        </div>
      </form>
      <div className="mt-3 flex flex-col items-center gap-1">
        <Text link color="primary" id="recover-link" variant="text" onClick={handleRecoverPassword}>
          {t('AUTH.LOGIN.forgetPassword')}
        </Text>
      </div>
      <div className="mt-3 flex flex-col items-center gap-1">
        <Text link color="primary" id="register" variant="text" onClick={redirectSignUp}>
          {t('AUTH.LOGIN.register')}
        </Text>
      </div>
    </div>
  );
};

export default memo(Login);
