import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, IconSvg } from '@/lib/v2/components';
import { HeaderBanner } from '@/lib/v2/examples';
import { FolderIcon, FolderToolIcon } from '@/lib/v2/icons/solid';

import { DeleteCampaigns } from '@/src/modules/CampaignsModule/components/CampaignsModal';
import { DuplicateCampaign } from '@/src/modules/CampaignsModule/components/CampaignsModal/DuplicateCampaign';

import { ActionButtons } from './components/ActionButtons';
import { Content } from './components/Content';
import Footer from './components/Footer/Footer';

import {
  atomDetailCampaign,
  atomLoadingDetail,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomModalTypeProcess,
  atomScreenTypeProcess,
  atomToggleDuplicateCampaignModal,
  atomToggleEditCreateCampaignModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignModal } from '@/modules/CampaignsModule/hooks/useCampaignModal';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import {
  ECampaignsTypeOptions,
  ModalType,
  ScreenType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

const HeaderBannerActions = () => {
  const { id } = useParams();

  const { getDetailCampaign } = useCampaign();
  const [detailCampaign, setDetailCampaign] = useAtom(atomDetailCampaign);

  const loadingDetail = useAtomValue(atomLoadingDetail);

  const [toggleDuplicateCampaignModal, setToggleDuplicateCampaignModal] = useAtom(
    atomToggleDuplicateCampaignModal
  );
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const setScreenTypeProcess = useSetAtom(atomScreenTypeProcess);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);

  const { toggleDeleteModal } = useCampaignModal();
  const idCampaign: number[] = [Number(id)];

  useEffect(() => {
    void getDetailCampaign({ campaignID: Number(id) });
  }, [getDetailCampaign, id]);

  const handleOnCloseDuplicateModal = () => {
    setToggleDuplicateCampaignModal(false);
  };

  const handleClickEditCampaign = useCallback(() => {
    setModalTypeProcess(ModalType.EDIT);
    setScreenTypeProcess(ScreenType.DETAIL);
    setToggleEditCategoryModal(true);
  }, [setModalTypeProcess, setToggleEditCategoryModal, setScreenTypeProcess]);

  useEffect(() => {
    return () => {
      setDetailCampaign(RESET);
    };
  }, []);

  return (
    <Flex column>
      <HeaderBanner
        withFooter
        actionsButtons={<ActionButtons toggleModal={handleClickEditCampaign} />}
        content={<Content loading={!id || loadingDetail} toggleModal={handleClickEditCampaign} />}
        footerContent={<Footer />}
        icon={
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="auto"
            svgComponent={
              detailCampaign.campaignTypeId === ECampaignsTypeOptions.MANUAL ? (
                <FolderIcon />
              ) : (
                <FolderToolIcon />
              )
            }
            width="40px"
            withPadding="small"
          />
        }
      />
      {toggleDeleteModal && <DeleteCampaigns redirect campaigns={idCampaign} />}
      {toggleDuplicateCampaignModal && <DuplicateCampaign />}
    </Flex>
  );
};

export default memo(HeaderBannerActions);
