import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import {
  Edge,
  EdgeMouseHandler,
  Node,
  NodeMouseHandler,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import {
  atomCurrentFormType,
  atomIsActionComplete,
  atomIsConditionFormComplete,
  atomIsLoadingRuleData,
  atomIsTriggerFormComplete,
  atomRuleData,
  atomToggleSidePanel,
} from '@/src/modules/RulesModule/atoms/rules';
import { NODE_WIDTH, VERTICAL_SPACING } from '@/src/modules/RulesModule/constants';
import { FormTypes, NodeData, TriggerTypes } from '@/src/modules/RulesModule/interfaces';
import { getId } from '@/src/modules/RulesModule/utils/getId';

import { useRuleStatus } from './useRuleStatus';

export enum NodeTypes {
  TRIGGER = 'trigger',
  CONDITION = 'condition',
  ACTION = 'action',
  SELECTOR = 'selector',
  EMPTY = 'empty',
}

enum EdgeTypes {
  EMPTY = 'empty',
  SELECTOR = 'selector',
}

interface UseFlowManagementProps {
  initialNodes: Node<NodeData>[];
  initialEdges: Edge[];
}

export const createEdge = ({
  source,
  target,
  type = EdgeTypes.SELECTOR,
}: {
  source: string;
  target: string;
  type?: EdgeTypes;
}): Edge => ({
  id: `e${source}-${target}`,
  source,
  target,
  type,
});

const useFlowManagement = ({ initialNodes, initialEdges }: UseFlowManagementProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [toggleSidePanel, setToggleSidePanel] = useAtom(atomToggleSidePanel);
  const [currentFormType, setCurrentFormType] = useAtom(atomCurrentFormType);

  const isTriggerFormComplete = useAtomValue(atomIsTriggerFormComplete);
  const isConditionFormComplete = useAtomValue(atomIsConditionFormComplete);
  const isActionComplete = useAtomValue(atomIsActionComplete);
  const rulesData = useAtomValue(atomRuleData);
  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);

  const { isRuleActive } = useRuleStatus();

  const centerX = window.innerWidth / 2 - NODE_WIDTH / 2;
  const prevNodesRef = useRef(nodes);
  const prevEdgesRef = useRef(edges);

  const createNode = useCallback(
    (type: NodeTypes, parentId?: string): Node<NodeData> => {
      const parentNode = nodes.find((node) => node.id === parentId);
      return {
        id: getId(),
        type,
        position: { x: centerX, y: parentNode ? parentNode.position.y + VERTICAL_SPACING : 0 },
        data: { parentId },
        width: NODE_WIDTH,
      };
    },
    [nodes, centerX]
  );

  const findNodeByType = useCallback(
    (type: NodeTypes) => nodes.find((node) => node.type === type),
    [nodes]
  );

  const updateNodes = useCallback(() => {
    if (isLoadingRuleData) return [];

    let newNodes = [...nodes];
    const triggerNode = findNodeByType(NodeTypes.TRIGGER);
    const conditionNode = findNodeByType(NodeTypes.CONDITION);
    const actionNode = findNodeByType(NodeTypes.ACTION);
    const selectorNode = findNodeByType(NodeTypes.SELECTOR);
    const emptyNode = findNodeByType(NodeTypes.EMPTY);

    if (rulesData.trigger?.value === TriggerTypes.FLOW_TEMPLATE) {
      if (!actionNode) {
        const newActionNode = createNode(NodeTypes.ACTION, triggerNode?.id);
        newNodes.push(newActionNode);

        return newNodes;
      }
    }

    if (!isLoadingRuleData && !conditionNode && !actionNode && !selectorNode && !emptyNode) {
      const emptyNode = createNode(NodeTypes.EMPTY, triggerNode ? triggerNode.id : '1');
      newNodes.push(emptyNode);
    }

    if (isConditionFormComplete && rulesData.trigger?.value === TriggerTypes.DATE) {
      if (triggerNode && !conditionNode) {
        const conditionNode = createNode(NodeTypes.CONDITION, triggerNode.id);
        newNodes.push(conditionNode);

        if (!actionNode && !selectorNode) {
          const emptyNode = createNode(NodeTypes.EMPTY, conditionNode.id);
          newNodes.push(emptyNode);
        }
      }
    }

    if (isActionComplete && !actionNode) {
      if (conditionNode) {
        const actionNode = createNode(NodeTypes.ACTION, conditionNode.id);
        newNodes.push(actionNode);
      } else if (triggerNode) {
        const actionNode = createNode(NodeTypes.ACTION, triggerNode.id);
        newNodes.push(actionNode);
      }
    }

    if (rulesData.trigger?.value === TriggerTypes.API_INTEGRATOR) {
      if (conditionNode) {
        newNodes = newNodes.filter((node) => node.type !== NodeTypes.CONDITION);
      }
    }

    if (actionNode || (conditionNode && selectorNode)) {
      newNodes = newNodes.filter((node) => node.type !== NodeTypes.EMPTY);
    } else {
      newNodes = newNodes.filter(
        (node) =>
          node.type !== NodeTypes.EMPTY ||
          (node.type === NodeTypes.EMPTY &&
            !newNodes.some((n) => n.type === NodeTypes.EMPTY && n.id !== node.id))
      );
    }

    return newNodes;
  }, [
    nodes,
    isConditionFormComplete,
    isActionComplete,
    findNodeByType,
    createNode,
    isLoadingRuleData,
    rulesData.trigger?.value,
  ]);

  const updateEdges = useCallback(() => {
    const newEdges: Edge[] = [];

    const triggerNode = findNodeByType(NodeTypes.TRIGGER);
    const conditionNode = findNodeByType(NodeTypes.CONDITION);
    const actionNode = findNodeByType(NodeTypes.ACTION);
    const selectorNode = findNodeByType(NodeTypes.SELECTOR);
    const emptyNode = findNodeByType(NodeTypes.EMPTY);

    // Scenario 1: Trigger node alone
    if (triggerNode && !conditionNode && !actionNode && emptyNode) {
      newEdges.push(
        createEdge({ source: triggerNode.id, target: emptyNode.id, type: EdgeTypes.SELECTOR })
      );
    }

    // Scenario 2: Trigger + Condition
    if (triggerNode && conditionNode && !actionNode && !selectorNode) {
      if (!emptyNode) {
        const newEmptyNode = createNode(NodeTypes.EMPTY, conditionNode.id);
        setNodes((nds) => [...nds, newEmptyNode]);
      }

      if (emptyNode) {
        newEdges.push(
          createEdge({ source: triggerNode.id, target: conditionNode.id, type: EdgeTypes.EMPTY }),
          createEdge({ source: conditionNode.id, target: emptyNode.id, type: EdgeTypes.SELECTOR })
        );
      }
    }

    // Scenario 3: Trigger + Action
    if (triggerNode && !conditionNode && !selectorNode && actionNode) {
      const isEmptyEdge =
        [TriggerTypes.API_INTEGRATOR, TriggerTypes.FLOW_TEMPLATE].includes(
          rulesData.trigger?.value as TriggerTypes
        ) || isRuleActive;

      newEdges.push(
        createEdge({
          source: triggerNode.id,
          target: actionNode.id,
          type: isEmptyEdge ? EdgeTypes.EMPTY : EdgeTypes.SELECTOR,
        })
      );
    }

    // Scenario 4: Trigger + Selector
    if (triggerNode && !conditionNode && !actionNode && selectorNode) {
      newEdges.push(
        createEdge({ source: triggerNode.id, target: selectorNode.id, type: EdgeTypes.EMPTY })
      );
    }

    // Scenario 5: Trigger + Condition + Selector
    if (triggerNode && conditionNode && !actionNode && selectorNode) {
      newEdges.push(
        createEdge({ source: triggerNode.id, target: conditionNode.id, type: EdgeTypes.EMPTY }),
        createEdge({ source: conditionNode.id, target: selectorNode.id, type: EdgeTypes.EMPTY })
      );
    }

    // Scenario 6: Trigger + Selector + Action
    if (triggerNode && selectorNode && actionNode) {
      newEdges.push(
        createEdge({ source: triggerNode.id, target: selectorNode.id, type: EdgeTypes.EMPTY }),
        createEdge({ source: selectorNode.id, target: actionNode.id, type: EdgeTypes.EMPTY })
      );
    }

    // Scenario 7: Trigger + Condition + Action
    if (triggerNode && conditionNode && actionNode) {
      newEdges.push(
        createEdge({ source: triggerNode.id, target: conditionNode.id, type: EdgeTypes.EMPTY }),
        createEdge({ source: conditionNode.id, target: actionNode.id, type: EdgeTypes.EMPTY })
      );
    }

    return newEdges;
  }, [createNode, findNodeByType, rulesData.trigger?.value, setNodes]);

  useEffect(() => {
    const newNodes = updateNodes();
    if (JSON.stringify(newNodes) !== JSON.stringify(prevNodesRef.current)) {
      setNodes(newNodes);
      prevNodesRef.current = newNodes;
    }
  }, [updateNodes, setNodes]);

  useEffect(() => {
    const newEdges = updateEdges();
    if (JSON.stringify(newEdges) !== JSON.stringify(prevEdgesRef.current)) {
      setEdges(newEdges);
      prevEdgesRef.current = newEdges;
    }
  }, [updateEdges, setEdges]);

  useEffect(() => {
    if (!isLoadingRuleData && !isTriggerFormComplete && !isRuleActive) {
      setToggleSidePanel(true);
    }
  }, [setToggleSidePanel, isLoadingRuleData, isTriggerFormComplete, isRuleActive]);

  const handleEdgeClick: EdgeMouseHandler = useCallback(
    (_, edge) => {
      const targetNode = nodes.find((item) => item.id === edge.target);
      const sourceNode = nodes.find((item) => item.id === edge.source);

      if (
        rulesData.trigger?.value === TriggerTypes.DATE &&
        sourceNode?.type === NodeTypes.TRIGGER &&
        targetNode?.type === NodeTypes.ACTION
      ) {
        const newSelectorNode = createNode(NodeTypes.SELECTOR, sourceNode.id);

        setNodes((nds) => [...nds, newSelectorNode]);
      }

      if (targetNode?.type !== NodeTypes.EMPTY) return;

      if (sourceNode) {
        const newSelectorNode = createNode(NodeTypes.SELECTOR, sourceNode.id);

        setNodes((nds) => [...nds.filter((n) => n.id !== targetNode.id), newSelectorNode]);
      }
    },
    [nodes, rulesData.trigger?.value, createNode, setNodes]
  );

  const handleNodeClick: NodeMouseHandler = useCallback(
    (_, node) => {
      if (node.type === NodeTypes.TRIGGER || node.type === NodeTypes.CONDITION) {
        setCurrentFormType(
          node.type === NodeTypes.TRIGGER ? FormTypes.TRIGGER : FormTypes.CONDITION
        );
        setToggleSidePanel(true);
      }
    },
    [setCurrentFormType, setToggleSidePanel]
  );

  return {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    handleNodeClick,
    handleEdgeClick,
    toggleSidePanel,
    currentFormType,
  };
};

export default useFlowManagement;
