/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { datadogRum } from '@datadog/browser-rum';
import { AxiosError, AxiosProgressEvent } from 'axios';
import { t } from 'i18next';
import _ from 'lodash';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { getEnv } from '@/src/application/hooks/util/useEnv';
import { timeout } from '@/src/compat/util';
import { IImportRecentList } from '@/src/ContactsModule/components/ImportRecentTable/ImportRecentTable';
import { IContactCustomFields } from '@/src/ContactsModule/interfaces/CustomFieldsInterfaces';
import {
  activityChannel,
  DistributeGroupsResponse,
  GroupAPIInterface,
  GroupsData,
  IContactActivity,
  IContactActivityGroups,
  IContactActivityItems,
  IContactActivityTags,
  IContactECommerceActivity,
  IContactExtendedData,
  IContactFieldsTranslations,
  IContactNPSInfo,
  IContactNPSInfoActivityList,
  IContactNPSInfoList,
  ICustomField,
  IECommerceFieldsTranslations,
  IGroup,
  IGroupByContactsQuery,
  IGroupByContactsResponse,
  IGroupList,
  IHappiness,
  IItem,
  InterestGroupsData,
  ISegmentConditions,
  ISegmentTableRow,
  IStatus,
  IStatusList,
  ITableContact,
  ITag,
  ITagContent,
  ITagsByContactsQuery,
  ITagsByContactsResponse,
  ProfileOptions,
  TableContact,
} from '@/src/ContactsModule/interfaces/Interfaces';
import { translateFieldsKeyToV2 } from '@/src/ContactsModule/presentation/Screens/CreateContacts/helper';
import {
  getTranslatedName,
  getTranslatedValue,
} from '@/src/ContactsModule/utils/contactCustomFieldsUtils';
import { mockActivityFilterData } from '@/src/ContactsModule/utils/mockActivityFilterData';
import { countriesMock } from '@/src/ContactsModule/utils/mockData/CountriesMock';
import {
  analyzeResultStub,
  importContactsStatusStub,
  preImportContactsStatusStub,
} from '@/src/ContactsModule/utils/mockData/importContactsStub';
import { NpsStatus } from '@/src/ContactsModule/utils/NpsStatus';
import { PrestashopStatusMap } from '@/src/ContactsModule/utils/PrestashopStatusMap';
import { quantifier } from '@/src/ContactsModule/utils/quantifier';
import { translateCustomFields } from '@/src/ContactsModule/utils/TranslateCustomsFields';
import { translateInterface } from '@/src/ContactsModule/utils/TranslateInterface';
import { NameFilterEnum, WrappingFilters } from '@/src/ContactsModule/utils/WrappingFilter';
import { wrapCustomFieldsList } from '@/src/ContactsModule/utils/wrapSegmentConditions';
import {
  IContactActivityChannel,
  IContactActivityChannelString,
} from '@/src/infrastructure/interfaces/IContactActivityChannel';
import {
  BillingFormState,
  CreateCheckoutBody,
  GetContactActivityGroupsParams,
  SegmentByIdCountParams,
  SegmentSearchParams,
  SenderParams,
  StepThreeBody,
  StepTwoBody,
} from '@/src/infrastructure/interfaces/IRequestParams.interface';
import {
  AccountLimitResponse,
  CountResponse,
  CreateCampaignActionResponse,
  DealResponse,
  InvoiceDetailV2,
  PlanChanges,
  PlansResponse,
  ProrationResponse,
  SmsPhoneNumberResponse,
  UpcomingBilling,
  VerificationResponse,
} from '@/src/infrastructure/interfaces/IResponses';
import {
  ICodification,
  ISenderCreateResponse,
} from '@/src/infrastructure/interfaces/ISenders.interface';
import { TagsMergeParams } from '@/src/infrastructure/interfaces/IV1RequestParams.interface';
import {
  ITableNpsContact,
  NpsContactListQuery,
  NpsContacts,
  NpsCount,
  NpsRank,
  NpsStats,
  NpsTotalContactsCount,
  NpsTotalStats,
  TableNpsContact,
} from '@/src/modules/ContactsModule/screens/HappinessMain/types';
import { importStatus } from '@/src/presentation/types/enum/importStatus.enum';
import { IAnalysisResult } from '@/src/presentation/types/interfaces/IAnalysisResult.interface';
import { IContractPaymentInfo } from '@/src/presentation/types/interfaces/IContractPaymentInfo';
import { IFreeTrialDemo } from '@/src/presentation/types/interfaces/IFreeTrialDemo';
import { IHomeInit } from '@/src/presentation/types/interfaces/IHomeInit';
import {
  FileAnalysis,
  ImportGroupProps,
} from '@/src/presentation/types/interfaces/IImport.interface';
import {
  IEmailData,
  IEmailVerified,
  ISenderCreatePayload,
  ISenderData,
  ISendersResponse,
  ISenderUpdatePayload,
} from '@/src/presentation/types/interfaces/ISenderData.interface';
import { SenderScore } from '@/src/presentation/types/models/SenderScore.class';
import { ConfigGeneral, IRestricted } from '@/src/presentation/types/types';
import { StepEnum } from '@/src/presentation/util/enum/StepEnum';
import { convertUTCToSpecificTimezone } from '@/src/utils/Date/dateUtils';
import { mapItemInterfaceEsToEn } from '@/src/utils/MapItemInterfaceEsToEn';
import { mapKeysToEnglish } from '@/src/utils/mapKeysToEnglish';

import { APIService } from './APIService';
import { IEmblueService } from './IEmblueService';
import {
  GenericServiceResponse,
  IExportContactsEmblueServiceResponse,
  IGroupEmblueService,
  IItemsEmblueService,
  IMigratedImagesAdded,
  ISegmentsEmblueService,
  ITagsEmblueService,
} from './ServiceInterfaces';

import { Note, NoteFilters, NotesResponse } from '@/modules//ContactsModule/types/Notes';
import {
  ActivityField,
  Channel,
  CreateSegmentResponse,
  DataResponse,
  ECommerceSegmentsEnum,
  EmblueRecipesEnum,
  EmblueSegmentInfo,
  Field,
  FieldsResponse,
  ISegment,
  ISegmentFilter,
  ISegmentFilterID,
  ListProfile,
  SegmentTypeEnum,
  Tag,
} from '@/modules//ContactsModule/types/Segments';
import { FormStepOne } from '@/modules/AuthModule/components/SignUpFormOne/SignUpFormOne';
import { FormStepTwo } from '@/modules/AuthModule/components/SignUpFormTwo/SignUpFormTwo';
import {
  BLOCKED_USER_RESPONSE,
  DISABLED_USER_RESPONSE,
  INVALID_PASSWORD_RESPONSE,
  INVALID_USER_RESPONSE,
} from '@/modules/AuthModule/constants';
import {
  RecoverPasswordRequest,
  RequestParamsChangePassword,
  RequestParamsLogin,
} from '@/modules/AuthModule/types/Auth';
import {
  ACTION_CREATE_MESSAGE,
  ACTION_TYPE_MAPPING_ROUTES,
} from '@/modules/CampaignsModule/constants';
import {
  IBeefreeCatalog,
  IBeefreeSendResponse,
} from '@/modules/CampaignsModule/interfaces/Beefree';
import {
  IDuplicateCampaignActions,
  IDuplicateCampaignActionsResponse,
  IReportParams,
  IScheduledSMSDeliveryV1,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ActionTypeName,
  IAddresseesByAction,
  IContactsEmailTest,
  IContactsEmailTestResponseV1,
  ICreateCampaign,
  ICreateCampaignResponse,
  IDuplicateCampaignsResponse,
  IDuplicateCampaignsResponseV1,
  IDuplicateCampaignsV1,
  IGroupsEmailTest,
  IGroupsEmailTestResponseV1,
  IMoveCampaignPayload,
  IPauseEmailResponseV1,
  ISaveV1Message,
  ISmsInfoResponseV1,
  ISmsRecipientsResponseV1,
  ISmsReportActionResponseV1,
  IV1GetShippingTags,
  IV1GetShippingTagsApiResponse,
  IV1GetShippingTagsResponse,
  TActionID,
} from '@/modules/CampaignsModule/interfaces/Campaigns';
import { IFilters } from '@/modules/ContactsModule/interfaces/BackOfficeAccounts';
import {
  ConfigurationResponseOK,
  ConfigurationResponseParseError,
} from '@/modules/ContactsModule/screens/ImportMain/V1ImportTypes';
import {
  ConfigColumProps,
  ConfigurationResponse,
  CustomsFieldsProps,
  OnErrorConfigurationResponse,
} from '@/modules/ContactsModule/screens/ImportMain/V2ImportTypes';
import { DiscaredStatus } from '@/modules/ContactsModule/types/Contacts';
import { ContactTableSearchFilters } from '@/modules/ContactsModule/types/ContactTableSearchFilters';
import { mapValuesOptionsEcommerceGroups } from '@/modules/ContactsModule/util/mapsValuesToOptions';
import { IContractsResponse, ILogsResponse } from '@/modules/ContractsModule/interfaces/contracts';
import {
  Billing,
  BillingInfo,
  Consumption,
  PaginationDataResponse,
  PaginationMyPlanFilters,
} from '@/modules/MyPlanModule/types';
import {
  ChangeWidgetStatusParams,
  DeleteWidgetParams,
  ITableWidget,
  ITableWidgetNPS,
  NPSWidgets,
  RestoreWidgetParams,
} from '@/modules/NPSModule/interfaces';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const hash = require('object-hash');
/**
 * Class handled mostly by Delta Team
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

export class EmblueService implements IEmblueService {
  private apiV1: APIService;
  private apiV2: APIService;
  private apiNodeV2: APIService;
  private apiExternalNodeV2: APIService;
  private apiWidgets: APIService;
  private onsiteToken?: string;
  homeInit: { userData: UserData; senderScore: SenderScore } | undefined;

  constructor({
    emaAppUrl,
    emaAppV2Url,
    apiNodeApp,
    widgetsUrl,
    jwtToken,
  }: {
    emaAppUrl: string;
    emaAppV2Url: string;
    apiNodeApp: string;
    widgetsUrl: string;
    jwtToken: string;
  }) {
    this.apiV1 = new APIService(emaAppUrl);
    this.apiV2 = new APIService(emaAppV2Url, jwtToken);
    this.apiNodeV2 = new APIService(apiNodeApp, jwtToken);
    this.apiExternalNodeV2 = new APIService(apiNodeApp);
    this.apiWidgets = new APIService(widgetsUrl);
  }

  async createCampaignAction(
    actionName: string,
    actionType: ActionTypeName,
    campaignId: string
  ): Promise<CreateCampaignActionResponse> {
    try {
      /**cspell: disable */
      const body = {
        idCampania: campaignId,
        nombre: actionName,
        tipoElementoId: ACTION_TYPE_MAPPING_ROUTES[actionType],
        destinatarios: [],
        segmentar: false,
        fechaEnvio: new Date().getTime(),
      };
      const { data } = await this.apiV1.post('/Services/Campanias.svc/CrearElemento', body);
      const dataType = data as {
        resultado: string;
        grupos: number[];
      };
      let id = 0;
      if (dataType.resultado === ACTION_CREATE_MESSAGE) {
        id = dataType.grupos[0];
      }
      /**cspell: enable */
      return { id, message: ACTION_CREATE_MESSAGE };
    } catch (error) {
      console.error(error);
      if (((error as any)?.response?.data?.Message as string)?.includes('INVALID_MFA_TOKEN')) {
        throw new Error('INVALID_MFA_TOKEN');
      }
      throw new Error('Error createCampaignAction');
    }
  }

  async getInvoiceData(month: string, year: string): Promise<InvoiceDetailV2> {
    try {
      const { data } = await this.apiV2.get(
        `/api/v2.1/my_plan/billing/detail?month=${month}&year=${year}`
      );
      return data as InvoiceDetailV2;
    } catch (error) {
      console.error(error);
      throw new Error('Error getInvoiceData');
    }
  }

  async getAccountLimits(accountId: number): Promise<DataResponse<AccountLimitResponse>> {
    try {
      const accountLimitsSessionStr = sessionStorage.getItem('account_limits');
      const areDifferentAccounts = Number(sessionStorage.getItem('account_id')) !== accountId;
      if (!accountLimitsSessionStr || areDifferentAccounts) {
        const response = await this.apiV2.get('/api/v2.1/contacts/account/limit');
        sessionStorage.setItem('account_limits', JSON.stringify(response.data));
        sessionStorage.setItem('account_id', accountId.toString());
        return { success: true, data: response.data as AccountLimitResponse };
      } else {
        const accountLimitsSession = JSON.parse(accountLimitsSessionStr) as AccountLimitResponse;
        return { success: true, data: accountLimitsSession };
      }
    } catch (error) {
      console.error(error);
      throw new Error('Error get account limits');
    }
  }

  async sendUpgradeInterest(action: ActionTypeName) {
    try {
      const { data } = await this.apiV2.post('/api/v2.1/account/upgrade/interest', { action });

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async segmentGroups(
    groups: number[],
    quantity: number,
    name: string
  ): Promise<{
    statusOk: boolean;
    groups: number[];
    errorType: { repeatedName: boolean; contentError: boolean };
  }> {
    /* cspell:disable */
    return await this.segmentContacts(groups, quantity, name, 'usuario');
    /* cspell:enable */
  }
  async segmentTags(
    tags: number[],
    quantity: number,
    name: string
  ): Promise<{
    statusOk: boolean;
    groups: number[];
    errorType: { repeatedName: boolean; contentError: boolean };
  }> {
    /* cspell:disable */
    return await this.segmentContacts(tags, quantity, name, 'interes');
    /* cspell:enable */
  }
  async segmentContacts(
    groups: number[],
    quantity: number,
    name: string,
    type: string
  ): Promise<{
    statusOk: boolean;
    groups: number[];
    errorType: { repeatedName: boolean; contentError: boolean };
  }> {
    /* cspell:disable */
    const paramsAdapter = { tipo: type, nombre: name, grupos: groups, cant: quantity };
    const data: DistributeGroupsResponse = (
      await this.apiV1.post('/Services/Contactos.svc/SegmentarGrupos', paramsAdapter)
    ).data as DistributeGroupsResponse;
    const result = {
      statusOk: data.resultado === 'SEGMENTACION_GRUPOS_OK',
      groups: data.grupos,
      errorType: {
        repeatedName: data.resultado === 'GRUPO_USUARIO_EXISTENTE',
        contentError: data.resultado === 'SEGMENTACION_GRUPOS_KO',
      },
    };
    /* cspell:enable */
    return result;
  }

  removeContactsToGroup(query: { contactsIds: number[]; groupsIds: number[] }): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  importCsv<T extends File>(file: T): Promise<string> {
    throw new Error('Method not implemented.');
  }
  addVerifiedEmail({ email }: { email: string }): Promise<any> {
    throw new Error('Method not implemented.');
  }

  setOnsiteToken(token: string) {
    this.onsiteToken = token;
  }

  static instance: EmblueService;

  static init({
    emaAppUrl,
    emaAppV2Url,
    apiNodeApp,
    widgetsUrl,
    jwtToken,
  }: {
    emaAppUrl: string;
    emaAppV2Url: string;
    apiNodeApp: string;
    widgetsUrl: string;
    jwtToken: string;
  }) {
    return (
      EmblueService.instance ||
      (EmblueService.instance = new EmblueService({
        emaAppUrl,
        emaAppV2Url,
        apiNodeApp,
        widgetsUrl,
        jwtToken,
      }))
    );
  }

  static getInstance(): IEmblueService {
    return EmblueService.instance;
  }

  async getFreeTrialBlock(): Promise<IFreeTrialDemo> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.get('/Services/AdminAgencia.svc/GetFreeTrialBlock');
    return data;
  }

  async migratedImages(accounts: number[]): Promise<IMigratedImagesAdded> {
    const { data }: any = await this.apiV2.post('api/v2.1/images/sync_accounts', { accounts });
    const { result } = data;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Object.values(result)?.[0] as IMigratedImagesAdded;
  }

  async getBeefreeCatalog(filters: IFilters): Promise<IBeefreeCatalog | null> {
    const { data }: any = await this.apiV2.get('v1/catalog/templates', filters);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return data;
  }

  async syncSizeImages(accounts: number[]): Promise<boolean> {
    const { data }: any = await this.apiV2.get('api/v2.1/images/sync_size', { accounts });
    const { result } = data;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return result;
  }

  async SetExtraDaysForFreeTrialDemo(): Promise<void> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post(
      '/Services/AdminAgencia.svc/SetExtraDaysForFreeTrialDemo'
    );
    return data;
  }

  async getPaymentInfo(): Promise<IContractPaymentInfo> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.get(
      '/Services/AdminAgencia.svc/CheckBlockByLackOfPayment'
    );

    data.currencySymbol = '';
    const result = data as IContractPaymentInfo;

    if (result.currency === 'PEN') {
      // Peru
      result.currencySymbol = 'S/';
    } else if (result.currency === 'EUR') {
      result.currencySymbol = '€';
    } else {
      result.currencySymbol = '$';
    }
    return data;
  }

  async setAlreadyPaidBill(query: {
    contractId: number;
    userEmail: string;
    emailAdministration: string;
    businessName: string;
  }): Promise<void> {
    /* cspell:disable */
    const paramsAdapter = {
      contractId: query.contractId,
      userEmail: query.userEmail,
      emailAdministracion: query.emailAdministration,
      razonSocial: query.businessName,
    };
    /* cspell:disable */
    const { data }: any = await this.apiV1.post(
      '/Services/AdminAgencia.svc/SetAlreadyPaid',
      paramsAdapter
    );
    return data;
  }

  async getValidationDomain(domain: string) {
    const { data }: any = await this.apiNodeV2.get(
      '/v2/internal/check-domain-v2?domain=' + domain + '&selectors=epexo',
      {
        withCredentials: false,
      }
    );
    return data;
  }

  async getSmartFinder() {
    const { data }: any = await this.apiV2.get('/api/v2.1/smart_finder');
    return data;
  }

  async searchMoreSmartFinder(type: string, search: string) {
    const { data }: any = await this.apiV2.get('/api/v2.1/smart_finder/' + type, {
      search,
    });
    return data;
  }

  async getAccountNameById(accountId: number): Promise<string> {
    const { data }: any = await this.apiV2.get(`/api/v2.1/smart_finder/accounts/${accountId}`);
    return data.name;
  }

  async downloadContactsDiscarded(processId: number): Promise<boolean> {
    const { data } = await this.apiV1.post('Services/Reportes.svc/ComenzarProcesamientoReporte', {
      parametros: [
        { clave: 'tipo', valor: 'importacionCSV' },
        { clave: 'process_id', valor: processId },
      ],
    });

    const { id_notificacion } = data as { id_notificacion: string };

    return id_notificacion === 'REPORTE_CREADO_OK';
  }

  async switchAccount(accountId: number): Promise<boolean> {
    const { data }: any = await this.apiV1.get(
      `/Services/Usuarios.svc/CambiarEmpresa?empresaId=${accountId}`
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data && data.id_notificacion === 'EMPRESA_CAMBIADA_OK';
  }

  async getContactExtraInfo(query: {
    emailId: number;
  }): Promise<{ contactExtraInfo: Partial<TableContact> }> {
    const { data }: any = await this.apiV2.post('/api/v2/contacts/extra_info_by_id', query);
    return {
      contactExtraInfo: {
        country: data.country || '',
        company: data.company || '',
      },
    };
  }

  async getContactCustomFields(query: {
    emailId: number;
  }): Promise<{ contactCustomFields: IContactCustomFields[] }> {
    const { data }: any = await this.apiV2.post('/api/v2/contacts/custom_fields', query);

    /* cspell:disable */
    const translations: IContactFieldsTranslations = {
      nombre: 'names',
      apellido: 'lastNames',
      id_contacto: 'idContact',
      sexo: 'gender',
      telefono_1: 'mainPhone',
      telefono_2: 'secondaryPhone',
      web_url: 'website',
      direccion: 'address',
      ciudad: 'city',
      pais: 'country',
      cumpleanios: 'birthday',
      empresa: 'company',
      cargo: 'position',
      email_secundario: 'secondaryEmail',
      id_trigger: 'idTrigger',
      recencia: 'recency',
      frecuencia: 'frequency',
      monto: 'amount',
      nps: 'nps',
      clasificacion_ecommerce: 'eCommerceClassification',
    };
    /* cspell:enable */

    const translationsECommerce: Record<string, string> = {
      newCustomer: t('PROFILE_ECOMMERCE_TABLE.newCustomer'),
      newLead: t('PROFILE_ECOMMERCE_TABLE.newLead'),
      brandDefender: t('PROFILE_ECOMMERCE_TABLE.brandDefender'),
      loyalCustomer: t('PROFILE_ECOMMERCE_TABLE.loyalCustomer'),
      preInactiveCustomer: t('PROFILE_ECOMMERCE_TABLE.preInactiveCustomer'),
      needsAttention: t('PROFILE_ECOMMERCE_TABLE.needsAttention'),
      inactiveCustomer: t('PROFILE_ECOMMERCE_TABLE.inactiveCustomer'),
      customerInRisk: t('PROFILE_ECOMMERCE_TABLE.customerInRisk'),
      cantLoseIt: t('PROFILE_ECOMMERCE_TABLE.cantLoseIt'),
    };

    const countries = countriesMock(t);

    const newList: IContactCustomFields[] = data.map(
      (item: { name: string; value: string } & IContactCustomFields): IContactCustomFields => {
        const newItem = { ...item };
        newItem.name = getTranslatedName(newItem.name, translations);
        newItem.value = getTranslatedValue(
          newItem.name,
          newItem.value,
          translationsECommerce,
          countries
        );
        newItem.value = newItem.value === null ? '' : newItem.value;
        return newItem;
      }
    );

    const updateFieldProperties = (fields: IContactCustomFields[]): IContactCustomFields[] => {
      return fields.map((field) => {
        if (field.name === 'eCommerceClassification') {
          return {
            ...field,
            defaultValues: null,
            dataType: 3,
            optionType: 1,
          };
        }
        return field;
      });
    };

    return {
      contactCustomFields: updateFieldProperties(newList),
    };
  }

  async getContactExtendedData(query: {
    emailId: number;
  }): Promise<{ contactExtendedData: IContactExtendedData }> {
    const { data }: any = await this.apiV2.post('/api/v2/contacts/extended_data', query);
    return {
      contactExtendedData: data,
    };
  }

  async getTagsByContacts(contactList: any): Promise<any> {
    //Promise<{ list: Map<number, ITag>; }>
    if (contactList.length === 0) {
      return {};
    }
    const listAux: TableContact[] = contactList;
    const ids = listAux.map((c: TableContact) => c.emailId);
    const body: any = {};
    body['ids'] = ids;
    const { data }: any = await this.apiV2.post('/api/v2/tags/by_contact_ids', body);
    return data;
  }

  currentGroupPageList: { group: IGroup }[] = [];
  async getGroups(query: IGroupEmblueService): Promise<IGroupList> {
    const [groups]: [groups: IGroup[]] = (
      await Promise.all([
        this.apiV2.post('/api/v2/groups', {
          identifier: query.identifier,
          pageNumber: query.page,
          rowsPerPage: query.pageSize,
          search: query.search,
          orderByColumn: query.orderColumn,
          orderByDirection: query.orderDirection,
        }),
      ])
    ).map((x) => x?.data) as [groups: IGroup[]];

    const list = _.flatten(groups).map((group) => ({
      group: {
        ...group,
      },
    }));

    this.currentGroupPageList = list;

    return {
      list: list,
    } as IGroupList;
  }

  async getGroupInfo({ groupId }: { groupId: number }) {
    const currentGroup = this.currentGroupPageList
      .map((x) => x.group)
      // eslint-disable-next-line eqeqeq
      .find((group) => Number(group.id) == groupId);
    let info: IGroup;

    if (currentGroup) {
      info = currentGroup;
    } else {
      const { data } = await this.apiV2.get(`/api/v2/groups/${groupId}`);
      info = data as IGroup;
    }

    return {
      groupInfo: info,
    };
  }

  currentSegmentPageList: { segment: ISegmentTableRow }[] = [];
  async getSegments(query: ISegmentsEmblueService) {
    const [segments]: [segments: ISegmentTableRow[]] = (
      await Promise.all([
        this.apiV2.post('/api/v2/segments', {
          identifier: query.identifier,
          pageNumber: query.page,
          rowsPerPage: query.pageSize,
          search: query.search,
          orderByColumn: query.orderColumn,
          orderByDirection: query.orderDirection,
          segmentTypes: query.segmentTypes ?? [0],
        }),
      ])
    ).map((x) => x && x.data) as [segments: ISegmentTableRow[]];

    const { timeOffset } = await this.getUserData();

    const list = _.flatten(segments).map((segment) => {
      return {
        segment: {
          ...segment,
          lastTotalContactsUpdate: convertUTCToSpecificTimezone(
            segment.lastTotalContactsUpdate as string,
            timeOffset as number
          ),
        },
      };
    });

    this.currentSegmentPageList = list;

    return {
      list: list,
    };
  }

  async getSegmentInfo({ segmentId }: { segmentId: number }) {
    const { data } = await this.apiV2.get(`/api/v2/segments/${segmentId}`);

    return {
      segmentInfo: data as ISegmentTableRow,
    };
  }

  async getValuesSegmentsFiltersContacts() {
    /* cspell:disable */
    const query = {
      emailId: 0,
      offset: 0,
      cantidadGrupos: 0,
      ordenColumna: 'nombre',
      sentidoOrden: 'asc',
      busqueda: '',
    };

    const translationsTags = {
      cantidadActividad: 'quantityActivity',
      cantidadFrecuencia: 'amountFrequency',
      cantidadUsuarios: 'quantityUsers',
      capaId: 'layerId',
      categoriaNombre: 'categoryName',
      color: 'color',
      contenedorId: 'containerId',
      contenido: 'content',
      contenidoTipo: 'contentType',
      nombre: 'name',
      orderDefault: 'orderDefault',
      tagCategoriaId: 'tagCategoryId',
      tagId: 'tagId',
      ultimoUso: 'lastUse',
    };

    const translationsChannels = {
      id_tipo_canal: 'channelTypeId',
      descripcion: 'description',
    };

    type Data = { aaData: Array<string[]> };

    const [userGroups, tags, channels, notAddressees, listProfiles, ecommerceGroups] =
      await Promise.all([
        this.apiV1.get('/Services/Contactos.svc/GetGruposPor', query),
        this.apiV1.post('/Services/Tags.svc/ObtenerTagsConfiguracion'),
        this.apiV1.get('/Services/Contactos.svc/getCanales', query),
        this.apiV1.get('/Services/Contactos.svc/GetGruposSistemaLite'),
        this.apiV1.get('/Services/Contactos.svc/GetListasOpcionesPerfiles', query),
        this.getECommerceGroups(),
      ]);

    const tagsTranslation: Tag[] = (tags.data as []).map((tag) =>
      mapItemInterfaceEsToEn(tag, {}, translationsTags)
    );
    const channelsTranslation: Channel[] = (channels.data as []).map((channel) =>
      mapItemInterfaceEsToEn(channel, {}, translationsChannels)
    );

    return {
      userGroups: (userGroups.data as Data).aaData,
      tags: tagsTranslation,
      channels: channelsTranslation,
      notAddressees: (notAddressees.data as Data).aaData,
      listProfiles: listProfiles.data as ListProfile[],
      ecommerceGroups,
    };
  }

  async getNotesContactData(contactId: string, filters: NoteFilters) {
    const { data } = await this.apiV2.post(`/api/v2/notes/${contactId}`, filters);
    return data as NotesResponse;
  }

  async createNote(note: Note) {
    try {
      const { data } = await this.apiV2.post('/api/v2/notes/create', note);
      if (getEnv() === 'production' || getEnv() === 'staging') void this.metricNote('created');
      return { success: true, data: data as Note };
    } catch (error) {
      console.error('Error', error);
      return { success: false, data: 'Error creating note' };
    }
  }

  private async metricNote(action: 'created' | 'edited' | 'deleted') {
    try {
      if (this.apiNodeV2) {
        await this.apiNodeV2.post(`/v2/metrics/notes/${action}`);
      }
    } catch (error) {
      console.error(error);
    }
  }

  currentTagPageList: { tag: ITag }[] = [];
  async getTags(query: ITagsEmblueService) {
    const [tags]: [tags: ITag[]] = (
      await Promise.all([
        this.apiV2.post('/api/v2/tags', {
          pageNumber: query.page,
          rowsPerPage: query.pageSize,
          search: query.search,
          orderByColumn: query.orderColumn,
          orderByDirection: query.orderDirection,
          CategoryIds: query.tagsCategoriesFilter,
          DataSourceIds: query.tagsDataSourceFilter,
        }),
      ])
    ).map((x) => x && x.data) as [tags: ITag[]];

    let tagsCountSource: any = [];
    const tagsFiltered = query.tagsDataSourceFilter && query.tagsDataSourceFilter?.length > 0;
    if (tagsFiltered) {
      tagsCountSource = await this.getCountTagsSource(tags, query);
    }

    const list = _.flatten(tags).map((tag) => ({
      tag: {
        ...tag,
        contacts:
          tagsFiltered && tagsCountSource[tag.id]
            ? tagsCountSource[tag.id][0].contacts
            : tag.contacts,
        activity:
          tagsFiltered && tagsCountSource[tag.id]
            ? tagsCountSource[tag.id][0].activity
            : tag.activity,
      },
    }));

    this.currentTagPageList = list;

    return {
      list: this.currentTagPageList,
    };
  }

  async getCountTagsSource(tagList: any, query: ITagsEmblueService): Promise<any> {
    // eslint-disable-next-line eqeqeq
    if (tagList.length == 0) {
      return {};
    }
    const listAux: ITag[] = tagList;
    const ids = listAux.map((t: ITag) => t.id);
    query['ids'] = ids;
    const { data }: any = await this.apiV2.post('/api/v2/tags/by_source_ids', {
      pageNumber: query.page,
      rowsPerPage: query.pageSize,
      search: query.search,
      orderByColumn: query.orderColumn,
      orderByDirection: query.orderDirection,
      CategoryIds: query.tagsCategoriesFilter,
      DataSourceIds: query.tagsDataSourceFilter,
      Ids: query.ids,
    });
    return data;
  }

  currentTagCloudPageList: { tag: ITag }[] = [];
  async getTagCloudInfo(query: ITagsEmblueService) {
    const [tags]: [tags: ITag[]] = (
      await Promise.all([
        this.apiV2.post('/api/v2/tags', {
          identifier: query.identifier,
          search: query.search,
          CategoryIds: query.tagsCategoriesFilter,
          DataSourceIds: query.tagsDataSourceFilter,
        }),
      ])
    ).map((x) => x && x.data) as [tags: ITag[]];

    const list = _.flatten(tags).map((tag) => ({
      tag: {
        ...tag,
      },
    }));

    this.currentTagCloudPageList = list;

    return {
      list: this.currentTagCloudPageList,
      count: this.currentTagCloudPageList.length,
    };
  }

  async getTagInfo({ tagId }: { tagId: number }) {
    // eslint-disable-next-line eqeqeq
    const currentTag = this.currentTagPageList.map((x) => x.tag).find((tag) => tag.id == tagId);
    const currentTagCloud = this.currentTagCloudPageList
      .map((x) => x.tag)
      // eslint-disable-next-line eqeqeq
      .find((tag) => tag.id == tagId);
    let info: ITag;

    if (currentTag) {
      info = currentTag;
    } else if (currentTagCloud) {
      info = currentTagCloud;
    } else {
      const { data } = await this.apiV2.get(`/api/v2/tags/${tagId}`);
      info = data as ITag;
    }

    return {
      tagInfo: info,
    };
  }

  async createCheckoutSession(body: CreateCheckoutBody): Promise<{ url: string }> {
    try {
      const { data } = await this.apiV2.post('/api/v2.1/my_plan/create_checkout', body);
      return { url: (data as { url: string }).url };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getBillingPortalSession(language: 'en' | 'es' | 'pt' = 'en'): Promise<{ url: string }> {
    try {
      const { data } = await this.apiV2.get<{ url: string }>(
        `/api/v2.1/my_plan/get_billing_portal?language=${language}`
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async paymentCompleted(
    body: CreateCheckoutBody
  ): Promise<{ success: boolean; errorType?: 'invalidPermissions' | 'unknown' }> {
    try {
      const { data } = await this.apiV2.post('/api/v2.1/my_plan/offers/purchase', body);
      const dataResponse = data as { success: boolean };
      return dataResponse;
    } catch (error) {
      console.error('Error paymentCompleted:', error);
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          const errorType = error.response?.data?.message
            ?.at(0)
            ?.toLowerCase()
            ?.includes('invalid permissions')
            ? 'invalidPermissions'
            : 'unknown';
          return { success: false, errorType };
        }
      }
      return { success: false };
    }
  }

  async getProration(body: CreateCheckoutBody): Promise<ProrationResponse> {
    try {
      const { data } = await this.apiV2.post<ProrationResponse>(
        '/api/v2.1/my_plan/offers/prorations',
        body
      );
      return data;
    } catch (error) {
      console.error(error);
      return { totalAmount: 0 };
    }
  }

  currentItemPageList: { item: IItem }[] = [];
  async getItems(query: IItemsEmblueService) {
    const [items]: [items: IItem[]] = (
      await Promise.all([
        this.apiV2.post('/api/v2/items', {
          lastDays: query.lastDays,
          dataSource: query.dataSource?.join(', '),
          pageNumber: query.page,
          rowsPerPage: query.pageSize,
          search: query.search,
          orderByColumn: query.orderColumn,
          orderByDirection: query.orderDirection,
        }),
      ])
    ).map((x) => x && x.data) as [items: IItem[]];

    const list = _.flatten(items).map((item) => ({
      item: {
        ...item,
      },
    }));

    this.currentItemPageList = list;
    return {
      list: list,
    };
  }

  currentItemProps = undefined;
  async getItemProps({ itemName }: { itemName: string }) {
    if (itemName) {
      const { data }: any = await this.apiV2.post('/api/v2/itemProps', { itemName });
      this.currentItemProps = data;
      return data;
    }
    return this.currentItemProps;
  }

  decideStep(data: ConfigGeneral) {
    if (data.has_sender !== 'true') return StepEnum.sender;
    if (data.has_contacts !== 'true') return StepEnum.contacts;
    if (data.sent_email !== 'true') return StepEnum.sendEmail;
    return StepEnum.finished;
  }

  async getGetCurrentStep(companyId: string): Promise<any> {
    /* cspell:disable */
    const response: any = await this.apiV1.get('/Services/AdminAgencia.svc/ObtenerConfigGeneral', {
      empresaId: companyId,
    });
    const { data } = response;
    let step;
    if (data.current_step) {
      step = data.current_step;
    } else {
      step = this.decideStep(data);
      /* cspell:disable */
      void this.apiV1.post('/Services/AdminAgencia.svc/CargarDatosConfiguracion', {
        /* cspell:disable */
        configuracion: JSON.stringify({ current_step: step }),
        /* cspell:disable */
        empresaid: companyId,
      });
    }
    return step;
  }

  setNextStep(companyId: string, step: number): number {
    /* cspell:disable */
    const dataJson: any = {
      current_step: step,
    };
    if (step === 1) {
      dataJson.current_step = 2;
      dataJson.has_sender = 'true';
    } else if (step === 2) {
      dataJson.current_step = 3;
      dataJson.has_contacts = 'true';
    } else if (step === 3) {
      dataJson.current_step = 0;
      dataJson.sent_email = 'true';
    }
    void this.apiV1.post('/Services/AdminAgencia.svc/CargarDatosConfiguracion', {
      /* cspell:disable */
      configuracion: JSON.stringify(dataJson),
      /* cspell:disable */
      empresaid: companyId,
    });
    return dataJson.current_step;
  }

  private parseHomeInit(homeInitResponse: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const creationDate = new Date(homeInitResponse.userData.creationDate as string);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    delete homeInitResponse.userData.creationDate;
    const homeInitParsed = homeInitResponse as IHomeInit;
    const today = new Date();
    const differenceMS = today.getTime() - creationDate.getTime();
    const daysSinceCreationDate = Math.floor(differenceMS / (1000 * 60 * 60 * 24)); // ms to Days
    homeInitParsed.userData.daysSinceCreationDate =
      daysSinceCreationDate < 0 ? 0 : daysSinceCreationDate;
    return homeInitParsed;
  }

  async getUserData(): Promise<UserData> {
    if (this.homeInit) {
      return this.homeInit.userData;
    }
    const homeInitResponse: any = (await this.apiV2.get('/api/v2/home/init')).data;

    this.homeInit = this.parseHomeInit(homeInitResponse);
    return this.homeInit.userData;
  }

  async getSenderScore(): Promise<any> {
    if (this.homeInit) {
      return this.homeInit.senderScore;
    }
    const homeInitResponse: any = (await this.apiV2.get('/api/v2/home/init')).data;

    this.homeInit = this.parseHomeInit(homeInitResponse);
    return this.homeInit.senderScore;
  }

  async getLastReports(): Promise<any> {
    /* cspell:disable */
    const { data } = await this.apiV1.post(
      '/Services/Reportes.svc/GetResumenEnviadosYPorcentajesUnicos',
      /* cspell:disable */
      {
        busqueda: '',
        offset: 0,
        cantidadAMostrar: 15,
        borrador: false,
        enCurso: true,
        cerradas: true,
        activa: true,
        inactiva: true,
        tipoElementoEnvioSimple: true,
        tipoElementoTrigger: true,
        tipoElementoSendEmail: true,
        tipoElementoSms: false,
        ordenColumna: 'estado',
        sentidoOrden: 'asc',
        fecha: 0,
        idCampania: [],
      }
    );
    return data;
  }

  async exportContacts(id: number, fields: any[]): Promise<any> {
    /* cspell:disable */
    const payload = fields.map((item) => {
      if (!item.metadata.defaultField && item.metadata.internalNumber) {
        return `campo_${item.metadata.internalNumber}`;
      } else {
        return item.metadata.internalColumn;
      }
    });

    const { data } = await this.apiV1.post(
      '/Services/Reportes.svc/ComenzarProcesamientoReporte',
      /* cspell:disable */
      {
        parametros: [
          { clave: 'tipo', valor: 'contactos' },
          { clave: 'categoria', valor: '' },
          { clave: 'filtro', valor: '' },
          { clave: 'busqueda', valor: '' },
          { clave: 'mostrarDesuscriptos', valor: 'false' },
          { clave: 'mostrarTodos', valor: 'true' },
          { clave: 'filtrarDatacrush', valor: 'false' },
          { clave: 'ordenColumna', valor: 'email_id' },
          { clave: 'sentidoOrden', valor: 'asc' },
          { clave: 'grupo', valor: id },
          { clave: 'stringGrupo', valor: '' },
          { clave: 'allDatos', valor: 'true' },
          { clave: 'campoPrincipal', valor: 'email' },
          { clave: 'grupo_sistema', valor: '0' },
          { clave: 'selectedItems', valor: '' },
          { clave: 'tipoGrupo', valor: 'perfil' },
          { clave: 'Channel', valor: '1' },
          {
            clave: 'cps',
            valor: payload.join(),
          },
          { clave: 'emailsReporte', valor: '' },
        ],
      }
    );
    return data;
  }

  async exportGroupContacts(id: number, fields: any[]): Promise<any> {
    /* cspell:disable */
    const payload = fields.map((item) => {
      if (!item.metadata.defaultField && item.metadata.internalNumber) {
        return `campo_${item.metadata.internalNumber}`;
      } else {
        return item.metadata.internalColumn;
      }
    });

    const { data } = await this.apiV1.post(
      '/Services/Reportes.svc/ComenzarProcesamientoReporte',
      /* cspell:disable */
      {
        parametros: [
          { clave: 'tipo', valor: 'contactos' },
          { clave: 'categoria', valor: '' },
          { clave: 'filtro', valor: '' },
          { clave: 'busqueda', valor: '' },
          { clave: 'mostrarDesuscriptos', valor: 'true' },
          { clave: 'filtrarDatacrush', valor: 'false' },
          { clave: 'ordenColumna', valor: 'email_id' },
          { clave: 'sentidoOrden', valor: 'asc' },
          { clave: 'grupo', valor: id.toString() },
          { clave: 'selectedItems', valor: '' },
          { clave: 'mostrarTodos', valor: 'true' },
          { clave: 'stringGrupo', valor: '' },
          { clave: 'allDatos', valor: 'true' },
          { clave: 'campoPrincipal', valor: 'email' },
          { clave: 'grupo_sistema', valor: '0' },
          { clave: 'tipoGrupo', valor: 'usuario' },
          { clave: 'Channel', valor: '1' },
          {
            clave: 'cps',
            valor: payload.join(),
          },
          { clave: 'emailsReporte', valor: '' },
        ],
      }
    );
    return data;
  }
  async getStatusContactsExport(): Promise<any> {
    /* cspell:disable */
    const response = await this.apiV1.post(
      '/Services/Reportes.svc/ObtenerReportePorUsuario',
      /* cspell:disable */
      {}
    );

    const data = response.data as IExportContactsEmblueServiceResponse;
    let status = 'loading';

    if (data.estado === 'Finalizado') status = 'success';

    return {
      status: status,
      filename: data.filename,
      id: data.id,
      report: data.reporte,
      token: data.token,
      url: data.url,
    };
  }

  // 1-1 relationship between count and lastRefresh (use hash() if has more than 1 dependencies)
  contactsCountCache: { count: number; lastRefresh: number } = { count: 0, lastRefresh: 0 };
  async getContactsCount(query: { lastRefresh: number }): Promise<{ count: number }> {
    const [count]: [{ totalContacts: number }] =
      this.contactsCountCache.count && query.lastRefresh <= this.contactsCountCache.lastRefresh
        ? [{ totalContacts: this.contactsCountCache.count }]
        : ((await Promise.all([this.apiV2.post('/api/v2/contacts/total')])).map(
            (x) => x && x.data
          ) as [any]);
    this.contactsCountCache = { count: count.totalContacts, lastRefresh: query.lastRefresh };

    localStorage.setItem('totalContacts', count.totalContacts.toString());

    return {
      count: count.totalContacts,
    };
  }

  async getTotalDiscardedContacts(query: {
    isDiscardedView: boolean;
    lastRefresh: number;
  }): Promise<{
    count: number;
  }> {
    if (!query.isDiscardedView) {
      return { count: 0 };
    }
    return await this.getContactsCountOfSearch({
      statusFilter: ['discarded'],
      lastRefresh: query.lastRefresh,
      tagsFilter: [],
      happinessFilter: [],
      groupsFilter: [],
      segmentsFilter: 0,
      discardedFilter: [],
    });
  }

  async getContactsCountOfSearch({
    search,
    tagsFilter,
    statusFilter,
    happinessFilter,
    groupsFilter,
    segmentsFilter,
    discardedFilter,
    lastRefresh,
  }: {
    search?: string;
    tagsFilter: number[];
    statusFilter: string[];
    happinessFilter: string[];
    discardedFilter: string[];
    groupsFilter: number[];
    segmentsFilter: number;
    lastRefresh: number;
  }): Promise<{ count: number }> {
    if (segmentsFilter !== 0) {
      return await this.getSegmentsByIdCountOfSearch({
        segmentId: segmentsFilter,
        search,
        lastRefresh,
      });
    }
    const needsToUpdateCount = !!(
      search ||
      statusFilter.length ||
      happinessFilter.length ||
      discardedFilter.length ||
      groupsFilter.length ||
      segmentsFilter !== 0 ||
      tagsFilter.length
    );
    statusFilter.sort(); // Temporally: to obtain same hash and improve caching
    happinessFilter.sort(); // Temporally: to obtain same hash and improve caching
    discardedFilter.sort(); // Temporally: to obtain same hash and improve caching
    tagsFilter.sort(); // Temporally: to obtain same hash and improve caching
    groupsFilter.sort(); // Temporally: to obtain same hash and improve caching
    if (!needsToUpdateCount) {
      return { count: 0 };
    }
    let lastRefreshContactsCache: number | undefined = this.getLastRefreshContactsValue();
    lastRefreshContactsCache =
      lastRefresh !== undefined && lastRefresh < lastRefreshContactsCache
        ? lastRefreshContactsCache
        : lastRefresh;

    const queryHash: string = hash({
      search,
      statusFilter,
      happinessFilter,
      discardedFilter,
      groupsFilter,
      segmentsFilter,
      tagsFilter,
      lastRefreshContactsCache,
    }) as string;

    if (this.countCache[queryHash]) {
      return { count: this.countCache[queryHash] };
    }
    const { data }: any = await this.apiV2.post('/api/v2/contacts/count', {
      search,
      tagIds: tagsFilter,
      statusFilterParam: statusFilter,
      happinessFilterParam: happinessFilter,
      groupIds: groupsFilter,
      segmentId: segmentsFilter,
      discardedStatusFilterParam: discardedFilter,
    });

    this.countCache[queryHash] = segmentsFilter !== 0 ? (data as CountResponse).total : data;
    return { count: segmentsFilter !== 0 ? (data as CountResponse).total : data };
  }

  async getSegmentsCountOfSearch({
    search,
    lastRefresh,
    segmentTypes,
  }: SegmentSearchParams): Promise<{ count: number }> {
    const typeSearchCount = 'SegmentsCount';
    let lastRefreshSegmentsCache: number | undefined = this.getLastRefreshSegmentsValue();
    lastRefreshSegmentsCache =
      lastRefresh !== undefined && lastRefresh < lastRefreshSegmentsCache
        ? lastRefreshSegmentsCache
        : lastRefresh;
    const queryHash: string = hash({
      search,
      typeSearchCount,
      segmentTypes,
      lastRefreshSegmentsCache,
    });

    if (this.countCache[queryHash]) {
      return { count: this.countCache[queryHash] };
    }
    const { data }: any = await this.apiV2.post('/api/v2/segments/count', {
      search,
      segmentTypes,
    });
    this.countCache[queryHash] = data;
    return { count: data };
  }

  async getSegmentsByIdCountOfSearch({
    segmentId,
    lastRefresh,
    search,
  }: SegmentByIdCountParams): Promise<{ count: number }> {
    const typeSearchCount = 'SegmentsByIdCount';
    let lastRefreshSegmentsCache: number | undefined = this.getLastRefreshSegmentsValue();
    lastRefreshSegmentsCache =
      lastRefresh !== undefined && lastRefresh < lastRefreshSegmentsCache
        ? lastRefreshSegmentsCache
        : lastRefresh;

    const queryHash: string = hash({
      search,
      segmentId,
      typeSearchCount,
      lastRefreshSegmentsCache,
    }) as string;

    if (this.countCache[queryHash]) {
      return { count: this.countCache[queryHash] };
    }

    const { data }: any = await this.apiV2.post('/api/v2.1/contacts/by_segments/count', {
      segmentId,
      search,
    });
    this.countCache[queryHash] = (data as CountResponse).total;
    return { count: (data as CountResponse).total };
  }

  async getGroupsCountOfSearch({
    search,
    lastRefresh,
  }: {
    search: string;
    lastRefresh: number;
  }): Promise<{ count: number }> {
    const typeSearchCount = 'GroupsCount';
    let lastRefreshGroupsCache: number | undefined = this.getLastRefreshGroupsValue();
    lastRefreshGroupsCache =
      lastRefresh !== undefined && lastRefresh < lastRefreshGroupsCache
        ? lastRefreshGroupsCache
        : lastRefresh;

    const queryHash: string = hash({ search, typeSearchCount, lastRefreshGroupsCache });

    if (this.countCache[queryHash]) {
      return { count: this.countCache[queryHash] };
    }
    const { data }: any = await this.apiV2.post('/api/v2/groups/count', { search });
    this.countCache[queryHash] = data;
    return { count: data };
  }

  async getTagsCountOfSearch({
    search,
    tagsCategoriesFilter,
    tagsDataSourceFilter,
    lastRefresh,
    identifier,
  }: {
    search?: string;
    tagsCategoriesFilter?: number[];
    tagsDataSourceFilter?: number[];
    lastRefresh?: number;
    identifier?: string;
  }): Promise<{ count: number }> {
    const typeSearchCount = 'TagsCount';
    tagsCategoriesFilter?.sort();
    tagsDataSourceFilter?.sort();
    let lastRefreshTagsCache: number | undefined = this.getLastRefreshTagsValue();
    lastRefreshTagsCache =
      lastRefresh !== undefined && lastRefresh < lastRefreshTagsCache
        ? lastRefreshTagsCache
        : lastRefresh;
    const queryHash: string = hash({
      search,
      typeSearchCount,
      tagsCategoriesFilter,
      tagsDataSourceFilter,
      lastRefreshTagsCache,
    });

    if (this.countCache[queryHash]) {
      return { count: this.countCache[queryHash] };
    }

    const { data }: any = await this.apiV2.post('/api/v2/tags/count', {
      search,
      CategoryIds: tagsCategoriesFilter,
      DataSourceIds: tagsDataSourceFilter,
      identifier: identifier,
    });
    this.countCache[queryHash] = data;
    return { count: data };
  }

  async getItemsCountOfSearch({
    search,
    lastDays,
    dataSource,
  }: {
    search: string;
    lastDays: number;
    dataSource: number[];
  }): Promise<{ count: number }> {
    const typeSearchCount = 'ItemsCount';
    dataSource.sort();
    const queryHash: string = hash({ search, typeSearchCount, lastDays, dataSource });

    if (this.countCache[queryHash]) {
      return { count: this.countCache[queryHash] };
    }

    const { data }: any = await this.apiV2.post('/api/v2/items/count', {
      search,
      lastDays: lastDays,
      dataSource: dataSource?.join(', '),
    });
    this.countCache[queryHash] = data;
    return { count: data };
  }

  // 1-1 relationship between count and lastRefresh (use hash() if has more than 1 dependencies)
  contactsStatusCache: { list: IStatus[]; lastRefresh: number } = { list: [], lastRefresh: 0 };
  async getContactStatusCount(query: { lastRefresh: number }) {
    let lastRefreshContactsCache: number | undefined = this.getLastRefreshContactsValue();
    lastRefreshContactsCache =
      query.lastRefresh !== undefined && query.lastRefresh < lastRefreshContactsCache
        ? lastRefreshContactsCache
        : query.lastRefresh;

    if (
      this.contactsStatusCache.list.length === 0 ||
      lastRefreshContactsCache > this.contactsStatusCache.lastRefresh
    ) {
      const showStatusCards = localStorage.getItem('showStatusCards');
      if (showStatusCards !== 'true') {
        return { list: [] };
      }
      const { data }: any = await this.apiV2.post('/api/v2/contacts/status');
      this.contactsStatusCache = { list: data, lastRefresh: query.lastRefresh };
    }
    return { list: this.contactsStatusCache.list };
  }

  // 1-1 relationship between count and lastRefresh (use hash() if has more than 1 dependencies)
  contactsDiscardedStatusCache: { list: IStatusList[]; lastRefresh: number } = {
    list: [],
    lastRefresh: 0,
  };
  async getContactDiscardedStatusCount(query: { lastRefresh: number }) {
    let lastRefreshContactsCache = this.getLastRefreshContactsValue();
    lastRefreshContactsCache =
      query.lastRefresh !== undefined && query.lastRefresh < lastRefreshContactsCache
        ? lastRefreshContactsCache
        : query.lastRefresh;

    if (
      this.contactsDiscardedStatusCache.list.length === 0 ||
      lastRefreshContactsCache > this.contactsDiscardedStatusCache.lastRefresh
    ) {
      const showStatusCards = localStorage.getItem('showStatusCards');
      if (showStatusCards !== 'true') {
        return { list: [] };
      }
      const { data }: any = await this.apiV2.post('/api/v2/contacts/discarded_status');
      this.contactsDiscardedStatusCache = { list: data, lastRefresh: query.lastRefresh };
    }
    return { list: this.contactsDiscardedStatusCache.list };
  }

  getContactStatus(): Promise<any> {
    const statusList = [
      { id: 'asleep', name: 'asleep' },
      { id: 'discarded', name: 'discarded' },
      { id: 'frequent', name: 'frequent' },
      { id: 'inactive', name: 'inactive' },
      { id: 'new', name: 'new' },
    ];

    return new Promise<{
      list: IStatus[];
    }>((resolve) => {
      resolve({
        list: statusList,
      });
    });
  }

  getContactDiscardedStatus(): Promise<any> {
    const discardedStatusList = [
      { id: 'unsafe', name: 'unsafe' },
      { id: 'suspended', name: 'suspended' },
      { id: 'quarantine', name: 'quarantine' },
      { id: 'blocked', name: 'blocked' },
      { id: 'unsubscribe', name: 'unsubscribe' },
      { id: 'complainers', name: 'complainers' },
    ];

    return new Promise<{
      list: IStatus[];
    }>((resolve) => {
      resolve({
        list: discardedStatusList,
      });
    });
  }

  getContactHappiness(): Promise<any> {
    const happinessList = [
      { id: 'detractors', name: 'detractors' },
      { id: 'passives', name: 'passives' },
      { id: 'promoters', name: 'promoters' },
      { id: 'noRegistry', name: 'noRegistry' },
    ];

    return new Promise<{
      list: IHappiness[];
    }>((resolve) => {
      resolve({
        list: happinessList,
      });
    });
  }

  cachedContacts: TableContact[][][] = [];
  lastPage = 0;
  currentPageList: ITableContact[] = [];
  countCache: { [x: string]: number } = {};
  // cspell:disable
  segmentConditionsCache: {
    [x: string]: { id: number; nombre: string; valores: ISegmentConditions[] };
  } = {};
  customFieldsCache: { [x: string]: ICustomField[] } = {};
  interestGroupsCache: { [x: string]: InterestGroupsData } = {};
  groupsCache: { [x: string]: GroupsData } = {};
  profileOptionsListCache: { [x: string]: ProfileOptions } = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getContacts({
    page,
    pageSize,
    search,
    includeTags,
    orderColumn,
    orderDirection,
    tagsFilter,
    statusFilter,
    happinessFilter,
    groupsFilter,
    segmentsFilter,
    discardedFilter,
  }: {
    page: number;
    search?: string;
    pageSize: number;
    includeTags: boolean;
    orderColumn: string;
    orderDirection: string;
    tagsFilter: number[];
    statusFilter: string[];
    happinessFilter: string[];
    groupsFilter: number[];
    discardedFilter?: string[];
    segmentsFilter: number;
  }) {
    const { data } =
      segmentsFilter > 0
        ? await this.apiV2.post('/api/v2.1/contacts/by_segments', {
            segmentId: segmentsFilter,
            page,
            perPage: pageSize,
            search,
          })
        : await this.apiV2.post('/api/v2/contacts', {
            pageNumber: page,
            rowsPerPage: pageSize,
            search,
            orderByColumn: orderColumn,
            orderByDirection: orderDirection,
            tagIds: tagsFilter,
            statusFilterParam: statusFilter,
            happinessFilterParam: happinessFilter,
            groupIds: groupsFilter,
            segmentId: segmentsFilter,
            discardedStatusFilterParam: discardedFilter,
          });

    const contacts: TableContact[] = data as [TableContact];
    let dictionaryTags: any;

    if (includeTags) {
      dictionaryTags = await this.getTagsByContacts(contacts);
    }

    // TODO: Cached contacts must be an array of pages
    // this.lastPage = page;
    // this.cachedContacts.push(contacts);

    const list = _.flatten(contacts).map((x) => ({
      contact: {
        ...x,
        status: x.status ? x.status : ('expired' as any),
        created: x.created ? new Date(x.created) : undefined,
        lastSend: x.lastSend ? new Date(x.lastSend) : undefined,
        lastActivity: x.lastActivity ? new Date(x.lastActivity) : undefined,
        tags: includeTags && dictionaryTags[x.emailId] ? dictionaryTags[x.emailId] : [],
      },
    }));

    this.currentPageList = list;

    return {
      list: list,
    };
  }

  async getContactInfo({ emailId, lastRefresh }: { emailId: number; lastRefresh?: number }) {
    const currentContact = this.currentPageList
      .map((x) => x.contact)
      .find((contact) => Number(contact.emailId) === Number(emailId));
    let info: TableContact;
    if (currentContact && !lastRefresh) {
      info = currentContact;
    } else {
      const { data } = await this.apiV2.get(`/api/v2/contacts/${emailId}`);
      info = data as TableContact;
    }

    return {
      contactInfo: info,
    };
  }

  async getContactActivitySummary({ emailId }: { emailId: number }) {
    const {
      data: { messages, openings, clicks },
    } = (await this.apiV2.get(`/api/v2/contacts/activity_summary/${emailId}`)) as {
      data: { messages: number; openings: number; clicks: number };
    };

    // eslint-disable-next-line @typescript-eslint/await-thenable
    return await {
      messages: messages,
      openings: openings,
      clicks: clicks,
    };
  }

  async getContactActivityChannel(
    query: IContactActivityChannelString
  ): Promise<{ list: IContactActivity[] }> {
    const { activityType, ...queryResult } = query;
    const activityTypes = activityType ? activityType?.map((i) => Number(i)) : undefined;
    const queryParams: IContactActivityChannel = { ...queryResult, activityTypes };
    const { data }: any = await this.apiV2.post('/api/v2/contacts/activity_list', queryParams);
    return {
      list: data,
    };
  }

  activityCount: { emailId?: number; count?: number } = {};

  async getContactActivityChannelCount(
    query: IContactActivityChannelString
  ): Promise<{ count: number }> {
    const { activityType, ...queryResult } = query;
    const activityTypes = activityType ? activityType?.map((i) => Number(i)) : undefined;
    const queryParams: IContactActivityChannel = { ...queryResult, activityTypes };
    const { data }: any = await this.apiV2.post('/api/v2/contacts/activity_count', queryParams);
    this.activityCount = { emailId: query.emailId, count: data };
    return {
      count: data,
    };
  }

  getContactActivityFilter(): Promise<any> {
    const data: any = mockActivityFilterData;
    return new Promise<{
      channels: activityChannel[];
      activities: activityChannel[];
    }>((resolve) => {
      resolve({
        channels: data.channels,
        activities: data.activities,
      });
    });
  }

  async getContactActivityTags(query: {
    emailId: number;
    pageNumber: number;
    rowsPerPage: number;
    search: string;
  }): Promise<{ list: IContactActivityTags[]; total: number }> {
    const { data }: any = await this.apiV2.post('/api/v2/tags/by_contact_activity', query);
    return {
      list: data.list,
      total: data.total,
    };
  }

  async getContactTagsById(query: { ids: number[] }) {
    const { data }: any = await this.apiV2.post('/api/v2/tags/by_contact_ids', query);
    return {
      list: data,
    };
  }

  async getContactActivityItems(query: {
    emailId: number;
    pageNumber: number;
    rowsPerPage: number;
    search: string;
  }): Promise<{ list: IContactActivityItems[]; total: number }> {
    const { data }: any = await this.apiV2.post('/api/v2/items/by_contact_activity', query);
    return {
      list: data.list,
      total: data.total,
    };
  }

  async getContactActivityGroups(
    query: GetContactActivityGroupsParams
  ): Promise<{ list: IContactActivityGroups[]; total: number }> {
    const { data }: any = await this.apiV2.post('/api/v2/groups/by_contact_activity', query);
    return {
      list: data.list,
      total: data.total,
    };
  }

  async createSender(payload: ISenderCreatePayload): Promise<any> {
    try {
      const { data } = await this.apiV2.post('/api/v2.1/configuration/senders/create', payload);
      return data as ISenderCreateResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { message, status, stack } = error.response?.data ?? {};

        return { message, status, stack };
      }

      return {
        message: ['UNKNOW'],
        status: 'error',
        stack: '',
      };
    }
  }
  async updateSender(payload: ISenderUpdatePayload): Promise<any> {
    try {
      const { data } = await this.apiV2.post('/api/v2.1/configuration/senders/update', payload);
      return data as ISenderCreateResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { message, status, stack } = error.response?.data ?? {};

        return { message, status, stack };
      }

      return {
        message: ['UNKNOW'],
        status: 'error',
        stack: '',
      };
    }
  }

  async recalculateSegment(query: { segmentId: number; lastRefresh: number }): Promise<any> {
    try {
      if (query.segmentId) {
        await this.apiV1.post('/Services/Contactos.svc/RecalcularPerfil', {
          perfilId: query.segmentId,
        });
      }
      return query.segmentId + query.lastRefresh;
    } catch (error) {
      return query.segmentId + query.lastRefresh + 1;
    }
  }

  getCodifications(): ICodification[] {
    try {
      const data = [
        {
          id: 28591,
          value: 'ISO-8859-1',
        },
        {
          id: 1251,
          value: 'Windows-1251',
        },
        {
          id: 1252,
          value: 'windows-1252',
        },
        {
          id: 1200,
          value: 'UTF-16',
        },
        {
          id: 10000,
          value: 'Macintosh',
        },
        {
          id: 0,
          value: 'utf-8',
        },
      ];

      return data;
    } catch (error) {
      return [];
    }
  }

  async recalculateTag(query: { tagIdList: number[] }): Promise<any> {
    if (query.tagIdList.length) {
      await this.apiV1.post('/Services/Tags.svc/RecalcularTag', {
        tags: query.tagIdList,
      });
      return query.tagIdList[0];
    }
    return undefined;
  }
  async createTag(
    tagName: string,
    tagCategoryId?: number,
    contentType?: number,
    content?: string,
    addDefault?: boolean,
    tagUrl?: string,
    encoding?: number
  ): Promise<any> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Tags.svc/CrearTag', {
      nombre: tagName,
      tagCategoriaId: tagCategoryId === null || tagCategoryId === undefined ? 0 : tagCategoryId,
      tipoContenido: contentType,
      contenido: content,
      agregarDefault: addDefault,
      url: tagUrl,
      encoding: encoding,
    });
    /* cspell:enable */
    return {
      notification: { content: data },
    };
  }

  async editTag(query: {
    tagId: number;
    tagName: string;
    tagCategoryId?: number;
    contentType?: number;
    content?: string;
    addDefault?: boolean;
    tagUrl?: string;
    encoding?: number;
  }): Promise<{ statusOk: boolean; errorType: { repeatedName: boolean; contentError: boolean } }> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Tags.svc/ModificarTag', {
      id: query.tagId,
      nombre: query.tagName,
      tagCategoriaId: query.tagCategoryId ?? 0,
      tipoContenido: query.contentType,
      contenido: query.content,
      agregarDefault: query.addDefault,
      url: query.tagUrl,
      encoding: query.encoding,
    });
    const result = {
      statusOk: data.id_notificacion === 'MODIFICAR_TAG_OK',
      errorType: {
        repeatedName: data.id_notificacion === 'TAG_EXISTENTE_OK',
        contentError: data.id_notificacion === 'TAG_PROCESS_CONTENT_KO',
      },
    };
    return result;
    /* cspell:enable */
  }

  async updateTagCategory(query: {
    tags: number[];
    toCategory?: number;
  }): Promise<{ statusOk: boolean; errorType: { contentError: boolean } }> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Tags.svc/CambiarCategoriasTag', {
      ids: query.tags,
      categoriaFinal: query.toCategory,
    });
    const result = {
      statusOk: data.id_notificacion === 'MODIFICAR_TAG_CATEGORIA_OK',
      errorType: {
        contentError: data.id_notificacion === 'MODIFICAR_CATEGORIA_KO',
      },
    };
    return result;
    /* cspell:enable */
  }

  async mergeTags(
    query: TagsMergeParams
  ): Promise<{ statusOk: boolean; errorType: { repeatedName: boolean; contentError: boolean } }> {
    /* cspell:disable */
    const payload = {
      ids: query.tags,
      categoria: [],
      desde: null,
      hasta: null,
      fuente: null,
      emailId: 0,
      nombre: '',
      orden: 'nombre asc',
      tagFinal: {
        nombre: query.name,
        tagCategoriaId: query.category,
        contenido: {
          contenido: query.content?.value,
          tagTipoContenidoId: query.content?.typeId,
        },
      },
    };
    const { data }: any = await this.apiV1.post('/Services/Tags.svc/MergeTag', payload);
    const result = {
      statusOk: data.id_notificacion === 'CREAR_TAG_OK',
      errorType: {
        repeatedName: data.id_notificacion === 'TAG_EXISTENTE_OK',
        contentError: data.id_notificacion === 'TAG_PROCESS_CONTENT_KO',
        timeoutDownload: data.id_notificacion === 'TIMEOUT_TAG_DOWNLOAD_CONTENT',
        downloadContent: data.id_notificacion === 'TAG_DOWNLOAD_CONTENT_KO',
      },
    };
    return result;
    /* cspell:enable */
  }

  async getTagContent(query: { id: number }): Promise<ITagContent> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Tags.svc/ObtenerContenidoTag', {
      tagId: query.id,
    });
    const data1 = data.ObtenerContenidoTagResult;
    const response: ITagContent = {
      id: data1.tagId,
      content: data1.contenido,
      encoding: data1.encoding,
      tagContentTypeId: data1.tagTipoContenidoId,
      url: data1.url,
    };
    /* cspell:enable */
    return response;
  }

  async createGroup(groupName: string): Promise<any> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.get(
      `/Services/Contactos.svc/CrearGrupoUsuario?nombre=${groupName}`
    );
    return {
      notification: { content: data },
    };
  }

  async editGroup(query: { groupId: number; groupName: string }): Promise<boolean> {
    /* cspell:disable */
    const paramsAdapter = { nombre: query.groupName, grupo: query.groupId };
    /* cspell:disable */
    const { data }: any = await this.apiV1.get(
      '/Services/Contactos.svc/ModificarNombreGrupo',
      paramsAdapter
    );
    /* cspell:disable */
    return data.id_notificacion === 'GRUPO_MODIFICADO_OK';
  }

  async addVerifiedSender({ email }: { email: string }): Promise<VerificationResponse> {
    /* cspell:disable */
    const { data } = await this.apiV1.post<{
      id_notificacion: 'id_notification';
    }>('/Services/Usuarios.svc/AgregarEmailVerificado', {
      email: email,
    });

    const keyMap: Record<string, string> = {
      id_notificacion: 'id_notification',
    };

    const verifiedEmailTranslation = mapKeysToEnglish<VerificationResponse>(data, keyMap);

    return verifiedEmailTranslation;
  }

  async getContactsForEmailTest(): Promise<IContactsEmailTest[]> {
    /* cspell:disable */
    try {
      const { data } = await this.apiV1.post('/Services/Usuarios.svc/GetListaContactoPrueba');
      const contacts = data as IContactsEmailTestResponseV1[];

      return contacts.map((item) => ({
        id: item.EmailId,
        contactId: item.EmailContactoPruebaId,
        email: item.Email,
        accountId: item.EmpresaId,
      }));
    } catch (error) {
      console.error(`❌ EM_Error getContactsForEmailTest:`, error);
      return [];
    }
  }

  async getGroupsForEmailTest(): Promise<IGroupsEmailTest[]> {
    /* cspell:disable */
    try {
      const { data } = await this.apiV1.get('/Services/Usuarios.svc/GetListaGruposPrueba');
      const groups = data as IGroupsEmailTestResponseV1[];

      return groups.map((item) => ({
        id: item.id,
        count: item.cantidad,
        name: item.nombre,
      }));
    } catch (error) {
      console.error(`❌ EM_Error getGroupsForEmailTest:`, error);
      return [];
    }
  }

  async sendEmailTest(actionId: number, groupId: number, contactId: number): Promise<boolean> {
    /* cspell:disable */
    const { data } = await this.apiV1.get('/Services/Campanias.svc/EnviarEmailPrueba', {
      elementoId: actionId,
      grupoPruebaId: groupId,
      emailIdContactoPrueba: contactId,
    });

    const { id_notificacion } = data as { id_notificacion: string };
    if (id_notificacion === 'GRUPO_PRUEBA_SIN_DESTINATARIOS') {
      throw new Error('GRUPO_PRUEBA_SIN_DESTINATARIOS');
      return false;
    }

    return id_notificacion === 'MAIL_ENVIADO_OK';
  }

  async finishDownloadReport(): Promise<boolean> {
    /* cspell:disable */
    const { data }: { data: any } = await this.apiV1.post(
      '/Services/Reportes.svc/TerminarReportePorUsuario'
    );

    return data.id_notificacion === 'REPORTES_TERMINADOS_OK';
  }

  async getSenders(query?: {
    search: string;
    limit: number;
    page: number;
  }): Promise<ISendersResponse> {
    const { data } = await this.apiV2.post('/api/v2.1/configuration/senders', query);
    return data as ISendersResponse;
  }

  async getContracts(query?: {
    search: string | null;
    limit: number;
    page: number;
    statusIds: number[] | null;
    planIds: number[] | null;
  }): Promise<IContractsResponse> {
    const { data } = await this.apiV2.post('/api/v2.1/backoffice/contracts/list', query);
    return data as IContractsResponse;
  }
  async getLogs(query?: {
    search: string | undefined;
    limit: number;
    page: number;
  }): Promise<ILogsResponse> {
    const { data } = await this.apiV2.get('/api/v2.1/backoffice/contracts/logs', query);
    return data as ILogsResponse;
  }

  async getSenderById(senderId: number): Promise<ISenderData> {
    const { data } = await this.apiV2.get(`/api/v2.1/configuration/senders/${senderId}`);
    return data as ISenderData;
  }

  async sendVerifyEmail(emailId: number, accountId: number) {
    // cspell:disable
    const { data }: { data: any } = await this.apiV1.post(
      '/Services/Usuarios.svc/ReenviarEmailRemitenteVerificado',
      {
        emailId,
        empresaId: accountId,
      }
    );
    const { id_notificacion } = data;
    return id_notificacion === 'EMAIL_VERIFICACION_ENVIADO_OK' ? true : false;
    // cspell:enable
  }

  async getSendersEmail(): Promise<IEmailVerified[]> {
    const { data } = await this.apiV2.post('/api/v2.1/configuration/senders/emails');
    return data as IEmailVerified[];
  }

  async createSendersEmail(email: string): Promise<any> {
    const { data } = await this.apiV2.post('/api/v2.1/configuration/senders/email/create', {
      email,
      isDefault: false,
    });
    return data;
  }

  async getListedEmailsSendersWithVerification(query: SenderParams): Promise<IEmailData[]> {
    /** cspell:disable */
    const { data } = await this.apiV1.post(
      '/Services/Usuarios.svc/ObtenerListadoEmailsRemitentesConVerificacion',
      {
        busqueda: query.search,
        ordenColumna: query.orderColumn,
        sentidoOrden: query.senseOrder,
      }
    );

    const keyMap: Record<string, string> = {
      predeterminado: 'default',
      detalle: 'detail',
      estado: 'state',
    };
    /** cspell:enable */

    const emailsDataTranslation = (data as []).map((sender) =>
      mapKeysToEnglish<IEmailData>(sender, keyMap)
    );

    return emailsDataTranslation;
  }

  async addAllContactsToGroup(
    query: ContactTableSearchFilters & { groupsToAssign: number[]; groupsToUnassign: number[] }
  ): Promise<boolean> {
    const { list } = await this.getContacts({
      page: 1,
      pageSize: query.resultCount,
      segmentsFilter: query.segmentsFilter,
      groupsFilter: query.groupsFilter,
      statusFilter: query.statusFilter,
      happinessFilter: query.happinessFilter,
      tagsFilter: query.tagsFilter,
      search: query.search,
      includeTags: false,
      orderColumn: '',
      orderDirection: '',
    });
    const contactsIds = list.map((c) => c.contact.emailId);

    return await this.addContactsToGroup({
      contactsIds,
      groupsToAssign: query.groupsToAssign,
      groupsToUnassign: query.groupsToUnassign,
    });
  }

  async getGroupsByContacts({
    contactsIds,
    allResults,
    filters,
  }: IGroupByContactsQuery): Promise<IGroupByContactsResponse> {
    if (!allResults && contactsIds.length === 0) return { allInCommon: [], partiallyInCommon: [] };
    if (allResults && filters) {
      const { list } = await this.getContacts({
        page: 1,
        pageSize: filters.resultCount,
        segmentsFilter: filters.segmentsFilter,

        groupsFilter: filters.groupsFilter,
        statusFilter: filters.statusFilter,
        happinessFilter: filters.happinessFilter,
        tagsFilter: filters.tagsFilter,
        search: filters.search,
        includeTags: false,
        orderColumn: '',
        orderDirection: '',
      });
      contactsIds = list.map((c) => c.contact.emailId);
    }
    const { data } = await this.apiV2.post('/api/v2.1/contacts/groups_by_contacts', {
      ids: contactsIds,
    });
    return data as IGroupByContactsResponse;
  }

  async getTagsListByContacts({
    contactsIds,
    allResults,
    filters,
  }: ITagsByContactsQuery): Promise<ITagsByContactsResponse> {
    if (contactsIds.length === 0) return { allInCommon: [], partiallyInCommon: [] };
    if (allResults && filters) {
      const { list } = await this.getContacts({
        page: 1,
        pageSize: filters.resultCount,
        segmentsFilter: filters.segmentsFilter,
        groupsFilter: filters.groupsFilter,
        statusFilter: filters.statusFilter,
        happinessFilter: filters.happinessFilter,
        tagsFilter: filters.tagsFilter,
        search: filters.search,
        includeTags: false,
        orderColumn: '',
        orderDirection: '',
      });
      contactsIds = list.map((c) => c.contact.emailId);
    }
    const { data } = await this.apiV2.post('/api/v2.1/contacts/tags_by_contacts', {
      ids: contactsIds,
    });
    return data as ITagsByContactsResponse;
  }

  async processContactsGroup(paramsAdapter: object) {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post(
      '/Services/Contactos.svc/AgregarQuitarContactosAGruposPorLote',
      paramsAdapter
    );
    /* cspell:enable */
    const result = await this.checkProcess(Number(data));
    return result;
  }
  async addContactsToGroup(query: {
    contactsIds: number[];
    groupsToAssign: number[];
    groupsToUnassign?: number[];
  }): Promise<boolean> {
    /* cspell:disable*/
    const paramsAdapter = {
      allSelected: false,
      Channel: 1,
      campoPrincipal: 'email',
      emailIds: query.contactsIds,
      grupoSelecto: query.groupsToAssign,
      grupoDeselecto: query.groupsToUnassign,
      tipoGrupo: 'usuario',
    };
    return await this.processContactsGroup(paramsAdapter);
  }

  async updateContactsToGroup(query: {
    contactsIds: number[];
    groupsToAssign: number[];
    groupsToUnassign: number[];
  }): Promise<boolean> {
    const paramsAdapter = {
      grupoSelecto: query.groupsToAssign,
      grupoDeselecto: query.groupsToUnassign,
      campoPrincipal: 'email',
      allSelected: false,
      emailIds: query.contactsIds,
      tipoGrupo: 'usuario',
    };
    return await this.processContactsGroup(paramsAdapter);
  }
  /* cspell:enable*/

  async checkProcess(processId: number): Promise<boolean> {
    let processing = true;
    let okResult = false;
    while (processing) {
      await timeout(1000);
      /* cspell:disable */
      const { data }: any = await this.apiV1.get(
        `/api/processstatus/status?processId=${processId}`
      );
      /* cspell:disable */
      if (data.Estado === 'FINALIZADO') {
        okResult = true;
        processing = false;
        /* cspell:disable */
      } else if (data.Estado === 'FALLO_AL_REALIZAR_ACCION') {
        okResult = false;
        processing = false;
      }
    }
    return okResult;
  }

  async addFlowsToAllContacts(
    query: ContactTableSearchFilters & { flowId: number }
  ): Promise<boolean> {
    const { list } = await this.getContacts({
      page: 1,
      pageSize: query.resultCount,
      groupsFilter: query.groupsFilter,
      segmentsFilter: query.segmentsFilter,
      statusFilter: query.statusFilter,
      happinessFilter: query.happinessFilter,
      tagsFilter: query.tagsFilter,
      search: query.search,
      includeTags: false,
      orderColumn: '',
      orderDirection: '',
    });

    const contactsIds = list.map((c) => c.contact.emailId);

    return await this.addFlowsToContacts({ flowId: query.flowId, contactsIds: contactsIds });
  }
  async addFlowsToContacts(query: { flowId: number; contactsIds: number[] }): Promise<boolean> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Contactos.svc/InsertContactsToFlow', {
      idFlow: query.flowId,
      /* cspell:disable */
      filtroContactos: {
        emailIds: query.contactsIds,
        campoPrincipal: 'email',
        tipoGrupo: 'usuario',
      },
    });
    const result = await this.checkProcess(Number(data));
    return result;
  }

  async getRestrictions(): Promise<IRestricted> {
    const { data: response }: any = await this.apiV1.get(
      '/Services/Usuarios.svc/GetRestrictions?component=new-element-campaign'
    );

    return {
      allAllowed: response.Data.AllAllowed,
      elementsBlocked: response.Data.ElementsBlocked,
    };
  }

  async addGroupsToFlow(query: {
    flowId: number;
    groupsId: number[];
    type: string;
  }): Promise<boolean> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Contactos.svc/InsertContactsToFlow', {
      idFlow: query.flowId?.toString(),
      /* cspell:disable */
      filtroContactos: null,
      filtroGrupos: {
        grupos: query.groupsId?.map((item) => item?.toString()),
        tipo: query.type,
      },
    });
    const result = await this.checkProcess(Number(data));
    return result;
  }

  async processContactsTags(paramsAdapter: object) {
    /* cspell:disable-next-line */
    const { data }: any = await this.apiV1.post(
      '/Services/Tags.svc/AsociarTagsContactos',
      paramsAdapter
    );
    return data.AsociarTagsContactosResult.id_notificacion === 'ASOCIAR_TAGS_CONTACTOS_OK';
  }
  async addContactsToTags(query: {
    contactsIds: number[];
    tagsToAssign: number[];
    tagsToUnassign?: number[];
  }): Promise<boolean> {
    /* cspell:disable-next-line */
    const paramsAdapter = {
      lote: {
        Channel: 1,
        emailIds: query.contactsIds,
        tipoGrupo: 'usuario',
        mostrarDesuscriptos: false,
      },
      tagsAsociados: query.tagsToAssign,
      tagsDesasociados: query.tagsToUnassign,
    };
    return await this.processContactsTags(paramsAdapter);
  }
  /* cspell:disable-next-line */
  async updateContactsToTags(query: {
    contactsIds: number[];
    tagsToAssign: number[];
    tagsToUnassign: number[];
  }): Promise<boolean> {
    /* cspell:disable-next-line */
    const paramsAdapter = {
      lote: {
        Channel: 1,
        emailIds: query.contactsIds,
        tipoGrupo: 'usuario',
        mostrarDesuscriptos: false,
      },
      tagsAsociados: query.tagsToAssign,
      tagsDesasociados: query.tagsToUnassign,
      limitTags: query.tagsToAssign.length + query.tagsToUnassign.length,
    };
    return await this.processContactsTags(paramsAdapter);
  }

  async associateTagsToAllContacts(
    query: ContactTableSearchFilters & { tagsToAssign: number[]; tagsToUnassign: number[] }
  ): Promise<boolean> {
    const { list } = await this.getContacts({
      page: 1,
      pageSize: query.resultCount,
      groupsFilter: query.groupsFilter,
      segmentsFilter: query.segmentsFilter,
      statusFilter: query.statusFilter,
      happinessFilter: query.happinessFilter,
      tagsFilter: query.tagsFilter,
      search: query.search,
      includeTags: false,
      orderColumn: '',
      orderDirection: '',
    });

    const contactsIds = list.map((c) => c.contact.emailId);

    return await this.addContactsToTags({
      contactsIds: contactsIds,
      tagsToAssign: query.tagsToAssign,
      tagsToUnassign: query.tagsToUnassign,
    });
  }

  async deleteAllSelectedContacts(query: ContactTableSearchFilters): Promise<boolean> {
    const { list } = await this.getContacts({
      page: 1,
      pageSize: query.resultCount,
      groupsFilter: query.groupsFilter,
      segmentsFilter: query.segmentsFilter,
      statusFilter: query.statusFilter,
      happinessFilter: query.happinessFilter,
      tagsFilter: query.tagsFilter,
      search: query.search,
      includeTags: false,
      orderColumn: '',
      orderDirection: '',
    });
    const contactsIds = list.map((c) => c.contact.emailId);
    return await this.deleteSelectedContacts({ contactsIds: contactsIds });
  }

  async deleteSelectedContacts(query: { contactsIds: number[] }): Promise<boolean> {
    /* cspell:disable */
    const paramsAdapter = {
      accion: 'eliminar_contacto',
      emailIds: query.contactsIds,
      Channel: 1,
      tipoGrupo: 'usuario',
    };

    /* cspell:disable */
    const { data }: any = await this.apiV1.post(
      '/Services/Contactos.svc/ModificarContactosPorLote',
      paramsAdapter
    );
    const result = await this.checkProcess(Number(data));
    if (result) {
      this.countCache = {};
    }
    return result;
  }

  async reactivateDiscardedUsersSelected(emailIds: number[]) {
    try {
      /* cspell:disable */
      const params = {
        accion: 'reactivar_contacto',
        emailIds,
        Channel: 1,
        tipoGrupo: 'sistema',
      };
      /* cspell:disable */

      const { data }: any = await this.apiV1.post(
        '/Services/Contactos.svc/ModificarContactosPorLote',
        params
      );
      const result = await this.checkProcess(Number(data));
      return { success: result, data: result };
    } catch (error) {
      console.error(error);
      return { success: false, data: false };
    }
  }

  async getAllEmailsIdsDiscardedUsers(
    query: ContactTableSearchFilters
  ): Promise<DataResponse<number[]>> {
    try {
      const { list } =
        (await this.getContacts({
          page: 1,
          pageSize: query.resultCount,
          groupsFilter: query.groupsFilter,
          segmentsFilter: query.segmentsFilter,
          statusFilter: query.statusFilter,
          happinessFilter: query.happinessFilter,
          tagsFilter: query.tagsFilter,
          search: query.search,
          includeTags: false,
          orderColumn: '',
          orderDirection: '',
          discardedFilter: query.discardedFilter,
        })) ?? {};
      const discaredStatus: DiscaredStatus[] = ['suspended', 'quarantine'];
      const emailsIds = list
        .filter((c) => discaredStatus.includes(c.contact.discarded as DiscaredStatus))
        .map((c) => c.contact.emailId);
      return { success: true, data: emailsIds };
    } catch (error) {
      console.error(error);
      return { success: false, data: [] };
    }
  }

  async suspendAllSelectedContacts(query: ContactTableSearchFilters): Promise<boolean> {
    const { list } = await this.getContacts({
      page: 1,
      pageSize: query.resultCount,
      groupsFilter: query.groupsFilter,
      segmentsFilter: query.segmentsFilter,
      statusFilter: query.statusFilter,
      happinessFilter: query.happinessFilter,
      tagsFilter: query.tagsFilter,
      search: query.search,
      includeTags: false,
      orderColumn: '',
      orderDirection: '',
    });
    const contactsIds = list.map((c) => c.contact.emailId);
    return await this.suspendSelectedContacts({ contactsIds: contactsIds });
  }

  async suspendSelectedContacts(query: { contactsIds: number[] }): Promise<boolean> {
    const firstContact = query.contactsIds.at(0);
    if (query.contactsIds.length === 1 && firstContact !== undefined) {
      return await this.suspendSingleContact({ contactId: firstContact });
    }
    /* cspell:disable */
    const paramsAdapter = {
      accion: 'desuscribir',
      emailIds: query.contactsIds,
      Channel: 1,
      tipoGrupo: 'usuario',
    };

    /* cspell:disable */
    const { data }: any = await this.apiV1.post(
      '/Services/Contactos.svc/ModificarContactosPorLote',
      paramsAdapter
    );
    const result = await this.checkProcess(Number(data));
    return result;
  }

  async suspendSingleContact(query: { contactId: number }): Promise<boolean> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.get(
      `/Services/Contactos.svc/EditarContactoSuscripcion?emailId=${query.contactId}`
    );
    /* cspell:disable */
    return data.id_notificacion === 'CONTACTO_DESUSCRIPTO_OK';
  }

  async getGroupsInUse(query: {
    groupsIds: number[];
  }): Promise<{ groupId: number; name: string }[]> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Contactos.svc/GetGruposEnUso', {
      grupoIds: query.groupsIds,
    });
    const dataArray: any[] = data;
    const response: { groupId: number; name: string }[] = dataArray.map((g) => {
      return { groupId: g[0], name: g[1] };
    });
    return response;
  }

  async deleteSelectedGroups(query: { groupsIds: number[] }): Promise<boolean> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Contactos.svc/EliminarGrupos', {
      grupoIds: query.groupsIds,
    });
    /* cspell:disable */
    return data.id_notificacion === 'GRUPOS_ELIMINADOS_OK';
  }

  async deleteSender(sender: ISenderData): Promise<boolean> {
    /* cspell:disable */
    const { data }: any = await this.apiV2.post('/api/v2.1/configuration/senders/delete', {
      id: sender.id,
    });
    /* cspell:disable */
    return data.status === 'ok';
  }

  async deleteSelectedTags(query: { tagsIds: number[] }): Promise<boolean> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Tags.svc/EliminarTags', {
      ids: query.tagsIds,
    });
    /* cspell:disable */
    return data.id_notificacion === 'ELIMINAR_TAGS_OK';
  }

  async getTagsDropDown(query: { identifier: string; lastRefresh?: number }) {
    const { data }: any = await this.apiV2.post('/api/v2/tags', { identifier: query.identifier });
    return { list: data };
  }

  async getTagsCategories(query: { identifier: string }) {
    const { data }: any = await this.apiV2.post('/api/v2/tags', { identifier: query.identifier });
    return { list: data };
  }

  async getFlowsByAccount() {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/Services/Integraciones.svc/GetFlowsByAccount');
    return { list: data };
  }

  async updateContactEditCustomFields(query: {
    emailId: number;
    customData: any;
  }): Promise<{ statusOk: boolean } | { statusError: boolean }> {
    try {
      const parseCustomFields =
        query.customData.length > 1
          ? query.customData.reduce(
              (prev: any, curr: any) =>
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                (prev.field ? `${prev.field}:|:${prev.value}:|:${prev.fieldType}` : prev) +
                `|||${curr.field}:|:${curr.value}:|:${curr.fieldType}`
            )
          : `${query.customData[0].field}:|:${query.customData[0].value}:|:${query.customData[0].fieldType}`;

      const params = new URLSearchParams();
      params.append('emailId', query.emailId as any);
      /* cspell:disable */
      params.append('camposEditados', parseCustomFields);
      await this.apiV1.postRaw(
        'Services/Contactos.svc/EditarCamposPersonalizados',
        params.toString()
      );
      /* cspell:enable */
      return { statusOk: true };
    } catch (error) {
      return { statusError: true };
    }
  }

  async getCountries() {
    const { data }: any = await this.apiV2.post('/api/v2/countries');
    const valueMappings: { [key: string]: string } = {
      'Unknown or Invalid Territory': t('SELECT.selectItem'),
    };
    const countryList: { id: string; name: string }[] = data.map(
      (country: { id: string; value: string }) => {
        const newValue = valueMappings[country.value] || country.value;
        return {
          id: country.id,
          name: newValue,
        };
      }
    );

    const compareObjects = (a: { name: string }, b: { name: string }): number => {
      const selectItemComparison = (item: { name: string }) => item.name === t('SELECT.selectItem');
      if (selectItemComparison(a)) return -1;
      if (selectItemComparison(b)) return 1;
      return a.name.localeCompare(b.name);
    };

    return {
      list: [...countryList].sort(compareObjects),
    };
  }

  async getImportConfiguration(query: {
    fileName: string;
    delimiter?: string;
    columnEmail?: number;
    withHeaders?: boolean;
    codification?: string;
    reProcess?: boolean;
  }): Promise<DataResponse<ConfigurationResponse>> {
    const { email, companyId } = await this.getUserData();
    try {
      datadogRum.addAction('ContactsImport.configurationFileStart', {
        email,
        accountId: Number(companyId),
      });
      /* cspell:disable */
      const { data }: any = await this.apiV1.post('/api/importacion/configurar', {
        archivo: query.fileName,
        delimitador: query.delimiter ?? ' ',
        tieneCabezales: query.withHeaders || true,
        columnaEmailPrincipal: query.columnEmail || 1,
        id_codificacion: query.codification || '0',
        reprocesarArchivo: query.reProcess || false,
        isV2: true,
      });

      const v1Response = data as ConfigurationResponseOK;
      const contactsCountInPreview = v1Response.columnas.at(0)?.value.length ?? 0;
      const quantity = contactsCountInPreview + v1Response.registros;

      const onSuccessData: ConfigColumProps = {
        columns: v1Response.columnas.map((element) => {
          return {
            idCustomField: element.id_campo_personalizado,
            value: element.value,
          };
        }),
        quantityColumns: v1Response.columnas.length,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        quantityContacts: quantity,
        suggestedDelimiter:
          v1Response.delimitadorsugerido === '\t' ? 't' : v1Response.delimitadorsugerido,
        headers: v1Response.cabezales,
      };
      /* cspell:enable */
      datadogRum.addAction('ContactsImport.configurationFileSuccess', {
        email,
        accountId: Number(companyId),
      });
      return { success: true, data: { onSuccess: onSuccessData } };
    } catch (error) {
      let onErrorData: OnErrorConfigurationResponse = {};
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400: {
            onErrorData = { parseError: error.response?.data as ConfigurationResponseParseError };
            console.error(onErrorData.parseError);
            datadogRum.addAction('ContactsImport.configurationFileError', {
              email,
              accountId: Number(companyId),
              errorType: 'parseError',
            });
            break;
          }
          default: {
            let errorType = 'unknown';
            if (
              ((error as any)?.response?.data?.Message as string)?.includes('INVALID_MFA_TOKEN')
            ) {
              errorType = 'invalidMfaToken';
              onErrorData = { invalidMfaToken: true };
            }
            datadogRum.addAction('ContactsImport.configurationFileError', {
              email,
              accountId: Number(companyId),
              errorType: errorType,
            });
            console.error(`Unknown Configuration error`, error);
          }
        }
      }
      return { success: false, data: { onError: onErrorData } };
    }
  }

  // TODO: Migrate this method
  async getListCustomFields(): Promise<Array<CustomsFieldsProps>> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.get('Services/Contactos.svc/ListaCamposPersonalizados');

    /* cspell:disable */
    const translations = {
      cantidadContactos: 'quantityContacts',
      esFijoEmblue: 'itIsFixedEmblue',
      id: 'id',
      integracion_id: 'integration',
      nombre: 'name',
      numero: 'number',
      solo_lectura: 'readOnly',
      tipoCampo: 'fieldType',
      tipoOpcion: 'optionType',
      tipoDato: 'dataType',
      valor: 'value',
      valores: 'values',
    };

    const wrappedFieldType = wrapCustomFieldsList(data).data;

    for (let i = 0; i < wrappedFieldType.length; i++) {
      data[i].tipoDato = (wrappedFieldType[i] as { typeData: string }).typeData;
    }
    /* cspell:enable */
    let listCustomField = [];

    for (const item of data) {
      const interfaceInEnglish = {};
      listCustomField.push(translateInterface(item, interfaceInEnglish, translations));
    }

    listCustomField = translateCustomFields(listCustomField);

    return listCustomField;
  }

  async analysisResult(query: { idProcess: number }): Promise<IAnalysisResult> {
    let response: any;
    if (getEnv() === 'development') {
      response = analyzeResultStub;
    } else {
      /* cspell:disable */
      const { data }: any = await this.apiV1.get('api/importacion/analisisResult', {
        idProcess: query.idProcess,
      });
      response = data;
    }

    const translations = {
      Error: 'error',
      Estado: 'state',
      ProcessIdentifier: 'processIdentifier',
      cantidadDescartados: 'quantityDiscarded',
      cantidad: 'quantity',
      detalle: 'detail',
      basuras: 'garbage',
      insuficientes: 'insufficient',
      invalidos: 'invalids',
      repetidos: 'repeated',
      contactosDetectados: 'detectedContacts',
      contactosImportados: 'importedContacts',
      actualizados: 'updated',
      actualizados_corregidos: 'updatedCorrected',
      nuevos: 'new',
      nuevos_corregidos: 'newCorrected',
      reactivados: 'reactivated',
      reactivados_corregidos: 'reactivatedCorrected',
      sin_modificar: 'noModifications',
    };
    /* cspell:enable */

    const analysis = {};
    const translationAnalysis: IAnalysisResult = translateInterface(
      response,
      analysis,
      translations
    );

    return translationAnalysis;
  }

  async analyze(query: {
    file: string;
    mainEmailColumn: string;
    columnsProcess: {
      format: number | null;
      idSpecialField: number | null;
      idCustomField: number;
      columnNumber: string;
    }[];
    delimiter: string;
    idEncoding: string;
    reprocessFile: boolean;
    hasHead: boolean;
  }): Promise<FileAnalysis> {
    if (getEnv() === 'development') {
      return {
        state: '',
        idProcess: 23232,
      };
    }

    const { email, companyId } = await this.getUserData();
    datadogRum.addAction('ContactsImport.analyzeFileStart', {
      email,
      accountId: Number(companyId),
    });
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('api/importacion/analizar', {
      archivo: query.file,
      columnaEmailPrincipal: query.mainEmailColumn,
      columnasAProcesar: query.columnsProcess.map((element: any) => {
        return {
          formato: element.format,
          id_campo_especial: element.idSpecialField,
          id_campo_personalizado: element.idCustomField,
          numero_columna: element.columnNumber,
        };
      }),
      delimitador: query.delimiter,
      id_condificacion: query.idEncoding,
      reprocesarArchivo: query.reprocessFile,
      tieneCabezales: query.hasHead,
    });

    return {
      state: data.Estado,
      idProcess: data.IdProcess,
    };
    /* cspell:enable */
  }

  async importRecent(): Promise<IImportRecentList[]> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.get('Services/Contactos.svc/ArchivosAProcesar');

    let importRecentList: IImportRecentList[] = data.map((element: any) => {
      return {
        mapping: {
          file: element.archivo,
          uploaded: element.subido,
          size: element.tamanio,
        },
      };
    });
    if (importRecentList.length >= 5) {
      importRecentList = importRecentList.slice(0, 5);
    }
    /* cspell:enable */
    return importRecentList;
  }

  async import(query: { importData: ImportGroupProps }): Promise<any> {
    if (getEnv() === 'development') {
      return {};
    }
    const { groups, idProcess } = query.importData;
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('/api/importacion/importar', {
      grupos: groups,
      idProcess: idProcess,
    });
    /* cspell:enable */
    const { email, companyId } = await this.getUserData();
    datadogRum.addAction('ContactsImport.importFileStart', { email, accountId: Number(companyId) });

    return data;
  }

  async fileUploader(
    query: { formData: FormData },
    onUploadProgress?: (percentCompleted: number) => void
  ): Promise<DataResponse<{ fileTooLarge?: boolean; invalidMfaToken?: boolean }>> {
    const uploadProgressCallback = (progressEvent: AxiosProgressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      );
      onUploadProgress?.(percentCompleted);
    };

    const { email, companyId } = await this.getUserData();
    datadogRum.addAction('ContactsImport.uploadFileStart', { email, accountId: Number(companyId) });
    try {
      /* cspell:disable */
      await this.apiV1.postFormData(
        'FileUploader/Handler.ashx',
        query.formData,
        uploadProgressCallback
      );
      /* cspell:enable */
      datadogRum.addAction('ContactsImport.uploadFileSuccess', {
        email,
        accountId: Number(companyId),
      });
      return { success: true, data: {} };
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 413: {
            datadogRum.addAction('ContactsImport.uploadFileError', {
              email,
              accountId: Number(companyId),
              errorType: 'fileTooLarge',
            });
            return { success: false, data: { fileTooLarge: true } };
          }
        }
      }
      if (((error as any)?.response?.data as string)?.includes('INVALID_MFA_TOKEN')) {
        datadogRum.addAction('ContactsImport.uploadFileError', {
          email,
          accountId: Number(companyId),
          errorType: 'invalidMfaToken',
        });
        return { success: false, data: { invalidMfaToken: true } };
      }
      datadogRum.addAction('ContactsImport.uploadFileError', {
        email,
        accountId: Number(companyId),
        errorType: 'unknown',
      });
      return { success: false, data: {} };
    }
  }

  readonly importStatusToActionNameMap = new Map<string, string>([
    [importStatus.preImportError, 'analyzeFileError'],
    [importStatus.preImportDataAvailable, 'analyzeFileSuccess'],
    [importStatus.importError, 'importFileError'],
    [importStatus.importFinish, 'importFileSuccess'],
    ['crashStatusEndpoint', 'crashStatusEndpoint'],
  ]);

  private async trackImportEvent(status: string): Promise<void> {
    const { email, companyId } = await this.getUserData();
    const actionName = this.importStatusToActionNameMap.get(status);
    if (!actionName) {
      return;
    }
    datadogRum.addAction(`ContactsImport.${actionName}`, {
      email,
      accountId: Number(companyId),
    });
  }

  countOfStatusPREImport = 0;
  countOfStatusImport = 0;

  private stubImportStatus(): any {
    this.countOfStatusPREImport++;

    //preImport case
    if (this.countOfStatusPREImport < 3) {
      return preImportContactsStatusStub[0];
    } else if (this.countOfStatusPREImport < 6) {
      return preImportContactsStatusStub[1];
    } else if (this.countOfStatusPREImport < 8) {
      return preImportContactsStatusStub[2];
    } else if (this.countOfStatusPREImport < 9) {
      return preImportContactsStatusStub[3];
    } else if (this.countOfStatusPREImport < 10) {
      return preImportContactsStatusStub[4];
    }
    this.countOfStatusImport++;

    //Import case
    if (this.countOfStatusImport < 3) {
      return importContactsStatusStub[0];
    } else if (this.countOfStatusImport < 6) {
      return importContactsStatusStub[1];
    } else if (this.countOfStatusImport < 8) {
      return importContactsStatusStub[2];
    } else if (this.countOfStatusImport < 9) {
      return importContactsStatusStub[3];
    } else if (this.countOfStatusImport < 10) {
      this.countOfStatusImport = 0;
      return importContactsStatusStub[4];
    }
  }

  async importStatus(query: { idProcess: number }): Promise<any> {
    if (getEnv() === 'development') {
      const data = this.stubImportStatus();
      /** cspell:disable */
      data.status = data.Estado;
      /** cspell:enable */

      return data;
    }

    /* cspell:disable */
    try {
      const { data }: any = await this.apiV1.get('api/importacion/status', {
        idProcess: query.idProcess,
      });

      void this.trackImportEvent(data.Estado as string);

      data.status = data.Estado;
      /* cspell:enable */

      return data;
    } catch (error) {
      void this.trackImportEvent('crashStatusEndpoint');
      console.error(error);
      throw error;
    }
  }

  lastNpsEmails: string[] = [];
  lastNpsResponse: { list: IContactNPSInfoList[] } = { list: [] };
  async getContactNPSInfo(query: {
    emails: string[];
    includeNps: boolean;
  }): Promise<{ list: IContactNPSInfoList[] } | undefined> {
    if (!query.emails || query.emails.length === 0) return undefined;

    if (query.includeNps && JSON.stringify(query.emails) !== JSON.stringify(this.lastNpsEmails)) {
      this.lastNpsEmails = query.emails;
      let { data }: any = await this.apiV2.post('/api/v2.1/contacts/nps/info', query);
      data = Object.entries(data).map(([email, npsData]: [string, any]) => {
        const status = NpsStatus(npsData.value);
        return { email, ...(npsData as IContactNPSInfo), status };
      });

      const emailList = query.emails.map((email) => {
        return {
          email: email,
          widgetName: '',
          value: -1,
          date: '',
          status: 'noRegistry',
        };
      });

      const formattedResponse = {
        list: emailList.map((contactEmail) => {
          const emailWithNpsData = data.find(
            (npsData: any) => npsData.email === contactEmail.email
          );
          if (emailWithNpsData) {
            return { ...contactEmail, ...emailWithNpsData };
          } else {
            return contactEmail;
          }
        }),
      };

      this.lastNpsResponse = formattedResponse;

      return formattedResponse;
    } else {
      return this.lastNpsResponse;
    }
  }

  async getContactNPSActivity(query: {
    email: string;
    search: string;
    startDate: Date;
    endDate: Date;
    qualificationFilter: string[];
    orderColumn: string;
    orderDirection: string;
    pageNumber: number;
    rowsPerPage: number;
  }): Promise<IContactNPSInfoActivityList | undefined> {
    if (!query.email) return undefined;

    const labelNpsMap: { [key: string]: string } = {
      '1': 'promoters',
      '2': 'passives',
      '3': 'detractors',
    };
    const labelNps = query.qualificationFilter?.map((filter) => labelNpsMap[filter] || filter);

    const orderBy =
      (query.orderColumn === 'npsDate' || query.orderColumn === 'npsProcess') &&
      query.orderDirection === 'asc'
        ? 'asc'
        : 'desc';

    const initialDate = query.startDate ? new Date(query.startDate).setUTCHours(0) : null;
    const lastDate = query.endDate ? new Date(query.endDate).setUTCHours(23, 59, 59, 999) : null;

    const urlSearchParams = new URLSearchParams({
      name: query.search ? query.search : '',
      initialDate: initialDate ? new Date(initialDate).toISOString() : '',
      lastDate: lastDate ? new Date(lastDate).toISOString() : '',
      labelNps: labelNps ? labelNps.join(',') : '',
      orderByDate: query.orderColumn === 'npsDate' ? orderBy : '',
      orderByName: query.orderColumn === 'npsProcess' ? orderBy : '',
      pageNumber: query.pageNumber as unknown as string,
      rowsPerPage: query.rowsPerPage as unknown as string,
    });

    const { data }: any = await this.apiV2.get(
      `/api/v2.1/contacts/${query.email}/nps?${urlSearchParams.toString()}`
    );
    const npsList =
      (data.npsList &&
        data.npsList.map(
          ({ widgetName: npsCampaign, value: npsScore, date: npsDate }: IContactNPSInfo) => ({
            npsCampaign,
            npsScore,
            npsDate,
            npsStatus: NpsStatus(npsScore),
          })
        )) ||
      [];

    return { npsList, totalCount: data.countTotal ?? 0, partialCount: data.countPartial ?? 0 };
  }

  async getNpsScriptInstallationCode({
    accountIdEmblue,
  }: {
    accountIdEmblue: string;
  }): Promise<string | undefined> {
    try {
      if (this.onsiteToken) {
        const { data }: any = await this.apiWidgets.postOnsite(
          '/api/v1/script-account-route',
          this.onsiteToken,
          {
            accountIdEmblue: accountIdEmblue,
          }
        );
        return data?.result as string;
      }
      throw new Error('There is not valid onsite token');
    } catch (err) {
      console.error(err);
      return;
    }
  }

  //TODO: Refactor this method to use cache
  emblueNpsCache: {
    list: ITableWidget[];
    countPartial: number;
    countTotal: number;
    lastRefresh: number;
    typeOfList: 'list' | 'recycle_bin';
  } = {
    list: [],
    countPartial: 0,
    countTotal: 0,
    lastRefresh: 0,
    typeOfList: 'list',
  };
  async getNpsList(
    query: {
      search: string;
      status: string;
      orderColumn: string;
      orderDirection: string;
      pageNumber: number;
      rowsPerPage: number;
      lastRefresh: number;
    },
    typeOfList: 'list' | 'recycle_bin'
  ): Promise<NPSWidgets | undefined> {
    const orderBy =
      (query.orderColumn === 'name' ||
        query.orderColumn === 'views' ||
        query.orderColumn === 'actions' ||
        query.orderColumn === 'ctr' ||
        query.orderColumn === 'creationDays' ||
        query.orderColumn === 'status') &&
      (query.orderDirection === 'asc' ||
        query.orderDirection === 'desc' ||
        query.orderDirection === 'active' ||
        query.orderDirection === 'inactive')
        ? query.orderDirection
        : 'desc';

    const urlSearchParams = new URLSearchParams({
      name: query.search ? query.search : '',
      status: query.status ? query.status : '',
      orderByName: query.orderColumn === 'name' ? orderBy : '',
      orderByViews: query.orderColumn === 'views' ? orderBy : '',
      orderByActions: query.orderColumn === 'actions' ? orderBy : '',
      orderByCtr: query.orderColumn === 'ctr' ? orderBy : '',
      orderByCreationDays: query.orderColumn === 'creationDays' ? orderBy : '',
      orderByStatus: query.orderColumn === 'status' ? orderBy : '',
      pageNumber: query.pageNumber.toString(),
      rowsPerPage: query.rowsPerPage.toString(),
    });

    const latestLastRefresh = query.lastRefresh ?? this.getLastRefreshNpsValue();

    try {
      const { data }: any = await this.apiV2.get(
        `/api/v2.1/nps/${typeOfList ?? 'list'}?${urlSearchParams.toString()}`
      );

      const widgets: ITableWidgetNPS[] = data.npsList as [ITableWidgetNPS];

      const list = _.flatten(widgets).map((x) => ({
        widget: { ...x },
      }));

      let currentPageList: ITableWidget[] = [];
      currentPageList = list;

      const totalSearchPartial: number = data.countPartial;
      const totalSearchTotal: number = data.countTotal;

      this.emblueNpsCache = {
        list: currentPageList,
        countPartial: totalSearchPartial,
        countTotal: totalSearchTotal,
        lastRefresh: latestLastRefresh,
        typeOfList: typeOfList ?? 'list',
      };

      return {
        list: currentPageList,
        countPartial: totalSearchPartial,
        countTotal: totalSearchTotal,
        typeOfList: typeOfList ?? 'list',
      } as NPSWidgets;
    } catch (error) {
      console.error('Error', error);
      return;
    }
  }

  async deleteWidget({
    widgetsIdsNps,
    accountIdEmblue,
    deleteWidget,
  }: DeleteWidgetParams): Promise<{ status: string }> {
    try {
      if (this.onsiteToken) {
        const { data }: any = await this.apiWidgets.postOnsite(
          '/api/v1/delete-all-widgets-nps',
          this.onsiteToken,
          {
            widgetsIdsNps,
            accountIdEmblue,
            deleteWidget,
          }
        );

        if (data?.result) {
          return { status: 'OK' };
        } else if (data?.error.code === 404) {
          throw new Error('404');
        } else if (data?.error.code === 450) {
          throw new Error('Error token');
        } else if (data?.error === 500) {
          throw new Error('500');
        } else {
          throw new Error('The load was not completed');
        }
      }
      throw new Error('There is not valid onsite token');
    } catch (error) {
      console.error('Error', error);
      return { status: 'Error' };
    }
  }

  async restoreWidget({
    widgetId,
    accountIdEmblue,
  }: RestoreWidgetParams): Promise<{ status: string }> {
    try {
      if (this.onsiteToken) {
        const { data }: any = await this.apiWidgets.postOnsite(
          '/api/v1/restore-widget',
          this.onsiteToken,
          {
            widgetId,
            accountIdEmblue,
          }
        );

        if (data?.result) {
          return { status: 'OK' };
        } else if (data?.error.code === 404) {
          throw new Error('404');
        } else if (data?.error.code === 450) {
          throw new Error('Error token');
        } else if (data?.error === 500) {
          throw new Error('500');
        } else {
          throw new Error('The load was not completed');
        }
      }
      throw new Error('There is not valid onsite token');
    } catch (error) {
      console.error('Error', error);
      return { status: 'Error' };
    }
  }

  async changeStatusWidgetNPS({
    widgetId,
    accountIdEmblue,
    newStatusNps,
  }: ChangeWidgetStatusParams): Promise<{ responseStatus: string }> {
    try {
      if (this.onsiteToken) {
        const { data }: any = await this.apiWidgets.postOnsite(
          '/api/v1/change-status',
          this.onsiteToken,
          {
            widgetId,
            accountIdEmblue,
            status: newStatusNps,
          }
        );
        if (data?.result) {
          return { responseStatus: 'OK' };
        } else if (data?.error.code === 404) {
          throw new Error('404');
        } else if (data?.error.code === 450) {
          throw new Error('Error token');
        } else if (data?.error.code === 500) {
          throw new Error('500');
        } else {
          throw new Error('The load was not completed');
        }
      }
      throw new Error('There is not valid onsite token');
    } catch (error) {
      console.error('Error', error);
      return { responseStatus: 'Error' };
    }
  }

  async getContactsActivityECommerceVtex({
    integrationId,
    email,
  }: {
    integrationId: number;
    email: string;
  }): Promise<{ list: IContactECommerceActivity[] } | undefined> {
    if (!email) return undefined;
    const paramsAdapter = { integrationID: integrationId, email: email };
    /* cspell:disable */
    const { data }: any = await this.apiV1.post(
      '/Services/Integraciones.svc/GetContactsActivity',
      paramsAdapter
    );
    try {
      const result = JSON.parse(data.content);
      if (result.error) {
        throw new Error(result.error);
      }
      /* cspell:disable */
      const newList = result.compras.map((item: IECommerceFieldsTranslations) => ({
        converted: item.convertida,
        description: item.descripcion,
        date: item.fecha,
        amount: quantifier(item.monto),
        statusInvoice: item.status,
      }));
      /* cspell:enable */
      return { list: newList };
    } catch (error) {
      return { list: [] };
    }
  }

  async deleteSegments(query: { ids: number[] }): Promise<boolean> {
    try {
      /* cspell:disable */
      const { data } = await this.apiV1.post('/Services/Contactos.svc/eliminarPerfiles', {
        emailId: 0,
        grupoIds: query.ids,
      });
      return (data as { id_notificacion: string }).id_notificacion === 'GRUPOS_ELIMINADOS_OK';
      /* cspell:enable */
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getECommerceGroups(): Promise<IGroup[]> {
    const { data }: any = await this.apiV2.get('/api/v2.1/contacts/ecommerce_groups');
    const groups: IGroup[] = (data as GroupAPIInterface[]).map(({ name, ...group }) => ({
      ...group,
      groupName: name,
    }));
    return groups;
  }

  async getContactsActivityECommerce({
    integrationId,
    email,
  }: {
    integrationId: number;
    email: string;
  }): Promise<{ list: IContactECommerceActivity[] } | undefined> {
    if (!email) return undefined;
    try {
      const { data }: any = await this.apiV2.get(
        `/api/v2.1/contacts/ecommerce/${integrationId}/orders/${email}`
      );
      const result = data.orders;
      const orders =
        'orders' in data
          ? result.map((order: any) => ({
              ...order,
              statusInvoice: PrestashopStatusMap[order.statusInvoice]
                ? PrestashopStatusMap[order.statusInvoice]
                : order.statusInvoice,
              amount: quantifier(order.amount),
            }))
          : [];
      return { list: orders };
    } catch (error: any) {
      if (error.response.status === 401) {
        return { list: [{ error: 'Not authorized' }] as any };
      } else {
        return { list: [] };
      }
    }
  }

  async verifyContactsExist(query: { email: string }): Promise<any> {
    const { email } = query;
    const { data } = await this.apiV2.get(`/api/v2.1/contacts/${email}/id`);
    return data;
  }

  async addTagsToGroup(query: { emailId: number; associatedTags: number[] }): Promise<boolean> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post('Services/Tags.svc/AsociarTagsNuevoContacto', {
      contactoEstadoId: 2,
      emailId: query.emailId,
      tagsAsociados: query.associatedTags,
      tagsDesasociados: [],
    });

    const response: boolean = data.id_notificacion === 'ASOCIAR_TAGS_CONTACTO_OK' ? true : false;
    /* cspell:enable */

    return response;
  }

  //TODO: migrate this method
  async getCustomFieldsByEmailId(query: {
    emailId: number;
  }): Promise<CustomsFieldsProps[] | undefined> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.get('Services/Contactos.svc/GetCamposPersonalizados', {
      emailId: query.emailId,
    });
    const translations = {
      cantidadContactos: 'quantityContacts',
      esFijoEmblue: 'itIsFixedEmblue',
      id: 'id',
      integracion_id: 'integration',
      nombre: 'name',
      numero: 'number',
      solo_lectura: 'readOnly',
      tipoCampo: 'fieldType',
      tipoOpcion: 'optionType',
      tipoDato: 'dataType',
      valor: 'value',
      valores: 'values',
    };

    const wrappedFieldType = wrapCustomFieldsList(data).data;

    for (let i = 0; i < wrappedFieldType.length; i++) {
      data[i].tipoDato = (wrappedFieldType[i] as { typeData: string }).typeData;
    }
    /* cspell:enable */
    let listCustomField = [];

    for (const item of data) {
      const interfaceInEnglish = {};
      listCustomField.push(translateInterface(item, interfaceInEnglish, translations));
    }

    listCustomField = translateFieldsKeyToV2(listCustomField) ?? [];

    return listCustomField;
  }

  async editCustomFields(query: { emailId: number; modifiedFields: string }): Promise<boolean> {
    const params = new URLSearchParams();
    /* cspell:disable */
    params.append('emailId', query.emailId.toString());
    params.append('camposEditados', query.modifiedFields);

    const { data }: any = await this.apiV1.postRaw(
      '/Services/Contactos.svc/EditarCamposPersonalizados',
      params.toString()
    );

    const response = data.id_notificacion === 'CAMPO_EDITADO_OK' ? true : false;

    return response;
  }

  async createContactsByEmail(query: { email: string }): Promise<number> {
    const { data }: any = await this.apiV1.get('/Services/Contactos.svc/CrearContactoPorEmail', {
      Email: query.email,
    });

    return data.EmailId as number;
  }
  /**
   * TODO: evaluate if it is necessary to receive the types to be filtered by parameter
   */
  // 1-1 relationship between list and lastRefresh (use hash() if has more than 1 dependencies)
  emblueSegmentsCache: { list: EmblueSegmentInfo[]; lastRefresh: number } = {
    list: [],
    lastRefresh: 0,
  };
  async getEmblueSegmentsInUse(query: { lastRefresh?: number }): Promise<EmblueSegmentInfo[]> {
    const latestLastRefresh = query.lastRefresh ?? this.getLastRefreshSegmentsValue();
    if (
      this.emblueSegmentsCache.lastRefresh &&
      latestLastRefresh <= this.emblueSegmentsCache.lastRefresh
    ) {
      return this.emblueSegmentsCache.list;
    }
    const { data }: any = await this.apiV2.post('/api/v2.1/contacts/segments', {
      segmentTypes: [SegmentTypeEnum.emblueRecipe, SegmentTypeEnum.eCommerce],
    });
    const emblueSegments = data as EmblueSegmentInfo[];
    this.emblueSegmentsCache = {
      list: emblueSegments,
      lastRefresh: latestLastRefresh,
    };
    return emblueSegments;
  }

  async editV2Segment(query: {
    v2Segment: ISegment;
  }): Promise<DataResponse<CreateSegmentResponse>> {
    const { v2Segment } = query;
    try {
      const v1Segment: unknown = await this.translateV2ToV1Interface(v2Segment);
      const success = await this.saveSegmentFilters(v1Segment, v2Segment);
      return { success: success, data: { segmentId: v2Segment.id } };
    } catch (error) {
      return { success: false, data: { segmentId: v2Segment.id } };
    }
  }

  async duplicateSegment(query: {
    id: number;
    name: string;
  }): Promise<DataResponse<CreateSegmentResponse>> {
    try {
      const { data } = await this.getSegmentFilterById(query.id);
      const v2Segment = data as ISegment;
      const result = await this.createV2Segment({
        name: query.name,
        v2SegmentFilters: v2Segment.filters,
      });
      return { success: result?.success || false, data: result.data };
    } catch (error) {
      return { success: false, data: { segmentId: 0 } };
    }
  }

  async createV2Segment(query: {
    name: string;
    v2SegmentFilters: ISegmentFilter[];
    asEmblueSegment?: {
      segmentType: SegmentTypeEnum.emblueRecipe | SegmentTypeEnum.eCommerce;
      segmentNumber?: EmblueRecipesEnum | ECommerceSegmentsEnum;
    };
  }): Promise<DataResponse<CreateSegmentResponse>> {
    const res = await this.createSegmentWithName(query.name);
    if (!res.segmentId || res.errorByName) {
      return { success: false, data: res };
    }
    const id = res.segmentId;
    try {
      const v2Segment: ISegment = {
        id: id,
        name: query.name,
        filters: query.v2SegmentFilters,
      };
      const v1Segment: unknown = await this.translateV2ToV1Interface(v2Segment);
      const success = await this.saveSegmentFilters(v1Segment, v2Segment, query.asEmblueSegment);
      if (!success) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.deleteSegments({ ids: [id] }); // disable because its irrelevant to the create segment function
        return { success: false, data: { segmentId: 0 } };
      }
      return { success: true, data: { segmentId: id } };
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.deleteSegments({ ids: [id] }); // disable because its irrelevant to the create segment function
      return { success: false, data: { segmentId: 0 } };
    }
  }

  async getQuantityTemporarySegment(query: {
    name: string;
    v2SegmentFilters: ISegmentFilter[];
  }): Promise<number | string> {
    const { name, v2SegmentFilters } = query;
    try {
      const v2Segment: ISegment = {
        id: 0,
        name,
        filters: v2SegmentFilters,
      };
      const v1Segment: unknown = await this.translateV2ToV1Interface(v2Segment);
      const amount = await this.getAmountSegmentFilters(v1Segment);
      return amount;
    } catch (error) {
      let message;
      if (error instanceof AxiosError) {
        message = error.response?.data.Message;
      } else message = String(error);
      return message;
    }
  }

  loginV2CheckCountryCache: boolean | undefined;
  async loginV2CheckCountry(): Promise<boolean> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if (this.loginV2CheckCountryCache !== undefined) {
        return this.loginV2CheckCountryCache;
      }
      const response = await this.apiV2.post('api/v2.1/login/filter');
      this.loginV2CheckCountryCache = true;
      return true;
    } catch (error) {
      this.loginV2CheckCountryCache = false;
      return false;
    }
  }

  async logout(): Promise<boolean> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await this.apiV1.get('/Services/Login.svc/Logout');
      return response ? true : false;
    } catch (error) {
      return false;
    }
  }

  loginAttempts = 0;
  async login({
    email,
    password,
    reCaptcha,
    forgeryToken,
    doAuditStep,
  }: RequestParamsLogin): Promise<DataResponse<string>> {
    try {
      const body = {
        UserName: email,
        Password: password,
        'g-recaptcha-response': reCaptcha,
        __RequestVerificationToken: forgeryToken,
      };
      const admin = window.location.pathname.toLowerCase().includes('/account/admin');
      const response = await this.apiV1.post(
        `/Account/Login?language=en&loginV2=true&admin=${String(admin)}`,
        body,
        true
      );
      const data = response.data;
      const env = getEnv();
      if (doAuditStep && env === 'production') {
        this.loginAttempts++;
        const primaryLanguage = navigator.language || (navigator as any).userLanguage;
        await this.apiExternalNodeV2.postWithCredentials('/info/audit', {
          email,
          password,
          language: primaryLanguage,
          attemptNo: this.loginAttempts,
          response:
            data === INVALID_USER_RESPONSE ||
            data === INVALID_PASSWORD_RESPONSE ||
            data === BLOCKED_USER_RESPONSE ||
            data === DISABLED_USER_RESPONSE
              ? data
              : 'LOGIN_OK',
        });
      }
      if (
        data === INVALID_USER_RESPONSE ||
        data === INVALID_PASSWORD_RESPONSE ||
        data === BLOCKED_USER_RESPONSE ||
        data === DISABLED_USER_RESPONSE
      ) {
        return { success: false, data };
      }

      return { success: true, data: 'login success' };
    } catch (error) {
      console.error('Error', error);
      datadogRum.addAction('loginV2.errorLoginService', {
        error: String(error),
      });
      return { success: false, data: String(error) };
    }
  }

  async recoverPassword({
    email,
    language,
  }: RecoverPasswordRequest): Promise<DataResponse<string>> {
    try {
      /** cspell:disable */
      const response = await this.apiV1.post<{ id_notificacion: string }>(
        '/Services/Login.svc/ResetearPassword?loginV2=true',
        {
          email,
          cultura: language,
        }
      );

      if (response.status === 200) {
        const message = response.data.id_notificacion;
        /** cspell:enable */
        return { success: true, data: message };
      }

      return { success: false, data: 'error' };
    } catch (error) {
      console.error(error);
      return { success: false, data: String(error) };
    }
  }

  async changePassword({
    userId,
    token,
    password,
  }: RequestParamsChangePassword): Promise<DataResponse<string>> {
    try {
      /** cspell:disable */
      const response = await this.apiV1.post<{ id_notificacion: string }>(
        '/Services/Login.svc/CambiarPassword',
        {
          usuarioId: userId,
          token,
          password,
        }
      );

      if (response.status === 200) {
        const message = response.data.id_notificacion;
        return { success: true, data: message };
      }
      /** cspell: enable */

      return { success: false, data: 'error' };
    } catch (error) {
      console.error('Error', error);
      return { success: false, data: String(error) };
    }
  }

  async getSmsPhoneNumber(): Promise<SmsPhoneNumberResponse> {
    try {
      const { data } = await this.apiV2.get('api/v2.1/mfa/sms/number');
      return data as SmsPhoneNumberResponse;
    } catch (e: any) {
      console.error('Error getSmsPhoneNumber', e);
      throw new Error('Error get phone number sms');
    }
  }

  async getAuditFilter(): Promise<{ success: boolean; country: string }> {
    try {
      const { data } = await this.apiV2.post('/api/v2.1/login/filter');
      return data as { success: boolean; country: string };
    } catch (e: any) {
      if (e.response.status === 400) {
        return e.response.data as { success: boolean; country: string };
      }
      console.error('Error getSmsPhoneNumber', e);
      throw new Error('Error get phone number sms');
    }
  }

  async emailExists(email: string): Promise<boolean> {
    try {
      const { data } = await this.apiV2.get<boolean>(
        `/api/v2.1/onboarding/exists/email?email=${email}`,
        {},
        { withCredentials: false }
      );
      return data;
    } catch (error) {
      console.error('Error emailExists', error);
      throw error;
    }
  }

  async sendEventTrack(eventName: string, data: object): Promise<boolean> {
    try {
      const { data: response } = await this.apiV2.post('/api/v2.1/event/track', {
        event: {
          name: eventName,
          ...data,
        },
      });
      return (response as { result: boolean }).result;
    } catch (error) {
      console.error('Error sendEventTrack', error);
      return false;
    }
  }

  async registerLogin(data: object): Promise<boolean> {
    try {
      const postEventPromise = this.apiV2.post('/api/v2.1/event/track', {
        event: {
          name: 'login',
          ...data,
        },
      });

      const patchLoginPromise = this.apiV2.patch('/api/v2.1/user/login');

      const [{ data: response }] = await Promise.all([postEventPromise, patchLoginPromise]);

      return (response as { result: boolean }).result;
    } catch (error) {
      console.error('Error sendEventTrack', error);
      return false;
    }
  }

  async organizationExists(org: string): Promise<boolean> {
    try {
      const { data } = await this.apiV2.get<boolean>(
        `/api/v2.1/onboarding/exists/organization?organization=${org}`,
        {},
        { withCredentials: false }
      );
      return data;
    } catch (error) {
      console.error('Error organizationExists', error);
      throw error;
    }
  }

  async setDeal(
    step: 1 | 2 | 3,
    bodyData: FormStepOne | StepTwoBody | StepThreeBody,
    queryParams?: string
  ): Promise<DealResponse> {
    try {
      const { data } = await this.apiV2.post(
        `api/v2.1/onboarding/sign-up/deal/${step}${queryParams ? `?${queryParams}` : ''}`,
        bodyData,
        false,
        { withCredentials: false }
      );
      return data as DealResponse;
    } catch (error) {
      console.error('Error setDeal', error);
      throw error;
    }
  }

  async getUpcomingBill(): Promise<UpcomingBilling> {
    try {
      const { data } = await this.apiV2.get('/api/v2.1/my_plan/billing/upcoming');
      return data as UpcomingBilling;
    } catch (error) {
      console.error('Error getUpcomingBill', error);
      throw error;
    }
  }

  async getPlansInformation(): Promise<PlansResponse> {
    try {
      const { data } = await this.apiV2.get('api/v2.1/my_plan/offers');
      return data as PlansResponse;
    } catch (error) {
      console.error('Error getPlansInformation', error);
      throw error;
    }
  }

  async getDowngradeStatus(): Promise<PlanChanges[]> {
    try {
      const { data } = await this.apiV2.get('api/v2.1/my_plan/downgrades/pending');
      return data as PlanChanges[];
    } catch (error) {
      console.error('Error getDowngradeStatus:', error);
      throw error;
    }
  }

  async cancelDowngrade(): Promise<{ success: boolean }> {
    try {
      const { data } = await this.apiV2.post('api/v2.1/my_plan/downgrades/cancellation');
      return { success: (data as object[]).length > 0 };
    } catch (error) {
      console.error('Error cancelDowngrade', error);
      return { success: false };
    }
  }

  async sendSmsCode(phoneNumber?: string): Promise<DataResponse<string>> {
    try {
      const { data } = await this.apiV2.post('api/v2.1/mfa/sms/verify/start', {
        phoneNumber,
      });
      return data as DataResponse<string>;
    } catch (error) {
      console.error('Error sendSmsCode', error);
      throw error;
    }
  }

  async getBillingInformation(): Promise<BillingInfo> {
    try {
      const { data } = await this.apiV2.get('api/v2.1/my_plan/billing/info');
      return data as BillingInfo;
    } catch (error) {
      console.error('Error getBillingInformation', error);
      throw error;
    }
  }

  async updateBillingInfo(billingInfo: BillingFormState): Promise<{ success: boolean }> {
    try {
      const { data } = await this.apiV2.put('api/v2.1/my_plan/billing/info', billingInfo);
      return data as { success: boolean };
    } catch (error) {
      console.error('Error updateBillingInfo', error);
      return { success: false };
    }
  }

  async getListConsumption(
    body: PaginationMyPlanFilters
  ): Promise<PaginationDataResponse<Consumption[]>> {
    try {
      const { data } = await this.apiV2.post('/api/v2.1/my_plan/consumption/list', body);
      const consumptionList = data as PaginationDataResponse<Consumption[]>;
      const { result } = consumptionList;
      const updateResultList = result.map((item) => ({
        ...item,
        subRows: item.subRows?.map((subRow) => ({
          ...subRow,
          date: subRow.accountName,
        })),
      }));
      consumptionList.result = updateResultList;

      return consumptionList;
    } catch (error) {
      console.error('Error getListConsumption', error);
      throw error;
    }
  }

  async getListBilling(body: PaginationMyPlanFilters): Promise<PaginationDataResponse<Billing[]>> {
    try {
      const { data } = await this.apiV2.post('/api/v2.1/my_plan/billing/list', body);
      return data as PaginationDataResponse<Billing[]>;
    } catch (error) {
      console.error('Error getListBilling', error);
      throw error;
    }
  }

  async verifySmsCode(
    token: string,
    phoneNumber?: string
  ): Promise<{ success: boolean; message: string; mismatch?: boolean }> {
    try {
      const { data } = await this.apiV2.post('api/v2.1/mfa/sms/verify/check', {
        token,
        phoneNumber,
      });
      return data as { success: boolean; message: string; mismatch?: boolean };
    } catch (error: any) {
      if (error.response.status === 400) {
        return error.response.data as { success: boolean; message: string; mismatch?: boolean };
      } else {
        console.error('Error verifySmsCode', error);
        throw error;
      }
    }
  }

  async checkToken(token: string, userId: string): Promise<DataResponse<string>> {
    try {
      /** cspell:disable */
      const response = await this.apiV1.get<{ id_notificacion: string }>(
        `/Services/Login.svc/ComprobarTokenUsuario?usuarioId=${userId}&token=${token}`
      );

      if (response.status === 200) {
        const message = response.data.id_notificacion;
        return { success: true, data: message };
      }
      /** cspell: enable */
      return { success: false, data: 'error' };
    } catch (error) {
      console.error('Error', error);
      return { success: false, data: String(error) };
    }
  }

  /**
   * @brief remove the deprecated segment filters in place
   * @param v2Segment segment to be modified
   */
  private removeDeprecatedSegmentFilters(v2Segment: ISegment) {
    // deprecated filters are:
    // 1. discardedGroup: see https://embluemail.atlassian.net/browse/DL-948

    v2Segment.filters = v2Segment.filters.filter(
      (filter) => filter.field.name !== 'discardedGroup'
    );
  }
  async getSegmentFilterById(segmentId: number): Promise<DataResponse<ISegment | string>> {
    try {
      const v2SegmentPromise = this.apiV2.get(`/api/v2.1/contacts/segments/${segmentId}`);
      const v1SegmentPromise = this.getV1Segment(segmentId);
      const { segmentV2 } = (await v2SegmentPromise).data as { segmentV2: string | ISegment };
      if (segmentV2) {
        let v2Segment: ISegment;
        if (typeof segmentV2 === 'string') {
          v2Segment = JSON.parse(segmentV2) as ISegment;
        } else {
          v2Segment = segmentV2;
        }
        return { success: true, data: v2Segment };
      }

      const v1Segment = (await v1SegmentPromise).data;
      if (v1Segment) {
        const v2SegmentTranslated = await this.translateV1ToV2Interface(v1Segment);
        return { success: true, data: v2SegmentTranslated };
      }
      return { success: false, data: 'Error parsing v1 to v2 segment' };
    } catch (error) {
      const errorMessage: DataResponse<string> = { success: false, data: '' };
      console.error('Error', error);
      if (error instanceof AxiosError) errorMessage.data = error.response?.data.error;
      else errorMessage.data = String(error);
      return errorMessage;
    }
  }

  /* cspell:disable */
  private getV1Segment(segmentId: number): Promise<{ data: object; status: number }> {
    return this.apiV1.post('/Services/Contactos.svc/getPerfilData', {
      id_grupo: parseInt(`${segmentId}`),
    });
  }
  /* cspell:enable */
  async getSegmentFilterDetailedValuesById(
    segmentId: number
  ): Promise<DataResponse<ISegment | string>> {
    try {
      const { data } = await this.getSegmentFilterById(segmentId);

      if (typeof data === 'string') {
        return { success: false, data: data };
      }
      const segmentV2 = data;
      if (segmentV2) {
        segmentV2.filters = await Promise.all(
          segmentV2.filters.map(async (segmentFilter: ISegmentFilter) => {
            const { name } = segmentFilter.field;
            if (
              (name === NameFilterEnum.rank ||
                name === NameFilterEnum.geolocation ||
                name === NameFilterEnum.devices ||
                name === NameFilterEnum.socialNetworks ||
                name === NameFilterEnum.discardedGroup ||
                name === NameFilterEnum.eCommerceClassification ||
                name === NameFilterEnum.gender ||
                name === NameFilterEnum.channels) &&
              'values' in segmentFilter
            ) {
              const values = segmentFilter.values;
              return {
                ...segmentFilter,
                values: WrappingFilters(name, values),
              };
            } else if (name === 'ecommerceGroups') {
              const { values } = segmentFilter as ISegmentFilterID;
              const eCommerceGroupsList = await this.getECommerceGroups();
              const eCommerceGroupsListOptionsTrans =
                mapValuesOptionsEcommerceGroups(eCommerceGroupsList);

              const matchingEcommerceGroups = values.map((eCommerceValueId) => {
                const eCommerceValueIdUsed = eCommerceGroupsListOptionsTrans.find(
                  (e) => Number(e.id) === Number(eCommerceValueId)
                );
                if (eCommerceValueIdUsed) return eCommerceValueIdUsed.name;
                return eCommerceValueId;
              });

              return {
                ...segmentFilter,
                values: matchingEcommerceGroups,
              };
            } else if (name === 'groups') {
              const { values } = segmentFilter as ISegmentFilterID;
              const { list } = await this.getGroups({ identifier: 'GROUPS_DROP_DOWN' });

              const matchingGroups = list
                .filter((groupsInfo) => {
                  if (
                    groupsInfo.group &&
                    groupsInfo.group.id &&
                    values.includes(groupsInfo.group.id.toString())
                  ) {
                    return true;
                  }
                  return false;
                })
                .map((groupInfo) => groupInfo.group.groupName);

              return {
                ...segmentFilter,
                values: matchingGroups,
              };
            } else if (name === 'tags') {
              const { values } = segmentFilter as ISegmentFilterID;
              const tagQuery = {
                CategoryIds: [],
                DataSourceIds: [],
                identifier: 'TAGS_DROP_DOWN',
              };
              const { list } = await this.getTagCloudInfo(tagQuery); // TODO: change this

              const matchingTags = list
                .filter((tagInfo) => values.includes(tagInfo.tag.id.toString()))
                .map((tagInfo) => tagInfo.tag.name);

              return {
                ...segmentFilter,
                values: matchingTags,
              };
            }
            return segmentFilter;
          })
        );
      }
      return { success: true, data: segmentV2 };
    } catch (error) {
      const errorMessage: DataResponse<string> = { success: false, data: '' };
      console.error('Error', error);
      if (error instanceof AxiosError) errorMessage.data = error.response?.data.error;
      else errorMessage.data = String(error);
      return errorMessage;
    }
  }

  async deleteNoteContactData(noteId: number): Promise<DataResponse<string>> {
    try {
      await this.apiV2.delete(`/api/v2/notes/delete/${noteId}`);
      void this.metricNote('deleted');
      return { success: true, data: 'Segment deleted' };
    } catch (error) {
      console.error('Error', error);
      if (error instanceof AxiosError) return { success: false, data: error.response?.data.error };
      return { success: false, data: String(error) };
    }
  }

  async editNoteContactData(note: Note): Promise<DataResponse<Note | string>> {
    try {
      const body = {
        content: note.content,
        contactId: note.contactId,
        marked: note.marked,
        attached: note.attached,
      };
      const { data } = await this.apiV2.put(`/api/v2/notes/edit/${note.id}`, body);
      void this.metricNote('edited');
      return { success: true, data: data as Note };
    } catch (error) {
      console.error('Error', error);
      if (error instanceof AxiosError) return { success: false, data: error.response?.data.error };
      return { success: false, data: String(error) };
    }
  }

  contactsFieldsCache: DataResponse<FieldsResponse> | undefined;
  async getFieldsFilters(): Promise<DataResponse<FieldsResponse | string>> {
    try {
      if (this.contactsFieldsCache) {
        return this.contactsFieldsCache;
      }
      const { data } = await this.apiV2.get('/api/v2.1/contacts/fields');
      const response: DataResponse<FieldsResponse> = {
        success: true,
        data: data as FieldsResponse,
      };

      /* cspell:disable */
      const translationsNames: IContactFieldsTranslations = {
        nombre: 'names',
        apellido: 'lastNames',
        id_contacto: 'idContact',
        sexo: 'gender',
        telefono_1: 'mainPhone',
        telefono_2: 'secondaryPhone',
        web_url: 'website',
        direccion: 'address',
        ciudad: 'city',
        pais: 'country',
        cumpleanios: 'birthday',
        empresa: 'company',
        cargo: 'position',
        email_secundario: 'secondaryEmail',
        id_trigger: 'idTrigger',
        recencia: 'recency',
        frecuencia: 'frequency',
        monto: 'amount',
        fecha_creacion: 'creationDate',
        nps: 'nps',
        clasificacion_ecommerce: 'eCommerceClassification',
      };

      const translationsValues: IContactFieldsTranslations = {
        nombre: 'name',
        apellido: 'lastName',
        id_contacto: 'contactId',
        sexo: 'gender',
        telefono_1: 'phone1',
        telefono_2: 'phone2',
        web_url: 'web_url',
        direccion: 'address',
        ciudad: 'city',
        pais: 'country',
        cumpleanios: 'birthday',
        empresa: 'company',
        cargo: 'position',
        email_secundario: 'secondaryEmail',
        id_trigger: 'idTrigger',
        recencia: 'recency',
        frecuencia: 'frequency',
        monto: 'amount',
        fecha_creacion: 'creationDate',
        nps: 'nps',
        clasificacion_ecommerce: 'eCommerceClassification',
      };

      const [defaultFields, customFields, activityFields] = Object.entries(response.data).map(
        ([key, fields]) => {
          if (key === 'defaultFields') {
            return (fields as Field[]).map((field: Field) => {
              if (translationsValues[field.name]) field.value = translationsValues[field.name];
              else field.value = field.name;
              if (translationsNames[field.name]) field.name = translationsNames[field.name];
              return field;
            });
          }
          if (key === 'activityFields') {
            return (fields as ActivityField[]).map((field: ActivityField) => {
              field.value = field.name;
              return field;
            });
          }
          if (key === 'customFields') {
            return fields as Field[];
          }
        }
      ) as [Field[], Field[], ActivityField[]];

      response.data.defaultFields = defaultFields;
      response.data.customFields = customFields;
      response.data.activityFields = activityFields;

      this.contactsFieldsCache = response;
      return response;
    } catch (error) {
      const errorMessage: DataResponse<string> = { success: false, data: '' };
      console.error('Error', error);
      if (error instanceof AxiosError) errorMessage.data = error.response?.data.error;
      else errorMessage.data = String(error);
      return errorMessage;
    }
  }

  /**
   *
   * @param v2Segment segment to translate
   * @returns an object of type PerfilDTO (See class in V1)
   */
  private async translateV2ToV1Interface(v2Segment: ISegment): Promise<any> {
    const { data }: any = await this.apiV2.post('/api/v2.1/contacts/segments/translate_v2_to_v1', {
      v2Segment: v2Segment,
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  }
  private async translateV1ToV2Interface(v1Segment: object): Promise<ISegment> {
    const { data }: any = await this.apiV2.post('/api/v2.1/contacts/segments/translate_v1_to_v2', {
      v1Segment: v1Segment,
    });
    return data as ISegment;
  }

  private async createSegmentWithName(
    name: string
  ): Promise<{ segmentId: number; errorByName?: boolean }> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.get(
      `/Services/Contactos.svc/CrearPerfil?nombre=${name}`
    );

    const resp: { key: string; value: string | number }[] = data;
    if (resp[1] && resp[1].value === 'PERFIL_CREADO_OK') {
      return { segmentId: Number(resp[0].value) };
    } else if (resp[0].value === 'PERFIL_CREADO_KO') {
      return { segmentId: 0 };
    }
    return { segmentId: 0, errorByName: resp[1].value === 'PERFIL_EXISTENTE' };
  }

  private async saveSegmentFilters(
    v1Segment: any,
    v2Segment: ISegment,
    asEmblueSegment?: {
      segmentType: SegmentTypeEnum.emblueRecipe | SegmentTypeEnum.eCommerce;
      segmentNumber?: EmblueRecipesEnum | ECommerceSegmentsEnum;
    }
  ): Promise<boolean> {
    const v2SegmentDTO = {
      JsonRepresentation: JSON.stringify(v2Segment),
      EmblueSegment: asEmblueSegment
        ? {
            IdSegmentType: asEmblueSegment.segmentType,
            SegmentNumber: asEmblueSegment.segmentNumber,
          }
        : null,
    };
    const { data }: any = await this.apiV1.post('/Services/Contactos.svc/GuardarPerfil', {
      perfil: v1Segment,
      v2Segment: v2SegmentDTO,
    });
    return data.id_notificacion === 'PERFIL_GUARDADO_OK';
  }
  /* cspell:enable */

  async renameSegment(name: string, segmentId: number): Promise<boolean> {
    try {
      const { success, data } = await this.getSegmentFilterById(segmentId);
      if (success) {
        const v2Segment = data as ISegment;
        let editResponse;
        if (v2Segment !== null) {
          v2Segment.name = name;
          editResponse = await this.editV2Segment({
            v2Segment,
          });
          return editResponse.success;
        } else {
          /* cspell:disable */
          const perfil = await this.apiV1.post('/Services/Contactos.svc/getPerfilData', {
            id_grupo: parseInt(`${segmentId}`),
          });
          const v1Segment = perfil.data as object & { nombre: string };
          v1Segment.nombre = name;
          /* cspell:enable */
          return await this.saveSegmentFilters(v1Segment, v2Segment);
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error', error);
      return false;
    }
  }

  private async getAmountSegmentFilters(v1Segment: any): Promise<number> {
    /* cspell:disable */
    const { data }: any = await this.apiV1.post(
      '/Services/Contactos.svc/GetCantidadPerfilTemporal',
      { perfil: v1Segment }
    );
    return data;
  }

  // Happiness
  async getNpsRank(query: { status: string }): Promise<NpsRank | undefined> {
    try {
      const { data }: any = await this.apiV2.get(`/api/v2.1/nps/rank?status=${query.status}`);
      return data as NpsRank;
    } catch (error) {
      console.error('Error', error);
      return;
    }
  }

  emblueNpsCountCache: {
    data: NpsCount;
    lastRefresh: number;
  } = {
    data: {
      count: 0,
    },
    lastRefresh: 0,
  };
  async getNpsCount(query: {
    status: string;
    lastRefresh?: number;
  }): Promise<NpsCount | undefined> {
    const latestLastRefresh = query.lastRefresh ?? this.getLastRefreshNpsValue();

    if (
      this.emblueNpsCountCache.lastRefresh &&
      latestLastRefresh <= this.emblueNpsCountCache.lastRefresh
    ) {
      return this.emblueNpsCountCache.data;
    }

    try {
      const { data }: any = await this.apiV2.get(`/api/v2.1/nps/count?status=${query.status}`);

      this.emblueNpsCountCache = {
        data: data as NpsCount,
        lastRefresh: latestLastRefresh,
      };

      return data as NpsCount;
    } catch (error) {
      console.error('Error', error);
      return;
    }
  }
  async getNpsStats(query: { status: string }): Promise<NpsStats | undefined> {
    try {
      const { data }: any = await this.apiV2.get(
        `/api/v2.1/nps/percentage_by_stats?status=${query.status}`
      );
      return data as NpsStats;
    } catch (error) {
      console.error('Error', error);
      return;
    }
  }
  async getNpsGeneralValue(query: { status: string }): Promise<number | undefined> {
    try {
      const { data }: any = await this.apiV2.get(
        `/api/v2.1/nps/general_value?status=${query.status}`
      );
      return data as number;
    } catch (error) {
      console.error('Error', error);
      return;
    }
  }
  async getNpsTotalStats(query: { status: string }): Promise<NpsTotalStats | undefined> {
    try {
      const { data }: any = await this.apiV2.get(
        `/api/v2.1/nps/total_stats?status=${query.status}`
      );
      return data as NpsTotalStats;
    } catch (error) {
      console.error('Error', error);
      return;
    }
  }
  async getNpsTotalContactsCount(query: {
    status: string;
  }): Promise<NpsTotalContactsCount | undefined> {
    try {
      const { data }: any = await this.apiV2.get(
        `/api/v2.1/nps/contacts_total_count?status=${query.status}`
      );
      return data as NpsTotalContactsCount;
    } catch (error) {
      console.error('Error', error);
      return;
    }
  }
  async getNpsContactList(query: NpsContactListQuery): Promise<NpsContacts | undefined> {
    try {
      const { data }: any = await this.apiV2.get(
        `/api/v2.1/nps/contacts_list?status=${query.npsStatus}&orderByName=${query.orderByName}`,
        {
          happiness: query.happiness,
          search: query.search,
          pageNumber: query.pageNumber,
          rowsPerPage: query.rowsPerPage,
        }
      );

      const contacts: TableNpsContact[] = data.list as [TableNpsContact];

      const list = _.flatten(contacts).map((x) => ({
        contact: { ...x },
      }));

      let currentPageList: ITableNpsContact[] = [];
      currentPageList = list;

      return {
        list: currentPageList,
        count: data.count,
      } as NpsContacts;
    } catch (error) {
      console.error('Error', error);
      return;
    }
  }

  ///// ***** GLOBAL LastRefreshCache SECTION ***** \\\\\

  //Contacts
  lastRefreshContactsValue = 0;
  getLastRefreshContactsValue(): number {
    return this.lastRefreshContactsValue;
  }
  incrementLastRefreshContactsValue(): void {
    this.lastRefreshContactsValue++;
  }
  //Groups
  lastRefreshGroupsValue = 0;
  getLastRefreshGroupsValue(): number {
    return this.lastRefreshGroupsValue;
  }
  incrementLastRefreshGroupsValue(): void {
    this.lastRefreshGroupsValue++;
  }
  //Tags
  lastRefreshTagsValue = 0;
  getLastRefreshTagsValue(): number {
    return this.lastRefreshTagsValue;
  }
  incrementLastRefreshTagsValue(): void {
    this.lastRefreshTagsValue++;
  }
  //Segments
  lastRefreshSegmentsValue = 0;
  getLastRefreshSegmentsValue(): number {
    return this.lastRefreshSegmentsValue;
  }
  incrementLastRefreshSegmentsValue(): void {
    this.lastRefreshSegmentsValue++;
  }
  //Nps
  lastRefreshNpsValue = 0;
  getLastRefreshNpsValue(): number {
    return this.lastRefreshNpsValue;
  }
  incrementLastRefreshNpsValue(): void {
    this.lastRefreshNpsValue++;
  }

  ///// ***** End GLOBAL LastRefreshCache SECTION ***** \\\\\

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async createV1Campaigns(payload: ICreateCampaign): Promise<ICreateCampaignResponse> {
    try {
      const { name, templateId, campaignType } = payload;
      const forceCache = new Date().getTime();
      const response = await this.apiV1.get(
        `/Services/Campanias.svc/CrearCampania?nombre=${name}&idTemplate=${templateId}&tipo=${campaignType}&force_cache=${forceCache}`
      );

      const { data } = response as { data: [{ Key: string; Value: number }] };

      let campaignId = 0;
      let actionId = 0;

      const keyMap: { [key: string]: (value: number) => void } = {
        id_campania: (value: number) => (campaignId = value),
        campania_elemento_id: (value: number) => (actionId = value),
      };

      data.forEach((item) => {
        const setter = keyMap[item.Key];
        if (setter) {
          setter(item.Value);
        }
      });

      return { statusOK: response.status === 200, campaignId: campaignId, actionId: actionId };
    } catch (error) {
      console.error(`❌ EM_Error createV1Campaigns:`, error);
      return { statusOK: false, campaignId: 0, actionId: 0 };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */

  async deleteV1Campaigns(campaignsID: number[]): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.apiV1.post('/Services/Campanias.svc/EliminarCampanias', {
        ids: campaignsID,
      });

      if (response.status !== 200) return { statusOK: false };

      return { statusOK: true };
    } catch (error) {
      console.error(`❌ EM_Error deleteV1Campaigns:`, error);
      return { statusOK: false };
    }
  }

  async duplicateV1Campaigns(
    payload: IDuplicateCampaignsV1
  ): Promise<IDuplicateCampaignsResponseV1> {
    try {
      const { name, campaignId } = payload;

      const forceCache = new Date().getTime();
      const response = await this.apiV1.get(`/Services/Campanias.svc/DuplicarCampania`, {
        campaniaId: campaignId,
        nombre: name,
        force_cache: forceCache,
      });

      const { data } = response as { data: { id_campania: number } };

      return { statusOK: response.status === 200, campaignIdDuplicate: data?.id_campania };
    } catch (error) {
      console.error(`❌ EM_Error duplicateV1Campaigns:`, error);
      return { statusOK: false, campaignIdDuplicate: 0 };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async deleteV1Actions(actionsId: number[]): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.apiV1.post('/Services/Campanias.svc/EliminarElementos', {
        ids: actionsId,
      });

      if (response.status !== 200) return { statusOK: false };

      return { statusOK: true };
    } catch (error) {
      console.error(`❌ EM_Error deleteV1Actions:`, error);
      return { statusOK: false };
    }
  }

  async duplicateV1Actions(
    payload: IDuplicateCampaignActions
  ): Promise<IDuplicateCampaignActionsResponse> {
    try {
      const { actionName, campaignActionId } = payload;
      const forceCache = new Date().getTime();
      const response = await this.apiV1.get(`/Services/Campanias.svc/DuplicarElemento`, {
        elementoId: campaignActionId,
        nombre: actionName,
        force_cache: forceCache,
      });
      const { data } = response as { data: { id_elemento: number } };
      return { statusOK: response.status === 200, actionIdDuplicate: data?.id_elemento };
    } catch (error) {
      console.error(`❌ EM_Error duplicateV1Actions:`, error);
      return { statusOK: false, actionIdDuplicate: 0 };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async moveV1Action(payload: IMoveCampaignPayload): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.apiV1.post(
        '/Services/Campanias.svc/CambiarElementoACampania',
        payload
      );

      if (response.status !== 200) return { statusOK: false };

      return { statusOK: true };
    } catch (error) {
      console.error(`❌ EM_Error moveV1Action:`, error);
      return { statusOK: false };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getV1ShippingTags(): Promise<IV1GetShippingTagsResponse> {
    try {
      const response = await this.apiV1.post(`/Services/Tags.svc/ObtenerTagsEnvio`);

      const data = response.data as IV1GetShippingTagsApiResponse;

      if (response.status !== 200) {
        return {
          tags: { staticTags: [], rulesTags: [] },
          statusOK: false,
        };
      }

      const translations = {
        TagsEstaticos: 'staticTags',
        TagsRegla: 'rulesTags',
        cantidadActividad: 'activityAmount',
        cantidadFrecuencia: 'frequencyAmount',
        cantidadUsuarios: 'userCount',
        capaId: 'layerId',
        categoriaNombre: 'categoryName',
        color: 'color',
        contenedorId: 'containerId',
        contenido: 'content',
        contenidoTipo: 'contentType',
        nombre: 'name',
        orderDefault: 'defaultOrder',
        tagCategoriaId: 'tagCategoryId',
        tagId: 'tagId',
        ultimoUso: 'lastUse',
        Nombre: 'name',
        ReglaId: 'ruleId',
      };

      const translatedResponse = {
        staticTags: data.TagsEstaticos.map((tag: any) =>
          mapItemInterfaceEsToEn(tag, {}, translations)
        ),
        rulesTags: data.TagsRegla.map((rule: any) =>
          mapItemInterfaceEsToEn(rule, {}, translations)
        ),
      };

      return {
        tags: translatedResponse as IV1GetShippingTags,
        statusOK: true,
      };
    } catch (error) {
      console.error(`❌ EM_Error getV1ShippingTags:`, error);
      return {
        tags: {} as IV1GetShippingTags,
        statusOK: false,
      };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async saveV1Message(payload: ISaveV1Message): Promise<{ statusOK: boolean }> {
    try {
      const response = await this.apiV1.post(`/Services/Campanias.svc/GuardarMensaje`, payload);

      if (response.status !== 200) return { statusOK: false };

      return { statusOK: true };
    } catch (error) {
      console.error(`❌ EM_Error saveV1Message:`, error);
      return { statusOK: false };
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getTotalAddresseesByAction(payload: IAddresseesByAction): Promise<number | null> {
    try {
      const response = await this.apiV1.post(
        `/Services/Campanias.svc/CantidadDestinatarios`,
        payload
      );

      if (response.status !== 200) return null;

      return Number(response?.data);
    } catch (error) {
      console.error(`❌ EM_Error getTotalAddresseesByAction:`, error);
      return null;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setPauseEmailActionV1(actionId: TActionID): Promise<IPauseEmailResponseV1 | null> {
    try {
      const timestamp = new Date().getTime();
      const response = await this.apiV1.get(
        `/Services/Campanias.svc/PausarElemento?elementoId=${actionId}&force_cache=${timestamp}`
      );
      const data = response.data as IPauseEmailResponseV1;

      if (response.status !== 200) return null;

      return data;
    } catch (error) {
      console.error(`❌ EM_Error setPauseEmailActionV1:`, error);
      return null;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getSmsInfo(actionId: TActionID): Promise<ISmsInfoResponseV1> {
    try {
      const timestamp = new Date().getTime();
      const response = await this.apiV1.get(
        `/Services/Campanias.svc/ObtenerResumenElementoSMSConfiguracion?elementoId=${actionId}&force_cache=${timestamp}`
      );
      const data = response.data as ISmsInfoResponseV1;

      if (response.status !== 200) return {} as ISmsInfoResponseV1;

      return data;
    } catch (error) {
      console.error(`❌ EM_Error getSmsInfo:`, error);
      return {} as ISmsInfoResponseV1;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getSmsRecipientsV1(actionId: TActionID): Promise<ISmsRecipientsResponseV1 | null> {
    try {
      const timestamp = new Date().getTime();
      const response = await this.apiV1.get(
        `/Services/Campanias.svc/obtenerGruposEnvio?elementoId=${actionId}&force_cache=${timestamp}`
      );
      const data = response.data as ISmsRecipientsResponseV1;

      if (response.status !== 200) return null;

      return data;
    } catch (error) {
      console.error(`❌ EM_Error getRecipientsV1:`, error);
      return null;
    }
  }

  /* cspell:enable */
  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async confirmElementV1(actionID: TActionID): Promise<GenericServiceResponse> {
    try {
      const timestamp = new Date().getTime();
      const { data } = (await this.apiV1.get(
        `/Services/Campanias.svc/ConfirmarElemento?elementoId=${actionID}&force_cache=${timestamp}`
      )) as { data: { id_notificacion: string } };

      const notification = data.id_notificacion;
      const success = notification === 'CONFIRMAR_ELEMENTO_OK';
      return { success, errorType: success ? undefined : data.id_notificacion };
    } catch (error) {
      console.error(`❌ EM_Error confirmElementV1:`, error);
      return { success: false, errorType: 'UNKNOWN' };
    }
  }
  /* cspell:enable */

  /* cspell:disable */
  async getContractCountry(): Promise<string | null> {
    try {
      const response = await this.apiV1.get(`/Services/Usuarios.svc/GetContractCountry`);

      if (response.status !== 200) return null;

      return String(response.data);
    } catch (error) {
      console.error(`❌ EM_Error GetContractCountry:`, error);
      return null;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getSetInfoSms(query: object): Promise<boolean> {
    try {
      const response = await this.apiV1.post(`/Services/Campanias.svc/SetearDatosSms`, query);
      const { id_notificacion } = response.data as { id_notificacion: string };

      if (response.status !== 200) return false;

      return id_notificacion === 'DATOS_SMS_OK';
    } catch (error) {
      console.error(`❌ EM_Error getSetInfoSms:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setSmsRecipientsV1(query: object): Promise<boolean> {
    try {
      const response = await this.apiV1.post(`/Services/Campanias.svc/SetearGruposEnvio`, query);
      const { id_notificacion } = response.data as { id_notificacion: string };

      if (response.status !== 200) return false;

      return id_notificacion === 'GRUPO_AGREGADO_OK';
    } catch (error) {
      console.error(`❌ EM_Error setSmsRecipientsV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setImmediateSMSDeliveryV1(actionId: TActionID): Promise<boolean> {
    const timestamp = new Date().getTime();
    try {
      const response = await this.apiV1.get(`/Services/Campanias.svc/SetearEnvioInmediato`, {
        elementoId: actionId,
        envioInmediato: true,
        force_cache: timestamp,
      });
      const { id_notificacion } = response.data as { id_notificacion: string };

      if (response.status !== 200) return false;

      return id_notificacion === 'FECHA_SETEADA_OK';
    } catch (error) {
      console.error(`❌ EM_Error setImmediateSMSDeliveryV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setScheduledSMSDeliveryV1(query: IScheduledSMSDeliveryV1): Promise<boolean> {
    const timestamp = new Date().getTime();
    const payload = { ...query, force_cache: timestamp };

    try {
      const response = await this.apiV1.get(
        `/Services/Campanias.svc/SetearFechaYHoraEnvio`,
        payload
      );
      const { id_notificacion } = response.data as { id_notificacion: string };

      if (response.status !== 200) return false;

      return id_notificacion === 'FECHA_SETEADA_OK';
    } catch (error) {
      console.error(`❌ EM_Error setScheduledSMSDeliveryV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setConfirmSMSV1(actionId: TActionID): Promise<boolean> {
    try {
      const response = await this.apiV1.post('/Services/Campanias.svc/ConfirmarSms', {
        elementoId: actionId,
      });
      const { id_notificacion } = response.data as { id_notificacion: string };

      if (response.status !== 200) return false;

      return id_notificacion === 'CONFIRMAR_SMS_OK';
    } catch (error) {
      console.error(`❌ EM_Error setConfirmSMSV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async setPauseSMSV1(actionId: TActionID): Promise<boolean> {
    const timestamp = new Date().getTime();
    try {
      const response = await this.apiV1.get('/Services/Campanias.svc/PausarElemento', {
        elementoId: actionId,
        force_cache: timestamp,
      });
      const { id_notificacion } = response.data as { id_notificacion: string };

      if (response.status !== 200) return false;

      return id_notificacion === 'ENVIO_PAUSADO_OK';
    } catch (error) {
      console.error(`❌ EM_Error setPauseSMSV1:`, error);
      return false;
    }
  }
  /* cspell:enable */

  //TODO: Temporal service. Migrate service to V2 in Monorepo
  /* cspell:disable */
  async getSmsInfoReport(actionId: TActionID): Promise<ISmsReportActionResponseV1> {
    try {
      const timestamp = new Date().getTime();
      const response = await this.apiV1.get('/Services/Campanias.svc/ObtenerElemento', {
        elementoId: actionId,
        force_cache: timestamp,
      });
      const data = response.data as ISmsReportActionResponseV1;

      if (response.status !== 200) return {} as ISmsReportActionResponseV1;

      return data;
    } catch (error) {
      console.error(`❌ EM_Error getSmsInfoReport:`, error);
      return {} as ISmsReportActionResponseV1;
    }
  }
  /* cspell:enable */

  /**
   * Downloads the report SMS for a given payload.
   *
   * @param payload - Params of the process.
   * @returns A promise that resolves to a boolean indicating whether the download was successful.
   */
  //TODO: Temporal service. Migrate service to V2 in Monorepo
  //cspell:disable
  async downloadReport(payload: IReportParams[]): Promise<boolean> {
    const { data } = await this.apiV1.post('Services/Reportes.svc/ComenzarProcesamientoReporte', {
      parametros: payload,
    });

    const { id_notificacion } = data as { id_notificacion: string };
    return id_notificacion === 'REPORTE_CREADO_OK';
  }
  //cspell:enable
}

type EmblueApiMethodNameMap = { [x in keyof IEmblueService]: x };
export const ServiceMethods: EmblueApiMethodNameMap = Object.getOwnPropertyNames(
  EmblueService.prototype
)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .reduce((h: any, k: any) => {
    h[k] = k;
    return h;
  }, {} as typeof EmblueService);
