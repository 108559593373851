import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { formValidations } from '@/src/constants/FormValidations';

import {
  atomActionsList,
  atomSelectedActionsRowsWithID,
  atomSelectedRowActionType,
  atomSelectedRowsAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomLoadingDuplicateAction,
  atomRefetchAllData,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { getCampaignIDByActionSelected } from '@/modules/CampaignsModule/utils';

export const useDuplicateActions = () => {
  const { inputLengths, formRegExp } = formValidations;
  const setToggleDuplicateActionsModal = useSetAtom(atomToggleDuplicateActionsModal);
  const setRefetchAllData = useSetAtom(atomRefetchAllData);
  const selectedRowsAction = useAtomValue(atomSelectedRowsAction);
  const actionsList = useAtomValue(atomActionsList);
  const selectedActionsRowsWithID = useAtomValue(atomSelectedActionsRowsWithID);
  const actionType = useAtomValue(atomSelectedRowActionType);
  const [loading, setLoading] = useAtom(atomLoadingDuplicateAction);
  const { t } = useTranslation();
  const { id: campaignID } = useParams();
  const { fetchServiceValidName } = useValidNameResource();
  const { duplicateAction } = useCampaignActions();

  const VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
        pattern: {
          value: formRegExp.lettersNumbersAndSpecials,
          message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
        },
      },
    }),
    [formRegExp, inputLengths, t]
  );

  const handleCloseModal = useCallback(() => {
    setToggleDuplicateActionsModal(false);
  }, [setToggleDuplicateActionsModal]);

  const handleValidation = useCallback(
    async (value: string) => {
      const isValidName = await fetchServiceValidName({
        name: value,
        resource: EResourceType.ACTION,
        campaignId:
          Number(campaignID) ||
          getCampaignIDByActionSelected({ rowAction: selectedRowsAction, actionsList }),
      });
      return {
        isValidInput: isValidName,
        message: t('CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS.existCampaign'),
      };
    },
    [actionsList, campaignID, fetchServiceValidName, selectedRowsAction, t]
  );

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      setLoading(true);
      await duplicateAction({
        actionName: nameValue,
        campaignActionId: Number(selectedActionsRowsWithID),
        actionType: Number(actionType),
      });
      handleCloseModal();
      setRefetchAllData(true);
      setLoading(false);
    },
    [
      setLoading,
      duplicateAction,
      selectedActionsRowsWithID,
      actionType,
      handleCloseModal,
      setRefetchAllData,
    ]
  );

  return {
    VALIDATION_RULES,
    handleValidation,
    handleCloseModal,
    handleSubmitData,
    loading,
  };
};
