/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import {
  CreateEventBody,
  EditEventBody,
  EventDataResponse,
  GetApiResponse,
  IAutomationParamStart,
  IExecutedTrigger,
  IGetAllEventsPayload,
  IGetAllEventsResponse,
  IGetAllFlowsPayload,
  IGetAllFlowsResponse,
  IGetDetailExecutedCountResponse,
  IGetExecutedCountResponse,
  IGetExecutedFilters,
  IGetReportEventsPayload,
  IGetReportEventsResponse,
  IGetReportFlowsPayload,
  IGetReportFlowsResponse,
  IRequestErrorResult,
} from '@/src/infrastructure/interfaces/IAutomation.interface';
import { CreateFlowResponse } from '@/src/infrastructure/interfaces/IResponses';
import { APIService } from '@/src/infrastructure/Protocol/APIService';
import { EmblueService } from '@/src/infrastructure/Protocol/EmblueService';

import { IAutomationService, IEmblueApiMethodNameMap } from './IAutomationService';

import { EVENT_ALREADY_EXISTS, FLOW_ALREADY_EXISTS } from '@/modules/AutomationModule/constants';
import { EventData, IEventsStatus } from '@/modules/AutomationModule/types/events';
import { DataResponse } from '@/modules/ContactsModule/types/Segments';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class AutomationService implements IAutomationService {
  private api: APIService;
  private apiFiles: APIService;
  private apiLocal: APIService;

  private constructor(payload: IAutomationParamStart) {
    this.api = new APIService(payload.url_ema_app_v2, payload.jwt);
    this.apiFiles = new APIService('');
    this.apiLocal = new APIService('http://localhost:3003', payload.jwt);
  }

  /**
   * @desc Catch errors from API
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    console.error(`❌ EM_Error AutomationService[${method}]:`, error);
  }

  /**
   * @desc Create new instance of AutomationService
   */
  static create(payload: IAutomationParamStart): IAutomationService {
    return new AutomationService(payload);
  }

  /**
   * @desc Get all flows of a user
   */
  async getFlows(filters: IGetAllFlowsPayload): Promise<IGetAllFlowsResponse> {
    try {
      const { data: response } = await this.api.get<IGetAllFlowsResponse>(
        `api/v2.1/automation/flows`,
        filters
      );

      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getFlows');
      return {
        result: [],
        pager: {
          countPartial: 0,
          countTotal: 0,
        },
      };
    }
  }

  /**
   * @desc Duplicate a event
   */
  async duplicateEvents(id: number, name: string): Promise<IRequestErrorResult | boolean> {
    try {
      const { status } = await this.api.post(`api/v2.1/automation/events/duplicate`, {
        id,
        name,
      });

      return status === 200;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { data } = error.response as { data: { type: string } };

        return {
          error: true,
          type: data.type,
        };
      }

      this.catchErrors(error as AxiosError<Error>, 'duplicateEvents');
      return {
        error: true,
        type: 'unknown',
      };
    }
  }

  /**
   * @desc Duplicate a flow
   */
  async duplicateFlows(id: number, name: string): Promise<IRequestErrorResult | boolean> {
    try {
      const { status } = await this.api.post(`api/v2.1/automation/flows/duplicate`, {
        id,
        name,
      });

      return status === 200;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { data } = error.response as { data: { type: string } };

        return {
          error: true,
          type: data.type,
        };
      }

      this.catchErrors(error as AxiosError<Error>, 'duplicateFlows');
      return {
        error: true,
        type: 'unknown',
      };
    }
  }

  /**
   * @desc Delete multiple flows
   */
  async deleteFlows(ids: number[]): Promise<boolean> {
    try {
      const { status } = await this.api.delete(`api/v2.1/automation/flows`, { ids });

      return status === 200;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'deleteFlows');
      return false;
    }
  }

  async getFlowsExecutions(
    triggerIds: number[],
    filters?: IGetExecutedFilters | null
  ): Promise<IGetExecutedCountResponse> {
    try {
      if (triggerIds?.length === 0) {
        return null;
      }
      const url = new URL('api/v2.1/automation/flows/executions', this.api.baseURL);

      if (filters?.dateFrom && filters?.dateTo) {
        url.searchParams.append('dateFrom', filters.dateFrom);
        url.searchParams.append('dateTo', filters.dateTo);
      }

      const { data: response } = await this.api.get<IExecutedTrigger[]>(url.toString(), {
        triggerIds,
      });

      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getFlowsExecutions');
      return null;
    }
  }

  async getEventsExecutions(
    eventsIds: number[],
    filters?: IGetExecutedFilters | null
  ): Promise<IGetExecutedCountResponse> {
    try {
      if (eventsIds?.length === 0) {
        return null;
      }
      const url = new URL('api/v2.1/automation/events/executions', this.api.baseURL);

      if (filters?.dateFrom && filters?.dateTo) {
        url.searchParams.append('dateFrom', filters.dateFrom);
        url.searchParams.append('dateTo', filters.dateTo);
      }

      const { data: response } = await this.api.get<IExecutedTrigger[]>(url.toString(), {
        eventsIds,
      });

      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getEventsExecutions');
      return null;
    }
  }

  async getDetailEventsExecutions(
    id: number,
    filters: IGetExecutedFilters
  ): Promise<IGetDetailExecutedCountResponse | null> {
    try {
      const url = new URL(`api/v2.1/automation/events/executions/${id}`, this.api.baseURL);
      const { timeOffset } = await EmblueService.getInstance().getUserData();
      url.searchParams.append('gmt', timeOffset?.toString() ?? '0');

      if (filters?.dateFrom && filters?.dateTo) {
        url.searchParams.append('dateFrom', filters.dateFrom);
        url.searchParams.append('dateTo', filters.dateTo);
      }
      if (filters?.counter) {
        url.searchParams.append('count', filters.counter.toString());
      }
      if (typeof filters.page === 'number')
        url.searchParams.append('page', filters?.page.toString());
      if (typeof filters.limit === 'number')
        url.searchParams.append('limit', filters?.limit.toString());

      const { data: response } = await this.api.get<IGetDetailExecutedCountResponse>(
        url.toString()
      );

      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getDetailEventsExecutions');
      return null;
    }
  }

  async getDetailFlowsExecutions(
    id: number,
    filters: IGetExecutedFilters
  ): Promise<IGetDetailExecutedCountResponse | null> {
    try {
      const url = new URL(`api/v2.1/automation/flows/executions/${id}`, this.api.baseURL);
      const { timeOffset } = await EmblueService.getInstance().getUserData();
      url.searchParams.append('gmt', timeOffset?.toString() ?? '0');

      if (filters?.dateFrom && filters?.dateTo) {
        url.searchParams.append('dateFrom', filters.dateFrom);
        url.searchParams.append('dateTo', filters.dateTo);
      }
      url.searchParams.append('count', filters?.counter?.toString() ?? 'false');

      if (typeof filters.page === 'number')
        url.searchParams.append('page', filters?.page.toString());
      if (typeof filters.limit === 'number')
        url.searchParams.append('limit', filters?.limit.toString());

      const { data: response } = await this.api.get<IGetDetailExecutedCountResponse>(
        url.toString()
      );

      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getDetailFlowsExecutions');
      return null;
    }
  }

  /**
   * @desc Change status of the flow
   */
  async changeStatus(id: number, status: number): Promise<boolean> {
    try {
      const { data: response } = await this.api.put<boolean>(
        `api/v2.1/automation/flows/change_status?id=${id}&status=${status}`
      );

      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'changeStatus');
      return false;
    }
  }

  /**
   * @desc Execute a flow
   */
  async executeFlow(id: number): Promise<boolean> {
    try {
      const { status } = await this.api.post<boolean>(`api/v2.1/automation/flows/execute`, {
        id,
      });

      return status === 200;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'changeStatus');
      return false;
    }
  }

  /**
   * @description rename a flow
   */
  async renameFlow(id: number, name: string): Promise<IRequestErrorResult | boolean> {
    try {
      const { status } = await this.api.put<boolean>(`api/v2.1/automation/flows`, {
        name,
        id,
      });
      return status === 200;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { data } = error.response as { data: { type: string } };

        return {
          error: true,
          type: data.type,
        };
      }

      this.catchErrors(error as AxiosError<Error>, 'renameFlow');
      return {
        error: true,
        type: 'unknown',
      };
    }
  }
  /**
   * @description rename a event
   */
  async renameEvent(id: number, name: string): Promise<IRequestErrorResult | boolean> {
    try {
      const { status } = await this.api.put<boolean>(`api/v2.1/automation/events/rename`, {
        name,
        id,
      });
      return status === 200;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { data } = error.response as { data: { type: string } };

        return {
          error: true,
          type: data.type,
        };
      }

      this.catchErrors(error as AxiosError<Error>, 'renameFlow');
      return {
        error: true,
        type: 'unknown',
      };
    }
  }

  async createFlow(flowName: string): Promise<DataResponse<CreateFlowResponse | string>> {
    try {
      const { data } = await this.api.post('/api/v2.1/automation/flows', { name: flowName });
      return { success: true, data: data as CreateFlowResponse };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400 && error.response?.data?.type === FLOW_ALREADY_EXISTS) {
          return { success: false, data: FLOW_ALREADY_EXISTS };
        }
      }
      console.error(error);
      return { success: false, data: 'Error creating flow' };
    }
  }

  async createEvent(createEvent: CreateEventBody): Promise<DataResponse<string>> {
    try {
      const response = await this.api.post('/api/v2.1/automation/events', createEvent);
      return { success: response.status === 200, data: 'OK' };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400 && error.response?.data?.type === EVENT_ALREADY_EXISTS) {
          return { success: false, data: EVENT_ALREADY_EXISTS };
        }
      }
      console.error(error);
      return { success: false, data: 'Error creating event' };
    }
  }

  async getEventById(id: number): Promise<EventData | null> {
    try {
      const { data } = await this.api.get<EventDataResponse | null>(
        `/api/v2.1/automation/events/${id}`
      );
      if (!data) return null;
      return {
        name: data.name,
        description: data.description,
        modelData:
          (data.attributes && data.attributes.length > 0) || (data.items && data.items.length > 0),
        attributes: data.attributes
          ? data.attributes.map((attribute) => ({
              value: attribute,
            }))
          : [],
        eventItems: data.items
          ? data.items.map((item) => ({
              value: item,
            }))
          : [],
        integrationId: data?.integrationId,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async editEvent(editEvent: EditEventBody): Promise<DataResponse<string>> {
    try {
      const response = await this.api.put('/api/v2.1/automation/events', editEvent);
      return { success: response.status === 200, data: 'OK' };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400 && error.response?.data?.type === EVENT_ALREADY_EXISTS) {
          return { success: false, data: EVENT_ALREADY_EXISTS };
        }
      }
      console.error(error);
      return { success: false, data: 'Error editing event' };
    }
  }

  async changeEventStatus(id: number, status: IEventsStatus): Promise<boolean> {
    try {
      const { data: response } = await this.api.put<boolean>(
        `api/v2.1/automation/events/change_status?id=${id}&status=${status}`
      );
      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'changeEventStatus');
      return false;
    }
  }

  async getEvents(filters: IGetAllEventsPayload): Promise<IGetAllEventsResponse> {
    try {
      const { data: response } = await this.api.get<IGetAllEventsResponse>(
        `api/v2.1/automation/events`,
        filters
      );

      return response;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getEvents');
      return {
        result: [],
        pager: {
          countPartial: 0,
          countTotal: 0,
        },
      };
    }
  }

  async getReportEvents(filters: IGetReportEventsPayload): Promise<string | null> {
    try {
      const { data } = await this.api.post<IGetReportEventsResponse>(
        `api/v2.1/automation/events/report`,
        filters
      );
      return data.url;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getReportEvents');
      return null;
    }
  }
  async getReportFlows(filters: IGetReportFlowsPayload): Promise<string | null> {
    try {
      const { data } = await this.api.post<IGetReportFlowsResponse>(
        `api/v2.1/automation/flows/report`,
        filters
      );
      return data.url;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getReportFlows');
      return null;
    }
  }
  async downloadReport(url: string): Promise<Blob | null> {
    try {
      const { data } = await this.apiFiles.get<Blob>(url, undefined, {
        headers: { 'Content-Type': 'application/zip' },
        withCredentials: false,
        responseType: 'blob',
      });
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getReportFlows');
      return null;
    }
  }

  async getApiKey(): Promise<DataResponse<GetApiResponse>> {
    try {
      const { data } = await this.api.get('/api/v2.1/automation/events/api_key');
      return { success: true, data: data as GetApiResponse };
    } catch (error) {
      console.error(error);
      return { success: false, data: { apiKey: '', apiKeyBase64: '' } };
    }
  }

  async deleteEvent(ids: number[]): Promise<boolean> {
    try {
      const response = await this.api.delete(`/api/v2.1/automation/events`, { ids });
      return response.status === 200;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status !== 200) {
          return false;
        }
      }
      console.error(error);
      return false;
    }
  }
}

export const AutomationServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  AutomationService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof AutomationService);
