export const RulesFlowIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <g mask="url(#mask0_4777_11100)">
        <path
          d="M19.7 25.6203V24.606H17.2803C16.4408 24.606 15.7253 24.3103 15.134 23.719C14.5427 23.1277 14.247 22.4122 14.247 21.5727V11.0333H11.8273V12.048C11.8273 12.8716 11.5357 13.575 10.9523 14.1583C10.369 14.7417 9.66556 15.0333 8.842 15.0333H5.03333C4.19356 15.0333 3.47811 14.7377 2.887 14.1463C2.29567 13.5552 2 12.8398 2 12V7.03333C2 6.19378 2.29567 5.47834 2.887 4.887C3.47811 4.29567 4.19356 4 5.03333 4H8.842C9.66556 4 10.369 4.29167 10.9523 4.875C11.5357 5.45833 11.8273 6.16189 11.8273 6.98567V8H19.7V6.98567C19.7 6.16189 19.9917 5.45833 20.575 4.875C21.1583 4.29167 21.8618 4 22.6853 4H26.494C27.3338 4 28.0492 4.29567 28.6403 4.887C29.2317 5.47834 29.5273 6.19378 29.5273 7.03333V12C29.5273 12.8398 29.2317 13.5552 28.6403 14.1463C28.0492 14.7377 27.3338 15.0333 26.494 15.0333H22.6853C21.8618 15.0333 21.1583 14.7417 20.575 14.1583C19.9917 13.575 19.7 12.8716 19.7 12.048V11.0333H17.2803V21.5727H19.7V20.558C19.7 19.7344 19.9917 19.031 20.575 18.4477C21.1583 17.8643 21.8618 17.5727 22.6853 17.5727H26.494C27.3338 17.5727 28.0492 17.8683 28.6403 18.4597C29.2317 19.0508 29.5273 19.7662 29.5273 20.606V25.5727C29.5273 26.4122 29.2317 27.1277 28.6403 27.719C28.0492 28.3103 27.3338 28.606 26.494 28.606H22.6853C21.8618 28.606 21.1583 28.3143 20.575 27.731C19.9917 27.1477 19.7 26.4441 19.7 25.6203Z"
          fill="#0056B8"
        />
      </g>
    </svg>
  );
};
