import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconSvg, Text } from '@/lib/v2/components';

import { atomRuleData } from '@/modules/RulesModule/atoms/rules';
import {
  TRANSLATE_RULES_SEQUENCE_SETTINGS,
  TRANSLATE_RULES_TRIGGER_TYPES,
} from '@/modules/RulesModule/constants';
import { RulesDateIcon } from '@/modules/RulesModule/images/icons';
import { RulesOptions } from '@/modules/RulesModule/interfaces';
import { capitalizeFirstLetter } from '@/modules/RulesModule/utils/capitalizeFirstLetter';

const NodeContentDate = () => {
  const { t } = useTranslation('rules');
  const rulesData = useAtomValue(atomRuleData);

  const formatTriggerDetails = useCallback(
    (formValues: RulesOptions): string => {
      const { frequency, days, period, execution, hours, minutes } = formValues;

      const formattedString = [
        frequency?.name,
        days?.name,
        period?.name,
        execution?.name,
        `${t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.atTime`)} ${hours?.name ?? '00'}:${
          minutes?.name ?? '00'
        }`,
      ].join(' ');

      return capitalizeFirstLetter(formattedString);
    },
    [t]
  );

  return (
    <div className="flex flex-col justify-center">
      <div className="mb-2 flex items-center">
        <IconSvg fillColor="primary" height="24px" svgComponent={<RulesDateIcon />} width="24px" />
        <Text className="ml-2 mt-1" fontWeight="semibold" variant="text">
          {t(`${TRANSLATE_RULES_TRIGGER_TYPES}.${rulesData.trigger?.id ?? ''}`)}
        </Text>
      </div>
      <hr className="w-full min-w-64" />
      <div className="flex flex-col justify-center pb-4 pt-2">
        <Text fontWeight="medium" variant="text">
          {rulesData?.customField?.name}
        </Text>
        <Text truncate fontWeight="medium" variant="text-sm">
          {rulesData && formatTriggerDetails(rulesData)}
        </Text>
      </div>
    </div>
  );
};

export default NodeContentDate;
