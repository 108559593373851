import EmojiRegex from 'emoji-regex';

import emojis from '@/src/assets/emojis.json';
import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { ICustomField } from '@/src/modules/CampaignsModule/hooks/useCustomFields';
import { ISpecialLink } from '@/src/modules/CampaignsModule/hooks/useEditorContent';
import { IBeefreeSaveLinks, INewLink } from '@/src/modules/CampaignsModule/interfaces/Beefree';

import Symbols, { CharSymbol } from './symbols';

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const replaceSymbols = (template: string) => {
  Symbols.forEach((item: CharSymbol) => {
    const symbolRegex = escapeRegExp(item.symbol);
    const regex = new RegExp(symbolRegex, 'g');
    template = template.replace(regex, item.code);
  });

  return template;
};

export const sanitizeHtml = (html: string) => {
  // eslint-disable-next-line no-misleading-character-class
  // cspell:disable
  return html
    .replaceAll(/&#x200c;/g, ' ')
    .replaceAll(/&#x2014;/g, ' ')
    .replaceAll(/&#x2039;/g, ' ')
    .replaceAll('&#x200b;', ' ')
    .replaceAll('&#x200B;', ' ')
    .replaceAll(/&#x200b;/g, ' ')
    .replaceAll(/&#x200B;/g, ' ')
    .replaceAll(/&#8203;/g, ' ')
    .replaceAll('\\u200B', '&#xA0;')
    .replaceAll(/\u200b/g, '&#xA0;')
    .replaceAll(/\u200B/g, '&#xA0;')
    .replaceAll(/&#8202;/g, ' ')
    .replaceAll('&#x200a;', ' ')
    .replaceAll('&#x200A;', ' ')
    .replaceAll(/&#x200a;/g, ' ')
    .replaceAll(/&#x200A;/g, ' ')
    .replaceAll(/&#8204;/g, ' ')
    .replaceAll(/&#8205;/g, ' ')
    .replaceAll(/&#8239;/g, ' ')
    .replaceAll(/&hairsp;/g, ' ')
    .replaceAll('&ZeroWidthSpace;', ' ')
    .replaceAll(/&ZeroWidthSpace;/g, ' ')
    .replaceAll(/&ThinSpace;/g, ' ')
    .replaceAll(/&NoBreakSpace;/g, ' ')
    .replaceAll(/&NoBreak;/g, ' ')
    .replaceAll(/&nbsp;/g, ' ')
    .replaceAll('&nbsp;', ' ')
    .replaceAll(/&FilledSmallSquare;/g, '') // ■
    .replaceAll(/&EmptySmallSquare;/g, '') // □
    .replaceAll(/&FilledMediumSquare;/g, '&#x25FC;') // ◼
    .replaceAll(/&EmptyMediumSquare;/g, '&#x25FB;') // ◻
    .replaceAll(/&FilledLargeSquare;/g, '&#x25FE;') // ◾
    .replaceAll(/&EmptyLargeSquare;/g, '&#x25FD;') // ◽
    .replaceAll(/&nearr;/g, '') // ↗
    .replaceAll(/&searr;/g, '') // ↘
    .replaceAll(/&swarr;/g, '') // ↙
    .replaceAll(/&nwarr;/g, '') // ↖
    .replaceAll(/&varr;/g, '') // ↕
    .replaceAll(/&rarrhk;/g, '') // ↪
    .replaceAll(/&larrhk;/g, '') // ↩
    .replaceAll(/&cudarrr;/g, '') // ⤵
    .replaceAll(/&squf;/g, '') // ▪
    .replaceAll(/&EmptyVerySmallSquare;/g, '') // ▫
    .replaceAll(/&EmptySmallSquare;/g, '') // □
    .replaceAll(/&phone;/g, '&#9742;')
    .replaceAll('&#x2060;', '')
    .replaceAll(/&#x2060;/g, '')
    .replaceAll(/&#160;/g, ' ')
    .replaceAll(/&#xA0;/gi, ' ')
    .replaceAll(/\u202F/g, ' ')
    .replaceAll(/\u2000/g, ' ')
    .replaceAll(/\u2001/g, ' ')
    .replaceAll(/\u2002/g, ' ')
    .replaceAll(/\u2003/g, ' ')
    .replaceAll(/\u2004/g, ' ')
    .replaceAll(/\u2005/g, ' ')
    .replaceAll(/\u2006/g, ' ')
    .replaceAll(/\u2007/g, ' ')
    .replaceAll(/\u2008/g, ' ')
    .replaceAll(/\u2009/g, ' ')
    .replaceAll(/\u200A/g, ' ')
    .replaceAll(/\u200B/g, ' ')
    .replaceAll(/\u200C/g, ' ')
    .replaceAll(/\u200D/g, ' ')
    .replaceAll(/\u200E/g, ' ')
    .replaceAll(/\u200F/g, ' ')
    .replaceAll(/\u202F/g, ' ')
    .replaceAll(/\uFEFF/g, ' ')
    .replaceAll(/\uFEFF/g, ' ');
  // cspell:enable
};

export const replaceCustomFields = (template: string, customFields: ICustomField[]): string => {
  if (typeof template !== 'string') return '';
  if (!Array.isArray(customFields) || customFields.length === 0) return template;

  const data: string[] = [];
  const dictionary: { [key: string]: ICustomField } = {};
  let result = template.replaceAll(/@\{([^}]+)\}/g, function (match, customField: string) {
    customField !== 'none' && data.push(customField);
    return match;
  });

  customFields.forEach((item) => {
    if (!data.includes(item.name)) return;

    dictionary[item.name] = item;

    result = result.replaceAll(`@{${item.name}}`, function () {
      return '${' + item.number?.toString() + '#' + item.name + '}';
    });
  });

  return result;
};

export const replaceFeaturesInComments = (template: string) => {
  // eslint-disable-next-line regexp/no-unused-capturing-group
  const customFieldsRegex = /custom-fields="([^"]+)"/g;
  // eslint-disable-next-line regexp/no-unused-capturing-group
  const tagRegex = /tag="([^"]+)"/g;
  // eslint-disable-next-line regexp/no-unused-capturing-group
  const hrefRegex = /href="([^"]*)"/g;
  // eslint-disable-next-line regexp/no-unused-capturing-group
  const gbInterestRegex = /gb-interest="([^"]*)"/g;

  return template
    .replaceAll(hrefRegex, '')
    .replaceAll(customFieldsRegex, '')
    .replaceAll(tagRegex, '')
    .replaceAll(gbInterestRegex, '');
};
export const replaceTags = (template: string, tags: ITag[]) => {
  const regex = /tag="([^"]*)"/g;

  return template.replace(regex, function (match, currentValue: string) {
    if (currentValue === 'none') return '';

    const currentTags = currentValue.split(',');

    const filtered = tags.filter((tag) => {
      return currentTags.includes(tag.name);
    });

    const result = currentTags.map((item: string) => {
      const selected = filtered.find((tag) => tag.name === item);
      return selected?.id || '0';
    });

    if (result.length > 0) {
      return `gb-interest="${result.join(',')}"`;
    }

    return match;
  });
};

export const replaceLinkCustomFields = (template: string, customFields: ICustomField[]) => {
  if (typeof template !== 'string') return '';
  if (!Array.isArray(customFields) || customFields.length === 0) return template;

  const parser = new DOMParser();
  const doc = parser.parseFromString(template, 'text/html');

  const customFieldsRegex = /custom-fields="([^"]+)"/g;
  const linksWithCustomFields = doc.querySelectorAll('a[custom-fields]');

  linksWithCustomFields.forEach((link) => {
    const matches = Array.from(link.outerHTML.matchAll(customFieldsRegex))[0];
    if (matches && matches[1] && matches[1] !== 'none') {
      const customFieldValue = matches[1];
      const matchingObject = customFields.find((obj) => obj.name === customFieldValue);

      if (matchingObject) {
        const oldLink = link.outerHTML;

        const number = matchingObject.number.toString();
        const href = link.getAttribute('href') || '';
        link.removeAttribute('custom-fields');
        link.setAttribute('href', href + '${' + number + '#' + customFieldValue + '}');

        template = template.replace(oldLink, link.outerHTML);
      }
    }
  });

  return template;
};

export const replaceSignature = (template: string, signatures: ISpecialLink[]) => {
  if (typeof template !== 'string') return '';
  if (!Array.isArray(signatures) || signatures.length === 0) return template;

  const parser = new DOMParser();
  const doc = parser.parseFromString(template, 'text/html');

  const signatureRegex = /signature="([^"]*)"/g;
  const linksWithSignature = doc.querySelectorAll('a[signature]');

  linksWithSignature.forEach((link) => {
    const matches = Array.from(link.outerHTML.matchAll(signatureRegex))[0];
    if (matches && matches[1] && matches[1] !== 'none') {
      const signatureValue = matches[1];
      const matchingObject = signatures.find((obj) => obj.label === signatureValue);

      if (matchingObject) {
        const oldSignature = link.outerHTML;
        const href = link.getAttribute('href') || '';
        link.removeAttribute('signature');
        link.setAttribute('href', `${href}${matchingObject.link}`);
        template = template.replace(oldSignature, link.outerHTML);
      }
    }
  });

  return template;
};
export const replaceSignatureComment = (template: string, signatures: ISpecialLink[]) => {
  const signatureRegex = /signature="([^"]*)"/g;
  const hrefRegex = /href="([^"]*)"/g;

  let match: (string | RegExp)[] | null;
  while ((match = signatureRegex.exec(template)) !== null) {
    const matchingObject = signatures.find((obj) => match !== null && obj.label === match[1]);

    if (matchingObject) {
      template = template.replace(match[0], '');
      const hrefMatch = hrefRegex.exec(template);
      if (!hrefMatch) continue;

      const url = normalizeUrl(hrefMatch[1]);

      template = template.replace(hrefMatch[1], `${url}${matchingObject.link}`);
    }
  }

  return template;
};
// cspell:disable
export interface ILinkTemplateV1 {
  id: string;
  nombre: string;
  url: string;
  tags: string[];
}

export const getLinks = (template: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(template, 'text/html');
  const result: ILinkTemplateV1[] = [];
  const customFieldsRegex = /href="([^"]+)"/g;
  const linksWithCustomFields = doc.querySelectorAll('a[href]');

  linksWithCustomFields.forEach((link) => {
    const matches = Array.from(link.outerHTML.matchAll(customFieldsRegex))[0];
    if (matches && matches[1] && !['none', '#'].includes(matches[1])) {
      const matchingObject = matches[1];
      const url = normalizeUrl(matchingObject).replaceAll('&amp;', '&');
      if (matchingObject) {
        result.push({
          id: `template_${result.length}`,
          nombre: url,
          url: url,
          tags:
            typeof link?.getAttribute('gb-interest') === 'string'
              ? link.getAttribute('gb-interest')?.split(',') ?? []
              : [],
        });
      }
    }
  });

  return result;
};

const replaceWhiteSpace = (template: string) => {
  const newlineRegex = /(?:\\n|\n|\r)+/g;
  const spaceRegex = /\s{2,}/g;

  return template
    .replace(newlineRegex, ' ')
    .replace(spaceRegex, ' ')
    .replace(/&nbsp;/g, ' ');
};
export const replaceLinksDatabase = (template: string, dbLinks: INewLink[]) => {
  // eslint-disable-next-line regexp/no-super-linear-backtracking
  const aRegex = /<a\s+(?:[^>]*?\s)?href=["']([^"']+)["'][^>]*>.*?<\/a>/gi;

  return replaceWhiteSpace(template).replace(aRegex, function (match, href: string) {
    if (['#', 'none'].indexOf(href) > -1) return match;

    const matchingUrl = normalizeUrl(href).replaceAll(/&amp;/g, '&');
    const linkIndex = dbLinks.findIndex((item) => item.url === matchingUrl);

    if (linkIndex === -1) {
      // eslint-disable-next-line no-console
      console.warn('[Beefree/TemplateAdapter] link not found: ' + matchingUrl);
      return match;
    }

    const linkDb = dbLinks.splice(linkIndex, 1)[0];

    const parser = new DOMParser();
    const doc = parser.parseFromString(match, 'text/html');

    const link = doc.querySelector('[href="' + href + '"]');

    if (linkDb && link) {
      const ids: string = linkDb?.linkId?.toString() ?? '';

      link.setAttribute('id', ids);

      const newLink = link.outerHTML
        .replaceAll(/&amp;/g, '&')
        .replace(href, `##${normalizeUrl(href).replaceAll(/&amp;/g, '&')},${ids}##`);

      return newLink;
    }
    return match;
  });
};

// cspell:enable

type IOnSaveLink = (links: ILinkTemplateV1[]) => Promise<IBeefreeSaveLinks>;
type IOnErrorLink = (links: ILinkTemplateV1[]) => Promise<void>;

interface ISaveTemplateLink {
  links: IBeefreeSaveLinks | null;
  result: string;
}
const mergeLinks = (originalLinks: ILinkTemplateV1[], linksDatabase: IBeefreeSaveLinks) => {
  const { newLinks = [], LinksTemporal = [] } = linksDatabase;
  const dbLinks = newLinks.length > 0 ? newLinks.slice() : LinksTemporal.slice();
  const result: INewLink[] = [];

  for (let i = 0; i < originalLinks.length; i++) {
    const originLink = originalLinks[i];
    const linkIndex = dbLinks.findIndex((item) => item.url === originLink.url);

    const linkDb = dbLinks.splice(linkIndex, 1)[0];

    linkDb && result.push(linkDb);
  }

  return result;
};

const saveTemplateLinks = async (
  template: string,
  onSaveLinks: IOnSaveLink,
  onErrorLinks: IOnErrorLink
): Promise<ISaveTemplateLink> => {
  let links;
  try {
    links = getLinks(template);
    const response = await onSaveLinks(links);

    const mergedLinks = mergeLinks(links, response);

    return {
      links: response,
      result: replaceLinksDatabase(template, mergedLinks),
    };
  } catch (error) {
    console.error(error);
    links && void onErrorLinks(links);
    return {
      result: template,
      links: null,
    };
  }
};

interface IOptions {
  listCustomField: ICustomField[];
  tags: ITag[];
  signature: ISpecialLink[];
  onSaveLinks: IOnSaveLink;
  onErrorLinks: IOnErrorLink;
}

export const emojiToHex = (emoji: string) => {
  if (!emoji) return emoji;
  const hex = emoji.codePointAt(0)?.toString(16);
  return hex;
};

// eslint-disable-next-line prefer-regex-literals
const emojiRegex = EmojiRegex();

export function replaceEmojis(template: string): string {
  const replaced = template.replace(emojiRegex, (match) => {
    const emojiData = emojis[match as keyof typeof emojis];
    if (emojiData === '' || !emojiData) {
      return '';
    }

    if (emojiData) {
      return `&#x${emojiData.replace(/-/g, ';&#x')};`;
    }
    return match;
  });
  return replaced;
}
export function searchComments(element: HTMLElement, callback: (comment: string) => void): string {
  let html = new XMLSerializer().serializeToString(element);
  const childNodes = element.childNodes;
  for (let i = 0; i < childNodes.length; i++) {
    const child = childNodes[i];
    if (child.nodeType === 8) {
      // 8 is COMMENT_NODE
      child.nodeValue && callback(child.nodeValue);
      html += searchComments(child as HTMLElement, callback);
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      html += searchComments(child as HTMLElement, callback);
    }
  }
  return html;
}

function normalizeUrl(url: string) {
  const verify = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/.test(url);
  const validate = (startsWith: string) => {
    return !url.startsWith(startsWith);
  };

  if (verify && validate('mailto:')) return `mailto:${url}`;
  else if (
    validate('$') &&
    validate('tel:') &&
    validate('sms:') &&
    validate('ftp:') &&
    validate('http:') &&
    validate('https:') &&
    validate('mailto:') &&
    validate('{')
  )
    return `https://${url}`;

  return url;
}
export const replaceComments = (template: string) => {
  const parser = new DOMParser();

  const doc = parser.parseFromString(template, 'text/html');

  searchComments(doc.documentElement, (comment: string) => {
    if (
      comment.includes('signature=') ||
      comment.includes('custom-fields=') ||
      comment.includes('href=') ||
      comment.includes('custom-fields=')
    )
      template = template.replace(comment, replaceFeaturesInComments(comment));
  });

  return template;
};

const adapter = async (template: string, json: string, options: IOptions) => {
  try {
    const { signature, tags } = options;
    let result = sanitizeHtml(template);

    result = replaceCustomFields(result, options.listCustomField);
    result = replaceTags(result, tags);
    result = replaceLinkCustomFields(result, options.listCustomField);
    result = replaceSignature(result, signature);

    const savedLink = await saveTemplateLinks(result, options.onSaveLinks, options.onErrorLinks);

    result = replaceEmojis(savedLink.result);
    result = replaceComments(result);
    result = replaceSymbols(result);

    return {
      template: sanitizeHtml(result),
      json: replaceEmojis(json),
    };
  } catch (error) {
    console.error(error);
    return {
      template,
      json,
    };
  }
};

export default adapter;
