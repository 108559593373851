import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton } from '@/lib/components';
import { Card, CardSection } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import Text from '@/lib/v2/components/Text/Text';
import { UploadArrowIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  ImportRecentProps,
  ImportRecentTable,
} from '@/src/ContactsModule/components/ImportRecentTable/ImportRecentTable';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { ImportSteps } from '@/src/modules/ContactsModule/screens/ImportMain/ImportStepEnum.enum';

import {
  atomFirstStepInternal,
  atomReadyToStartImport,
} from '@/modules/ContactsModule/atoms/Step1';
import { atomFileName, atomFiles } from '@/modules/ContactsModule/atoms/Steps';
import { SectionHeader } from '@/modules/ContactsModule/components';
import { useResetImport } from '@/modules/ContactsModule/hooks/useResetImport';

const RecentFileTable = () => {
  const [files] = useAtom(atomFiles);
  const [importRecentFiles] = useEmblue(ServiceMethods.importRecent);
  const { t } = useTranslation();
  const { resetImport } = useResetImport();
  const [, setFileName] = useAtom(atomFileName);
  const [, setReadyToStartImport] = useAtom(atomReadyToStartImport);
  const [internalStep] = useAtom(atomFirstStepInternal);

  const onRowClick = useCallback(
    (rowSelected: ImportRecentProps) => {
      resetImport();
      setFileName(rowSelected.file);
      setReadyToStartImport(true);
    },
    [resetImport, setFileName, setReadyToStartImport]
  );

  if (files?.step !== ImportSteps.import || internalStep !== 'readyToSelectFile') return null;

  return (
    <Card className="mt-6">
      <CardSection noPadding>
        <SectionHeader
          withoutDivide
          icon={<UploadArrowIcon />}
          title={
            <Text fontWeight="medium" variant="title-1">
              {t('IMPORT_MAIN.importRecent')}
            </Text>
          }
        />
        <Flex column withGap alignment="start" className="px-4 pt-4" gapSize="medium">
          {importRecentFiles ? (
            <ImportRecentTable importRecentList={importRecentFiles} onRowClick={onRowClick} />
          ) : (
            <TableSkeleton columns={3} />
          )}
        </Flex>
      </CardSection>
    </Card>
  );
};

export default memo(RecentFileTable);
