import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import { formValidations } from '@/src/constants/FormValidations';

import { atomDetailCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  MIN_DATE,
  TRANSLATE_MODAL,
} from '@/modules/CampaignsModule/components/CampaignsModal/CreateEditDuplicateCampaigns/constants';
import { REGEX_OTHER_STRATEGY } from '@/modules/CampaignsModule/constants';
import { CreateCampaignForm } from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IDefaultFormToCompareProps {
  strategiesList: DropdownItemProps[];
}

export const useCampaignForm = ({ strategiesList }: IDefaultFormToCompareProps) => {
  const { t } = useTranslation();
  const { formRegExp, inputLengths } = formValidations;
  const detailCampaign = useAtomValue(atomDetailCampaign);

  const maxDate = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 2);
    return date;
  }, []);

  const minDateEmptyDefault = useMemo(() => new Date(MIN_DATE), []);

  const isUserStrategy = useMemo(() => {
    return strategiesList.find((strategy) => strategy.label === detailCampaign?.strategyName);
  }, [strategiesList, detailCampaign]);

  const VALIDATION_RULES: { [K in keyof CreateCampaignForm]: UseControllerProps['rules'] } = {
    name: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
      },
    },
    objective: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.maxObjective,
        message: t('FORMS_RULES_MESSAGES.maxObjectiveLength'),
      },
      pattern: {
        value: formRegExp.lettersNumbersAndSpecials,
        message: t(`${TRANSLATE_MODAL}.INPUTS_ERROR.pattern`),
      },
    },
    strategy: {
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
    },
    otherStrategy: {
      pattern: {
        value: REGEX_OTHER_STRATEGY,
        message: t(`${TRANSLATE_MODAL}.INPUTS_ERROR.pattern`),
      },
      required: {
        value: true,
        message: t('FORMS_RULES_MESSAGES.required'),
      },
      minLength: {
        value: inputLengths.min,
        message: t('FORMS_RULES_MESSAGES.minLength'),
      },
      maxLength: {
        value: inputLengths.max,
        message: t('FORMS_RULES_MESSAGES.maxLength'),
      },
    },
    date: {
      validate: (value: Date[]) => {
        if (value && Array.isArray(value)) {
          const [startDate, endDate] = value as [Date | null, Date | null];
          if (!startDate || !endDate) return t(`${TRANSLATE_MODAL}.INPUTS_ERROR.dateRange`);
        }
      },
      required: {
        value: true,
        message: t(`${TRANSLATE_MODAL}.INPUTS_ERROR.required`),
      },
    },
  };

  return {
    maxDate,
    minDateEmptyDefault,
    isUserStrategy,
    VALIDATION_RULES,
  };
};
