import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { MASTER_ACTION } from '@/src/modules/CampaignsModule/constants';
import {
  EActionTypes,
  IFilterByTableActions,
  IHistorySearchAction,
  ITableCampaignActions,
  ITouchRulesResponse,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { IOrderByTable, IPager } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { ISenderData } from '@/src/presentation/types/interfaces/ISenderData.interface';

export const atomRecurrentActionsList = atomWithReset<ITableCampaignActions[] | undefined>(
  undefined
);
atomRecurrentActionsList.debugLabel = 'atomRecurrentActionsList';

export const atomActionMaster = atomWithReset<ITableCampaignActions | undefined>(undefined);
atomActionMaster.debugLabel = 'atomActionMaster';

export const atomSenderList = atomWithReset<ISenderData[] | undefined>(undefined);
atomSenderList.debugLabel = 'atomSenderList';

export const atomRecipientsList = atomWithReset<{ aaData: [] }>({ aaData: [] });
atomRecipientsList.debugLabel = 'atomRecipientsList';

export const atomTouchRules = atomWithReset<ITouchRulesResponse>({} as ITouchRulesResponse);
atomTouchRules.debugLabel = 'atomTouchRules';

export const atomSelectedRowsAction = atom<number[]>([]);
atomSelectedRowsAction.debugLabel = 'atomSelectedRowsAction';

export const atomTotalActionsSelectedRows = atom<number>((get) => {
  const selectedRows = get(atomSelectedRowsAction);
  return selectedRows.length || 0;
});
atomTotalActionsSelectedRows.debugLabel = 'atomTotalActionsSelectedRows';

export const atomFiltersAction = atom<IFilterByTableActions | undefined>(undefined);
atomFiltersAction.debugLabel = 'atomFiltersAction';

export const atomPagerAction = atom<IPager | undefined>(undefined);
atomPagerAction.debugLabel = 'atomPagerAction';

export const atomOrderByAction = atom<IOrderByTable>({
  sortField: 'creationDate',
  sortOrder: 'desc',
});
atomOrderByAction.debugLabel = 'atomOrderByAction';

export const atomHistorySearchPagerActions = atomWithReset<IHistorySearchAction | undefined>(
  undefined
);
atomHistorySearchPagerActions.debugLabel = 'atomHistorySearchPagerActions';

export const atomActionType = atomWithReset<EActionTypes | undefined>(undefined);
atomActionType.debugLabel = 'atomActionType';

export const atomSelectedActionsRowsWithID = atom<number[]>((get) => {
  const selectedActionsRowsWithID = get(atomSelectedRowsAction)
    .map((index) => {
      const action = get(atomRecurrentActionsList)?.[index]?.action;
      if (action && action.channel !== MASTER_ACTION) {
        return action.actionId;
      }
    })
    .filter((actionId) => actionId !== undefined) as number[];

  return selectedActionsRowsWithID;
});
atomSelectedActionsRowsWithID.debugLabel = 'atomSelectedActionsRowsWithID';

export const atomActionDragDrop = atomWithReset<boolean>(false);
atomActionDragDrop.debugLabel = 'atomActionDragDrop';
