// cspell:disable-next-line
import { datadogRum } from '@datadog/browser-rum';
import BeePlugin from '@mailupinc/bee-plugin';
import {
  BeePluginError,
  BeePluginMessageEditDetail,
  IEntityContentJson,
  IPluginRow,
} from '@mailupinc/bee-plugin/dist/types/bee';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';

import { Environments, getEnv } from '@/src/application/hooks/util/useEnv';
import { SendActionsPostMessage } from '@/src/compat/sendActionsPostMessage';
import configData from '@/src/config.json';
import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { useBeefreeService } from '@/src/infrastructure/Protocol/Beefree/useBeefreeService';
import BeefreeEN from '@/src/locales/en/beefree.json';
import BeefreeES from '@/src/locales/es/beefree.json';
import BeefreePT from '@/src/locales/pt/beefree.json';
import TemplateAdapter, {
  ILinkTemplateV1,
} from '@/src/modules/CampaignsModule/adapters/TemplateAdapter';
import {
  atomBeefreeAuthorize,
  atomBeefreePreview,
  atomBeefreePristine,
  atomBeefreeSaved,
  atomBeefreeSaving,
  atomEditorMail,
  atomModalTemplate,
  atomTemplateDataSave,
  EditorMailData,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import {
  IAuthBeefree,
  IBeefreeRowType,
  ISaveRowError,
} from '@/src/modules/CampaignsModule/interfaces/Beefree';

import { useBeefreeLogger } from './useBeefreeLogger';
import useContentDialog from './useContentDialog';
import { ICustomField } from './useCustomFields';
import useRows from './useRows';

export interface ISpecialLink {
  type: string;
  label: string;
  link: string;
}

interface IRowSaveData {
  name: string;
  isEmblueRow: boolean;
}
interface IUseEditor {
  onSaveRow: (data: IRowSaveData, row: IPluginRow) => Promise<boolean>;
  onSaveMail: (jsonFile: string, htmlFile: string) => Promise<boolean>;
  onSend: () => void;
  onSaveTemplate: (pageJson: string, templateVersion: number) => void;
  onRemoveRow: (id: string) => Promise<void>;
  tags: ITag[] | null;
  companyId: number;
  listCustomField: ICustomField[];
  signature: ISpecialLink[];
}

const BeefreeDictionary = {
  es: {
    code: 'es-ES',
    translations: BeefreeES,
  },
  en: {
    code: 'en-US',
    translations: BeefreeEN,
  },
  pt: {
    code: 'pt-BR',
    translations: BeefreePT,
  },
};

type BeefreeLanguages = 'es' | 'en' | 'pt';

export const useEditor = ({
  onSaveRow,
  onSaveMail,
  onSend,
  onSaveTemplate,
  onRemoveRow,
  companyId,
  listCustomField,
  tags,
  signature,
}: IUseEditor) => {
  const ref = useRef<BeePlugin>();
  const { t, i18n } = useTranslation();
  const env = getEnv() as Exclude<Environments, 'storybook'>;
  const [initialized, setInitialized] = useAtom(atomBeefreeAuthorize);
  const [disabled, setDisabled] = useState(true);
  const [saved, setSaved] = useAtom(atomBeefreeSaved);
  const [pristine, setPristine] = useAtom(atomBeefreePristine);
  const setShowPreview = useSetAtom(atomBeefreePreview);
  const [mailContent, setMailContent] = useAtom(atomEditorMail);
  const { getRows, closeSaveRow } = useRows();
  const { loadModal } = useContentDialog();
  const { logger } = useBeefreeLogger();
  // cspell:disable
  const customFonts = useMemo(
    () => [
      {
        name: 'Calibri',
        fontFamily: "'Calibri', Arial, sans-serif",
      },
      {
        fontFamily: 'Cabin',
        name: 'Cabin',
        url: 'https://fonts.googleapis.com/css2?family=Cabin&display=swap',
      },
      {
        fontFamily: 'Oswald',
        name: 'Oswald',
        url: 'https://fonts.googleapis.com/css2?family=Oswald&display=swap',
      },
      {
        fontFamily: 'Carlito',
        name: 'Carlito',
        url: 'https://fonts.googleapis.com/css2?family=Carlito&display=swap',
      },
      {
        fontFamily: 'Roboto Slab',
        name: 'Roboto Slab',
        url: 'https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap',
      },
      {
        fontFamily: 'Playfair Display',
        name: 'Playfair Display',
        url: 'https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap',
      },
      {
        fontFamily: 'Poppins',
        name: 'Poppins',
        url: 'https://fonts.googleapis.com/css2?family=Poppins&display=swap',
      },
      {
        fontFamily: 'Cormorant Garamond',
        name: 'Cormorant Garamond',
        url: 'https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap',
      },
      {
        fontFamily: 'Questrial',
        name: 'Questrial',
        url: 'https://fonts.googleapis.com/css2?family=Questrial&display=swap',
      },
      {
        fontFamily: 'Shrikhand',
        name: 'Shrikhand',
        url: 'https://fonts.googleapis.com/css2?family=Shrikhand&display=swap',
      },
      {
        fontFamily: 'Impact',
        name: 'Impact',
        url: 'https://fonts.googleapis.com/css2?family=Impact&display=swap',
      },
      {
        fontFamily: 'Raleway',
        name: 'Raleway',
        url: 'https://fonts.googleapis.com/css2?family=Raleway&display=swap',
      },
      {
        fontFamily: 'Abril Fatface',
        name: 'Abril Fatface',
        url: 'https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap',
      },
      {
        fontFamily: 'Chivo',
        name: 'Chivo',
        url: 'https://fonts.googleapis.com/css2?family=Chivo&display=swap',
      },
      {
        fontFamily: 'Nunito',
        name: 'Nunito',
        url: 'https://fonts.googleapis.com/css2?family=Nunito&display=swap',
      },
      {
        fontFamily: 'Dosis',
        name: 'Dosis',
        url: 'https://fonts.googleapis.com/css2?family=Dosis&display=swap',
      },
      {
        fontFamily: 'Fira Sans',
        name: 'Fira Sans',
        url: 'https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap',
      },
      {
        fontFamily: 'Noto Serif',
        name: 'Noto Serif',
        url: 'https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap',
      },
      {
        fontFamily: 'Alegreya',
        name: 'Alegreya',
        url: 'https://fonts.googleapis.com/css2?family=Alegreya&display=swap',
      },
      {
        fontFamily: 'Permanent Marker',
        name: 'Permanent Marker',
        url: 'https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap',
      },
      {
        fontFamily: 'Libre Baskerville',
        name: 'Libre Baskerville',
        url: 'https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap',
      },
      {
        fontFamily: 'Quattrocento',
        name: 'Quattrocento',
        url: 'https://fonts.googleapis.com/css2?family=Quattrocento&display=swap',
      },
      {
        fontFamily: 'Varela Round',
        name: 'Varela Round',
        url: 'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap',
      },
      {
        fontFamily: 'Catamaran',
        name: 'Catamaran',
        url: 'https://fonts.googleapis.com/css2?family=Catamaran&display=swap',
      },
      {
        fontFamily: 'Oxygen',
        name: 'Oxygen',
        url: 'https://fonts.googleapis.com/css2?family=Oxygen&display=swap',
      },
      {
        fontFamily: 'Inter',
        name: 'Inter',
        url: 'https://fonts.googleapis.com/css2?family=Inter&display=swap',
      },
      {
        fontFamily: 'Rubik',
        name: 'Rubik',
        url: 'https://fonts.googleapis.com/css2?family=Rubik&display=swap',
      },
      {
        fontFamily: 'Lora',
        name: 'Lora',
        url: 'https://fonts.googleapis.com/css2?family=Lora&display=swap',
      },
      {
        fontFamily: 'Merriweather',
        name: 'Merriweather',
        url: 'https://fonts.googleapis.com/css2?family=Merriweather&display=swap',
      },
      {
        fontFamily: 'Bodoni Moda',
        name: 'Bodoni Moda',
        url: 'https://fonts.googleapis.com/css2?family=Bodoni+Moda&display=swap',
      },
      {
        fontFamily: 'Monda',
        name: 'Monda',
        url: 'https://fonts.googleapis.com/css2?family=Monda&display=swap',
      },
      {
        fontFamily: 'Arvo',
        name: 'Arvo',
        url: 'https://fonts.googleapis.com/css2?family=Arvo&display=swap',
      },
      {
        fontFamily: 'Dancing Script',
        name: 'Dancing Script',
        url: 'https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap',
      },
      {
        fontFamily: 'Palatino Linotype',
        name: 'Palatino Linotype',
        url: 'https://fonts.googleapis.com/css2?family=Palatino+Linotype&display=swap',
      },
      {
        fontFamily: 'Book Antiqua',
        name: 'Book Antiqua',
        url: 'https://fonts.googleapis.com/css2?family=Book+Antiqua&display=swap',
      },
      {
        fontFamily: 'Palatino',
        name: 'Palatino',
        url: 'https://fonts.googleapis.com/css2?family=Palatino&display=swap',
      },
    ],
    []
  );
  // cspell:enable

  const handleOnChange = useCallback(
    (_json: string, _detail: BeePluginMessageEditDetail, _version: number) => {
      if (!pristine) {
        setPristine(true);
        setSaved(false);
      }
    },
    [pristine, setPristine, setSaved]
  );

  const mergeTags = useMemo(() => {
    return listCustomField?.map((item) => {
      const { name, emblueField } = item;
      const slug = `@{${name}}`;
      const previewValue = emblueField
        ? t(`EDITOR_CONTENT.DEFAULT_FIELDS_VALUES.${name.toLocaleLowerCase().replaceAll(' ', '_')}`)
        : '';
      return {
        name: name, // Name in view
        value: slug, // Value in html
        previewValue: previewValue, // Preview toggle merge tag
      };
    });
  }, [listCustomField, t]);

  const specialLinks: ISpecialLink[] = useMemo(() => {
    const result: ISpecialLink[] = [];

    Array.isArray(mergeTags) &&
      listCustomField.forEach((item) => {
        result.push({
          type: 'Custom Fields',
          label: item.name,
          link: '${' + item.number.toString() + '#' + item.name + '}',
        });
      });

    Array.isArray(signature) &&
      signature?.forEach((item) => {
        result.push({
          label: item.label,
          type: 'Signature',
          link: item.link,
        });
      });

    return result;
  }, [listCustomField, mergeTags, signature]);

  const handleTogglePreview = useCallback(
    (status: boolean) => {
      setShowPreview(status);
    },
    [setShowPreview]
  );

  const handleGetRows = useCallback(
    async (
      resolve: (item: IPluginRow[]) => void,
      _reject: () => void,
      args: IPluginRow[]
    ): Promise<void> => {
      if (!('handle' in args)) return;
      const handle = args.handle as string;
      const rows = await getRows(handle);
      rows && resolve(rows);
    },
    [getRows]
  );

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleOnSaveRow = async (): Promise<void> => {
    closeSaveRow();
  };

  const handleSaveRow = async (
    resolve: (item: { name: string; guid: string }, extra: { synced: boolean }) => void,
    reject: () => void,
    row: IPluginRow
  ): Promise<void> => {
    // Save New Row
    const modalResult = await loadModal();
    const { name = '', synced = false, isEmblueRow = false } = modalResult;
    const response = await onSaveRow(
      { name, isEmblueRow },
      {
        ...row,
        metadata: {
          ...(row.metadata ?? {}),
          name,
        },
      }
    );

    if (response && name) {
      const guid = crypto.randomUUID();
      await logger('saveRow', 'action', {
        name,
        guid,
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      resolve({ name, guid }, { synced });
    } else {
      await logger('saveRow', 'error', {
        name,
        row,
      });
      reject();
    }
  };

  const handleLoaded = useCallback(() => {
    setDisabled(false);
  }, []);

  const getToken = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clientId: string = configData?.endpoints?.[env]?.BEEFREE_CLIENT_ID;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clientSecret: string = configData?.endpoints?.[env]?.BEEFREE_CLIENT_SECRET;
    const payload: IAuthBeefree = {
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: 'password',
    };

    await ref?.current?.getToken(payload.client_id, payload.client_secret);
  }, [env]);

  const handleOnError = useCallback(
    async (error: BeePluginError) => {
      const { code, detail, message, data } = error;

      await logger('onError', 'error', {
        code,
        detail,
        message,
        data,
      });

      if (code === 5101) {
        void getToken();
      } else if (code === 5102 && mailContent) {
        void getToken();
        void start(mailContent?.contentHtmlEditable);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getToken, mailContent]
  );

  useEffect(() => {
    return () => {
      setShowPreview(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DEFAULT_CONFIGURATION = useMemo(() => {
    const defaultOption = ['none'];

    return {
      uid: companyId.toString(),
      container: 'bee-plugin-container',
      language: BeefreeDictionary[i18n.language as BeefreeLanguages].code,
      enable_display_conditions: true,
      preventClose: true,
      trackChanges: true,
      saveRows: true,
      translations: BeefreeDictionary[i18n.language as BeefreeLanguages].translations,
      loadingSpinnerDisableOnSave: false,
      loadingSpinnerDisableOnDialog: true,
      editorFonts: {
        showDefaultFonts: true,
        customFonts: customFonts,
      },

      rowsConfiguration: {
        emptyRows: true,
        defaultRows: false,
        externalContentURLs: [
          {
            name: t('EDITOR_CONTENT.ROWS.byDefault'),
            value: 'byDefault',
            handle: 'byDefault',
            isLocal: true,
            behaviors: {
              canEdit: false,
              canDelete: false,
              canDeleteSyncedRows: false,
              canEditSyncedRows: false,
            },
          },
          {
            name: t('EDITOR_CONTENT.ROWS.savedUser'),
            value: 'saved-user',
            handle: 'saved-user',
            isLocal: true,
            behaviors: {
              canEdit: false,
              canDelete: true,
              canDeleteSyncedRows: false,
              canEditSyncedRows: false,
            },
          },
        ],
      },
      customAttributes: {
        attributes: [
          {
            key: 'custom-fields',
            value: defaultOption.concat(mergeTags?.map(({ name }) => name) ?? []),
            target: 'link',
          },
          {
            key: 'tag',
            value: defaultOption.concat(tags?.map((tag) => tag.name) ?? []),
            target: 'link',
          },
          {
            key: 'signature',
            value: defaultOption.concat(signature?.map(({ label }) => label) ?? []),
            target: 'link',
          },
        ],
      },
      onSave: onSaveMail,
      onSend: onSend,
      onLoad: handleLoaded,
      onSaveAsTemplate: onSaveTemplate,
      onError: handleOnError,
      onTogglePreview: handleTogglePreview,
      onSaveRow: handleOnSaveRow,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyId,
    handleTogglePreview,
    i18n,
    mergeTags,
    onSaveMail,
    onSaveTemplate,
    onSend,
    signature,
    tags,
  ]);
  const mergeContents = useMemo(() => {
    return signature.map((item) => {
      const name = item.label.substring(1, item.label.length - 1);
      return {
        name: name,
        value: '{' + name + '}',
      };
    });
  }, [signature]);

  const start = useCallback(
    async (htmlJson: object | IEntityContentJson) => {
      if (!ref.current || !Array.isArray(tags)) {
        setTimeout(() => {
          void start(htmlJson);
        }, 1000);
        return;
      }
      await logger('viewAction', 'action');
      void ref.current.start(
        {
          ...DEFAULT_CONFIGURATION,
          ...{ mergeTags },
          specialLinks,
          mergeContents,
          contentDialog: {
            onDeleteRow: {
              handler: async (resolve, reject, args) => {
                try {
                  const { row } = args as { row: IPluginRow };
                  const rowId: string = (row?.metadata?.guid as string) ?? '';
                  await onRemoveRow(rowId);
                  resolve(true);
                } catch (error) {
                  reject();
                }
              },
            },
            saveRow: {
              handler: handleSaveRow,
            },
          },
          onChange: handleOnChange,
          hooks: {
            getRows: {
              handler: handleGetRows,
            },
          },
        },
        htmlJson
      );

      setTimeout(() => {
        if (document.querySelector('#bee-plugin-container')?.innerHTML !== '') return;
        void start(htmlJson);
      }, 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags, DEFAULT_CONFIGURATION, mergeTags, specialLinks, mergeContents]
  );
  const initialize = useCallback(async () => {
    if (ref.current) return;

    ref.current = new BeePlugin();

    await getToken();
  }, [getToken]);

  useEffect(() => {
    const notHaveMergeTags = !Array.isArray(mergeTags) || mergeTags.length === 0;
    const notHaveSpecialLink = !Array.isArray(specialLinks) || specialLinks.length === 0;
    const notHaveMergeContents = !Array.isArray(mergeContents) || mergeContents.length === 0;
    const notHaveMailContent = mailContent === null;

    if (notHaveMergeTags || notHaveSpecialLink || notHaveMergeContents || notHaveMailContent)
      return;

    void start(mailContent?.contentHtmlEditable);
  }, [mailContent, mergeContents, mergeTags, specialLinks, start]);

  useEffect(() => {
    if (!initialized && mergeTags) {
      initialize()
        .then(() => {
          return setInitialized(true);
        })
        .catch(console.error);
    }
  }, [initialize, initialized, mergeTags, setInitialized, ref]);

  useEffect(() => {
    return () => {
      const editor = document.querySelector('#bee-plugin-container');
      setInitialized(false);
      setPristine(false);
      setMailContent(null);
      if (editor) editor.innerHTML = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    initialized,
    disabled,
    saved,
    beefree: ref.current,
  };
};

interface IUseServicesEditor {
  actionId: number;
  accountId: number;
  listCustomField: ICustomField[];
  tags: ITag[] | null;
  signature: ISpecialLink[];
}

export const useServicesEditor = ({
  actionId,
  accountId,
  listCustomField,
  tags,
  signature,
}: IUseServicesEditor) => {
  const { t } = useTranslation();
  const serviceBeefree = useBeefreeService();
  const [mailContent, setMailContent] = useAtom(atomEditorMail);
  const [, setShowModalTemplate] = useAtom(atomModalTemplate);
  const [, setTemplateDataSave] = useAtom(atomTemplateDataSave);
  const [, setBeefreeSaving] = useAtom(atomBeefreeSaving);
  const [, setBeefreeSaved] = useAtom(atomBeefreeSaved);
  const [, setPristine] = useAtom(atomBeefreePristine);
  const { logger } = useBeefreeLogger();

  const onSaveLinks = useCallback(
    async (links: ILinkTemplateV1[]) => {
      await logger('toSaveLinks', 'action', links);

      // cspell:disable
      const response = await serviceBeefree.saveTemplateLinks(actionId, [], links);

      if (response) {
        await logger('saveLinks', 'action', response);
      }

      // cspell:enable
      return response;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionId, serviceBeefree]
  );

  const onErrorLinks = useCallback(
    async (links: ILinkTemplateV1[]): Promise<void> => {
      await logger('saveLinks', 'error', {
        links,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSaveMail = useCallback(
    async (jsonFile: string, htmlFile: string): Promise<boolean> => {
      if (!Array.isArray(tags)) return false;

      const storageCreate = localStorage.getItem('@beefree/template.create');
      const createTemplate = storageCreate === 'true';

      await serviceBeefree.deleteCurrentLinks(actionId);

      const { template, json } = await TemplateAdapter(htmlFile, jsonFile, {
        listCustomField,
        tags,
        signature,
        onSaveLinks,
        onErrorLinks,
      });

      if (createTemplate) {
        setTemplateDataSave({
          jsonData: jsonFile,
          html: htmlFile,
        });
        return false;
      }

      const response = await serviceBeefree.saveEmail({
        contentCss: '',
        contentHtml: template,
        actionId: actionId,
        accountAutoId: accountId,
        contentHtmlEditable: json,
        defaultTemplateId: 1,
        type: 'beefree',
      });

      SendActionsPostMessage('Beefree', 'ClearActionCache');
      setBeefreeSaving(false);
      setPristine(false);
      setBeefreeSaved(true);

      if (response && !mailContent?.contentHtmlEditable) await logger('saveMail', 'action');
      if (!response) {
        await logger('saveMail', 'error', {
          jsonFile,
        });
        return false;
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tags,
      listCustomField,
      signature,
      onSaveLinks,
      onErrorLinks,
      serviceBeefree,
      actionId,
      accountId,
      setBeefreeSaving,
      setPristine,
      setTemplateDataSave,
    ]
  );

  const onSend = useCallback(() => {
    void serviceBeefree.sendEmail(actionId);
  }, [actionId, serviceBeefree]);

  const onSaveTemplate = useCallback(() => {
    setShowModalTemplate(true);
  }, [setShowModalTemplate]);

  const getEmailContent = useCallback(async () => {
    const response = await serviceBeefree.getEmail(actionId);

    try {
      response.contentHtmlEditable = JSON.parse(
        response.contentHtmlEditable as string
      ) as IEntityContentJson;
    } catch {
      response.contentHtmlEditable = {};
    }

    setMailContent(response as EditorMailData);
  }, [actionId, serviceBeefree, setMailContent]);

  const onSaveRow = useCallback(
    async (data: IRowSaveData, row: IPluginRow) => {
      const response = await serviceBeefree.saveRow({
        name: data.name,
        json: JSON.stringify(row),
        type: data.isEmblueRow ? IBeefreeRowType.EMBLUE : IBeefreeRowType.USER,
      });

      if (response?.error && response?.type === ISaveRowError.NAME_ALREADY_EXISTS) {
        toast({
          title: t('EDITOR_CONTENT.PREVIEW_LIVE.ERRORS.ALREADY_EXISTS.title'),
          body: t('EDITOR_CONTENT.PREVIEW_LIVE.ERRORS.ALREADY_EXISTS.body'),
          variant: 'error',
        });
        return false;
      } else if (!response?.error) {
        toast({
          title: t('EDITOR_CONTENT.PREVIEW_LIVE.SAVED.title'),
          body: t('EDITOR_CONTENT.PREVIEW_LIVE.SAVED.body'),
          variant: 'success',
        });
        return true;
      }
      return false;
    },
    [serviceBeefree, t]
  );

  const onRemoveRow = useCallback(
    async (guid: string) => {
      const response = await serviceBeefree.deleteRow(guid);
      if (response) {
        await logger('deleteRow', 'action', { guid });
      } else {
        await logger('deleteRow', 'error', { guid });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceBeefree]
  );

  useEffect(() => {
    if (mailContent === null) void getEmailContent();
  }, [getEmailContent, mailContent]);

  return {
    onSaveRow,
    onSaveMail,
    onSaveTemplate,
    onRemoveRow,
    mailContent,
    onSend,
  };
};
