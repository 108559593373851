/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import './i18next.config';

import { createStore, Provider } from 'jotai';
import React from 'react';
import { createRoot } from 'react-dom/client';

import ErrorBoundary from './application/components/ErrorBoundary';
import { getEnv } from './application/hooks/util/useEnv';
import { SOURCE_INBOX } from './modules/AuthModule/constants';
import { App } from './App';
import Auth from './Auth';
import configData from './config.json';

import '@/lib/app.css';
import './index.css';

const env = getEnv();

const { JWT_DEV_TOKEN } = configData.endpoints[env];
const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : window.__JWTToken;

const onsiteEnabled = window.__onsiteEnabled || false;

const appRoot = onsiteEnabled
  ? document.getElementById('root-onsite')
  : document.getElementById('root');
appRoot.setAttribute('notranslate', true);

const root = createRoot(appRoot);
const myStore = createStore();

const urlParams = new URLSearchParams(window.location.search);
const sourceParam = urlParams.get('source');

if (sourceParam && !localStorage.getItem('redirectInbox')) {
  localStorage.setItem('redirectInbox', true);
}

const redirectInboxValue = localStorage.getItem('redirectInbox');

if ((redirectInboxValue || sourceParam === SOURCE_INBOX) && jwtToken) {
  localStorage.removeItem('redirectInbox');

  const baseUrl = configData.endpoints[env === 'storybook' ? 'development' : env].URL_INBOX;
  const loginPath = '/emblue/login';
  const redirectUrl = `${String(baseUrl)}${loginPath}?token=${String(jwtToken)}`;

  window.location.href = redirectUrl;
} else {
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={myStore}>{!jwtToken ? <Auth /> : <App />}</Provider>
      </ErrorBoundary>
    </React.StrictMode>,
    appRoot
  );
}
