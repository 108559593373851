import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@/lib/v2/components';
import { Option } from '@/lib/v2/components/Select/Select';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { contactSystemActivityFieldsIDs } from '@/src/modules/ContactsModule/util/v2SegmentFrontAdapter';

import { useGetListValuesOptions } from './useGetListValuesOptions';

import {
  ActivityField,
  Field,
  FieldDataType,
  FieldsResponse,
  FieldType,
  IOptionFilter,
  SegmentFilterActivityType,
  SegmentFilterRelationType,
} from '@/modules/ContactsModule/types/Segments';

/* cspell:disable */
// TODO: missing translations

export const useGetListFiltersOptions = () => {
  const [filterOptions, setFilterOptions] = useState<IOptionFilter[]>([]);

  const [listFields] = useEmblue(ServiceMethods.getFieldsFilters);
  const {
    isLoading,
    userGroups,
    tags,
    channels,
    notAddressees,
    getLocalization,
    devices,
    socialNetwork,
    rank,
    gender,
    ecommerceGroups,
  } = useGetListValuesOptions();

  const { t } = useTranslation();

  const CONTACTS_OPTIONS_SEGMENTS_FILTER = useMemo<IOptionFilter[]>(
    () => [
      {
        id: 6,
        name: t('SEGMENTS_CONDITIONS.FILTERS.groups'),
        value: 'groups',
        group: t('SEGMENT_FILTER_GROUP.groups').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: true,
        optionsValues: userGroups,
      },
      {
        id: 8,
        name: t('SEGMENTS_CONDITIONS.FILTERS.tags'),
        value: 'tags',
        group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: true,
        daysSelect: true,
        optionsValues: tags,
      },
      {
        id: 24,
        name: t('SEGMENTS_CONDITIONS.FILTERS.items'),
        value: 'items',
        group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.TEXT_MULTIPLE,
        andSwitch: false,
        daysSelect: true,
      },
      {
        id: 20,
        name: t('SEGMENTS_CONDITIONS.FILTERS.geoLocation'),
        value: 'geoLocation',
        group: t('SEGMENT_FILTER_GROUP.contacts').toUpperCase(),
        groupValue: 'relation',
        dataType: 'COUNTRY',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: false,
        optionsValues: getLocalization,
      },
      {
        id: 18,
        name: t('SEGMENTS_CONDITIONS.FILTERS.rank'),
        value: 'rank',
        group: t('SEGMENT_FILTER_GROUP.groups').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: false,
        optionsValues: rank,
      },
      {
        id: 17,
        name: t('SEGMENTS_CONDITIONS.FILTERS.devices'),
        value: 'devices',
        group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: true,
        optionsValues: devices,
      },
      {
        id: 16,
        name: t('SEGMENTS_CONDITIONS.FILTERS.socialNetworks'),
        value: 'socialNetworks',
        group: t('SEGMENT_FILTER_GROUP.contacts').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: true,
        optionsValues: socialNetwork,
      },
      {
        id: 21,
        name: t('SEGMENTS_CONDITIONS.FILTERS.email'),
        value: 'email',
        group: t('SEGMENT_FILTER_GROUP.contacts').toUpperCase(),
        groupValue: 'relation',
        dataType: 'EMAIL',
        fieldType: FieldType.TEXT_MULTIPLE,
        andSwitch: false,
      },
      {
        id: 26,
        name: t('SEGMENTS_CONDITIONS.FILTERS.channels'),
        value: 'channels',
        group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: true,
        optionsValues: channels,
      },
      {
        id: 1,
        name: t('SEGMENTS_CONDITIONS.FILTERS.ecommerceGroups'),
        value: 'ecommerceGroups',
        group: t('SEGMENT_FILTER_GROUP.eCommerce').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: true,
        daysSelect: false,
        optionsValues: ecommerceGroups,
      },
      {
        id: 7,
        name: t('SEGMENTS_CONDITIONS.FILTERS.discardedGroup'),
        value: 'discardedGroup',
        group: t('SEGMENT_FILTER_GROUP.groups').toUpperCase(),
        groupValue: 'relation',
        dataType: 'ID',
        fieldType: FieldType.SELECT_MULTIPLE,
        andSwitch: true,
        optionsValues: notAddressees,
      },
    ],
    [
      channels,
      devices,
      ecommerceGroups,
      getLocalization,
      notAddressees,
      rank,
      socialNetwork,
      t,
      tags,
      userGroups,
    ]
  );

  const daysOptions: Option[] = useMemo(() => {
    return Array.from({ length: 90 }, (__, i) => ({ id: i + 1, name: i + 1, value: i + 1 }));
  }, []);

  const ACTIVITY_OPTIONS_SEGMENTS_FILTER: IOptionFilter[] = useMemo(
    () => [
      {
        id: 22,
        name: t('SEGMENTS_CONDITIONS.FILTERS.sendings'),
        value: 'sendings',
        group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
        groupValue: 'activity',
        dataType: 'ACTIVITY',
        fieldType: FieldType.SELECT,
        optionsValues: daysOptions,
      },
      {
        id: 10,
        name: t('SEGMENTS_CONDITIONS.FILTERS.opens'),
        value: 'opens',
        group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
        groupValue: 'activity',
        dataType: 'ACTIVITY',
        fieldType: FieldType.SELECT,
        optionsValues: daysOptions,
      },
      {
        id: 11,
        name: t('SEGMENTS_CONDITIONS.FILTERS.clicks'),
        value: 'clicks',
        group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
        groupValue: 'activity',
        dataType: 'ACTIVITY',
        fieldType: FieldType.SELECT,
        optionsValues: daysOptions,
      },
      {
        id: 27,
        name: t('SEGMENTS_CONDITIONS.FILTERS.bounces'),
        value: 'bounces',
        group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
        groupValue: 'activity',
        dataType: 'ACTIVITY',
        fieldType: FieldType.SELECT,
        optionsValues: daysOptions,
      },
    ],
    [daysOptions, t]
  );

  const mapFields = useCallback(
    (fields: FieldsResponse) => {
      const cpSystemFields: IOptionFilter[] = [];

      const conditionalOptionsByDataType: Record<string, FieldType> = {
        STRING: FieldType.TEXT_MULTIPLE,
        GENDER: FieldType.SELECT,
        COUNTRY: FieldType.SELECT_MULTIPLE,
        DATE: FieldType.DATE,
        NUMERIC: FieldType.TEXT_MULTIPLE,
        EMAIL: FieldType.TEXT_MULTIPLE,
        ACTIVITY: FieldType.SELECT,
        NUMBER: FieldType.TEXT_MULTIPLE,
        COMBO: FieldType.SELECT_MULTIPLE,
        RADIO: FieldType.SELECT_MULTIPLE,
      };

      const dynamicValuesOptionsByFilter: Record<string, Option[] | undefined> = {
        gender: gender,
        country: getLocalization,
      };

      const generateValuesOptionsMetadataDefaultValues = (
        defaultValues: string[],
        filterType?: string
      ): Option[] | undefined => {
        return defaultValues.map((value, index) => ({
          id: index,
          name:
            filterType === 'eCommerceClassification'
              ? t(`PROFILE_ECOMMERCE_TABLE.${value}`)
              : value,
          value,
        }));
      };

      Object.entries(fields).forEach(([key, fieldValue]) => {
        if (key === 'activityFields') {
          (fieldValue as ActivityField[]).forEach((field) => {
            const { name, type, value } = field;
            if (name !== 'lastSend')
              cpSystemFields.push({
                id: contactSystemActivityFieldsIDs[name],
                name: t(`CONTACT_INFORMATION.${name}`),
                value: value as SegmentFilterActivityType,
                group: t('SEGMENT_FILTER_GROUP.activity').toUpperCase(),
                groupValue: 'relation',
                dataType: type.toUpperCase() as FieldDataType,
                fieldType: conditionalOptionsByDataType[type.toUpperCase()],
              });
          });
        } else {
          (fieldValue as Field[]).forEach((field) => {
            const { id, name, type, value } = field;
            let groupDefaultFields;
            if (name === 'creationDate') {
              groupDefaultFields = t('SEGMENT_FILTER_GROUP.activity').toUpperCase();
            } else if (name === 'nps') {
              groupDefaultFields = t('SEGMENT_FILTER_GROUP.nps').toUpperCase();
            } else if (
              name === 'eCommerceClassification' ||
              name === 'frequency' ||
              name === 'amount' ||
              name === 'recency'
            ) {
              groupDefaultFields = t('SEGMENT_FILTER_GROUP.eCommerce').toUpperCase();
            } else {
              groupDefaultFields = t('SEGMENT_FILTER_GROUP.contacts').toUpperCase();
            }

            cpSystemFields.push({
              id,
              name: key === 'defaultFields' ? t(`CONTACT_INFORMATION.${name}`) : name,
              value: value as SegmentFilterRelationType,
              group:
                key === 'defaultFields'
                  ? groupDefaultFields
                  : t('SEGMENT_FILTER_GROUP.userCustom').toUpperCase(),
              groupValue: 'relation',
              dataType: type === 'number' ? 'NUMERIC' : (type.toUpperCase() as FieldDataType),
              fieldType:
                conditionalOptionsByDataType[
                  field.metadata.optionType !== null
                    ? field.metadata.optionType.toUpperCase()
                    : type.toUpperCase()
                ],
              andSwitch: false,
              optionsValues:
                dynamicValuesOptionsByFilter[name] ||
                generateValuesOptionsMetadataDefaultValues(
                  field.metadata.defaultValues ?? [],
                  name
                ),
              field: field,
            });
          });
        }
      });

      return cpSystemFields;
    },
    [gender, getLocalization, t]
  );

  useEffect(() => {
    if (isLoading || !listFields) return;
    let cpSystemOptions: IOptionFilter[] = [];
    if (listFields?.success) {
      cpSystemOptions = mapFields(listFields.data as FieldsResponse);
    } else
      toast({
        variant: 'error',
        title: t('SEGMENT_FILTERS.error'),
        body: listFields?.data as string,
        autoCloseDelay: 5000,
      });

    const contactInformation = [...CONTACTS_OPTIONS_SEGMENTS_FILTER, ...cpSystemOptions];

    const options: IOptionFilter[] = [...contactInformation, ...ACTIVITY_OPTIONS_SEGMENTS_FILTER];

    setFilterOptions(options);
  }, [
    ACTIVITY_OPTIONS_SEGMENTS_FILTER,
    CONTACTS_OPTIONS_SEGMENTS_FILTER,
    isLoading,
    listFields,
    mapFields,
    t,
  ]);

  return {
    isLoading: isLoading || !listFields || !filterOptions,
    filterOptions,
    setFilterOptions,
  };
};
