import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { openModal } from '@/lib/components/Modal/Modal';
import { RouteEnum } from '@/lib/components/TopBar/routeEnum';
import { PlusIcon } from '@/lib/icon';
import { Dropdown } from '@/lib/v2/components';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';
import { useShowModalSignal } from '@/lib/v2/hooks/useShowModalSignal';

import { useStateUserContext } from '@/src/application/context/UserContext';
import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import { SendActionsPostMessage } from '@/src/compat/sendActionsPostMessage';
import useMessage from '@/src/compat/useMessage';
import { CreateAndEditGroupModal } from '@/src/ContactsModule/components/ContactsModals/CreateAndEditGroupModal';
import { CreateAndEditTagModal } from '@/src/ContactsModule/components/ContactsModals/CreateAndEditTagModal';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue, useService } from '@/src/infrastructure/Protocol/useEmblue';

import { useMutationCreateSegmentModalContext } from '@/modules/ContactsModule/contexts/CreateSegmentModalContext';
import { CreateEmblueSegmentModal } from '@/modules/ContactsModule/screens/SegmentsMain/components/CreateEmblueSegment/CreateEmblueSegmentModal';

interface ContactsActionDropdownProps {
  setLastRefreshParent?: Dispatch<SetStateAction<number>>;
}

export const ContactsActionDropdown = ({ setLastRefreshParent }: ContactsActionDropdownProps) => {
  const { t } = useTranslation();
  const [tagsCategories] = useEmblue(ServiceMethods.getTagsCategories, {
    identifier: 'TAGS_FILTER_CATEGORY',
  });
  const navigate = useNavigate();

  const { canCreateGroups } = useStateUserContext();

  const [showCreateEmblueSegmentModal, setShowCreateEmblueSegmentModal] = useState<boolean>(false);
  const { setCreateSegmentModal } = useMutationCreateSegmentModalContext();

  const service = useService();
  const lastRefreshGroupsIncrement = useCallback(() => {
    service?.incrementLastRefreshGroupsValue();
  }, [service]);
  const lastRefreshTagsIncrement = useCallback(() => {
    service?.incrementLastRefreshTagsValue();
  }, [service]);
  const lastRefreshSegmentsIncrement = useCallback(() => {
    service?.incrementLastRefreshSegmentsValue();
  }, [service]);
  //Remove this when create and import contact V2 its on production
  useMessage(EventListenerType.RefreshSegments, () => {
    lastRefreshSegmentsIncrement();
  });

  const importContacts = useCallback(() => {
    navigate({ pathname: `/v2/${RouteEnum.ContactsImport}` });
  }, [navigate]);

  const listCreateAction: DropdownItemProps[] = useMemo(() => {
    return [
      {
        id: 'newContact',
        value: 'newContact',
        label: t('CONTACTS_ACTIONS_DROPDOWN.ContactNew'),
        onClick: () => {
          SendActionsPostMessage('CreateContact', 'ContactActionModal');
        },
      },
      {
        id: 'contactImport',
        value: 'contactImport',
        label: t('CONTACTS_ACTIONS_DROPDOWN.ContactImport'),
        onClick: () => importContacts(),
      },
      {
        id: 'createTag',
        value: 'createTag',
        label: t('CONTACTS_ACTIONS_DROPDOWN.Tag'),
        onClick: () => {
          //TO DO: change this openModal to new Modal component v2
          openModal({
            element: (
              <CreateAndEditTagModal
                lastRefreshIncrement={lastRefreshTagsIncrement}
                tagsCategories={tagsCategories?.list}
              />
            ),
          });
        },
      },
      {
        id: 'createGroup',
        value: 'createGroup',
        label: t('CONTACTS_ACTIONS_DROPDOWN.Group'),
        onClick: () => {
          //TO DO: change this openModal to new Modal component v2
          openModal({
            element: (
              <CreateAndEditGroupModal
                lastRefreshIncrement={lastRefreshGroupsIncrement}
                setLastRefreshParent={setLastRefreshParent}
              />
            ),
          });
        },
        disabled: !canCreateGroups,
      },
      {
        id: 'createSegment',
        value: 'createSegment',
        label: t('CONTACTS_ACTIONS_DROPDOWN.Segment'),
        onClick: () => {
          setShowCreateEmblueSegmentModal(true);
        },
      },
    ];
  }, [
    t,
    canCreateGroups,
    importContacts,
    lastRefreshTagsIncrement,
    tagsCategories?.list,
    lastRefreshGroupsIncrement,
    setLastRefreshParent,
  ]);

  useShowModalSignal('CreateContact', () =>
    SendActionsPostMessage('CreateContact', 'ContactActionModal')
  );

  return (
    <>
      <Dropdown
        menuOnTheLeft
        color="primary"
        dropdownItems={listCreateAction}
        icon={<PlusIcon />}
        id="create-button"
        label={t('CONTACTS_ACTIONS_DROPDOWN.Create')}
      />
      <CreateEmblueSegmentModal
        isShow={showCreateEmblueSegmentModal}
        lastRefreshIncrement={lastRefreshSegmentsIncrement}
        setCreateCustomSegment={setCreateSegmentModal}
        onClose={setShowCreateEmblueSegmentModal}
      />
    </>
  );
};
