import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import { TableSkeleton } from '@/lib/components';
import { TableBar } from '@/lib/components/TableBar';
import { Flex } from '@/lib/v2/components';

import { ActionsTable } from '@/src/modules/CampaignsModule/components/CampaignActions/ActionsTable';

import { ActionBarButtons } from './components/ActionBarButtons';
import { FilterBar } from './components/FilterBar';

import {
  atomActionsList,
  atomTotalActionsSelectedRows,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomTotalsProcess } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  DeleteActions,
  DuplicateActions,
  EditActions,
  MoveActions,
} from '@/modules/CampaignsModule/components/CampaignsModal';

export const TableContainer = () => {
  const actionsList = useAtomValue(atomActionsList);
  const totalSelectedRows = useAtomValue(atomTotalActionsSelectedRows);
  const totalsCampaignList = useAtomValue(atomTotalsProcess);

  //TODO: refactor in customHook
  const wrapperActionsTable = classNames({
    'mt-[-52px]': actionsList && actionsList?.length > 0,
  });

  return (
    <>
      <Flex column>
        {actionsList ? (
          <>
            <TableBar>
              {totalSelectedRows > 0 ? (
                <ActionBarButtons />
              ) : (
                <>{totalsCampaignList.total > 0 && <FilterBar />}</>
              )}
            </TableBar>
            <Flex column withGap className={wrapperActionsTable} itemAlignment="stretch">
              <ActionsTable />
            </Flex>
          </>
        ) : (
          <div className="size-full py-10">
            <TableSkeleton columns={6} />
          </div>
        )}
      </Flex>
      {<EditActions />}
      {<MoveActions />}
      {<DeleteActions />}
      {<DuplicateActions />}
    </>
  );
};
