import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Text } from '@/lib/v2/components';
import { CopyIcon, MoveIcon, PencilIcon, RemoveIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import { atomSelectedActionsRowsWithID } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomDetailCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomToggleDeleteActionsModal,
  atomToggleDuplicateActionsModal,
  atomToggleEditActionsModal,
  atomToggleMoveActionsModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ECampaignsTypeOptions, IActionIcon } from '@/modules/CampaignsModule/interfaces/Campaigns';

const ActionBarButtons = () => {
  const { t } = useTranslation();

  const setToggleMoveActionsModal = useSetAtom(atomToggleMoveActionsModal);
  const setToggleDuplicateActionsModal = useSetAtom(atomToggleDuplicateActionsModal);
  const setToggleDeleteActionsModal = useSetAtom(atomToggleDeleteActionsModal);

  const selectedActionsRowsWithID = useAtomValue(atomSelectedActionsRowsWithID);
  const detailCampaign = useAtomValue(atomDetailCampaign);

  const setToggleEditActionsModal = useSetAtom(atomToggleEditActionsModal);

  const handleDuplicateAction = useCallback(() => {
    setToggleDuplicateActionsModal(true);
  }, [setToggleDuplicateActionsModal]);

  const hasMultipleSelectedRows = selectedActionsRowsWithID.length > 1;
  const isAutomaticCampaign = detailCampaign?.campaignTypeId === ECampaignsTypeOptions.AUTOMATIC;

  const ICONS_LIST_ACTIONS: IActionIcon[] = [
    {
      icon: <PencilIcon />,
      onClick: () => {
        setToggleEditActionsModal(true);
      },
      tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipEdit'),
      hide: selectedActionsRowsWithID.length > 1,
      id: 'edit-campaign-action',
    },
    {
      icon: <MoveIcon />,
      onClick: () => {
        setToggleMoveActionsModal(true);
      },
      tooltip:
        detailCampaign?.campaignTypeId === ECampaignsTypeOptions.MANUAL
          ? t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipMove')
          : t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipMoveAutomatic'),
      hide: false,
      id: 'move-campaign-action',
    },
    {
      icon: <CopyIcon />,
      onClick: handleDuplicateAction,
      tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDuplicate'),
      hide: hasMultipleSelectedRows || isAutomaticCampaign,
      id: 'duplicate-campaign-action',
    },
    {
      icon: <RemoveIcon />,
      onClick: () => {
        setToggleDeleteActionsModal(true);
      },
      tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDelete'),
      hide: false,
      id: 'delete-campaign-action',
    },
  ];

  return (
    <Flex alignment="start" className="bg-emblue-primary">
      <Flex alignment="start" className="px-4">
        <div className="min-w-[120px]">
          <Text color="white" variant="text-sm">
            {selectedActionsRowsWithID.length > 1
              ? `${selectedActionsRowsWithID.length} ${t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.selected')}`
              : `${selectedActionsRowsWithID.length} ${t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.select')}`}
          </Text>
        </div>
        <Flex withGap alignment="start" gapSize="small">
          {ICONS_LIST_ACTIONS.map((element) => {
            const { icon, hide, tooltip, onClick: handleOnClick, id } = element;
            return (
              <>
                {!hide && (
                  <div key={id}>
                    <Button
                      fullWidth
                      outline
                      standard
                      iconLeft={icon}
                      id={id}
                      tooltip={tooltip}
                      onClick={handleOnClick}
                    />
                  </div>
                )}
              </>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default memo(ActionBarButtons);
