import { FieldValues } from 'react-hook-form';

import { TouchRulesCheckbox } from './TouchRulesCheckbox';

import { IFormProps } from '@/modules/RulesModule/interfaces';

interface TouchRulesProps<T extends FieldValues> {
  form: IFormProps<T>;
  disabled?: boolean;
}

const TouchRules = <T extends FieldValues>({ form, disabled }: TouchRulesProps<T>) => {
  return <TouchRulesCheckbox disabled={disabled} form={form} />;
};

export default TouchRules;
