import { ComponentType, PropsWithChildren } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Container, If } from '@/lib/v2/components';

import { DeprecatedBanner } from '@/src/modules/CampaignsModule/components/DeprecatedBanner';
import { useDeprecatedBanner } from '@/src/modules/CampaignsModule/hooks/useDeprecatedBanner';

const CampaignWithWrapperLayout = (WrappedComponent?: ComponentType<PropsWithChildren<object>>) => {
  const WrapperComponent = (props: PropsWithChildren<object>) => {
    // TODO remove this when the feature flag showDragDropRemovalNotice is removed
    const { showDeprecatedDragDropForPath } = useDeprecatedBanner();
    const { actionType } = useParams();

    return (
      <>
        <If condition={showDeprecatedDragDropForPath && actionType !== 'email-trigger'}>
          <DeprecatedBanner />
        </If>
        {WrappedComponent ? (
          <Container fullHeight>
            <WrappedComponent {...props} />
          </Container>
        ) : (
          <Outlet />
        )}
      </>
    );
  };

  return WrapperComponent;
};

export default CampaignWithWrapperLayout;
