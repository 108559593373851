export const RulesConditionIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <mask height="24" id="mask0_4764_11091" maskUnits="userSpaceOnUse" width="24" x="0" y="0">
        <rect fill="#D9D9D9" height="24" width="24" />
      </mask>
      <g mask="url(#mask0_4764_11091)">
        <path
          d="M4 13V11H11.6L16.6 6H14V4H20V10H18V7.4L12.4 13H4ZM14 20V18H16.6L13.4 14.85L14.85 13.4L18 16.6V14H20V20H14Z"
          fill="#0056B8"
        />
      </g>
    </svg>
  );
};
