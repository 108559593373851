import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Input, Text } from '@/lib/v2/components';

import { useTemplateCreatorsPermissions } from '@/src/modules/CampaignsModule/hooks/useTemplateCreatorsPermissions';
interface IProps {
  onSuccess: (name: string, saveAsEmbluer: boolean) => void;
  onCancel: () => void;
}

const ModalSaveRow = ({ onSuccess, onCancel }: IProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');
  const [saveAsEmbluer, setSaveAsEmbluer] = useState<boolean>(false);
  const isUserCreatorEmail = useTemplateCreatorsPermissions();

  const handleSave = () => {
    onSuccess(name, saveAsEmbluer);
  };
  const handleCancel = () => {
    setName('');
    onCancel();
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => setName(event?.target?.value);

  return (
    <div className="relative inline-block w-full gap-3 px-0">
      <Text>{t('BEEFREE_MODAL_SAVE_ROW.title')}</Text>
      <Input
        placeholder={t('BEEFREE_MODAL_SAVE_ROW.input.placeholder')}
        value={name}
        onChange={handleOnChange}
      />

      {isUserCreatorEmail && (
        <div className="my-4">
          <Checkbox
            checked={saveAsEmbluer}
            id="saveAsEmbluer"
            label={t('BEEFREE_MODAL_SAVE_ROW.saveAsEmblueRow')}
            onChange={(e) => setSaveAsEmbluer(e.target.checked)}
          />
        </div>
      )}

      <div className="flex w-full justify-end gap-4 pt-4">
        <Button outline onClick={handleCancel}>
          {t('BEEFREE_MODAL_SAVE_ROW.cancel')}
        </Button>
        <Button onClick={handleSave}>{t('BEEFREE_MODAL_SAVE_ROW.save')}</Button>
      </div>
    </div>
  );
};
export default ModalSaveRow;
