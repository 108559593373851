import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, If } from '@/lib/v2/components';

import { atomIsLoadingRuleData } from '@/modules/RulesModule/atoms/rules';
import { TRANSLATE_RULES_CREATE_RULES } from '@/modules/RulesModule/constants';
import { useRuleStatus } from '@/modules/RulesModule/hooks/useRuleStatus';

const HeaderActionButtons = ({ onCancel }: { onCancel: () => void }) => {
  const { t } = useTranslation('rules');

  const { isRuleActive } = useRuleStatus();
  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);

  return (
    <>
      <Button outline id="header-action__cancel" size="small" onClick={onCancel}>
        {t(`${TRANSLATE_RULES_CREATE_RULES}.cancel`)}
      </Button>
      <If condition={!isRuleActive}>
        <Button id="header-action__save" isLoading={isLoadingRuleData} size="small" type="submit">
          {t(`${TRANSLATE_RULES_CREATE_RULES}.save`)}
        </Button>
      </If>
    </>
  );
};

export default memo(HeaderActionButtons);
