// cspell:disable
export const confirmMailTranslationKeyError = new Map([
  ['CUENTA_DEMO_EXCEDE_LIMITE', 'SENDING_LIMIT_EXCEEDED'],
  ['SENDING_LIMIT_EXCEEDED', 'SENDING_LIMIT_EXCEEDED'],
  ['DAILY_LIMIT_EXCEEDED', 'DAILY_LIMIT_EXCEEDED'],
  ['BLOCKED_SENDING_HOURS', 'BLOCKED_SENDING_HOURS'],
  ['UNKNOWN', 'UNKNOWN'],
  ['BLOCKED_USER_PERMISSION_TO_CONFIRM_EMAIL', 'BLOCKED_USER_PERMISSION_TO_CONFIRM_EMAIL'],
]);
// cspell:enable
