/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, If, Input, Text } from '@/lib/v2/components';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { readCookie, setCookieWithExpiry } from '@/src/compat/util';

import Header from './Header';

interface GetCodeProps {
  onConfirm: (token: string) => void;
  onResendCode: () => void;
  onChangePhone?: () => void;
  onSkip: () => void;
  canSkip?: boolean;
  isLoading?: boolean;
  hasError: boolean;
}

const waitTime = 60;

const GetCode = ({
  onConfirm,
  onResendCode,
  canSkip,
  onSkip,
  isLoading,
  hasError,
}: GetCodeProps) => {
  const { t } = useTranslation();

  const [token, setToken] = useState('');
  const [seconds, setSeconds] = useState(waitTime);
  const [attempts, setAttempts] = useState(4);

  const handleResendCode = useCallback(() => {
    onResendCode();
    setSeconds(waitTime);
  }, [onResendCode]);

  const handleConfirmCode = useCallback(() => {
    onConfirm(token);
    setAttempts(attempts === 0 ? 0 : attempts - 1);
  }, [attempts, onConfirm, token]);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [seconds]);

  useEffect(() => {
    if (hasError && attempts === 0) {
      setCookieWithExpiry('errorMfa', 'true', 0.04166);
    }
  }, [attempts, hasError]);

  useEffect(() => {
    const errorMfa = readCookie('errorMfa');
    if (errorMfa === 'true') {
      setAttempts(0);
    }
  }, []);

  const hasSixDigits = token.length === 6;

  return (
    <div className="flex w-full flex-col items-center gap-8">
      <Header
        description={
          hasError || attempts === 0
            ? t('AUTH.TWO_AUTHENTICATE.errorDescription', { number: attempts })
            : t('AUTH.TWO_AUTHENTICATE.get_code_description')
        }
        title={
          hasError || attempts === 0
            ? t('AUTH.TWO_AUTHENTICATE.errorTitle')
            : t('AUTH.TWO_AUTHENTICATE.two_step')
        }
      />

      <Input
        error={attempts === 0}
        label={t('AUTH.TWO_AUTHENTICATE.code')}
        message={attempts === 0 ? t('GET_CODE.errorCodeMessage') : ''}
        placeHolder={t('AUTH.TWO_AUTHENTICATE.codePlaceholder')}
        type="number"
        value={token}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value.length <= 6) setToken(e.target.value);
        }}
      />

      <div className="flex w-full flex-col items-center gap-4">
        <Button
          fullWidth
          disabled={!hasSixDigits || attempts === 0}
          isLoading={isLoading}
          onClick={handleConfirmCode}
        >
          {t('AUTH.TWO_AUTHENTICATE.confirm')}
        </Button>
        <div className="flex w-full flex-col items-center gap-2">
          <If condition={attempts !== 0}>
            <Text
              className={seconds > 0 ? 'opacity-50' : ''}
              color="primary"
              fontWeight="medium"
              variant="text"
              onClick={seconds > 0 ? undefined : handleResendCode}
            >
              {t('AUTH.TWO_AUTHENTICATE.resendCode')}
            </Text>
          </If>
          <If condition={seconds > 0 || attempts !== 0}>
            <Text fontWeight="medium" variant="text">
              <Trans
                components={{ blue: <Text as={'span'} color="primary" fontWeight="medium" /> }}
                i18nKey="GET_CODE.reSendMessage"
                values={{ seconds }}
              />
            </Text>
          </If>
          {canSkip && (
            <Button fullWidth onClick={onSkip}>
              {t('AUTH.TWO_AUTHENTICATE.skip')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetCode;
