import { datadogRum } from '@datadog/browser-rum';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export enum AutomationVersion {
  V1 = 'V1',
  V1_5 = 'V1_5',
}

export const useAutomationLogger = () => {
  const service = useService();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logger = async (
    name: string,
    version: AutomationVersion,
    type: 'action' | 'error',
    data: unknown = {}
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { email, companyId } = await service.getUserData();

    if (type === 'action') {
      datadogRum.addAction('automation.' + version + '.' + name, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        email,
        accountId: Number(companyId),
      });
    } else {
      datadogRum.addError('automation.' + version + '.' + name, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        email,
        accountId: Number(companyId),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        error: data,
      });
    }
  };

  return { logger };
};
