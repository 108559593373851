import { useEffect, useState } from 'react';

import { UserData } from '@/src/application/hooks/useUserDataInterface';
import { useVersion } from '@/src/application/hooks/useVersion';
import { getEnv } from '@/src/application/hooks/util/useEnv';
import { parseJwt } from '@/src/compat/util';
import configData from '@/src/config.json';
import { JWT } from '@/src/infrastructure/enum/DeniedPermissions';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';
import { initUserData } from '@/src/presentation/types/models/User.class';

export const useUserData = (): [UserData, Error | undefined] => {
  const env = getEnv();
  const { JWT_DEV_TOKEN } = configData.endpoints[env === 'storybook' ? 'development' : env];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : (window as any).__JWTToken;
  const userPayload = jwtToken ? parseJwt(jwtToken) : {};
  const { version } = useVersion();
  const [user, setUser] = useState<UserData>(initUserData(userPayload) as UserData);
  const [userValue, error] = useEmblue(ServiceMethods.getUserData);

  useEffect(() => {
    if (userValue && version === 'v2') {
      userValue.companyName =
        userPayload.accountName !== undefined && userPayload.accountName !== ''
          ? userPayload.accountName
          : userValue.companyName;
      userValue.agencyName =
        userPayload.agencyName !== undefined && userPayload.agencyName !== ''
          ? userPayload.agencyName
          : userValue.agencyName;
      userValue.companyId =
        userPayload.accountId !== undefined && userPayload.accountId !== ''
          ? userPayload.accountId.toString()
          : userValue.companyId;
      userValue.contractId =
        userPayload.contractId !== undefined && userPayload.contractId !== ''
          ? userPayload.contractId
          : userValue.contractId;
      userValue.email =
        userPayload.email !== undefined && userPayload.email !== ''
          ? userPayload.email
          : userValue.email;
      userValue.name =
        (userPayload.given_name !== undefined && userPayload.given_name !== '') ||
        (userPayload.family_name !== undefined && userPayload.family_name !== '')
          ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            userPayload.given_name + ' ' + userPayload.family_name
          : userValue.name;
      if (userPayload.deniedPermissions !== undefined && userPayload.deniedPermissions !== '') {
        userValue.deniedPermissions = {
          deniedReportsPermissions: userPayload.deniedPermissions.includes(
            JWT.deniedReportsPermissions
          ),
          deniedContactsPermissions: userPayload.deniedPermissions.includes(
            JWT.deniedContactsPermissions
          ),
          deniedCampaignsPermissions: userPayload.deniedPermissions.includes(
            JWT.deniedCampaignsPermissions
          ),
          deniedActionsPermissions: userPayload.deniedPermissions.includes(
            JWT.deniedActionsPermissions
          ),
        };
      }
      userValue.isSSO = user.isSSO;
      userValue.type = user.type;
      userValue.v = user.v;
      userValue.app = user.app;
      setUser(userValue);
    }
  }, [
    userValue,
    setUser,
    version,
    userPayload.accountName,
    userPayload.accountId,
    userPayload.contractId,
    userPayload.email,
    userPayload.given_name,
    userPayload.family_name,
    userPayload.agencyName,
    userPayload.deniedPermissions,
    userPayload.type,
  ]);

  return [user, error];
};
