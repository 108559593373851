import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { IconSvg } from '@/lib/v2/components';
import { InsightCardProps } from '@/lib/v2/examples/InsightCard/InsightCard';
import { DragAndDropIcon } from '@/lib/v2/icons/general';

import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';

const ACTIONS_CARDS = 'CAMPAIGN_ACTIONS_MAIN.CREATE_EMAIL_ACTION.CARD_LIST';

const useRulesEmail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId, actionId, actionType } = useParams();

  const EDITOR_ROUTE = useMemo(
    () => (route: string) => {
      if (campaignId && actionId) {
        return `${CAMPAIGNS_PATH}/${campaignId}/${actionType}/${actionId}/${route}`;
      }
    },
    [campaignId, actionId, actionType]
  );

  const handleCardClick = useCallback(() => {
    const route = EDITOR_ROUTE('editor');
    if (route) {
      navigate(route);
    }
  }, [navigate, EDITOR_ROUTE]);

  const cardList = useMemo<InsightCardProps[]>(
    () => [
      {
        icon: (
          <IconSvg
            height={'80px'}
            svgComponent={<DragAndDropIcon />}
            width={'80px'}
            withPadding="small"
          />
        ),
        description: t(`${ACTIONS_CARDS}.DRAG_AND_DROP.description`),
        title: t(`${ACTIONS_CARDS}.DRAG_AND_DROP.title`),
        isLoading: true,
        onClick: handleCardClick,
        id: 'drag-and-drop-card',
      },
    ],
    [t, handleCardClick]
  );

  return { cardList };
};

export default useRulesEmail;
