import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { IconSvg, If } from '@/lib/v2/components';
import { ModalActionCreation, ModalSingleInput } from '@/lib/v2/examples';
import { CreateActionIcon } from '@/lib/v2/icons/outline';
import { EmailTriggerIcon } from '@/lib/v2/icons/solid';

import { useTabsPanes } from './hooks/useTabsPanes';

import {
  atomActionInputModalIsOpen,
  atomActionTypeWithOptionalRule,
  atomCreateRules,
} from '@/modules/CampaignsModule/atoms/createActionsAtom';
import { useCreateAction } from '@/modules/CampaignsModule/hooks/useCreateAction';
import { ERulesActionType } from '@/modules/CampaignsModule/interfaces/CampaignActions';

type CreateActionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateActionsModal = ({ isOpen, onClose }: CreateActionsModalProps) => {
  const { t } = useTranslation();
  const { id: campaignId } = useParams();
  const actionInputModalIsOpen = useAtomValue(atomActionInputModalIsOpen);
  const [createRules, setCreateRules] = useAtom(atomCreateRules);
  const setActionTypeWithOptionalRule = useSetAtom(atomActionTypeWithOptionalRule);

  const {
    handleValidation,
    handleSubmitData,
    handleCloseModal,
    createActionIsLoading,
    VALIDATION_RULES,
  } = useCreateAction(campaignId ?? '');

  const {
    state: { tabItems, tabPanes },
  } = useTabsPanes({ onClose });

  const handleOnSubmitCreateItem = useCallback(
    (actionName: string) => {
      void handleSubmitData(actionName);
      setActionTypeWithOptionalRule(RESET);
    },
    [handleSubmitData, setActionTypeWithOptionalRule]
  );

  const rootActionPanes = useMemo(() => createRules === ERulesActionType.ALL, [createRules]);

  const handleOnBack = useMemo(() => {
    return !rootActionPanes ? () => setCreateRules(ERulesActionType.ALL) : undefined;
  }, [rootActionPanes, setCreateRules]);

  return (
    <>
      <ModalSingleInput
        disabledUntilOnChange
        id="create-action-modal"
        isLoading={createActionIsLoading}
        isOpen={actionInputModalIsOpen}
        rules={VALIDATION_RULES}
        title={
          rootActionPanes
            ? t('CREATE_ACTIONS_MODAL.title')
            : t('ALL_CARDS_ACTIONS.RULES_TYPES.title')
        }
        onClose={handleCloseModal}
        onSubmit={handleOnSubmitCreateItem}
        onValidateInputFetch={handleValidation}
      />
      <ModalActionCreation
        currentTab={1}
        icon={
          <IconSvg
            rounded
            bgColor="primary"
            height="40px"
            svgComponent={
              <>
                <If condition={rootActionPanes}>
                  <CreateActionIcon />
                </If>
                <If condition={!rootActionPanes}>
                  <EmailTriggerIcon />
                </If>
              </>
            }
            width="40px"
            withPadding="small"
          />
        }
        isOpen={isOpen}
        tabItems={tabItems}
        tabPanes={tabPanes}
        title={
          rootActionPanes
            ? t('CREATE_ACTIONS_MODAL.title')
            : t('ALL_CARDS_ACTIONS.RULES_TYPES.title')
        }
        onBack={handleOnBack}
        onClose={onClose}
      />
    </>
  );
};

export default CreateActionsModal;
